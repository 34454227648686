import React, { useState, useContext, useEffect } from "react";

import PrimaryButton from "components/button/primary-button";
import BookingHeader from "../booking-header";
import ExtraServiceCard from "./extra-service-card";
import TravelPermitCard from "./travel-permit";
import { useTermsData } from "hooks/useTermsData";
import { CreateBookingContext } from "../create-rent-request";
import PaymentTermsModal from "components/Modals/paymentTermsModal";
import { bookingTotalCalculateApi } from "views/examples/_actions";
import { TotalPrice } from "./total-price";
import { SelectedCar } from "../selected-car";
import { SelectedLocation } from "../selected-location";
import AppContext from "context/app.context";

const ExtraServiceContainer = (props) => {
  const [price, setPrice] = useState(null);
  const [counter, setCounter] = useState(0);
  const [travelPermit, setTravelPermit] = useState(0);
  const [cdwelection, setCdwSelection] = useState("0");
  const { data: terms } = useTermsData();

  const { selectedBooking, dispatch } = useContext(AppContext);
  useEffect(() => {
    if (selectedBooking) {
      console.log(
        "🚀 ~ file: extra-service-container.jsx:27 ~ useEffect ~ selectedBooking:",
        selectedBooking
      );
      setCounter(parseInt(selectedBooking.no_of_addtional_driver));
      setBooking({
        ...booking,
        no_of_addtional_driver: parseInt(
          selectedBooking.no_of_addtional_driver
        ),
        travel_permit: selectedBooking.gcc_permit_seleted,
        gcc_permit_seleted: selectedBooking.gcc_permit_seleted,
        cdw_optional: selectedBooking.cdw_optional,
        cdw_selected: selectedBooking.cdw_selected,
      });
      setTravelPermit(parseInt(selectedBooking.gcc_permit_seleted));
      calculateTotalPrice(null, "updateEdit", selectedBooking);
    }
  }, [selectedBooking]);

  const { booking, setBooking, setExtrasSelected, setCarSelected } =
    useContext(CreateBookingContext);

  const extraServiceContinueHandler = () => {
    setExtrasSelected(true);
  };

  const counterChangeHandler = (counter) => {
    setCounter(counter);
    calculateTotalPrice(counter);
    setBooking({ ...booking, no_of_addtional_driver: counter });
  };

  const travelPermitHandler = (arg) => {
    setTravelPermit(arg);
    calculateTotalPrice(arg, "tp");
    setBooking({ ...booking, travel_permit: arg, gcc_permit_seleted: arg });
  };

  const cdwSelectionHandler = (arg) => {
    setCdwSelection(arg);
    calculateTotalPrice(arg, "cdw");
    setBooking({ ...booking, cdw_optional: arg, cdw_selected: arg });
  };

  const calculateTotalPrice = async (counter, type, selectedBooking) => {
    var response = {};
    if (type === "tp") {
      response = await bookingTotalCalculateApi({
        fromdate: booking.pickup_date_time,
        todate: booking.dropoff_date_time,
        rate_id: booking.selectedCar?.rat_id,
        no_of_addtional_driver: booking.no_of_addtional_driver,
        pickup: booking?.pickup_station,
        dropoff: booking?.dropoff_station,
        travel_permit: counter,
        cdw_optional: parseInt(
          booking?.cdw_selected ? booking?.cdw_selected : 0
        ),
      });
    } else if (type === "cdw") {
      response = await bookingTotalCalculateApi({
        fromdate: booking.pickup_date_time,
        todate: booking.dropoff_date_time,
        rate_id: booking.selectedCar?.rat_id,
        no_of_addtional_driver: booking.no_of_addtional_driver,
        pickup: booking?.pickup_station,
        dropoff: booking?.dropoff_station,
        travel_permit: travelPermit,
        cdw_optional: counter,
      });
    } else if (type === "updateEdit") {
      response = await bookingTotalCalculateApi({
        fromdate: booking.pickup_date_time,
        todate: booking.dropoff_date_time,
        rate_id: booking.selectedCar?.rat_id,
        no_of_addtional_driver: parseInt(
          selectedBooking.no_of_addtional_driver
        ),
        pickup: booking?.pickup_station,
        dropoff: booking?.dropoff_station,
        travel_permit: parseInt(selectedBooking.gcc_permit_seleted),
        cdw_optional: parseInt(selectedBooking.cdw_selected),
      });
    } else {
      response = await bookingTotalCalculateApi({
        fromdate: booking.pickup_date_time,
        todate: booking.dropoff_date_time,
        rate_id: booking.selectedCar?.rat_id,
        no_of_addtional_driver: counter,
        pickup: booking?.pickup_station,
        dropoff: booking?.dropoff_station,
        travel_permit: travelPermit,
        cdw_optional: parseInt(
          booking?.cdw_selected ? booking?.cdw_selected : 0
        ),
      });
    }
    if (response?.data?.data && response?.data?.data.length > 0) {
      setPrice(response?.data?.data[0]);
    }
  };

  if (booking && !price) {
    if (booking.selectedCar.cdw_optional) {
      booking.cdw_optional = parseInt(booking.selectedCar.cdw_optional);
      booking.cdw_selected = parseInt(booking.selectedCar.cdw_optional);
    }
    if (parseInt(booking?.rat_cdw_optional) === 0 && booking.selectedCar)
      booking.cdw_selected = 1;
    calculateTotalPrice(counter);
  }

  return (
    <>
      <div
        style={{
          marginLeft: "100px",
          marginTop: "50px",
          marginRight: "50px",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginBottom: "30px",
        }}
      >
        <div className="row">
          <BookingHeader
            activeStep={2}
            backClicked={() => {
              setCarSelected(false);
            }}
            showContinue={true}
            continueClicked={extraServiceContinueHandler}
          />
        </div>
        <div className="row">
          <div className="col-12">
            {booking && <SelectedCar car={booking.selectedCar} />}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {booking && <SelectedLocation booking={booking} />}
          </div>
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-4">
            <ExtraServiceCard
              title="Additional Driver"
              description={booking?.rat_addtional_driver_text}
              price={price?.additional_driver_applied}
              showCounter="true"
              counterChanged={counterChangeHandler}
              driverCount={counter}
            />
          </div>
          <div className="col-4">
            <TravelPermitCard
              title="Travel Permit"
              travelPermit={travelPermitHandler}
              selected={booking?.travel_permit ? booking?.travel_permit : "0"}
            />
          </div>
          <div className="col-4">
            <ExtraServiceCard
              title="Collision Damage Waiver"
              description={booking?.rat_cdw_text}
              price={booking?.cdw_daily}
              cdwOptional={booking?.rat_cdw_optional}
              cdwSelection={cdwSelectionHandler}
              cdwSelected={booking?.cdw_selected}
              // counterChanged={travelPermitHandler}
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <ul className="details-wrapper" style={{ paddingTop: 20 }}>
              <div className="row col-12 font-24" style={{ marginLeft: -40 }}>
                Terms and Conditions
              </div>
              {terms &&
                terms.length > 0 &&
                terms.map((term, index) => {
                  return (
                    <li style={{ marginBottom: "10px" }} key={index}>
                      <p>{term}</p>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <TotalPrice price={price} />
          </div>
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          <div
            className="col-12"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
              }}
            >
              <PrimaryButton
                title="Previous"
                iconName="fa-arrow-left"
                iconPosition="left"
                withIcon="true"
                onClick={() => setCarSelected(false)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <PrimaryButton
                title="Continue"
                iconName="fa-arrow-right"
                iconPosition="right"
                withIcon="true"
                onClick={extraServiceContinueHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtraServiceContainer;
