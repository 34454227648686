import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";

const AddRoleModal = (props) => {
  const [roleName, setRoleName] = useState("");
  const [roleNameAr, setRoleNameAr] = useState("");
  const [roleNameErr, setRoleNameErr] = useState(false);
  const [roleNameArErr, setRoleNameArErr] = useState(false);

  const handleValidation = () => {
    let isValid = true;
    if (!roleName) {
      isValid = false;
      setRoleNameErr(true);
    }
    if (!roleNameAr) {
      isValid = false;
      setRoleNameArErr(true);
    }
    return isValid;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      let obj = {
        role_name: roleName,
        role_name_ar: roleNameAr,
      };
      props?.onCloseModal(obj);
    }
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Add Role</label>
      </div>
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="roleName">
                  Role Name<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="roleName"
                  id="roleName"
                  placeholder="Role Name"
                  value={roleName}
                  onChange={(ev) => setRoleName(ev.target.value)}
                  className={`${!roleName && roleNameErr && "on-focus"}`}
                />
                {!roleName && roleNameErr && (
                  <div className="error-text">Role Name is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="roleNameAr">
                  Role Name (Arabic)<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="roleNameAr"
                  id="roleNameAr"
                  placeholder="Role Name (Arabic)"
                  value={roleNameAr}
                  onChange={(ev) => setRoleNameAr(ev.target.value)}
                  className={`${!roleNameAr && roleNameArErr && "on-focus"}`}
                  style={{ textAlign: "right" }}
                />
                {!roleNameAr && roleNameArErr && (
                  <div className="error-text">
                    Role Name (Arabic) is required
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddRoleModal;
