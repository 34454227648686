const SelectedCarField = ({ label, value }) => {
  return (
    <>
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div>{label}</div>
        <div>:</div>
        <div>{value}</div>
      </div>
    </>
  );
};
export const SelectedCar = ({ car }) => {
  return (
    <>
      {car && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <h2 style={{ borderBottom: "2px solid #ddd" }}>Selected Car </h2>

          <div>
            <h3>{car.rat_vehicle_desc}</h3>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SelectedCarField label="Category" value={car.rat_fleet_desc} />
            <SelectedCarField label="Body Type" value={car.rat_bdy_desc} />
            <SelectedCarField label="Gear Type" value={car.gear_type} />
            <SelectedCarField
              label="Rate"
              value={`${car.rate_daily}/${car.rate_monthly} (Daily/Monthly)`}
            />
            <SelectedCarField label="Fuel" value={car.rat_fuel} />
          </div>
        </div>
      )}
    </>
  );
};
