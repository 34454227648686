import React, { useState, useContext, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Button, Form, FormGroup, Input, InputGroup, Label } from "reactstrap";
import { toast } from "react-toastify";

import DateTimePicker from "components/date-picker/date-time-picker";
import { useRentalStationsData } from "hooks/useRentalStationsData";

import { CreateBookingContext } from "./create-rent-request";
import { formatDate } from "utils/dateUtils";
import { add, Duration, isAfter } from "date-fns";
import AppContext from "context/app.context";

const pickuptimeDuration = {
  hours: 24,
  minutes: 5,
  seconds: 0,
};

const dropofftimeDuration = {
  hours: 48,
  minutes: 5,
  seconds: 0,
};

const nextDayDuration = {
  hours: 24,
  minutes: 0,
  seconds: 0,
};

const CreateRentRequestLocationSelector = (props) => {
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [startDate, setStartDate] = useState(
    add(new Date(), pickuptimeDuration)
  );
  const [pickupAnywhere, setPickupAnywhere] = useState(false);
  const [dropoffAnywhere, setDropoffAnywhere] = useState(false);

  const [pickupDate, setPickupDate] = useState(
    add(new Date(), pickuptimeDuration)
  );
  const [dropoffDate, setDropoffDate] = useState(
    add(new Date(), dropofftimeDuration)
  );

  const { selectedBooking, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (selectedBooking) {
      setPickupLocation(selectedBooking.pickupStation_loc_id);
      setPickupDate(selectedBooking.pickup_date_and_time);
      setDropoffLocation(selectedBooking.dropoffStation_loc_id);
      setDropoffDate(selectedBooking.dropoff_date_and_time);
    }
  }, [selectedBooking]);

  const { booking, setBooking, setLocationSelected } =
    useContext(CreateBookingContext);

  const { data: locations } = useRentalStationsData();

  const submitBtnHandler = (e) => {
    setBooking({
      ...booking,
      pickup_station: pickupLocation,
      dropoff_station: dropoffLocation,
      pickup_date_time: formatDate(pickupDate),
      dropoff_date_time: formatDate(dropoffDate),
    });

    setLocationSelected(true);
  };

  const pickupLocationChangeHandler = (event) => {
    setPickupLocation(event.target.value);
  };

  const pickupDateChangeHandler = (event) => {
    if (event && event.length > 0) {
      let date = new Date(event[0]);
      // if (isAfter(date, startDate)) {
      setPickupDate(event[0]);
      // } else {
      //   toast.error("Please select a future date");
      //   setPickupDate(startDate);
      // }
    }
  };

  const dropoffLocationChangeHandler = (event) => {
    setDropoffLocation(event.target.value);
  };

  const dropoffDateChangeHandler = (event) => {
    if (event && event.length > 0) {
      let date = new Date(event[0]);
      let dateDifferance =
        (new Date(event[0])?.getTime() - new Date(pickupDate)?.getTime()) /
        3600000;
      console.log(dateDifferance);
      if (dateDifferance > 24) {
        setDropoffDate(event[0]);
      } else {
        // toast.error("Please select a future date");
        setDropoffDate(add(new Date(pickupDate), nextDayDuration));
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "100px",
      }}
    >
      <div style={{ flex: "1" }}>
        <Form>
          <div className="row">
            <div className="col text-center">
              <h3 className="mb-0 color-primary">Pick-Up</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Label for="location">Location</Label>

                <Input
                  type="select"
                  name="location"
                  id="location"
                  onChange={pickupLocationChangeHandler}
                  value={pickupLocation}
                  style={{ paddingRight: "5px" }}
                >
                  <option value={null}>Select Location</option>
                  {locations?.map((location) => {
                    return (
                      <option key={location.loc_id} value={location.loc_id}>
                        {location.loc_desc}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <div className="form-group">
                <input
                  type="checkbox"
                  checked={pickupAnywhere}
                  onChange={() => setPickupAnywhere((oldData) => !oldData)}
                  id="insurance"
                  value="insurance"
                  className="mr-1"
                />
                <label className="ml-1" htmlFor="insurance">
                  Anywhere in the region.
                </label>
              </div>
            </div>
            <div className="col-12">
              <FormGroup>
                <Label for="prefDate">Date / Time</Label>
                <InputGroup size="md">
                  <DateTimePicker
                    dateTime={pickupDate}
                    enableTime={true}
                    minDate={
                      selectedBooking ? null : add(new Date(), nextDayDuration)
                    }
                    // maxDate={selectedBooking ? null : dropoffDate}
                    format="Y-m-d H:i"
                    is24HourFormat="true"
                    onDateTimeSelect={(selectedDateTime) => {
                      console.log(dropoffDate);
                      console.log(selectedDateTime[0]);
                      let dateDifferance =
                        (new Date(dropoffDate)?.getTime() -
                          selectedDateTime[0]?.getTime()) /
                        3600000;

                      if (dateDifferance < 24)
                        setDropoffDate(
                          add(new Date(selectedDateTime[0]), nextDayDuration)
                        );

                      pickupDateChangeHandler(selectedDateTime);
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col text-center">
              <h3 className="mb-0 color-primary">Drop-Off</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Label for="location">Location</Label>

                <Input
                  type="select"
                  name="location"
                  id="location"
                  onChange={dropoffLocationChangeHandler}
                  value={dropoffLocation}
                >
                  <option value={null}>Select Location</option>
                  {locations?.map((location) => {
                    return (
                      <option key={location.loc_id} value={location.loc_id}>
                        {location.loc_desc}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <div className="form-group">
                <input
                  type="checkbox"
                  checked={dropoffAnywhere}
                  onChange={() => setDropoffAnywhere((oldData) => !oldData)}
                  id="insurance"
                  value="insurance"
                  className="mr-1"
                />
                <label className="ml-1" htmlFor="insurance">
                  Anywhere in the region.
                </label>
              </div>
            </div>
            <div className="col-12">
              <FormGroup>
                <Label for="prefDate">Date / Time</Label>
                <InputGroup size="md">
                  <DateTimePicker
                    dateTime={dropoffDate}
                    enableTime={true}
                    minDate={
                      selectedBooking
                        ? add(new Date(pickupDate), nextDayDuration)
                        : add(new Date(pickupDate), nextDayDuration)
                    }
                    format="Y-m-d H:i"
                    is24HourFormat="true"
                    onDateTimeSelect={(selectedDateTime) => {
                      dropoffDateChangeHandler(selectedDateTime);
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </div>
          </div>
          <div className="d-flex justify-content-center pt-4">
            <Button color="btn primary-bg" onClick={submitBtnHandler}>
              Choose Your Car
            </Button>
          </div>
        </Form>
      </div>
      <div style={{ flex: "1" }}>
        <img
          src="https://ebooking.avisksa.com/images/header-image.jpg"
          alt=""
        ></img>
      </div>
    </div>
  );
};

export default CreateRentRequestLocationSelector;
