import TaskContent from "../../template/taskContent";
import React from "react";
import { Card, CardBody } from "reactstrap";

const TaskCard = (props) => {
  const {
    authorization = null,
    feedback = null,
    notification = null,
    data,
  } = props;

  return (
    <Card className="dashboard-card-own task-card">
      <CardBody>
        {data ? (
          <div className="task-card-wrapper">
            {authorization && (
              <TaskContent
                count={data?.authorization_count}
                label={data?.authorization_label}
                pathname="/admin/authorization_management"
                searchId="aut_status"
                searchFilter="1"
                type="Authorization"
                {...props}
              />
            )}
            {feedback && (
              <TaskContent
                count={data?.feedback_count}
                label={data?.feedback_label}
                pathname="/admin/general_feedback"
                searchId="com_status"
                type="Feedback"
                searchFilter="1"
                {...props}
              />
            )}
            {notification && (
              <TaskContent
                count={data?.notification_count}
                label={data?.notification_label}
                pathname="/admin/notification_management"
                type="Notification"
                {...props}
              />
            )}
            {!authorization && !feedback && !notification && (
              <div className="pt-5 color-primary d-flex justify-content-center align-items-center">
                Data Not Available
              </div>
            )}
          </div>
        ) : (
          <div className="pt-5 color-primary d-flex justify-content-center align-items-center">
            Data Not Available
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default TaskCard;
