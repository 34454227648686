/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { logOut } from "_shared/commonFunctions";
import { connect } from "react-redux";
import { toast } from "react-toastify";

var ps;

const Sidebar = (props) => {
  const { bgColor, routes, logo, userMenu, userRights } = props;
  const [collapseOpen, setCollapseOpen] = useState(false);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    let sidebarMenu = [];
    routes?.forEach((route) => {
      userMenu?.forEach((menu) => {
        if (menu?.menu_type_id === route?.module_type) sidebarMenu.push(route);
      });
    });
    sidebarMenu.unshift(routes[0]);
    sidebarMenu.push(routes[routes.length - 2]);
    return sidebarMenu.map((prop, key) => {
      if (prop.name === "Login" || prop?.name === "User Profile") {
        return null;
      } else {
        return (
          <li
            key={key}
            className={`navitem-own ${
              props?.history.location.pathname === `/admin${prop.path}`
                ? "active"
                : ""
            }`}
            onClick={() => {
              if (prop.path === "/") {
                logOut(props?.history, "/auth/login");
                toast.success("Logged Out");
              } else {
                props?.history.push(`/admin${prop.path}`);
              }
            }}
          >
            <a
              className={`navlink-own `}
              // to={prop.layout + prop.path}
              // tag={NavLinkRRD}
              onClick={closeCollapse}
              // activeClassName="active"
            >
              <img
                className="navicon pb-3"
                alt={prop?.alt}
                src={prop?.imgSrc}
              />
              <div className="item-name">{prop.name}</div>
            </a>
          </li>
        );
      }
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white navbar-own"
      expand="sm"
      id="sidenav-main"
    >
      <Container fluid>
        <Nav navbar className="">
          {createLinks(routes)}
        </Nav>
        {/* </Collapse> */}
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};
Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateProps = ({ common }) => ({
  userMenu: common?.roleData?.user_menu,
  userRights: common?.roleData?.user_rights,
});

export default connect(mapStateProps)(Sidebar);
