import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";

import UserHeader from "../../components/Headers/userHeader";
import AddUserModal from "../../components/Modals/addUserModal";
import ViewImageModal from "../../components/Modals/viewImageModal";
import UserTable from "../../components/tables/userTable";
import ConfirmModal from "../../components/Modals/confirmModal";
import { globalLoader } from "actions/commonActions";
import {
	createUserApi,
	updateUserApi,
	updateStatusApi,
	profileDetailsApi,
} from "./_actions";
import StatusUpdateModal from "components/Modals/statusUpdateModal";
import ViewModal from "components/Modals/viewModal";
import { profileApiData } from "actions/commonActions";

// hooks
import { useUserData } from "hooks/useUserData";
import { useDesignationsUserData } from "hooks/useDesignationsData";
import { useCompaniesData } from "hooks/useCompaniesData";
import { useRolesData } from "hooks/useRolesData";
import { useUsageData } from "hooks/useUsageData";
import { useStatusData } from "hooks/useStatusData";

const UserList = (props) => {
	const { userRights, currentCompany } = props;

	const [openUserModal, setOpenUserModal] = useState(false);
	const [statusUpdate, setStatusUpdate] = useState(false);
	const [formData, setFormData] = useState(null);
	const [type, setType] = useState(null);
	const [openViewModal, setOpenViewModal] = useState(false);
	const [openImageModal, setOpenImageModal] = useState(false);
	const [imageData, setImageData] = useState(null);
	const [imgType, setImgType] = useState("");
	const [isEdit, setIsEdit] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [emailList, setEmailList] = useState([]);
	const [canCreate, setCanCreate] = useState(true);
	const [canEdit, setCanEdit] = useState(false);

	useEffect(() => {
		userRights?.forEach((item) => {
			if (item?.menu_type_id === "module_1") {
				if (item?.create === "1") {
					setCanCreate(true);
				}
				if (item?.update === "1") {
					setCanEdit(true);
				}
			}
		});
	}, [userRights]);

	useEffect(() => {
		refetch();
	}, [currentCompany]);

	const { data: companies } = useCompaniesData();

	const { data: roles } = useRolesData();

	const { data: usage } = useUsageData();

	const { data: status } = useStatusData(17);

	const { data: designationsList } = useDesignationsUserData();

	let {
		data: users,
		isFetched,
		refetch,
		isFetching,
	} = useUserData(currentCompany);

	globalLoader(isFetching);
	useEffect(() => {
		if (users && users.length > 0) {
			let temp = users?.map((arr) => arr?.email);
			setEmailList(temp);
		}
	}, [users]);

	const userCreateHandler = async (obj = null) => {
		if (obj) {
			globalLoader(true);
			try {
				let res;
				if (isEdit) {
					res = await updateUserApi(obj);
				} else {
					res = await createUserApi(obj);
				}
				if (res?.data?.status === 0) {
					globalLoader(false);
					refetch();
					toast.success(res?.data?.message);
					if (isEdit) {
						try {
							let res = await profileDetailsApi();
							if (res?.data?.status === 0) {
								profileApiData(res?.data?.data[0]);
							} else {
							}
						} catch (err) {
							if (err?.response?.status === 401) {
								toast.error("Error while fetching profile details");
							}
						}
					}
				} else {
					console.log(res);
					toast.error("Update unsuccessful. Please try again");
				}
			} catch (err) {
				globalLoader(false);
			}
		}
		setIsEdit(false);
		setOpenUserModal(false);
		setFormData(null);
	};

	const openImage = (val, type) => {
		let imgArr = [{ img_url: val }];
		setImageData(imgArr);
		setImgType(type);
		setOpenImageModal(true);
	};

	const closeImageModal = () => {
		setImgType(null);
		setImageData(null);
		setOpenImageModal(false);
	};

	const editUser = (obj) => {
		console.log("🚀 ~ file: userList.jsx:151 ~ editUser ~ obj", obj);
		setFormData(obj);
		setIsEdit(true);
		setOpenUserModal(true);
	};

	const deleteUser = (obj) => {
		setFormData(obj);
		setConfirmModal(true);
	};

	const confirmation = (obj) => {
		if (obj) {
		}
		setConfirmModal(false);
		setFormData(null);
	};

	const openUpdateStatus = (obj) => {
		setFormData(obj);
		setStatusUpdate(true);
	};

	const updateStatusHandler = async (status) => {
		if (status) {
			let obj = {
				type: "17",
				status: status,
				id: formData?.user_id,
			};

			globalLoader(true);
			try {
				let res = await updateStatusApi(obj);
				if (res?.data?.status === 0) {
					toast.success(res?.data?.message);
				} else {
				}
				refetch();
			} catch (err) {
				globalLoader(false);
			}
		}
		setStatusUpdate(false);
		setFormData(null);
	};

	const viewCompany = (data, type) => {
		setFormData(data);
		setType(type);
		setOpenViewModal(true);
	};

	const closeViewCompany = () => {
		setFormData(null);
		setType(null);
		setOpenViewModal(false);
	};

	return (
		<>
			<UserHeader />

			<Container className="mt--8" fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-0">
								<Row className="align-items-center">
									<h3 className="mb-0 color-primary">Users</h3>
									{canCreate && (
										<div className="col text-right">
											<button
												className="btn btn-sm primary-bg"
												onClick={() => setOpenUserModal(true)}
											>
												Add User
											</button>
										</div>
									)}
								</Row>
							</CardHeader>

							{!isFetching && (
								<div>
									{isFetched &&
										(users && users.length > 0 ? (
											<UserTable
												tableData={users}
												statusList={status}
												designationList={designationsList}
												companyList={companies}
												canEdit={canEdit}
												updateStatus={openUpdateStatus}
												editUser={editUser}
												deleteUser={deleteUser}
												openImage={openImage}
												viewCompany={viewCompany}
											/>
										) : (
											<div className="px-3 pt-3 pb-3">
												<div className="no-data-available color-primary">
													Data Not Available
												</div>
											</div>
										))}
								</div>
							)}
						</Card>
					</div>
				</Row>
			</Container>

			{openUserModal && (
				<AddUserModal
					open={openUserModal}
					isEdit={isEdit}
					formData={formData}
					designationList={designationsList}
					companyList={companies}
					roleList={roles}
					usageList={usage}
					emailList={emailList}
					onCloseModal={userCreateHandler}
				/>
			)}

			{statusUpdate && (
				<StatusUpdateModal
					open={statusUpdate}
					statusList={status}
					formData={formData?.user_status}
					onCloseModal={updateStatusHandler}
				/>
			)}

			{openImageModal && (
				<ViewImageModal
					open={openImageModal}
					onCloseModal={closeImageModal}
					data={imageData}
					imgType={imgType}
				/>
			)}

			{confirmModal && (
				<ConfirmModal
					open={confirmModal}
					onCloseModal={confirmation}
					id={formData?.user_id}
					name={`${formData?.first_name} ${formData?.last_name}`}
					type="User"
				/>
			)}
			{openViewModal && (
				<ViewModal
					open={openViewModal}
					onCloseModal={closeViewCompany}
					data={formData}
					type={type}
				/>
			)}
		</>
	);
};

const mapStateProps = (state) => ({
	currentCompany: state?.common?.currentCompany?.value,
	userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(UserList);
