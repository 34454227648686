import React, { useState, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import Flatpickr from "react-flatpickr";

const AddReportAccidentModal = (props) => {
  const docRef = useRef();
  const imgRef = useRef();
  const [plate, setPlate] = useState("");
  const [datetime, setDatetime] = useState(null);
  const [location, setLocation] = useState("");
  const [docs, setDocs] = useState([]);
  const [images, setImages] = useState([]);
  const [details, setDetails] = useState("");
  const [towed, setTowed] = useState("");
  const [replace, setReplace] = useState("");
  const [plateErr, setPlateErr] = useState(false);
  const [datetimeErr, setDatetimeErr] = useState(false);
  const [locationErr, setLocationErr] = useState(false);

  const handleValidation = () => {
    let isValid = true;
    if (!plate) {
      isValid = false;
      setPlateErr(true);
    }
    if (!datetime) {
      isValid = false;
      setDatetimeErr(true);
    }
    if (!location) {
      isValid = false;
      setLocationErr(true);
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = {
        plate: plate,
        datetime: new Date(datetime),
        location: location,
        docs: docs,
        images: images,
        details: details,
        towed: towed,
        replace: replace,
      };
      return props?.onCloseModal(obj);
    }
  };

  const handleDocUpload = (ev) => {
    let file = ev.target.files;
    let obj = [];
    for (let i = 0; i < file.length; i++) {
      let val = {
        doc_name: file[i].name,
        doc_url: URL.createObjectURL(file[i]),
      };
      obj.push(val);
    }
    setDocs(obj);
  };

  const handleImgUpload = (ev) => {
    let file = ev.target.files;
    let obj = [];
    for (let i = 0; i < file.length; i++) {
      let val = {
        img_datetime: file[i].lastModifiedDate,
        img_id: Math.floor(Math.random() * 1000),
        img_name: file[i].name,
        img_type: "Damage",
        img_type_id: Math.floor(Math.random() * 1000),
        img_url: URL.createObjectURL(file[i]),
      };
      obj.push(val);
    }
    setImages(obj);
  };
  return (
    <>
      <Modal
        open={props?.open}
        center
        onClose={() => props?.onCloseModal()}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
      >
        <div className="px-3 pt-5">
          <Form>
            <div className="row">
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="plate">
                    Plate Number <span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="plate"
                    placeholder="Plate Number"
                    value={plate}
                    onChange={(ev) => setPlate(ev.target.value)}
                  />
                  {!plate && plateErr && (
                    <div className="error-text">Plate Number is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="datetime">
                    Date & Time <span className="color-primary">*</span>
                  </Label>
                  {/* <Datetime
                    isValidDate={valid}
                    className="datetime-picker-own"
                    inputProps={{
                      className: "form-control",
                    }}
                    input
                    dateFormat
                    timeFormat
                    value={datetime ? datetime : new Date()}
                    onChange={(ev) => setDatetime(ev)}
                  /> */}
                  <Flatpickr
                    className="form-control datepicker-own"
                    options={{
                      minDate: "today",
                      altInput: true,
                      dateFormat: "Y-m-d H:i",
                      enableTime: true,
                    }}
                    onChange={(date) => setDatetime(date)}
                    value={datetime !== null && datetime}
                  />

                  {!datetime && datetimeErr && (
                    <div className="error-text">Date & Time is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="location">
                    Location <span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="location"
                    placeholder="Location"
                    value={location}
                    onChange={(ev) => setLocation(ev.target.value)}
                  />
                  {!location && locationErr && (
                    <div className="error-text">Location is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="docs">
                    Upload Police Report (pdf/jpeg/jpg/png)
                  </Label>
                  <div className="file-upload-wrapper">
                    <input
                      ref={docRef}
                      type="file"
                      id="docs"
                      className="form-own-input"
                      accept=".pdf,.jpeg,.jpg,.png"
                      onChange={handleDocUpload}
                      multiple
                    />
                    <input
                      type="text"
                      name="docs_name"
                      id="docs_name"
                      className="form-own-input-filename form-control"
                      value={
                        docs.length > 0 ? docs.map((obj) => obj.doc_name) : ""
                      }
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        docRef.current.click();
                      }}
                    >
                      {docs.length > 0 ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="images">Images (jpeg/jpg/png)</Label>
                  <div className="file-upload-wrapper">
                    <input
                      ref={imgRef}
                      type="file"
                      id="images"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg"
                      onChange={handleImgUpload}
                      multiple
                    />
                    <input
                      type="text"
                      name="images_name"
                      id="images_name"
                      className="form-own-input-filename form-control"
                      value={
                        images.length > 0
                          ? images.map((obj) => obj.img_name)
                          : ""
                      }
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        imgRef.current.click();
                      }}
                    >
                      {images.length > 0 ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="details">
                    Accident Details{" "}
                    <span className="error-text pl-2">{`${
                      199 - details.length
                    } characters left`}</span>
                  </Label>
                  <Input
                    type="text"
                    id="details"
                    placeholder="Accident Details"
                    value={details}
                    onChange={(ev) => setDetails(ev.target.value)}
                    maxLength={199}
                  />
                  {details && details.length === 199 && (
                    <div className="error-text">
                      Cannot exceed 199 characters
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="towed">Car to be Towed</Label>
                  <Input
                    type="select"
                    id="towed"
                    placeholder="Car to be Towed"
                    value={towed}
                    onChange={(ev) => setTowed(ev.target.value)}
                  >
                    <option>Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </Input>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="replace">Require Replacement</Label>
                  <Input
                    type="select"
                    id="replace"
                    placeholder="Require Replacement"
                    value={replace}
                    onChange={(ev) => setReplace(ev.target.value)}
                  >
                    <option>Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </Input>
                </div>
              </div>
              <div className="d-flex justify-content-center pt-4 col-12">
                <button
                  className="btn grey-bg"
                  onClick={() => props?.onCloseModal()}
                >
                  Cancel
                </button>
                <button className="btn primary-bg" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddReportAccidentModal;
