import React, { useEffect, useRef, useState } from "react";

import CryptoJs from "crypto-js";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import "react-responsive-modal/styles.css";

import { validEmail } from "_shared/commonFunctions";
import { validNumber } from "_shared/commonFunctions";
import { toBase64 } from "actions/commonActions";

import { useRegionsData } from "hooks/useRegionData";

const AddUserModal = (props) => {
  const idRef = useRef();
  const profileRef = useRef();
  const [first_name, setFirst_name] = useState("");
  const [first_nameErr, setFirst_nameErr] = useState(false);
  const [last_name, setLast_name] = useState("");
  const [last_nameErr, setLast_nameErr] = useState(false);
  const [first_name_arabic, setFirst_name_arabic] = useState("");
  const [last_name_arabic, setLast_name_arabic] = useState("");
  const [pword, setPword] = useState("");
  const [pwordErr, setPwordErr] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState(false);
  const [national_id, setNational_id] = useState("");
  const [national_idErr, setNational_idErr] = useState(false);
  const [id_proof_image, setId_proof_image] = useState("");
  const [id_proof_image_type, setId_proof_image_type] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [sameEmail, setSameEmail] = useState(false);
  const [profile_image, setProfile_image] = useState("");
  const [profile_image_type, setProfile_image_type] = useState("");
  const [designation_code, setDesignation_code] = useState("");
  const [designation_codeErr, setDesignation_codeErr] = useState(false);
  const [company_code, setCompany_code] = useState([]);
  const [company_codeErr, setCompany_codeErr] = useState(false);
  const [role_code, setRole_code] = useState([]);
  const [role_codeErr, setRole_codeErr] = useState(false);
  const animatedComponents = makeAnimated();
  const [idUrl, setIdUrl] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [toggle, setToggle] = useState(true);
  const [originalEmail, setOriginalEmail] = useState(null);
  const [idFileName, setIdFileName] = useState("");
  const [profileFileName, setProfileFileName] = useState("");
  const [usageType, setUsageType] = useState([]);
  const [usageTypeErr, setUsageTypeErr] = useState(false);
  const [regionError, setRegionError] = useState(false);
  const totalSize = 1024000;

  const [selectedRegions, setSelectedRegions] = useState([]);

  useEffect(() => {
    setSelectedRegions([]);
    if (props?.isEdit) {
      setPropsToData();
    }
    // eslint-disable-next-line
  }, []);

  const findSelectedRegions = () => {
    const selectedRegionsTemp = [];

    const userRegions = props?.formData?.user_region;
    const userRegionArray = userRegions?.split("");

    userRegionArray?.forEach((userRegion) => {
      regions?.forEach((region) => {
        if (userRegion === region?.value) {
          selectedRegionsTemp.push(region);
        }
      });
    });

    setSelectedRegions(selectedRegionsTemp);
  };

  const { data: regions } = useRegionsData();

  useEffect(() => {
    if (regions && regions.length > 0) {
      findSelectedRegions();
    }
  }, [regions]);

  const setPropsToData = () => {
    if (props?.formData) {
      let img;
      let arr1, arr2;
      let tempArr = [];
      setFirst_name(props?.formData?.first_name);
      setLast_name(props?.formData?.last_name);
      setFirst_name_arabic(props?.formData?.first_name_a);
      setLast_name_arabic(props?.formData?.last_name_a);
      setMobile(props?.formData?.mobile);
      setNational_id(props?.formData?.national_id);
      img = props?.formData?.id_proof_image;
      setId_proof_image(img);
      if (img && img !== "") {
        setId_proof_image_type(img.split(/[#?]/)[0].split(".").pop().trim());
      } // eslint-disable-next-line
      setIdFileName(props?.formData?.id_proof_image.replace(/^.*[\\\/]/, ""));
      setEmail(props?.formData?.email);
      setOriginalEmail(props?.formData?.email);
      img = props?.formData?.profile_image;
      setProfile_image(img);
      if (img && img !== "") {
        setProfile_image_type(img.split(/[#?]/)[0].split(".").pop().trim());
      }
      setProfileFileName(
        // eslint-disable-next-line
        props?.formData?.profile_image.replace(/^.*[\\\/]/, "")
      );
      setDesignation_code(
        props?.designationList.find(
          (data) => data?.value === props?.formData?.designation_code
        )
      );
      if (props?.formData?.company) {
        arr1 = props?.companyList;
        arr2 = props?.formData?.company;
        if (arr1.length > 0 && arr2.length > 0) {
          arr1?.forEach((data) => {
            for (let i = 0; i < arr2.length; i++) {
              if (arr2[i]?.company_code === data?.value) {
                tempArr.push(data);
              }
            }
          });
        }
        setCompany_code(tempArr);
      }
      if (props?.formData?.role_id) {
        arr1 = props?.roleList;
        arr2 = props?.formData?.role_id;
        tempArr = [];
        arr1?.forEach((data) => {
          for (let i = 0; i < arr2.length; i++) {
            if (arr2[i]?.role_id === data?.value) tempArr.push(data);
          }
        });
        setRole_code(tempArr);
      }
      if (props?.formData?.usage_type) {
        arr1 = props?.usageList;
        arr2 = props?.formData?.usage_type;
        tempArr = [];
        arr1?.forEach((data) => {
          for (let i = 0; i < arr2.length; i++) {
            if (arr2[i]?.usage_type === data?.value) tempArr.push(data);
          }
        });
        setUsageType(tempArr);
      }
    }
  };

  const handleValidation = () => {
    let isValid = true;
    if (!mobile) {
      setMobileErr(true);
      isValid = false;
    }
    if (!national_id) {
      setNational_idErr(true);
      isValid = false;
    }
    if (invalidEmail) {
      isValid = false;
    }
    if (!first_name) {
      isValid = false;
      setFirst_nameErr(true);
    }
    if (!last_name) {
      isValid = false;
      setLast_nameErr(true);
    }
    if (!pword && !props?.isEdit) {
      isValid = false;
      setPwordErr(true);
    }
    if (!email) {
      isValid = false;
      setEmailErr(true);
    }
    if (!designation_code) {
      isValid = false;
      setDesignation_codeErr(true);
    }
    if (company_code.length === 0) {
      isValid = false;
      setCompany_codeErr(true);
    }
    if (mobile.length < 9) {
      isValid = false;
    }
    if (national_id.length < 6) {
      isValid = false;
    }
    if (role_code.length === 0) {
      isValid = false;
      setRole_codeErr(true);
    }
    if (usageType.length === 0) {
      isValid = false;
      setUsageTypeErr(true);
    }
    if (selectedRegions.length === 0) {
      isValid = false;
      setRegionError(true);
    }
    let tempArr = props?.emailList;
    if (props?.isEdit && tempArr && tempArr.length > 0) {
      const index = tempArr.indexOf(originalEmail);
      if (index > -1) {
        tempArr.splice(index, 1);
      }
    }
    let duplicateEmail = tempArr.find((a) => a === email);
    if (duplicateEmail) {
      isValid = false;
      setSameEmail(true);
    }
    return isValid;
  };

  const emailValidation = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validEmail(value);
    if (isValid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
    setSameEmail(false);
  };

  const numberValidation = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setMobile(value);
      // } else {
      //   setMobile("");
      // }
    }
  };

  const idValidation = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setNational_id(value);
      // } else {
      //   setNational_id("");
      // }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let region = "";
      selectedRegions.forEach((selectedRegion) => {
        region = region + selectedRegion.value;
      });

      let obj = {
        first_name: first_name,
        last_name: last_name,
        first_name_arabic: first_name_arabic,
        last_name_arabic: last_name_arabic,
        pword:
          pword && pword.length > 0 ? CryptoJs.MD5(pword).toString() : null,
        mobile: mobile,
        national_id: national_id,
        id_proof_image: idUrl,
        id_proof_image_type: id_proof_image_type,
        email: email,
        profile_image: profileUrl,
        profile_image_type: profile_image_type,
        designation_code: designation_code?.value,
        company_code: company_code.map((company) => parseInt(company?.value)),
        role_id: role_code.map((role) => parseInt(role?.value)),
        usage_type: usageType.map((type) => parseInt(type?.value)),
        region_id: region,
      };
      if (props?.isEdit) {
        obj.user_id = props?.formData?.user_id;
      }
      props?.onCloseModal(obj);
    }
  };

  const handleSingleFileUpload = async (ev, type) => {
    let file = ev.target.files[0];
    if (file?.size > totalSize) {
      toast.error("Upload file should be less than 1MB.");
    } else {
      if (
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        let obj = {
          img_datetime: file.lastModifiedDate,
          img_id: Math.floor(Math.random() * 1000),
          img_name: file.name,
          img_type: type,
          img_url: URL.createObjectURL(file),
        };
        const fileType = file?.name.split(/[#?]/)[0].split(".").pop().trim();
        const result = await toBase64(file).catch((e) => Error(e));
        if (result instanceof Error) {
          console.log("Error: ", result.message);
          return;
        }
        if (type === "National ID") {
          setIdFileName(file.name);
          setId_proof_image(obj);
          setId_proof_image_type(fileType);
          setIdUrl(result);
        }
        if (type === "Profile Image" && file.type !== "application/pdf") {
          setProfileFileName(file.name);
          setProfile_image(obj);
          setProfile_image_type(fileType);
          setProfileUrl(result);
        }
        if (type === "Profile Image" && file.type === "application/pdf") {
          toast.error(
            "Please upload png, jpg/jpeg files for profile image only"
          );
        }
      } else {
        toast.error("Unsupported file type.");
      }
    }
  };

  return (
    <>
      <Modal
        open={props?.open}
        center
        onClose={() => props?.onCloseModal()}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
      >
        <div className="popup-header-bar">
          <label className="text-center weight-800">
            {props?.isEdit ? "Edit User" : "Add User"}
          </label>
        </div>
        <div className="px-3 pt-5">
          <Form>
            <div className="row">
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="first_name">
                    First Name<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="First Name"
                    value={first_name}
                    onChange={(ev) => setFirst_name(ev.target.value)}
                    className={`${!first_name && first_nameErr && "on-focus"}`}
                  />
                  {!first_name && first_nameErr && (
                    <div className="error-text">First Name is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="last_name">
                    Last Name<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Last Name"
                    value={last_name}
                    onChange={(ev) => setLast_name(ev.target.value)}
                    className={`${!last_name && last_nameErr && "on-focus"}`}
                  />
                  {!last_name && last_nameErr && (
                    <div className="error-text">Last Name is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="first_name_arabic">First Name (Arabic)</Label>
                  <Input
                    type="text"
                    name="first_name_arabic"
                    id="first_name_arabic"
                    placeholder="First Name (Arabic)"
                    value={first_name_arabic}
                    onChange={(ev) => setFirst_name_arabic(ev.target.value)}
                    style={{ textAlign: "right" }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="last_name_arabic">Last Name (Arabic)</Label>
                  <Input
                    type="text"
                    name="last_name_arabic"
                    id="last_name_arabic"
                    placeholder="Last Name (Arabic)"
                    value={last_name_arabic}
                    onChange={(ev) => setLast_name_arabic(ev.target.value)}
                    style={{ textAlign: "right" }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="email">
                    Email<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(ev) => emailValidation(ev)}
                    className={`${
                      !email && emailErr
                        ? "on-focus"
                        : email && sameEmail
                        ? "on-focus"
                        : ""
                    }`}
                    autoComplete=" new-password"
                  />
                  {email && invalidEmail && (
                    <div className="error-text">Email is invalid</div>
                  )}
                  {!email && emailErr && (
                    <div className="error-text">Email is required</div>
                  )}
                  {email && sameEmail && (
                    <div className="error-text">Email already exists</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="pword">
                    Password
                    <span className="color-primary">
                      {props?.isEdit ? "" : "*"}
                    </span>
                  </Label>
                  <div className="d-flex">
                    <Input
                      type={toggle ? "password" : "text"}
                      name="pword"
                      id="pword"
                      placeholder="Password"
                      maxLength={12}
                      value={pword}
                      onChange={(ev) => setPword(ev.target.value)}
                      autoComplete="new-password"
                      className={`password-input ${
                        !props?.isEdit && !pword && pwordErr && "on-focus"
                      }`}
                    />
                    <span>
                      <i
                        className={`fas fa-eye password-toggle ${
                          !toggle && "fa-eye-slash"
                        }`}
                        onClick={() => setToggle((toggle) => !toggle)}
                      ></i>
                    </span>
                  </div>
                  {!pword && pwordErr && !props?.isEdit && (
                    <div className="error-text">Password is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="mobile">
                    Mobile<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    placeholder="Mobile"
                    value={mobile}
                    onChange={(ev) => numberValidation(ev)}
                    className={`${!mobile && mobileErr && "on-focus"}`}
                    maxLength={10}
                  />
                  {!mobile && mobileErr && (
                    <div className="error-text">Mobile is required</div>
                  )}
                  {mobile && mobile.length < 9 && (
                    <div className="error-text">
                      Mobile number less than 9 digits
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="national_id">
                    National ID<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="national_id"
                    id="national_id"
                    placeholder="National ID"
                    value={national_id}
                    maxLength={10}
                    className={`${
                      !national_id && national_idErr && "on-focus"
                    }`}
                    onChange={(ev) => idValidation(ev)}
                  />
                  {!national_id && national_idErr && (
                    <div className="error-text">National ID is required</div>
                  )}
                  {national_id && national_id.length < 6 && (
                    <div className="error-text">
                      National ID less than 6 digits
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="id_proof_image">
                    Upload National ID Image (pdf/png/jpg/jpeg)
                  </Label>
                  <div className="file-upload-wrapper">
                    <input
                      ref={idRef}
                      type="file"
                      name="id_proof_image"
                      id="id_proof_image"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg,.pdf"
                      onChange={(ev) =>
                        handleSingleFileUpload(ev, "National ID")
                      }
                    />
                    <input
                      type="text"
                      name="id_proof_image_name"
                      id="id_proof_image_name"
                      className="form-own-input-filename form-control"
                      value={idFileName ? idFileName : ""}
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        idRef.current.click();
                      }}
                    >
                      {id_proof_image ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="profile_image">
                    Upload Profile Image (png/jpg/jpeg)
                  </Label>
                  <div className="file-upload-wrapper">
                    <input
                      ref={profileRef}
                      type="file"
                      name="profile_image"
                      id="profile_image"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg"
                      onChange={(ev) =>
                        handleSingleFileUpload(ev, "Profile Image")
                      }
                    />
                    <input
                      type="text"
                      name="profile_image_name"
                      id="profile_image_name"
                      className="form-own-input-filename form-control"
                      value={profileFileName ? profileFileName : ""}
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        profileRef.current.click();
                      }}
                    >
                      {profile_image ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="designation_code">
                    Designation<span className="color-primary">*</span>
                  </Label>
                  <Select
                    placeholder="Select Designation"
                    options={props?.designationList}
                    onChange={(ev) => setDesignation_code(ev)}
                    value={designation_code}
                    menuPlacement="top"
                    isSearchable={false}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      !designation_code && designation_codeErr && "on-focus"
                    }`}
                    components={animatedComponents}
                  />
                  {!designation_code && designation_codeErr && (
                    <div className="error-text">
                      Designation code is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="company_code">
                    Company<span className="color-primary">*</span>
                  </Label>
                  <Select
                    closeMenuOnSelect={false}
                    placeholder="Select Company"
                    options={props?.companyList}
                    onChange={(ev) => setCompany_code(ev)}
                    value={company_code}
                    menuPlacement="top"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      company_code.length === 0 && company_codeErr && "on-focus"
                    }`}
                    isMulti
                    components={animatedComponents}
                    styles={{
                      multiValue: (base) => ({
                        ...base,
                        width: `max-content`,
                        maxWidth: `100%`,
                      }),
                    }}
                  />
                  {company_code.length === 0 && company_codeErr && (
                    <div className="error-text">Company is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="Role_code">
                    Role<span className="color-primary">*</span>
                  </Label>
                  <Select
                    isMulti
                    styles={{
                      multiValue: (base) => ({
                        ...base,
                        width: `max-content`,
                        maxWidth: `100%`,
                      }),
                    }}
                    components={animatedComponents}
                    placeholder="Select Role"
                    options={props?.roleList}
                    onChange={(ev) => setRole_code(ev)}
                    value={role_code}
                    menuPlacement="top"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      role_code.length === 0 && role_codeErr && "on-focus"
                    }`}
                    closeMenuOnSelect={false}
                  />
                  {role_code.length === 0 && role_codeErr && (
                    <div className="error-text">Role is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="usageType">
                    Usage Type<span className="color-primary">*</span>
                  </Label>
                  <Select
                    isMulti
                    styles={{
                      multiValue: (base) => ({
                        ...base,
                        width: `max-content`,
                        maxWidth: `100%`,
                      }),
                    }}
                    components={animatedComponents}
                    placeholder="Select Usage Type"
                    options={props?.usageList}
                    onChange={(ev) => setUsageType(ev)}
                    value={usageType}
                    menuPlacement="top"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      usageType.length === 0 && usageTypeErr && "on-focus"
                    }`}
                    closeMenuOnSelect={false}
                  />
                  {usageType.length === 0 && usageTypeErr && (
                    <div className="error-text">Usage Type is required</div>
                  )}
                </div>
              </div>

              <div className="col-6">
                <div className="form-group-own">
                  <Label for="usageType">
                    Region<span className="color-primary">*</span>
                  </Label>
                  <Select
                    isMulti
                    styles={{
                      multiValue: (base) => ({
                        ...base,
                        width: `max-content`,
                        maxWidth: `100%`,
                      }),
                    }}
                    components={animatedComponents}
                    placeholder="Select Region"
                    options={regions}
                    onChange={(ev) => {
                      setSelectedRegions(ev);
                    }}
                    value={selectedRegions}
                    menuPlacement="top"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      selectedRegions.length === 0 && regionError && "on-focus"
                    }`}
                    closeMenuOnSelect={false}
                  />
                  {selectedRegions.length === 0 && regionError && (
                    <div className="error-text"> Region is required</div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center pt-4 col-12">
                <button
                  className="btn grey-bg"
                  onClick={() => props?.onCloseModal()}
                >
                  Cancel
                </button>
                <button className="btn primary-bg" onClick={handleSubmit}>
                  {props?.isEdit ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddUserModal;
