import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { toast } from "react-toastify";

import UserHeader from "../../components/Headers/userHeader";
import CarReplacementTable from "../../components/tables/carReplacementTable";
import { globalLoader } from "actions/commonActions";
import AssignUsersModal from "../../components/Modals/assignUsersModal";
import { useAppUserData } from "hooks/useAppUserData";
import { updateAssignUserApi } from "./_actions";

import { useCarReplacementData } from "hooks/useCarReplacement";
import { useUserDropdownData } from "hooks/useAuthorizationData";

const CarReplacement = ({ currentCompany }) => {
  const [assignUserModalOpen, setAssignUserModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState(null);
  const [userOptionsList, setUserOptionsList] = useState(null);
  const { data: users } = useAppUserData(currentCompany);
  useEffect(() => {
    refetch();
  }, [currentCompany]);
  useEffect(() => {
    prepareUsers(users);
  }, [users]);

  const prepareUsers = (users) => {
    setUserOptionsList([]);
    if (users && users.length > 0) {
      let usersList = [];
      users.forEach((item) => {
        if (item) {
          usersList.push({
            label: `${item.user_id} - ${item.first_name} ${item.last_name}`,
            value: `${item.user_id}`,
          });
        }
      });

      setUserOptionsList(usersList);
    }
  };

  const {
    data: cars,
    isLoading,
    isFetched,
    refetch,
  } = useCarReplacementData(currentCompany);

  if (isLoading) {
    globalLoader(true);
  }

  if (isFetched) {
    globalLoader(false);
  }

  const openassignUserModal = (obj = null) => {
    if (obj) {
      setFormData(obj);
      setIsEdit(true);
    }
    setAssignUserModalOpen(true);
  };

  const closeassignUserModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      let res;
      res = await updateAssignUserApi(obj);

      if (res?.data?.status === 0) {
        toast.success(res?.data?.message);
        refetch();
      } else {
        toast.error(res?.data?.message);
        globalLoader(false);
      }
    }
    setFormData(null);
    setAssignUserModalOpen(false);
    setIsEdit(false);
  };
  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Replacement/Pool Cars</h3>
                </Row>
              </CardHeader>
              {isFetched && userOptionsList ? (
                cars && cars.length > 0 ? (
                  <CarReplacementTable
                    tableData={cars}
                    assignUsers={openassignUserModal}
                  />
                ) : (
                  <div className="px-3 pt-3 pb-3">
                    <div className="no-data-available color-primary">
                      Data Not Available
                    </div>
                  </div>
                )
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
      {assignUserModalOpen && (
        <AssignUsersModal
          open={assignUserModalOpen}
          onCloseModal={closeassignUserModal}
          isEdit={isEdit}
          formData={formData}
          options={userOptionsList}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateProps)(CarReplacement);
