import React from "react";

import { useEffect, useState, useContext } from "react";
import { portalMyBookingCarListApi } from "views/examples/_actions";
import Car from "./car";
import BookingHeader from "./booking-header";

import { CreateBookingContext } from "./create-rent-request";
import AppContext from "context/app.context";

const CreateRentRequestCarSelector = (props) => {
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelctedCar] = useState(null);
  const [bookedCar, setBookedCar] = useState(null);

  const { selectedBooking, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (selectedBooking && cars.length > 0) {
      console.log(
        "🚀 ~ file: create-rent-request-car-selector.jsx:19 ~ useEffect ~ selectedBooking:",
        selectedBooking,
        cars
      );
      cars.forEach((car) => {
        if (car.rat_id === selectedBooking.rat_id) {
          setBookedCar(car);

          setSelctedCar(car);
          setBooking({
            ...booking,
            car_id: car.rat_id,
            rate: car.rate_daily,
            rate_type: car.rat_id,
            rat_cdw_text: car.rat_cdw_text,
            cdw_daily: car.cdw_daily,
            rat_addtional_driver_text: car.rat_addtional_driver_text,
            rat_cdw_optional: car.rat_cdw_optional,
            selectedCar: car,
          });
        }
      });
    }
  }, [selectedBooking, cars]);

  const { booking, setBooking, setCarSelected, setLocationSelected } =
    useContext(CreateBookingContext);

  useEffect(() => {
    getRentCarList();
  }, []);

  const getRentCarList = async () => {
    try {
      let res = await portalMyBookingCarListApi();
      if (res?.data?.status === 0) {
        setCars(res?.data?.data);
      }
    } catch (err) {}
  };

  const selectCarHandler = (car) => {
    setSelctedCar(car);
    setBooking({
      ...booking,
      car_id: car.rat_id,
      rate: car.rate_daily,
      rate_type: car.rat_id,
      rat_cdw_text: car.rat_cdw_text,
      cdw_daily: car.cdw_daily,
      rat_addtional_driver_text: car.rat_addtional_driver_text,
      rat_cdw_optional: car.rat_cdw_optional,
      selectedCar: car,
    });
    setCarSelected(true);
  };

  return (
    <>
      <div
        className="row"
        style={{ marginLeft: "80px", marginTop: "150px", marginRight: "30px" }}
      >
        <BookingHeader
          activeStep={1}
          backClicked={() => {
            setLocationSelected(false);
          }}
        />
      </div>
      <div
        className="row"
        style={{ marginLeft: "100px", marginTop: "20px", marginRight: "50px" }}
      >
        <div
          className="row col-12"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3 className="weight-800">Select your preferred vehicle type</h3>
          <h5 style={{ color: "#32325d9c" }}>
            *When you confirm your booking and choose a vehicle type, we will
            inform you of the vehicle model(s) available.
          </h5>
        </div>

        <div className="row" style={{ width: "100%" }}>
          {bookedCar && (
            <Car
              car={bookedCar}
              isSelected={true}
              onSelectCar={selectCarHandler}
            />
          )}
        </div>

        <div className="row">
          {cars.map((car, index) => {
            return (
              <Car
                car={car}
                isSelected={false}
                onSelectCar={selectCarHandler}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CreateRentRequestCarSelector;
