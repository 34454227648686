import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import { globalLoader } from "actions/commonActions";
import { logOut } from "_shared/commonFunctions";

import { updatePortalLocation } from "./_actions";
import { createPortalLocation } from "./_actions";
import { updateStatusApi } from "./_actions";

import UserHeader from "../../components/Headers/userHeader";
import ServiceCenterTable from "../../components/tables/serviceCenterTable";

import AddServiceCenterModal from "../../components/Modals/addServiceCenterModal";
import ConfirmModal from "components/Modals/confirmModal";
import StatusUpdateModal from "components/Modals/statusUpdateModal";

import { useServiceCenterData } from "hooks/useServiceCenterData";
import { useStatusData } from "hooks/useStatusData";

const ServiceCenterManagement = (props) => {
  const { userRights, currentCompany } = props;

  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [openServiceCenterModal, setOpenServiceCenterModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_8") {
        if (item?.create === "1") {
          setCanCreate(true);
        }
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: serviceCenterList,
    isFetching,
    isFetched,
    refetch,
  } = useServiceCenterData(currentCompany);

  globalLoader(isFetching);

  const { data: status } = useStatusData(16);

  const addServiceCenter = (obj = null) => {
    if (obj) {
      setIsEdit(true);
      setFormData(obj);
      setOpenServiceCenterModal(true);
    }
  };

  const closeServiceCenterModal = async (obj = null) => {
    if (obj) {
      try {
        let res;
        if (isEdit) {
          res = await updatePortalLocation(obj);
        } else {
          res = await createPortalLocation(obj);
        }
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
          refetch();
        } else {
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          logOut(props?.history, "/auth/login");
          globalLoader(false);
        } else {
          globalLoader(false);
        }
      }
    }
    setIsEdit(false);
    setFormData(null);
    setOpenServiceCenterModal(false);
  };

  const closeConfirmModal = (val) => {
    if (val) {
    }
    setFormData(null);
    setOpenConfirmModal(false);
  };

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "16",
        status: status,
        id: formData?.loc_id,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        logOut(props?.history, "auth/login");
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Locations</h3>
                  {canCreate && (
                    <div className="col text-right">
                      <button
                        className="btn btn-sm primary-bg"
                        onClick={() => setOpenServiceCenterModal(true)}
                      >
                        Add Location
                      </button>
                    </div>
                  )}
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    serviceCenterList && serviceCenterList.length > 0 ? (
                      <ServiceCenterTable
                        statusList={status}
                        tableData={serviceCenterList}
                        editData={addServiceCenter}
                        updateStatus={openUpdateStatus}
                        canEdit={canEdit}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {openServiceCenterModal && (
        <AddServiceCenterModal
          isEdit={isEdit}
          formData={formData}
          open={openServiceCenterModal}
          onCloseModal={closeServiceCenterModal}
        />
      )}

      {openConfirmModal && (
        <ConfirmModal
          open={openConfirmModal}
          onCloseModal={closeConfirmModal}
          id={formData?.code}
        />
      )}
      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.loc_status}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(ServiceCenterManagement);
