import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { vehicleDetailsApi } from "../views/examples/_actions";

export const useVehicleDetailsData = (plateNo) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["vehicle-details", plateNo, profile_email],
    () => vehicleDetailsApi(plateNo),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const drivers = data?.data?.data;
          if (drivers && drivers.length > 0) {
            return drivers[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    }
  );
};
