import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Card, CardBody, CardHeader } from "reactstrap";

const GraphCard = (props) => {
  const { graphCard } = props;
  const [data, setData] = useState(null);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "rgb(212,0,42)",
          font: {
            size: 18,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "rgb(212,0,42)",
          font: {
            size: 18,
          },
        },
      },
    },
  };

  useEffect(() => {
    setChartData(); // eslint-disable-next-line
  }, []);

  const setChartData = () => {
    let labels = [],
      chartData = [];
    if (graphCard) {
      graphCard?.forEach((item) => {
        if (item?.month === "1") labels.push("Jan");
        if (item?.month === "2") labels.push("Feb");
        if (item?.month === "3") labels.push("Mar");
        if (item?.month === "4") labels.push("Apr");
        if (item?.month === "5") labels.push("May");
        if (item?.month === "6") labels.push("Jun");
        if (item?.month === "7") labels.push("Jul");
        if (item?.month === "8") labels.push("Aug");
        if (item?.month === "9") labels.push("Sep");
        if (item?.month === "10") labels.push("Oct");
        if (item?.month === "11") labels.push("Nov");
        if (item?.month === "12") labels.push("Dec");
        chartData.push(parseInt(item?.count));
      });
    }

    const chart = {
      labels: labels,
      datasets: [
        {
          data: chartData,
          fill: true,
          backgroundColor: "rgba(212,0,42,0.1)",
          borderColor: "rgb(212, 0, 42)",
          pointBackgroundColor: "rgb(212,0,42)",
          borderWidth: "1",
          tension: "0.3",
        },
      ],
    };
    setData(chart);
  };

  return (
    <Card className="dashboard-card-own graph-card-wrapper">
      <CardHeader className="border-bottom-0 color-primary font-13 weight-600 pb-1">
        <div>
          {graphCard
            ? graphCard[0]?.graph_head_lable
            : "Revenue - Leased Vehicles (in Saudi Riyal)"}
        </div>
      </CardHeader>
      <CardBody className="pt-1">
        {data ? (
          <div className="chart-own">
            <Line data={data} options={options} />
          </div>
        ) : (
          <div className="mt-5 pt-4 color-primary d-flex justify-content-center align-items-center">
            Data Not Available
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default GraphCard;
