import React, { useState, useEffect } from "react";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import { globalLoader } from "actions/commonActions";
import UserHeader from "../../components/Headers/userHeader";
import { getPopupDetails } from "_shared/commonFunctions";

import RoadAssistanceTable from "../../components/tables/roadAssistanceTable";
import DetailsModal from "../../components/Modals/detailsModal";

import { useRoadSideAssistanceData } from "hooks/useRoadSideAssistanceData";

const RoadsideAssistance = (props) => {
  const { currentCompany } = props;

  const searchFilter = props?.location?.state?.morni_id;
  const searchId = props?.location?.state?.id;
  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");

  const {
    data: roadsideAssistanceList,
    isFetching,
    isFetched,
    refetch,
  } = useRoadSideAssistanceData(currentCompany);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  globalLoader(isFetching);

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setData(getPopupDetails(obj, type));
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle("");
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Roadside Assistance</h3>
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    roadsideAssistanceList &&
                    roadsideAssistanceList.length > 0 ? (
                      <RoadAssistanceTable
                        tableData={roadsideAssistanceList}
                        searchFilter={searchFilter}
                        searchId={searchId}
                        customerModalOpen={customerModalOpen}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}

              {userModal && (
                <DetailsModal
                  open={userModal}
                  onCloseModal={customerModalClose}
                  data={data}
                  title={detailsTitle}
                />
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateProps)(RoadsideAssistance);
