import React, { useEffect, useState, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { validDecimalNumber } from "_shared/commonFunctions";
import { validNumber } from "_shared/commonFunctions";
import { MAX_IMG_SIZE } from "actionTypes/types";
import { toast } from "react-toastify";
import { toBase64 } from "actions/commonActions";
import makeAnimated from "react-select/animated";
import Select from "react-select";

const RentRateModal = (props) => {
  const imgRef = useRef();
  const animatedComponents = makeAnimated();
  const { data, bodyTypeList, categoryList, hireTypeList, yearList } = props;
  const [group, setGroup] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [fleet, setFleet] = useState("");
  const [year, setYear] = useState("");
  const [daily, setDaily] = useState("");
  const [monthly, setMonthly] = useState("");
  const [cdwDaily, setCdwDaily] = useState("");
  const [cdwMonthly, setCdwMonthly] = useState("");
  const [excessCharge, setExcessCharge] = useState("");
  const [accidentDeduct, setAccidentDeduct] = useState("");
  const [category, setCategory] = useState("");
  const [gear, setGear] = useState("");
  const [door, setDoor] = useState("");
  const [seat, setSeat] = useState("");
  const [hireType, setHireType] = useState("");
  const [fuel, setFuel] = useState("");
  const [imagelink, setImagelink] = useState("");
  const [fileName, setFileName] = useState("");
  const [groupErr, setGroupErr] = useState(false);
  const [bodyTypeErr, setBodyTypeErr] = useState(false);
  const [fleetErr, setFleetErr] = useState(false);
  const [yearErr, setYearErr] = useState(false);
  const [dailyErr, setDailyErr] = useState(false);
  const [monthlyErr, setMonthlyErr] = useState(false);
  const [cdwDailyErr, setCdwDailyErr] = useState(false);
  const [cdwMonthlyErr, setCdwMonthlyErr] = useState(false);
  const [excessChargeErr, setExcessChargeErr] = useState(false);
  const [accidentDeductErr, setAccidentDeductErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [gearErr, setGearErr] = useState(false);
  const [doorErr, setDoorErr] = useState(false);
  const [seatErr, setSeatErr] = useState(false);
  const [hireTypeErr, setHireTypeErr] = useState(false);
  const [fuelErr, setFuelErr] = useState(false);
  const [imagelinkErr, setImagelinkErr] = useState(false);
  const [displayImg, setDisplayImg] = useState("");
  const gearList = [
    { value: "1", label: "Auto" },
    { value: "2", label: "Manual" },
  ];
  const fuelList = [
    { value: "1", label: "Petrol" },
    { value: "2", label: "Diesel" },
  ];

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = () => {
    if (data) {
      setGroup(data?.rat_vehicle_desc);
      setBodyType({ value: data?.rat_bdy_id, label: data?.rat_bdy_desc });
      setFleet(data?.rat_fleet_desc);
      setYear(data?.year);
      setDaily(data?.rate_daily);
      setMonthly(data?.rate_monthly);
      setCdwDaily(data?.cdw_daily);
      setCdwMonthly(data?.cdw_monthly);
      setExcessCharge(data?.excess_km_charge);
      setAccidentDeduct(data?.accident_deduct);
      setCategory(data?.cat_code);
      setGear({ value: data?.gear_type_id, label: data?.gear_type });
      setDoor(data?.no_of_doors);
      setSeat(data?.no_of_seats);
      setHireType({
        value: data?.rate_hire_type,
        label: data?.rate_hire_type_desc,
      });
      setFuel({ value: data?.rat_fuel_id, label: data?.rat_fuel });
      setFileName(data?.image_url.replace(/^.*[\\/]/, ""));
      const img = data?.image_url;
      setImagelink(img);
      setFileName(img.replace(/^.*[\\/]/, ""));
      setDisplayImg(img.replace(/^.*[\\/]/, "").replace(/\.[^/.]+$/, ""));
    }
  };

  const numberValidation = async (ev) => {
    let isValid;
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let { name, value } = ev.target;
    if (name === "excessCharge") {
      isValid = await validDecimalNumber(value);
    } else {
      isValid = await validNumber(value);
    }
    if (isValid) {
      if (name === "year") setYear(value);
      if (name === "daily") setDaily(value);
      if (name === "monthly") setMonthly(value);
      if (name === "cdwDaily") setCdwDaily(value);
      if (name === "cdwMonthly") setCdwMonthly(value);
      if (name === "excessCharge") setExcessCharge(value);
      if (name === "accidentDeduct") setAccidentDeduct(value);
      if (name === "door") setDoor(value);
      if (name === "seat") setSeat(value);
    }
  };

  const handleImgUpload = async (ev) => {
    let file = ev.target.files[0];
    if (file?.size > MAX_IMG_SIZE) {
      toast.error("Upload file should be less than 1MB.");
    } else {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        const fileType = file?.name.split(/[#?]/)[0].split(".").pop().trim();
        const result = await toBase64(file).catch((e) => Error(e));
        if (result instanceof Error) {
          console.log("Error: ", result.message);
          return;
        }
        const randomName =
          Math.floor(Math.random() * (99999 - 10000 + 1)) + 9999;
        setFileName(`${randomName}.${fileType}`);
        setImagelink(result);
        setDisplayImg(randomName);
      } else {
        toast.error("Unsupported file type.");
      }
    }
  };

  const handleImageNameChange = (ev) => {
    const { value } = ev.target;
    setDisplayImg(value);
    const fileType = fileName.split(/[#?]/)[0].split(".").pop().trim();
    setFileName(`${value}.${fileType}`);
  };

  const handleValidation = () => {
    let flag = true;
    if (!group) {
      setGroupErr(true);
      flag = false;
    }
    if (!bodyType) {
      flag = false;
      setBodyTypeErr(true);
    }
    if (!fleet) {
      flag = false;
      setFleetErr(true);
    }
    if (!year) {
      flag = false;
      setYearErr(true);
    }
    if (!daily) {
      flag = false;
      setDailyErr(true);
    }
    if (!monthly) {
      flag = false;
      setMonthlyErr(true);
    }
    if (!cdwDaily) {
      flag = false;
      setCdwDailyErr(true);
    }
    if (!cdwMonthly) {
      flag = false;
      setCdwMonthlyErr(true);
    }
    if (!excessCharge) {
      flag = false;
      setExcessChargeErr(true);
    }
    if (!accidentDeduct) {
      flag = false;
      setAccidentDeductErr(true);
    }
    if (!category) {
      flag = false;
      setCategoryErr(true);
    }
    if (!gear) {
      flag = false;
      setGearErr(true);
    }
    if (!door) {
      flag = false;
      setDoorErr(true);
    }
    if (!seat) {
      flag = false;
      setSeatErr(true);
    }
    if (!hireType) {
      flag = false;
      setHireTypeErr(true);
    }
    if (!fuel) {
      flag = false;
      setFuelErr(true);
    }

    if (!fileName) {
      setImagelinkErr(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      const obj = {
        rat_bdy_id: bodyType.value,
        rat_vehicle_types: group,
        rat_fleet_select: fleet,
        rat_vehicle_year: year,
        rat_daily: daily,
        rat_monthly: monthly,
        rat_cdw_daily: cdwDaily,
        rat_cdw_monthly: cdwMonthly,
        rat_excesskm_charge: excessCharge,
        rat_accident_deduct: accidentDeduct,
        rat_veh_category: category.value,
        rat_status: "1",
        rat_gear:
          gear.value === "501" ? "1" : gear.value === "502" ? "2" : gear.value,
        rat_door: door,
        rat_seat: seat,
        rat_hire_type: hireType.value,
        rat_fuel:
          fuel.value === "401" ? "1" : fuel.value === "402" ? "2" : fuel.value,
        rat_image_link: imagelink,
        // rat_image_link: "Base 64",
        rat_image_filename: fileName,
      };
      if (data) {
        obj.rat_id = data?.rat_id;
      }
      props?.onCloseModal(obj);
    }
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">
          {data ? "Edit Rent Rate" : "Add Rent Rate"}
        </label>
      </div>
      <div className="px-3 pt-3">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="group">
                  Group<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="group"
                  id="group"
                  placeholder="Group"
                  value={group}
                  onChange={(ev) => setGroup(ev.target.value)}
                  className={`${!group && groupErr && "on-focus"}`}
                />
                {!group && groupErr && (
                  <div className="error-text">Group is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="bodyId">
                  Body Type<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Body Type"
                  options={bodyTypeList}
                  onChange={(ev) => setBodyType(ev)}
                  value={bodyType}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !bodyType && bodyTypeErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!bodyType && bodyTypeErr && (
                  <div className="error-text">Body Type is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="fleet">
                  Fleet<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="fleet"
                  id="fleet"
                  placeholder="Fleet"
                  value={fleet}
                  onChange={(ev) => setFleet(ev.target.value)}
                  className={`${!fleet && fleetErr && "on-focus"}`}
                />
                {!fleet && fleetErr && (
                  <div className="error-text">Fleet is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="year">
                  Year<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Select Year"
                  options={yearList}
                  onChange={(ev) => setYear(ev)}
                  value={year}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !year && year && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!year && yearErr && (
                  <div className="error-text">Year is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="daily">
                  Daily Rate<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="daily"
                  id="daily"
                  placeholder="Daily Rate"
                  value={daily}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!daily && dailyErr && "on-focus"}`}
                />
                {!daily && dailyErr && (
                  <div className="error-text">Daily Rate is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="monthly">
                  Monthly Rate<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="monthly"
                  id="monthly"
                  placeholder="Monthly Rate"
                  value={monthly}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!monthly && monthlyErr && "on-focus"}`}
                />
                {!monthly && monthlyErr && (
                  <div className="error-text">Monthly Rate is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="cdwDaily">
                  CDW Daily Rate<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="cdwDaily"
                  id="cdwDaily"
                  placeholder="CDW Daily Rate"
                  value={cdwDaily}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!cdwDaily && cdwDailyErr && "on-focus"}`}
                />
                {!cdwDaily && cdwDailyErr && (
                  <div className="error-text">CDW Daily Rate is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="cdwMonthly">
                  CDW Monthly Rate<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="cdwMonthly"
                  id="cdwMonthly"
                  placeholder="CDW Monthly Rate"
                  value={cdwMonthly}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!cdwMonthly && cdwMonthlyErr && "on-focus"}`}
                />
                {!cdwMonthly && cdwMonthlyErr && (
                  <div className="error-text">CDW Monthly Rate is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="excessCharge">
                  Excess KM Charge <span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="excessCharge"
                  id="Excess Charge"
                  placeholder="Excess KM Charge"
                  value={excessCharge}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${
                    !excessCharge && excessChargeErr && "on-focus"
                  }`}
                />
                {!excessCharge && excessChargeErr && (
                  <div className="error-text">Excess KM Charge is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="accidentDeduct">
                  Accident Deduct<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="accidentDeduct"
                  id="accidentDeduct"
                  placeholder="Accident Deduct"
                  value={accidentDeduct}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${
                    !accidentDeduct && accidentDeductErr && "on-focus"
                  }`}
                />
                {!accidentDeduct && accidentDeductErr && (
                  <div className="error-text">Accident Deduct is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="category">
                  Vehicle Category<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Vehicle Category"
                  options={categoryList}
                  onChange={(ev) => setCategory(ev)}
                  value={category}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !category && categoryErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!category && categoryErr && (
                  <div className="error-text">Vehicle Category is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="gear">
                  Gear Type<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Gear Type"
                  options={gearList}
                  onChange={(ev) => setGear(ev)}
                  value={gear}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !gear && gearErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!gear && gearErr && (
                  <div className="error-text">Gear Type is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="door">
                  Doors<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="door"
                  id="door"
                  placeholder="Doors"
                  value={door}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!door && doorErr && "on-focus"}`}
                />
                {!door && doorErr && (
                  <div className="error-text">Doors is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="seat">
                  Seats<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="seat"
                  id="seat"
                  placeholder="Seats"
                  value={seat}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!seat && seatErr && "on-focus"}`}
                />
                {!seat && seatErr && (
                  <div className="error-text">Seats is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="hireType">
                  Hire Type<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Hire Type"
                  options={hireTypeList}
                  onChange={(ev) => setHireType(ev)}
                  value={hireType}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !hireType && hireTypeErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!hireType && hireTypeErr && (
                  <div className="error-text">Hire Type is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="fuel">
                  Fuel Type<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Fuel Type"
                  options={fuelList}
                  onChange={(ev) => setFuel(ev)}
                  value={fuel}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !fuel && fuelErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!fuel && fuelErr && (
                  <div className="error-text">Fuel Type is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="imgLink">
                  Upload Image (jpg/jpeg/png)
                  <span className="color-primary">*</span>
                </Label>
                <div className="file-upload-wrapper">
                  <input
                    ref={imgRef}
                    type="file"
                    name="file"
                    id="file"
                    className="form-own-input"
                    accept=".png,.jpg,.jpeg,.pdf"
                    onChange={(ev) => handleImgUpload(ev)}
                  />
                  <input
                    type="text"
                    name="file_name"
                    id="file_name"
                    className={`form-own-input-filename form-control ${
                      !fileName && imagelinkErr && "on-focus"
                    }`}
                    value={displayImg ? displayImg : ""}
                    onChange={handleImageNameChange}
                  />
                  <button
                    className="btn primary-bg file-upload-btn"
                    onClick={(ev) => {
                      ev.preventDefault();
                      imgRef.current.click();
                    }}
                  >
                    {!fileName ? "Upload" : "Change"}
                  </button>
                </div>
                {!fileName && imagelinkErr && (
                  <div className="error-text">Image is required</div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                {data ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default RentRateModal;
