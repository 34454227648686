import React from "react";
import { Button } from "reactstrap";

const IconButton = ({ title, iconName, iconPosition, onClick }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      onClick={onClick}
    >
      {iconPosition === "left" && <i className={`fas  pr-2 ${iconName}`}></i>}
      {title && (
        <Button color="link" style={{ paddingLeft: "0px", color: "#333" }}>
          {title}
        </Button>
      )}

      {iconPosition === "right" && <i className={`fas  pl-2 ${iconName}`}></i>}
    </div>
  );
};

export default IconButton;
