export const COUNTRY_LIST = [
  {
    value: "1",
    label: "Bahrain",
  },
  {
    value: "2",
    label: "Kuwait",
  },
  {
    value: "3",
    label: "Qatar",
  },
  {
    value: "4",
    label: "United Arab Emirates",
  },
  {
    value: "5",
    label: "Oman",
  },
];
