import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { getRentalTermsApi } from "views/examples/_actions";

export const useTermsData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["terms", profile_email], () => getRentalTermsApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data?.data?.data[0].tc;
    },
  });
};
