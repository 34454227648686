/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// reactstrap components
import { Container } from "reactstrap";

const UserHeader = ({ currentCompany }) => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "200px",
          // backgroundImage:
          //   "url(" +
          //   require("../../assets/img/theme/profile-cover.jpg").default +
          //   ")",
          // backgroundSize: "cover",
          // backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask primary-bg " />
        {/* Header container */}
        <Container
          className="d-flex align-items-center justify-content-center"
          fluid
        >
          <div className="company-name">
            {currentCompany?.value !== null && currentCompany?.label}
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => ({
  currentCompany: state?.common?.currentCompany,
});

export default connect(mapStatetoProps)(UserHeader);
