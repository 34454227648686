import { useRef, useState } from "react";
import { Label } from "reactstrap";
import imgToBase64 from "utils/fileUtils";
import { getFileType } from "utils/fileUtils";

export const FileUpload = ({ title, placeholder, errorMessage, onUpload }) => {
  const idRef = useRef();

  const [fileName, setFileName] = useState("");

  const handleFileUpload = async (event) => {
    let file = event.target.files[0];

    setFileName(file?.name);
    let result = await imgToBase64(file);
    if (result) {
      onUpload({
        file: result,
        type: getFileType(file?.type),
      });
    }
  };

  return (
    <div className="form-group-own">
      <Label for="title">{title}</Label>
      <div className="file-upload-wrapper">
        <input
          ref={idRef}
          type="file"
          name="title"
          id="title"
          className="form-own-input"
          accept=".png,.jpg,.jpeg,.pdf"
          onChange={handleFileUpload}
        />
        <input
          type="text"
          className="form-own-input-filename form-control"
          value={fileName ? fileName : ""}
          readOnly
        />
        <button
          className="btn primary-bg file-upload-btn"
          onClick={(ev) => {
            ev.preventDefault();
            idRef.current.click();
          }}
        >
          Upload
        </button>
      </div>
      {errorMessage && <div className="error-text">{errorMessage}</div>}
    </div>
  );
};
