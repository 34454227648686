import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { usageListApi } from "../views/examples/_actions";

export const useUsageData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["usage", profile_email], () => usageListApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      let usages = [];
      data?.data?.data?.forEach((usage) => {
        let newUsage = {
          value: usage?.put_id,
          label: usage?.put_desc,
        };
        usages.push(newUsage);
      });
      return usages;
    },
  });
};
