import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Label } from "reactstrap";

const StatusUpdateModal = ({ open, onCloseModal, formData, statusList }) => {
  const [status, setStatus] = useState(formData);
  return (
    <Modal
      open={open}
      center
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={() => onCloseModal(null)}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Update Status</label>
      </div>
      <div className="px-3 pt-3">
        <Form>
          <div className="row">
            <div className="col-12 ">
              <div className="form-group-own-status">
                <div className="py-2">
                  {statusList.map((list) => {
                    return (
                      <div key={list?.id}>
                        <input
                          type="radio"
                          name="status"
                          id={list?.name}
                          checked={status === list?.id}
                          onChange={() => setStatus(list?.id)}
                        />
                        <label className="pl-2" htmlFor={list?.name}>
                          {list?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center col-12 pt-2">
              <button
                className="btn grey-bg"
                onClick={() => onCloseModal(null)}
              >
                Cancel
              </button>
              <button
                className="btn primary-bg"
                onClick={(e) => {
                  e.preventDefault();
                  onCloseModal(status);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default StatusUpdateModal;
