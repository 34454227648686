import React, { useEffect, useState } from "react";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { globalLoader } from "actions/commonActions";
import { logOut } from "_shared/commonFunctions";
import { sendNotificationApi } from "./_actions";
import { updateStatusApi } from "./_actions";
import { getPopupDetails } from "_shared/commonFunctions";

import AccidentTable from "../../components/tables/accidentTable";
import UserHeader from "../../components/Headers/userHeader";

import ReplyNotification from "../../components/Modals/replyNotificationModal";
import StatusUpdateModal from "../../components/Modals/statusUpdateModal";
import AddReportAccidentModal from "components/Modals/addReportAccidentModal";
import ViewImageModal from "../../components/Modals/viewImageModal";
import DriverDetailsModal from "components/Modals/driverDetailsModal";
import DetailsModal from "../../components/Modals/detailsModal";

import { useStatusData } from "hooks/useStatusData";
import { useAccidentsData } from "hooks/useAccidentsData";

const ReportedAccident = (props) => {
  const { userRights, currentCompany } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [addAccidentReport, setAddAccidentReport] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imgType, setImgType] = useState("");
  const [imageData, setImageData] = useState("");

  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);
  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [nationalId, setNationalId] = useState("");

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_10") {
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: accidentList,
    isFetching,
    isFetched,
    refetch,
  } = useAccidentsData(currentCompany);

  globalLoader(isFetching);

  const { data: status } = useStatusData(2);

  useEffect(() => {
    window.history.replaceState(null, "");
  }, []);

  const openImage = (obj = null, type = null) => {
    if (obj) {
      let img = type === "Police Report" ? obj?.police_paper : obj?.images;
      setImageData(img);
      setImgType(type);
      setOpenImageModal(true);
    }
  };

  const closeImageModal = () => {
    setOpenImageModal(false);
    setImgType(null);
    setImageData(null);
  };

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "2",
        status: status,
        id: formData?.acc_id,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }

        refetch();
      } catch (err) {
        logOut(props?.history, "auth/login");
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const closeAccidentReport = (obj = null) => {
    if (obj) {
    }
    setAddAccidentReport(false);
  };

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setData(getPopupDetails(obj, type));
      setNationalId(obj?.national_id);
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle("");
  };

  const openNotificationModal = (obj) => {
    let notiData = {
      user_id: obj.userid,
      tag: obj?.company_name,
      type: "2",
      typeDesc: "Accident",
    };
    setFormData(notiData);
    setNotificationModalOpen(true);
  };

  const closeNotificationModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await sendNotificationApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (err) {
        toast.error("Error while posting notification");
        console.log(err);
      }
      globalLoader(false);
      refetch();
    }
    setFormData(null);
    setNotificationModalOpen(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8 pb-3" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Reported Accidents</h3>
                  {/* <div className="col text-right">
                    <button
                      className="btn btn-sm primary-bg"
                      onClick={() => setAddAccidentReport(true)}
                    >
                      Add Accident Report
                    </button>
                  </div> */}
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    accidentList && accidentList?.length > 0 ? (
                      <AccidentTable
                        statusList={status}
                        tableData={accidentList}
                        viewImage={openImage}
                        updateStatus={openUpdateStatus}
                        canEdit={canEdit}
                        searchFilter={
                          props?.location?.state?.fromDash ? "1" : null
                        }
                        searchId={
                          props?.location?.state?.fromDash ? "acc_status" : null
                        }
                        customerModalOpen={customerModalOpen}
                        sendNotification={openNotificationModal}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {openImageModal && (
        <ViewImageModal
          open={openImageModal}
          onCloseModal={closeImageModal}
          data={imageData}
          imgType={imgType}
        />
      )}
      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.acc_status}
        />
      )}
      {addAccidentReport && (
        <AddReportAccidentModal
          open={addAccidentReport}
          onCloseModal={closeAccidentReport}
        />
      )}
      {userModal && detailsTitle === "customer" && (
        <DriverDetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          formData={nationalId}
        />
      )}

      {userModal && detailsTitle === "company" && (
        <DetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          data={data}
          title={detailsTitle}
        />
      )}
      {notificationModalOpen && (
        <ReplyNotification
          open={notificationModalOpen}
          onCloseModal={closeNotificationModal}
          formData={formData}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(ReportedAccident);
