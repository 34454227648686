import DescModal from "components/Modals/descModal";
import React, { useMemo, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";
import { SelectColumnFilter } from "./_filters";
import TableContainer from "./_table";

const sortItems = (prev, curr, columnId) => {
  if (
    prev.original[columnId].trim().toLowerCase() >
    curr.original[columnId].trim().toLowerCase()
  ) {
    return 1;
  } else if (
    prev.original[columnId].trim().toLowerCase() <
    curr.original[columnId].trim().toLowerCase()
  ) {
    return -1;
  } else {
    return 0;
  }
};

const ServiceCenterTable = (props) => {
  const { statusList } = props;
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };
  const column = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#pablo"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    onClick={() => props?.editData(cell?.row?.original)}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                  {/* <DropdownItem>Delete</DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "loc_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.loc_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },

      {
        Header: "code",
        accessor: "loc_code",
        disableFilters: true,
      },
      {
        Header: "Type",
        accessor: "loc_type",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Phone",
        accessor: "loc_phone",
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.loc_phone}</div>
        ),
        disableFilters: true,
      },

      {
        Header: "Description (Arabic)",
        accessor: "loc_desc_ar",
        Cell: ({ row }) => (
          <div className="">
            <div
              className={`${
                row?.original?.loc_desc_ar.length > 15
                  ? "view-more-arabic"
                  : "text-right"
              }`}
            >
              <span>{row?.original?.loc_desc_ar}</span>
            </div>
            {row?.original?.loc_desc_ar.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer text-right"
                onClick={() =>
                  handleMore("Description (Arabic)", row?.original?.loc_desc_ar)
                }
              >
                View More
              </div>
            )}
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Center Adderess",
        accessor: "loc_address",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.loc_address.length > 15 ? "view-more" : ""
              }`}
            >
              <span>{row?.original?.loc_address}</span>
            </div>
            {row?.original?.loc_address.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Center Adderess", row?.original?.loc_address)
                }
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Adderess (Arabic)",
        accessor: "loc_address_ar",
        Cell: ({ row }) => (
          <div className="mr-3">
            <div
              className={`${
                row?.original?.loc_address_ar.length > 15
                  ? "view-more-arabic"
                  : "text-right"
              }`}
            >
              <span>{row?.original?.loc_address_ar}</span>
            </div>
            {row?.original?.loc_address_ar.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer text-right"
                onClick={() =>
                  handleMore("Adderess (Arabic)", row?.original?.loc_address_ar)
                }
              >
                View More
              </div>
            )}
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Map link",
        accessor: "loc_google_adrs",
        Cell: ({ row }) =>
          row?.original?.loc_google_adrs !== "" && (
            <div className="ml-2">
              <a
                href={`${row?.original?.loc_google_adrs}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-map-marker-alt color-primary cursor-pointer"></i>
              </a>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Description",
        accessor: "loc_desc",
        Cell: ({ row }) => (
          <>
            {/* <div
              className={`${
                row?.original?.loc_desc.length > 190 ? "view-more" : ""
              }`}
            > */}
            <span>{row?.original?.loc_desc}</span>
            {/* </div>
            {row?.original?.loc_desc.length > 190 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Description", row?.original?.loc_desc)
                }
              >
                View More
              </div>
            )} */}
          </>
        ),
        disableFilters: true,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "ID",
        accessor: "loc_id",
        disableFilters: true,
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);
  return (
    <>
      <TableContainer columns={column} data={data} />{" "}
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}
    </>
  );
};

export default ServiceCenterTable;
