import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { toast } from "react-toastify";

import UserHeader from "../../components/Headers/userHeader";
import DamageTable from "../../components/tables/damagetable";

import { globalLoader } from "actions/commonActions";
import { getPopupDetails } from "_shared/commonFunctions";

import { sendNotificationApi, updateStatusApi } from "./_actions";

import StatusUpdateModal from "components/Modals/statusUpdateModal";
import ReplyNotification from "../../components/Modals/replyNotificationModal";
import DamageListModal from "components/Modals/damageListModal";
import ViewImageModal from "components/Modals/viewImageModal";
import ViewMoreModal from "../../components/Modals/viewMoreModal";
import DriverDetailsModal from "components/Modals/driverDetailsModal";
import DetailsModal from "../../components/Modals/detailsModal";

import { useDamageData } from "hooks/useDamageData";
import { useStatusData } from "hooks/useStatusData";

const DamageList = (props) => {
  const { userRights, currentCompany } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [openDamageListModal, setOpenDamageListModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imgType, setImgType] = useState("");
  const [imageData, setImageData] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);
  const [list, setList] = useState(null);
  const [desc, setDesc] = useState(null);
  const [openViewMore, setOpenViewMore] = useState(false);
  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [nationalId, setNationalId] = useState("");

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_1") {
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: damages,
    isFetching,
    isFetched,
    refetch,
  } = useDamageData(currentCompany);

  globalLoader(isFetching);

  const { data: status } = useStatusData(4);

  const openImage = (obj) => {
    if (obj?.images[0] !== null) {
      setImageData(obj?.images);
      setImgType("Damage");
      setOpenImageModal(true);
    }
  };

  const closeImageModal = () => {
    setImageData("");
    setImgType("");
    setOpenImageModal(false);
  };

  const closeDamageListModal = (obj = null) => {
    if (obj) {
    }
    setOpenDamageListModal(false);
  };

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const viewMore = (obj, desc) => {
    setList(obj?.damage_details);
    setDesc(desc);
    setOpenViewMore(true);
  };
  const closeViewMore = () => {
    setList(null);
    setDesc(null);
    setOpenViewMore(false);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "4",
        status: status,
        id: formData?.dmg_id,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setData(getPopupDetails(obj, type));
      setNationalId(obj?.national_id);
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle("");
  };

  const openNotificationModal = (obj) => {
    let notiData = {
      user_id: obj.userid,
      tag: obj?.company_name,
      type: "4",
      typeDesc: "Damage",
    };
    setFormData(notiData);
    setNotificationModalOpen(true);
  };

  const closeNotificationModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await sendNotificationApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (err) {
        toast.error("Error while posting notification");
        console.log(err);
      }
      globalLoader(false);
      refetch();
    }
    setFormData(null);
    setNotificationModalOpen(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Self Inspection</h3>
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    damages && damages?.length > 0 ? (
                      <DamageTable
                        statusList={status}
                        tableData={damages}
                        canEdit={canEdit}
                        searchFilter={
                          props?.location?.state?.fromDash ? "1" : null
                        }
                        searchId={
                          props?.location?.state?.fromDash ? "dmg_status" : null
                        }
                        openImage={openImage}
                        updateStatus={openUpdateStatus}
                        viewMore={viewMore}
                        customerModalOpen={customerModalOpen}
                        sendNotification={openNotificationModal}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {openViewMore && (
        <ViewMoreModal
          open={openViewMore}
          list={list}
          desc={desc}
          onCloseModal={closeViewMore}
        />
      )}
      {openDamageListModal && (
        <DamageListModal
          open={openDamageListModal}
          onCloseModal={closeDamageListModal}
        />
      )}

      {openImageModal && (
        <ViewImageModal
          open={openImageModal}
          data={imageData}
          imgType={imgType}
          onCloseModal={closeImageModal}
        />
      )}
      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          statusList={status}
          formData={formData?.dmg_status}
          onCloseModal={closeUpdateStatus}
        />
      )}
      {userModal && detailsTitle === "customer" && (
        <DriverDetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          formData={nationalId}
        />
      )}

      {userModal && detailsTitle === "company" && (
        <DetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          data={data}
          title={detailsTitle}
        />
      )}
      {notificationModalOpen && (
        <ReplyNotification
          open={notificationModalOpen}
          formData={formData}
          onCloseModal={closeNotificationModal}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(DamageList);
