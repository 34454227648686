import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import { replacementCarListApi } from "../views/examples/_actions";

export const useCarReplacementData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["car-replacement", currentCompany, profile_email],
    () => replacementCarListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const cars = data?.data?.data;
          if (cars) {
            let sortedCars = sort(cars).desc(
              (car) => new Date(car?.start_date)
            );
            return sortedCars;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    }
  );
};
