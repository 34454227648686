import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ContractDetailsModal = (props) => {
  return (
    <Modal
      open={props?.open}
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      center
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Contract Details</label>
      </div>
      <ul className="details-wrapper list-unstyled">
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            Contract Number
          </div>
          <div className="col-6">
            {props?.type === "fleet"
              ? props?.formData?.con_no
              : props?.formData?.reservation_no}
          </div>
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            Company Name
          </div>
          <div className="col-6">{props?.formData?.cust_name}</div>
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            Company Code
          </div>
          <div className="col-6">{props?.formData?.cust_code}</div>
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">Type</div>
          <div className="col-6">{props?.formData?.cust_type}</div>
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            Contract Start Date
          </div>
          <div className="col-6">{`${new Date(
            props?.type === "fleet"
              ? props?.formData?.from_dt
              : props?.formData?.start_date
          ).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          })}`}</div>
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            Contract End Date
          </div>
          <div className="col-6">{`${new Date(
            props?.type === "fleet"
              ? props?.formData?.to_dt
              : props?.formData?.end_date
          ).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          })}`}</div>
        </li>
        <li className="row">
          <div className="col-6 color-primary text-right weight-600">
            Status
          </div>
          <div className="col-6">
            {props?.type === "fleet"
              ? props?.formData?.contract_status_desc
              : props?.formData?.reservation_status}
          </div>
        </li>
      </ul>
    </Modal>
  );
};

export default ContractDetailsModal;
