import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Label } from "reactstrap";

const ViolationStatusModal = ({ open, onCloseModal, formData, statusList }) => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, []);

  const setPropsToState = () => {
    if (formData) {
      setStatus(formData?.V_status);
    }
  };

  return (
    <Modal
      open={open}
      center
      onClose={() => onCloseModal(null)}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Update Status</label>
      </div>
      <div className="px-3 pt-5">
        {formData?.V_bill_availability === "Yes" ? (
          <Form>
            <div className="row">
              <div className="col-12 ">
                <div className="form-group-own-status">
                  <div className="py-2">
                    {statusList.map((list) => {
                      return (
                        <div key={list?.id}>
                          <input
                            type="radio"
                            name="status"
                            id={list?.name}
                            checked={status === list?.id}
                            onChange={() => setStatus(list?.id)}
                          />
                          <label className="pl-2" htmlFor={list?.name}>
                            {list?.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center col-12 pt-2">
                <button
                  className="btn grey-bg"
                  onClick={() => onCloseModal(null)}
                >
                  Cancel
                </button>
                <button
                  className="btn primary-bg"
                  onClick={(e) => {
                    e.preventDefault();
                    onCloseModal(status);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        ) : (
          <div className="row">
            <p className="color-primary h3 col-12 text-center">
              Bill not available at the moment. Please try again later.
            </p>
            <div className="col-12 d-flex justify-content-center pt-3">
              <button
                className="btn primary-bg"
                onClick={() => onCloseModal(null)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViolationStatusModal;
