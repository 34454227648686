import React, { useEffect, useState, useContext, useRef } from "react";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";

import PrimaryButtonLg from "components/button/primary-button-lg";
import BookingHeader from "./booking-header";
import { CreateBookingContext } from "./create-rent-request";

import { globalLoader } from "actions/commonActions";

import { createBooking, updateBookingApi } from "../examples/_actions";

import { useCountriesData } from "hooks/useCountriesData";
import { useAppUserData } from "hooks/useAppUserData";
import { useFileTypesData } from "hooks/useFileTypesData";
import { useCompaniesFilterData } from "hooks/useCompaniesData";
import { UsePaymentModesData } from "hooks/usepaymentModesData";
import { useIDTypeData } from "hooks/useIDTypeData";

import styles from "./booking-confirmation.module.css";
import DateTimePicker from "components/date-picker/date-time-picker";
import { FileUpload } from "components/file-upload/file-upload";
import { formatDate } from "utils/dateUtils";
import BookingSuccessModal from "components/Modals/bookingSuccessModal";
import AppContext from "context/app.context";

const userData = {
  company: "",
  designation: "",
  designation_code: "",
  email: "",
  email_status: "",
  first_name: "",
  first_name_a: "",
  id_proof_image: "",
  last_name: "",
  last_name_a: "",
  mobile: "",
  mobile_status: "",
  national_id: "",
  national_id_status: "",
  profile_image: "",
  role_id: [],
  usage_type: [],
  user_id: "",
  user_status: "",
  date_of_birth: "",
  gender: "",
  company_code: "",
};

const genderOptions = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
  {
    label: "Other",
    value: "O",
  },
];
const BookingConfirmation = ({ currentCompany, history }) => {
  const [userOptionsList, setUserOptionsList] = useState([]);
  const [selectedUserOBj, setSelectedUserObj] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCustomerOBj, setSelectedCustomerObj] = useState(null);
  const [selectedUser, setSelectedUser] = useState(userData);
  const [lastSelectedUser, setLastSelectedUser] = useState(userData);
  const [selectedGender, setSelectedGender] = useState(genderOptions[0]);
  const [dateofBirth, setDateofBirth] = useState("");
  const [newUser, setNewUser] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [idImage, setIDImage] = useState("");
  const [idImageType, setIDImageType] = useState("");
  const [licenceImage, setLicenceImage] = useState("");
  const [licenceImageType, setLicenceImageType] = useState("");
  const [showBookingSuccess, setShowBookingSuccess] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [otherDocumentsRequired, setOtherDocumentsRequired] = useState(false);
  const [otherDocumentsUploaded, setOtherDocumentsUploaded] = useState(false);
  const [isSubmitCalled, setIsSubmitCalled] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const initialLoad = useRef(true);
  const [selectedFileType, setSelectedFileType] = useState({
    label: "Select File Type",
    value: -1,
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { selectedBooking, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (selectedBooking) {
      setBooking({
        ...booking,
        booking_id: selectedBooking?.booking_id,
        first_name: selectedBooking?.first_name,
        last_name: selectedBooking?.last_name,
        mobile_number: selectedBooking?.mobile_number,
        user_id: selectedBooking?.user_id,
        id_number: selectedBooking?.id_number,
        nationality: selectedBooking?.nationality,
        cust_code: selectedBooking?.cust_code,
        cust_name: selectedBooking?.cust_name,
        date_of_birth: selectedBooking?.date_of_birth,
        gender: selectedBooking?.gender,
        id_proof_image: selectedBooking?.id_proof_image,
        id_proof_image_type: selectedBooking?.id_proof_image_type,
        license_image: selectedBooking?.license_image,
        license_image_type: selectedBooking?.license_image_type,
        image: selectedBooking?.image,
        email: selectedBooking?.email,
        mobile_country: selectedBooking?.mobile_country,
        id_type: selectedBooking?.id_type,
        pay_mode: selectedBooking?.pay_mode,
      });
      setLastSelectedUser(selectedBooking);
      // if (selectedBooking.date_of_birth)
      //   setDateofBirth(
      //     formatDate(parseInt(selectedBooking.date_of_birth + "000"),"yyyy-MM-dd HH:mm:ss")
      //   );
    }
  }, [selectedBooking]);

  const { booking, setBooking, setExtrasSelected } =
    useContext(CreateBookingContext);

  const animatedComponents = makeAnimated();

  const { data: countries } = useCountriesData();
  const { data: users } = useAppUserData(currentCompany);
  const { data: fileTypes } = useFileTypesData();
  const { data: companies } = useCompaniesFilterData();
  const { data: paymentModes } = UsePaymentModesData();
  const { data: idTypes } = useIDTypeData();

  useEffect(() => {
    if (companies && companies.length > 0) {
      const list = companies.slice(1);
      setCompanyList(list);
      if (list && list.length > 0) {
        // setSelectedCustomerObj(list[0]);
      }
    }
  }, [companies]);

  function validateField(data, type) {
    if (!data) return false;
    if (type == "string") {
      return data?.trim().length > 0;
    } else if (type == "object") {
      return data?.value ? true : false;
    } else if (type == "date") {
      return data && data != "";
    } else if (type == "phone") {
      return data?.trim().length > 0;
    } else if (type == "email") {
      return String(data)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
  }

  useEffect(() => {
    if (fileTypes && fileTypes.length > 0) {
      setSelectedFileType(fileTypes[0]);
    }
  }, [fileTypes]);

  useEffect(() => {
    prepareUsers(users);
    setDateofBirth(null);

    setSelectedUser(userData);
    setSelectedUserObj(null);
  }, [users]);

  useEffect(() => {
    if (selectedBooking && users && users.length > 0) {
      users.forEach((user) => {
        if (user.user_id === selectedBooking.userid) {
          setSelectedUser(user);
          setSelectedUserObj({
            label: `${user.user_id} - ${user.first_name} ${user.last_name}`,
            value: `${user.user_id}`,
          });
        }
      });
    }
  }, [users, selectedBooking]);

  useEffect(() => {}, [selectedBooking]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      if (selectedBooking) {
        selectedBooking.date_of_birth = null;
        selectedBooking.userid = null;
      }
      setResetForm(Date.now());
    }
  }, [currentCompany]);

  useEffect(() => {
    if (selectedBooking && idTypes && idTypes.length > 0) {
      idTypes.forEach((id) => {
        if (id.value === selectedBooking.id_type) {
          setSelectedId(id);
        }
      });
    }
  }, [idTypes, selectedBooking]);

  useEffect(() => {
    if (selectedBooking && paymentModes && paymentModes.length > 0) {
      paymentModes.forEach((mode) => {
        if (mode.value === selectedBooking.pay_mode) {
          setSelectedMode(mode);
        }
      });
    }
  }, [paymentModes, selectedBooking]);

  const prepareUsers = (users) => {
    setUserOptionsList([]);
    if (users && users.length > 0) {
      let usersList = [];
      users.forEach((item) => {
        if (item) {
          usersList.push({
            label: `${item.user_id} - ${item.first_name} ${item.last_name}`,
            value: `${item.user_id}`,
          });
        }
      });

      setUserOptionsList(usersList);
    }
  };

  const dropdownStyles = {
    option: (styles, { provided }) => {
      const whiteSpace = "nowrap";
      const overflow = "hidden";
      const textOverflow = "ellipsis";
      return {
        ...provided,
        whiteSpace,
        overflow,
        textOverflow,
        ...styles,
      };
    },
  };

  const userSelectionHandler = (selectedUser) => {
    setSelectedUserObj(selectedUser);
    users.forEach((user) => {
      if (user?.user_id === selectedUser?.value) {
        setSelectedUser(user);
      }
    });
  };

  const genderSelectionHandler = (gender) => {
    setSelectedGender(gender);
  };

  const idTypeSelectionHandler = (id) => {
    if (id?.label === "Passport") {
      setOtherDocumentsRequired(true);
    } else {
      setOtherDocumentsRequired(false);
    }
    setSelectedId(id);
  };

  const modeSelectionHandler = (mode) => {
    setSelectedMode(mode);
  };

  const createBookingHandler = async () => {
    setIsSubmitCalled(true);
    if (
      newUser &&
      validateField(selectedCustomerOBj, "object") &&
      validateField(selectedUser?.first_name, "string") &&
      validateField(selectedUser?.last_name, "string") &&
      validateField(
        selectedBooking?.date_of_birth
          ? selectedBooking?.date_of_birth
          : dateofBirth,
        "date"
      ) &&
      validateField(selectedCountry, "object") &&
      validateField(selectedUser?.email, "email") &&
      validateField(selectedGender, "object") &&
      validateField(selectedUser?.national_id, "string") &&
      validateField(selectedUser?.mobile, "phone") &&
      validateField(selectedId, "object")
    ) {
      if (
        !otherDocumentsRequired ||
        (otherDocumentsRequired && otherDocumentsUploaded)
      ) {
        globalLoader(true);
        createNewBooking();
      }
    } else if (
      !newUser &&
      validateField(selectedUser?.user_id, "string") &&
      validateField(
        selectedBooking?.date_of_birth
          ? selectedBooking?.date_of_birth
          : dateofBirth,
        "date"
      ) &&
      validateField(selectedUser?.email, "email") &&
      validateField(selectedGender, "object") &&
      validateField(selectedId, "object")
    ) {
      if (
        !otherDocumentsRequired ||
        (otherDocumentsRequired && otherDocumentsUploaded)
      ) {
        globalLoader(true);
        createNewBooking();
      }
    }
  };

  const countrySelectionHandler = (country) => {
    setSelectedCountry(country);
    let newUser = { ...selectedUser };
    newUser.nationality = country?.value;
    setSelectedUser(newUser);
  };

  const companyChangeHandler = (selectedCompany) => {
    setSelectedCustomerObj(selectedCompany);
  };

  const onIDUpload = (image) => {
    setIDImage(image?.file);
    setIDImageType(image?.type);
  };

  const onLicenceUpload = (image) => {
    setLicenceImage(image?.file);
    setLicenceImageType(image?.type);
  };

  const onImageUpload = (image) => {
    setOtherDocumentsUploaded(true);
    setOtherDocumentsRequired(false);
    const newImage = {
      img_file_type: image?.type,
      img_type_id: selectedFileType?.value,
      img_url: image?.file,
    };

    setUploadedFiles([...uploadedFiles, newImage]);
  };

  const createNewBooking = async () => {
    delete booking.mobile_number;
    booking.cdw_optional =
      !booking.cdw_optional || booking.cdw_optional === ""
        ? "0"
        : booking.cdw_optional.toString();
    booking.cdw_selected = booking.cdw_optional;
    booking.travel_permit =
      !booking.travel_permit || booking.travel_permit === ""
        ? "0"
        : booking.travel_permit.toString();
    booking.gcc_permit_seleted = booking.travel_permit;

    booking.no_of_addtional_driver =
      !booking.no_of_addtional_driver || booking.no_of_addtional_driver === ""
        ? "0"
        : booking.no_of_addtional_driver.toString();
    let bookingObj = {
      ...booking,
      first_name: selectedUser?.first_name,
      last_name: selectedUser?.last_name,
      mobile_no: selectedUser?.mobile,
      user_id: selectedUser?.user_id,
      id_number: selectedUser?.national_id,
      nationality: selectedUser?.nationality,
      cust_code: newUser
        ? selectedCustomerOBj?.value
        : selectedUser?.company_code,
      cust_name: selectedUser?.company_name,
      date_of_birth: dateofBirth?.split(" ")[0],
      gender: selectedGender?.value,
      id_proof_image: idImage,
      id_proof_image_type: idImageType,
      license_image: licenceImage,
      license_image_type: licenceImageType,
      image: uploadedFiles,
      email: selectedUser?.email,
      mobile_country: selectedCountry?.value,
      id_type: selectedId?.value,
      pay_mode: selectedMode?.value,
    };

    setBooking(bookingObj);

    console.log(
      "🚀 ~ file: booking-confirmation.jsx:326 ~ createNewBooking ~ booking:",
      booking
    );
    try {
      let res = null;
      if (booking?.booking_id.length > 0) {
        res = await updateBookingApi(bookingObj);
      } else {
        res = await createBooking(bookingObj);
      }

      if (res?.data?.status === 0) {
        setBookingId(res?.data?.data[0].booking_id);
        setShowBookingSuccess(true);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
      globalLoader(false);
    } catch (err) {
      toast.error("Error while creating new booking request");
      console.log(err);
      globalLoader(false);
    }
  };

  return (
    <>
      <div
        style={{ marginLeft: "100px", marginTop: "100px", marginRight: "50px" }}
      >
        <div className="row">
          <BookingHeader
            activeStep={3}
            backClicked={() => {
              setExtrasSelected(false);
            }}
          />
        </div>
        <div className="row" style={{ marginBottom: "50px" }}>
          <div className="col-2"></div>
          <div className="col-8">
            <div className="row" style={{ marginTop: "20px" }}>
              <div className={`col-12 ${styles.header_form_information} `}>
                <h4>Confirmation</h4>
                <p>
                  Please provide the following information to confirm your
                  booking.
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-12"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h3 style={{ marginBottom: "0px" }}>New User?</h3>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={newUser}
                    onChange={() => {
                      setNewUser(!newUser);
                      setLastSelectedUser(selectedUser);
                      setDateofBirth(null);
                      if (selectedBooking) {
                        selectedBooking.date_of_birth = null;
                        selectedBooking.userid = null;
                      }

                      setResetForm(Date.now());
                      if (!selectedBooking) setSelectedUserObj(null);

                      setSelectedUser(
                        !newUser || !selectedBooking
                          ? userData
                          : lastSelectedUser
                      );
                      // setSelectedUser(userData);
                    }}
                  ></input>
                  <span className="slider"></span>
                </label>
              </div>
            </div>

            <div className="row" style={{ marginTop: "20px" }}>
              {!newUser && (
                <div className="col-6">
                  <Label for="user">
                    User ID<span className="color-primary">*</span>
                  </Label>
                  <Select
                    styles={dropdownStyles}
                    closeMenuOnSelect={true}
                    placeholder="User ID"
                    options={userOptionsList}
                    onChange={userSelectionHandler}
                    value={selectedUserOBj}
                    menuPlacement="bottom"
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={userOptionsList.length === 0}
                    className={`select-dropdown-own ${
                      !validateField(selectedUserOBj, "object") &&
                      isSubmitCalled &&
                      "on-focus"
                    }`}
                    components={animatedComponents}
                  />
                  {!validateField(selectedUserOBj, "object") &&
                    isSubmitCalled && (
                      <div className="error-text">User ID is required</div>
                    )}
                </div>
              )}

              {newUser && (
                <div className="col-6">
                  <Label for="user">
                    Customer<span className="color-primary">*</span>
                  </Label>
                  <Select
                    styles={dropdownStyles}
                    closeMenuOnSelect={true}
                    placeholder=""
                    options={companyList}
                    onChange={companyChangeHandler}
                    value={selectedCustomerOBj}
                    menuPlacement="bottom"
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={companyList.length === 0}
                    className={`select-dropdown-own ${
                      !validateField(selectedCustomerOBj, "object") &&
                      isSubmitCalled &&
                      "on-focus"
                    }`}
                    components={animatedComponents}
                  />
                  {!validateField(selectedCustomerOBj, "object") &&
                    isSubmitCalled && (
                      <div className="error-text">Customer is required</div>
                    )}
                </div>
              )}
              <div className="col-6">
                <FormGroup>
                  <Label for="first_name">
                    First Name
                    <span className="color-primary">*</span>
                  </Label>
                  <InputGroup size="md">
                    <Input
                      id="first_name"
                      value={selectedUser?.first_name}
                      disabled={!newUser}
                      onChange={(event) => {
                        let newUser = { ...selectedUser };
                        newUser.first_name = event.target.value;
                        setSelectedUser(newUser);
                      }}
                      className={` ${
                        newUser &&
                        !validateField(selectedUser?.first_name, "string") &&
                        isSubmitCalled &&
                        "on-focus"
                      }`}
                    />
                  </InputGroup>
                </FormGroup>
              </div>

              <div className="col-6">
                <FormGroup>
                  <Label for="last_name">
                    Last Name<span className="color-primary">*</span>
                  </Label>
                  <InputGroup size="md">
                    <Input
                      id="last_name"
                      value={selectedUser ? selectedUser?.last_name : ""}
                      disabled={!newUser}
                      onChange={(event) => {
                        let newUser = { ...selectedUser };
                        newUser.last_name = event.target.value;
                        setSelectedUser(newUser);
                      }}
                      className={` ${
                        newUser &&
                        !validateField(selectedUser?.last_name, "string") &&
                        isSubmitCalled &&
                        "on-focus"
                      }`}
                    />
                  </InputGroup>
                </FormGroup>
              </div>

              <div className="col-6">
                <FormGroup>
                  <Label for="prefDate">
                    Date of Birth<span className="color-primary">*</span>
                  </Label>

                  <InputGroup size="md">
                    <div
                      className={` ${
                        !validateField(
                          selectedBooking?.date_of_birth
                            ? selectedBooking?.date_of_birth
                            : dateofBirth,
                          "date"
                        ) && isSubmitCalled
                          ? "on-focus w-100"
                          : "w-100"
                      }`}
                    >
                      <DateTimePicker
                        enableTime={false}
                        format="d-m-Y"
                        maxDate={new Date().setFullYear(
                          new Date().getFullYear() - 18
                        )}
                        onDateTimeSelect={(selectedDateTime) => {
                          if (selectedDateTime && selectedDateTime.length > 0) {
                            setDateofBirth(
                              formatDate(
                                selectedDateTime[0],
                                "yyyy-MM-dd HH:mm:ss"
                              )
                            );
                          }
                        }}
                        initialDateTime={
                          selectedBooking && selectedBooking.date_of_birth
                            ? parseInt(selectedBooking.date_of_birth + "000")
                            : null
                        }
                        reset={resetForm}
                      />
                    </div>
                  </InputGroup>
                </FormGroup>
              </div>

              {newUser && (
                <div className="col-6">
                  <Label for="country">
                    Nationality<span className="color-primary">*</span>
                  </Label>
                  <Select
                    styles={dropdownStyles}
                    closeMenuOnSelect={true}
                    placeholder="Countries"
                    options={countries}
                    onChange={countrySelectionHandler}
                    value={selectedCountry}
                    menuPlacement="bottom"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      !validateField(selectedCountry, "object") &&
                      isSubmitCalled &&
                      "on-focus"
                    }`}
                    components={animatedComponents}
                  />
                  {!validateField(selectedCountry, "object") &&
                    isSubmitCalled && (
                      <div className="error-text">Country is required</div>
                    )}
                </div>
              )}
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">
                    Email Address<span className="color-primary">*</span>
                  </Label>
                  <InputGroup size="md">
                    <Input
                      id="exampleEmail"
                      type="email"
                      value={selectedUser ? selectedUser?.email : ""}
                      onChange={(event) => {
                        let newUser = { ...selectedUser };
                        newUser.email = event.target.value;
                        setSelectedUser(newUser);
                      }}
                      className={`select-dropdown-own ${
                        !validateField(selectedUser?.email, "email") &&
                        isSubmitCalled &&
                        "on-focus"
                      }`}
                    />
                  </InputGroup>
                </FormGroup>
              </div>

              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">
                    Gender<span className="color-primary">*</span>
                  </Label>
                  <Select
                    styles={dropdownStyles}
                    closeMenuOnSelect={true}
                    options={genderOptions}
                    onChange={genderSelectionHandler}
                    value={selectedGender}
                    menuPlacement="bottom"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      !validateField(selectedGender, "object") &&
                      isSubmitCalled &&
                      "on-focus"
                    }`}
                    components={animatedComponents}
                  />
                </FormGroup>
              </div>

              <div className="col-6">
                <FormGroup>
                  <Label for="id_card_number">
                    ID Number<span className="color-primary">*</span>
                  </Label>
                  <InputGroup size="md">
                    <Input
                      id="id_card_number"
                      value={selectedUser ? selectedUser?.national_id : ""}
                      disabled={!newUser}
                      onChange={(event) => {
                        let newUser = { ...selectedUser };
                        newUser.national_id = event.target.value;
                        setSelectedUser(newUser);
                      }}
                      className={`select-dropdown-own ${
                        newUser &&
                        !validateField(selectedUser?.national_id, "string") &&
                        isSubmitCalled &&
                        "on-focus"
                      }`}
                    />
                  </InputGroup>
                </FormGroup>
              </div>

              <div className="col-6">
                <FormGroup>
                  <Label for="phone_number">
                    Phone Number<span className="color-primary">*</span>
                  </Label>
                  <InputGroup size="md">
                    <Input
                      id="phone_number"
                      value={selectedUser ? selectedUser?.mobile : ""}
                      disabled={!newUser}
                      onChange={(event) => {
                        let newUser = { ...selectedUser };
                        newUser.mobile = event.target.value;
                        setSelectedUser(newUser);
                      }}
                      className={`select-dropdown-own ${
                        newUser &&
                        !validateField(selectedUser?.mobile, "phone") &&
                        isSubmitCalled &&
                        "on-focus"
                      }`}
                    />
                  </InputGroup>
                </FormGroup>
              </div>

              <div className="col-6">
                <FormGroup>
                  <Label for="prefDate">
                    ID Type<span className="color-primary">*</span>
                  </Label>
                  {idTypes && (
                    <Select
                      styles={dropdownStyles}
                      closeMenuOnSelect={true}
                      options={idTypes}
                      onChange={idTypeSelectionHandler}
                      value={selectedId}
                      menuPlacement="bottom"
                      isSearchable={true}
                      isClearable={true}
                      className={`select-dropdown-own ${
                        !validateField(selectedId, "object") &&
                        isSubmitCalled &&
                        "on-focus"
                      }`}
                      components={animatedComponents}
                    />
                  )}
                </FormGroup>
              </div>

              <div className="col-6">
                <FormGroup>
                  <Label for="prefDate">Payment Mode</Label>
                  <Select
                    styles={dropdownStyles}
                    closeMenuOnSelect={true}
                    options={paymentModes}
                    value={selectedMode}
                    onChange={modeSelectionHandler}
                    menuPlacement="bottom"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      !validateField(selectedGender, "object") &&
                      isSubmitCalled &&
                      "on-focus"
                    }`}
                    components={animatedComponents}
                  />
                </FormGroup>
              </div>

              <div className="col-6">
                <FileUpload title="ID Image" onUpload={onIDUpload} />
              </div>
              <div className="col-6">
                <FileUpload title="Licence Image" onUpload={onLicenceUpload} />
              </div>

              <div className="col-6">
                <FileUpload
                  title="Supporting Documents"
                  onUpload={onImageUpload}
                  errorMessage={
                    otherDocumentsRequired
                      ? "Supporting Documents Required"
                      : ""
                  }
                />
              </div>
              {/* <div className="col-3" style={{ marginTop: "-20px" }}>
                <Label for="user">
                  Image Type<span className="color-primary">*</span>
                </Label>
                <Select
                  styles={dropdownStyles}
                  closeMenuOnSelect={true}
                  placeholder=""
                  options={fileTypes}
                  onChange={(item) => {
                    setSelectedFileType(item);
                  }}
                  value={selectedFileType}
                  menuPlacement="bottom"
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={!fileTypes || fileTypes.length === 0}
                  className={`select-dropdown-own ${
                    !selectedFileType && isSubmitCalled && "on-focus"
                  }`}
                  components={animatedComponents}
                />
              </div> */}

              <div className="col-12" style={{ marginTop: "-10px" }}>
                <PrimaryButtonLg
                  title="Submit Request"
                  onClick={createBookingHandler}
                />
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      {showBookingSuccess && (
        <BookingSuccessModal
          bookingId={bookingId}
          open={showBookingSuccess}
          onCloseModal={() => {
            setShowBookingSuccess(false);
            history?.push({
              pathname: "/admin/rent_request_corp",
            });
          }}
        />
      )}
    </>
  );
};

export default BookingConfirmation;
