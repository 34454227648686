import React from "react";
import { Card, CardBody } from "reactstrap";

const ReportCard = (props) => {
  const { count, imgSrc, type, label, flag } = props;

  const handleClick = () => {
    let pathName;
    if (type === "Accidents") {
      pathName = "/admin/reported_accidents";
    }
    if (type === "Self Inspection") {
      pathName = "/admin/damage_listing";
    }
    if (type === "Traffic Violations") {
      pathName = "/admin/traffic_violation";
    }
    props?.history?.push({
      pathname: pathName,
      state: {
        fromDash: true,
      },
    });
  };

  return (
    <Card className="dashboard-card-own report-card">
      <CardBody className="report-card-wrapper">
        {flag ? (
          <>
            <div className="d-flex justify-content-between">
              <div className="color-primary weight-800">{count}</div>
              <div className="report-img-wrapper">
                <img src={imgSrc} alt="..." />
              </div>
            </div>
            <div className="weight-800 font-14">{type} Reported</div>
            <div className="font-12 text-muted">{label}</div>
            <div>
              <button
                className="listing-btn float-right mt-2"
                onClick={handleClick}
              >
                Take Action <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </>
        ) : (
          <div className="pt-5 mt-5 color-primary d-flex justify-content-center align-items-center">
            Data Not Available
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default ReportCard;
