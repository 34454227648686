import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { toast } from "react-toastify";

import UserHeader from "../../components/Headers/userHeader";
import NotificationTable from "../../components/tables/notificationTable";

import { globalLoader } from "../../actions/commonActions";
import { getPopupDetails } from "../../_shared/commonFunctions";
import { sendNotificationApi } from "./_actions";

import SendNotificationModal from "../../components/Modals/sendNotificationModal";
import DetailsModal from "../../components/Modals/detailsModal";

import { useUserNotificationsData } from "hooks/useNotificationsData";
import { useNotificationsData } from "hooks/useNotificationsData";
import { useCompaniesData } from "hooks/useCompaniesData";
import { useAppUserData } from "hooks/useAppUserData";

const NotificationManagement = (props) => {
  const { currentCompany } = props;
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [statusList] = useState([
    { id: "0", name: "False" },
    { id: "1", name: "True" },
  ]);

  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: userNotifications,
    isFetching,
    isFetched,
    refetch,
  } = useUserNotificationsData(currentCompany);

  globalLoader(isFetching);

  const { data: notifications } = useNotificationsData(currentCompany);

  const { data: companies } = useCompaniesData(currentCompany);

  const { data: appUsers } = useAppUserData(currentCompany);

  useEffect(() => {
    if (appUsers && appUsers.length > 0) {
      const options = [];
      appUsers.forEach((user) => {
        options.push({
          value: user.user_id,
          label: user.first_name + " " + user.last_name,
        });
      });

      setUserOptions(options);
    }
  }, [appUsers]);

  const notificationModalClose = async (obj) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await sendNotificationApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (err) {
        toast.error("Error while posting notification");
        console.log(err);
      }
      globalLoader(false);
      refetch();
    }
    setOpenNotificationModal(false);
  };

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setData(getPopupDetails(obj, type));
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle("");
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">
                    Notification Management
                  </h3>
                  <div className="col text-right">
                    <button
                      className="btn btn-sm primary-bg"
                      onClick={() => setOpenNotificationModal(true)}
                    >
                      Send Notification
                    </button>
                  </div>
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    userNotifications && userNotifications.length > 0 ? (
                      <NotificationTable
                        statusList={statusList}
                        tableData={userNotifications}
                        notificationList={notifications}
                        customerModalOpen={customerModalOpen}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {openNotificationModal && (
        <SendNotificationModal
          open={openNotificationModal}
          notificationList={notifications}
          userOptions={userOptions}
          companyOptions={companies}
          onCloseModal={notificationModalClose}
        />
      )}
      {userModal && (
        <DetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          data={data}
          title={detailsTitle}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateProps)(NotificationManagement);
