import React, { useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { useState } from "react";
import { globalLoader } from "actions/commonActions";

import ViewImageModal from "./viewImageModal";
import { getDriverByID } from "views/examples/_actions";

const DriverDetailsModal = (props) => {
  const [user, setUser] = useState(null);

  const [imgType, setImgType] = useState("");
  const [viewImageModal, setViewImageModal] = useState(false);
  const [imgData, setImgData] = useState(null);

  useEffect(() => {
    if (props?.formData) {
      fetchUsersList(props?.formData);
    }
  }, [props]);

  const fetchUsersList = async (id) => {
    globalLoader(true);

    const response = await getDriverByID(id);

    if (response && response.data && response.data.data) {
      setUser(response.data.data[0]);
    }

    globalLoader(false);
  };

  const showImageHandler = (val = null, type = null) => {
    if (val) {
      let temp = [{ img_url: val }];
      setImgData(temp);
      setImgType(type);
      setViewImageModal(true);
    }
  };

  const closeImageModal = () => {
    setImgType("");
    setViewImageModal(false);
    setImgData(null);
  };

  return (
    <Modal
      open={props?.open}
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      center
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">User Details</label>
      </div>
      <ul
        className="details-wrapper list-unstyled pt-3"
        style={{ width: "500px" }}
      >
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            National ID
          </div>
          {user?.id_no && (
            <div
              className="col-6"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div>{user?.id_no}</div>
              {user?.id_proof_image && (
                <div
                  onClick={() =>
                    showImageHandler(user?.id_proof_image, "National ID")
                  }
                >
                  <div className="ml-3">
                    <i className="fas fa-eye color-primary cursor-pointer"></i>
                  </div>
                </div>
              )}
            </div>
          )}
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            First Name
          </div>
          <div className="col-6">{user?.first_name}</div>
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            Last Name
          </div>
          <div className="col-6">{user?.last_name}</div>
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            Mobile
          </div>
          <div className="col-6">{user?.mobile}</div>
        </li>
        <li className="row pb-3">
          <div className="col-6 color-primary text-right weight-600">
            Nationality
          </div>
          <div className="col-6">{user?.nationality}</div>
        </li>
        {user?.licence_no && (
          <li className="row">
            <div className="col-6 color-primary text-right weight-600">
              Licence
            </div>
            <div
              className="col-6"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div>{user?.licence_no}</div>
              {user?.license_image && (
                <div
                  onClick={() =>
                    showImageHandler(user?.license_image, "Driving License")
                  }
                >
                  <div className="ml-3">
                    <i className="fas fa-eye color-primary cursor-pointer"></i>
                  </div>
                </div>
              )}
            </div>
          </li>
        )}
      </ul>

      {viewImageModal && (
        <ViewImageModal
          open={viewImageModal}
          onCloseModal={closeImageModal}
          data={imgData}
          imgType={imgType}
        />
      )}
    </Modal>
  );
};

export default DriverDetailsModal;
