import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { appUserListApi } from "../views/examples/_actions";

export const useAppUserData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["app-users", currentCompany, profile_email],
    () => appUserListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const users = data?.data?.data;
          return users;
        } else {
          return null;
        }
      },
    }
  );
};
