import React, { useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

const BookingCard = (props) => {
  const { carBookingCard, searchId = null, searchFilter = null } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Card className="dashboard-card-own booking-card-wrapper">
      <CardHeader className="card-header-own">
        <div className="font-15 color-primary weight-600">
          Car Booking Today
        </div>
        <button
          className="listing-btn"
          onClick={() =>
            props?.history?.push({
              pathname: "/admin/rent_request",
              state:
                searchFilter && searchId
                  ? { searchId: searchId, searchFilter: searchFilter }
                  : null,
            })
          }
        >
          Take Action <i className="fas fa-arrow-right"></i>
        </button>
      </CardHeader>
      <CardBody className="d-flex flex-column justify-content-between">
        {carBookingCard && carBookingCard.length > 0 ? (
          <>
            <div className="booking-card-name">
              <div className="d-flex justify-content-between">
                <div
                  className="text-truncate pr-1"
                  style={{ maxWidth: "180px" }}
                >
                  <strong>Company: </strong>
                  {carBookingCard[currentIndex]?.company_name}
                </div>
                <div
                  className="pl-1 text-truncate"
                  style={{ maxWidth: "180px" }}
                >
                  <strong>Name: </strong>
                  {carBookingCard[currentIndex]?.user_name}
                </div>
              </div>
              <div
                className="text-truncate weight-600 color-primary"
                style={{ maxWidth: "100px" }}
              >
                {carBookingCard[currentIndex]?.make_model}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="row font-12 table-wrapper">
                <div className="col-6 pr-0 mb-2">
                  <div className="weight-600 color-primary">Amount</div>
                  <div className="table-data">
                    {carBookingCard[currentIndex]?.amount}
                  </div>
                </div>
                <div className="col-6 pl-0 mb-2">
                  <div className="weight-600 color-primary">Location</div>
                  <div className="table-data">
                    {carBookingCard[currentIndex]?.location}
                  </div>
                </div>
                <div className="col-6 pr-0 mt-2">
                  <div className="weight-600 color-primary">Duration</div>
                  <div className="table-data">
                    {carBookingCard[currentIndex]?.duration}{" "}
                    {carBookingCard[currentIndex]?.unit}
                  </div>
                </div>
                <div className="col-6 pl-0 mt-2">
                  <div className="weight-600 color-primary">Start Date</div>
                  <div className="text-nowrap table-data">
                    {new Date(
                      carBookingCard[currentIndex]?.start_date
                    ).toLocaleString("en-Us", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour12: true,
                      minute: "2-digit",
                      hour: "2-digit",
                    })}
                  </div>
                </div>
              </div>
              <div className="img-wrapper">
                <img src={carBookingCard[currentIndex]?.image_link} alt="" />
              </div>
            </div>
            <div className="d-flex align-items-center font-12">
              <i
                className={`fas fa-arrow-left pr-1 ${
                  currentIndex > 0 && "color-primary cursor-pointer"
                }`}
                onClick={() => {
                  if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
                }}
              ></i>
              <i
                className={`fas fa-arrow-right px-2 ${
                  currentIndex < carBookingCard.length - 1 &&
                  "color-primary cursor-pointer"
                }`}
                onClick={() => {
                  if (currentIndex < carBookingCard.length - 1)
                    setCurrentIndex(currentIndex + 1);
                }}
              ></i>
              <span className="text-muted pl-1">
                {currentIndex + 1} / {carBookingCard.length}
              </span>
            </div>
          </>
        ) : (
          <div className="mt-5 pt-4 color-primary d-flex justify-content-center align-items-center">
            Data Not Available
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default BookingCard;
