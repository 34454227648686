import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { validNumber } from "../../_shared/commonFunctions";

const AddChauffeurServiceModal = (props) => {
  const { data } = props;
  const [desc, setDesc] = useState("");
  const [descAr, setDescAr] = useState("");
  const [hours, setHours] = useState("");
  const [hoursErr, setHoursErr] = useState(false);
  const [descErr, setDescErr] = useState(false);
  const [descArErr, setDescArErr] = useState(false);

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = () => {
    if (data) {
      setDesc(data?.rate_type_desc);
      setHours(data?.hours || "");
      setDescAr(data?.rate_type_desc_ar || "");
    }
  };

  const numberValidation = async (ev) => {
    let isValid;
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let { name, value } = ev.target;

    isValid = await validNumber(value);

    if (isValid) {
      if (name === "hours") setHours(value);
    }
  };

  const handleValidation = () => {
    let flag = true;
    if (!desc) {
      setDescErr(true);
      flag = false;
    }
    if (!hours) {
      setHoursErr(true);
      flag = false;
    }
    if (!descAr) {
      setDescArErr(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      const obj = {
        rtp_desc: desc,
        rtp_hours: hours,
        rtp_desc_ar: descAr,
      };
      if (data) {
        obj.rtp_type_id = data?.rate_type;
        obj.rtp_active = data?.rate_status;
      }
      props?.onCloseModal(obj);
    }
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">
          {data ? "Edit Chauffeur Service" : "Add Chauffeur Service"}
        </label>
      </div>
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="desc">
                  Description<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="desc"
                  id="desc"
                  placeholder="Description"
                  value={desc}
                  onChange={(ev) => setDesc(ev.target.value)}
                  className={`${!desc && descErr && "on-focus"}`}
                />
                {!desc && descErr && (
                  <div className="error-text">Description is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="hours">
                  Hours<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="hours"
                  id="hours"
                  placeholder="Hours"
                  value={hours}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!hours && hoursErr && "on-focus"}`}
                />
                {!hours && hoursErr && (
                  <div className="error-text">Hours is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="descAr">
                  Description (Arabic)<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="descAr"
                  id="descAr"
                  placeholder="Description (Arabic)"
                  value={descAr}
                  onChange={(ev) => setDescAr(ev.target.value)}
                  className={`${!descAr && descArErr && "on-focus"}`}
                  style={{ textAlign: "right" }}
                />
                {!descAr && descArErr && (
                  <div className="error-text">
                    Description (Arabic) is required
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center my-3 pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={(ev) => {
                  ev.preventDefault();
                  props?.onCloseModal();
                }}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                {data ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddChauffeurServiceModal;
