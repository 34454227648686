import {
  listingTableData,
  profileApiData,
  roleApiData,
} from "../actions/commonActions";
import Cookies from "js-cookie";
import { globalLoader } from "actions/commonActions";
import { portalLogoutApi } from "views/examples/_actions";
import { companyApiData } from "actions/commonActions";
import { currentCompanyData } from "actions/commonActions";
import { portalActivityStatusList } from "views/examples/_actions";
import { toast } from "react-toastify";

export const logOut = async (value, link) => {
  globalLoader(true);
  roleApiData(null);
  profileApiData(null);
  listingTableData(null);
  companyApiData(null);
  currentCompanyData(null);
  Cookies.remove("access_token");
  Cookies.remove("profile_email");
  value?.push(link);
  try {
    let res = await portalLogoutApi();
    console.log(res);
  } catch (err) {
    console.log(err);
  }
  globalLoader(false);
};

export const validEmail = (val) => {
  // eslint-disable-next-line
  let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  if (regex.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const validNumber = (val) => {
  const regex = /^[0-9\b]+$/;
  if (val === "" || regex.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const validDecimalNumber = (val) => {
  const regex = /^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
  if (val === "" || regex.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const validDate = (val) => {
  return val instanceof Date && !isNaN(val);
};

export const convertDateTime = (val) => {
  let dt = new Date(val);
  let newDt = `${dt.getFullYear()}-${
    dt.getDate() < 10 ? `0${dt.getDate()}` : `${dt.getDate()}`
  }-${
    dt.getMonth() + 1 < 10 ? `0${dt.getMonth() + 1}` : `${dt.getMonth() + 1}`
  } ${dt.getHours() < 10 ? `0${dt.getHours()}` : `${dt.getHours()}`}:${
    dt.getMinutes() < 10 ? `0${dt.getMinutes()}` : `${dt.getMinutes()}`
  }:${
    dt.getSeconds() + 1 < 10
      ? `0${dt.getSeconds() + 1}`
      : `${dt.getSeconds() + 1}`
  }`;
  return newDt;
};

export const getStatusArray = async (id) => {
  let statusArray = [];
  try {
    let res = await portalActivityStatusList(id);
    if (res?.data?.status === 0) {
      res?.data?.data?.forEach((item) => {
        statusArray.push({ name: item?.sta_desc, id: item?.sta_id });
      });
      return statusArray;
    } else {
      console.log(res);
      return null;
    }
  } catch (err) {
    toast.error("Error in getting status");
    console.log(err);
  }
};

export const tableDropdownData = (id, list) => {
  return (
    list &&
    list.length > 0 &&
    list.filter((a) => a.id === id).map((b) => b.name)
  );
};

export const getPopupDetails = (obj, type) => {
  let modalData;
  if (type === "company") {
    modalData = [
      { label: "Company Code", value: obj?.company_code },
      { label: "Company Name", value: obj?.company_name },
    ];
  }
  if (type === "customer") {
    modalData = [
      { label: "National Id", value: obj?.national_id },
      { label: "First Name", value: `${obj?.first_name}` },
      { label: "Last Name", value: `${obj?.last_name}` },
      { label: "Mobile", value: obj?.mobile },
      // { label: "Nationality", value: obj?.mobile_country },
    ];
  }
  return modalData;
};

export const YEAR_LIST = () => {
  let max = new Date().getFullYear();
  let min = max - 100;
  let arr = [];
  for (let i = max; i >= min; i--) {
    arr.push({ value: i.toString(), label: i.toString() });
  }
  return arr;
};

export const handleArrayData = (arr) => {
  let newArr = [];
  arr.forEach((item) => {
    let obj = {
      img_url: item.img_url,
      make: item.make,
      makemodel: item.makemodel,
      model: item.model,
      plate_no: item.plate_no,
      istimara: [],
      insurance: [],
      mvpi: [],
      mot: [],
      image: [],
    };
    item.docs.forEach((doc) => {
      if (doc.doc_type_id === "1") {
        let url = [];
        doc.doc_list.forEach((list) => {
          url.push(list.url);
        });
        obj.istimara.push(url);
      }
      if (doc.doc_type_id === "2") {
        let url = [];
        doc.doc_list.forEach((list) => {
          url.push(list.url);
        });
        obj.insurance.push(url);
      }
      if (doc.doc_type_id === "3") {
        let url = [];
        doc.doc_list.forEach((list) => {
          url.push(list.url);
        });
        obj.mvpi.push(url);
      }
      if (doc.doc_type_id === "4") {
        let url = [];
        doc.doc_list.forEach((list) => {
          url.push(list.url);
        });
        obj.mot.push(url);
      }
      if (doc.doc_type_id === "9") {
        let url = [];
        doc.doc_list.forEach((list) => {
          url.push(list.url);
        });
        obj.image.push(url);
      }
    });
    newArr.push(obj);
  });
  return newArr;
};
