import React from "react";
import { Card, CardBody } from "reactstrap";

const StatsCard = (props) => {
  const { statsCard, type, imgSrc } = props;
  return (
    <Card className="dashboard-card-own stats-card-wrapper">
      <CardBody className="d-flex flex-column justify-content-between pb-3 pl-2">
        {statsCard && statsCard.length > 0 ? (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <img
                  style={{ width: "70px" }}
                  src={
                    require(`../../../assets/img/icons/common/${imgSrc}.jpg`)
                      .default
                  }
                  alt="..."
                />
              </div>
              <div className="text-right">
                <div>
                  {statsCard[0]?.is_profit ? (
                    <i className="fas fa-caret-up color-green mr-1"></i>
                  ) : (
                    <i className="fas fa-caret-down color-orange mr-1"></i>
                  )}
                  <span className="weight-800">
                    {statsCard[0]?.percentage} %
                  </span>
                </div>
                <div className="text-muted font-10">{statsCard[0]?.lable}</div>
              </div>
            </div>
            <div className="pl-2">
              <div className="color-primary weight-800 font-20">
                {statsCard[0]?.count}
              </div>
              <div className="font-13">{type}</div>
            </div>
          </>
        ) : (
          <div className="mt-5 pt-4 color-primary d-flex justify-content-center align-items-center">
            Data Not Available
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default StatsCard;
