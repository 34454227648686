import React, { useRef, useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Moment from "moment";
import firebase from "firebase/compat/app";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../src/firebase";
import { firestore } from "../../../src/firebase";
import { profileDetailsApi } from "../../views/examples/_actions";

const Chat = (props) => {
  const { onCloseModal, open, chatContext } = props;

  const [userDataLoading, setUserDataLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [userData, setUserData] = useState({});
  const closeHandler = async () => {
    const userRef = firestore
      .collection("threads")
      .doc(chatContext.booking_id)
      .collection("users")
      .doc(userData.user_id);
    await userRef.set(
      {
        user_last_viewed_time: firebase.firestore.FieldValue.serverTimestamp(),
        user_id: userData.user_id,
      },
      { merge: true }
    );
    setIsModalOpen(false);
    onCloseModal();
  };

  const notifyUser = (message) => {
    if (chatContext.rac_source == "1") {
      let obj = {
        message: message,
        tag: `AVIS CARE - Rent`,
        type: "6",
        user_id: [chatContext?.userid],
      };
      props.sendNotification(obj);
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      let res = await profileDetailsApi();
      let userData = res?.data?.data[0];
      setUserData(userData);
      setUserDataLoading(false);
    } catch (err) {
      if (err?.response?.status === 401) {
        // toast.error("Error while fetching profile details");
      }
    }
  };

  return (
    <Modal
      open={open}
      center
      onClose={() => closeHandler()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own chat-modal" }}
    >
      <div
        className="chat-container"
        // style={{ borderRadius: "40px", padding: "1.2rem" }}
      >
        <div className="chat-header-bar">
          <label className="text-center weight-800">
            AVISCare Conversation
          </label>
        </div>
        {!userDataLoading && (
          <section>
            {
              <ChatRoom
                chatId={chatContext.booking_id}
                isModalOpen={isModalOpen}
                notifyUser={notifyUser}
              />
            }
          </section>
        )}
        {userDataLoading && (
          <i
            className="fa fa-circle-o-notch fa-spin"
            style={{ fontSize: "24px" }}
          ></i>
        )}
      </div>
    </Modal>
  );

  function ChatRoom(props) {
    const { chatId, isModalOpen, notifyUser } = props;
    const dummy = useRef();
    const messagesRef = firestore
      .collection("threads")
      .doc(chatId)
      .collection("messages");
    let query = firestore
      .collection("threads")
      .doc(chatId)
      .collection("messages");
    query = query.orderBy("message_send_time");
    const [messages] = useCollectionData(query, { idField: "id" });

    const [formValue, setFormValue] = useState("");
    const [file, setFile] = useState(null); // progress
    const [attachment, setAttachment] = useState(null); // progress
    const [percent, setPercent] = useState(0); // Handle file upload event and update state
    function handleChange(event) {
      setFile(event.target.files[0]);
    }

    const onUnmount = () => {};

    useEffect(() => {
      if (!isModalOpen) onUnmount();
    }, [isModalOpen]);

    useEffect(() => {
      if (messages) {
        setTimeout(() => {
          dummy.current?.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    }, [messages]);
    useEffect(() => {
      if (attachment && attachment.url) {
        sendMessage();
      }
    }, [attachment]);

    const handleSend = async (e) => {
      e.preventDefault();
      if (file) handleUpload();
      else {
        sendMessage();
      }
    };

    const sendMessage = async () => {
      await messagesRef.add({
        ...(attachment && {
          attachment: { url: attachment.url, type: attachment.type },
        }),
        comments: "",
        company: userData.company,
        designation: userData.designation,
        messsage_text: formValue,
        message_send_time: firebase.firestore.FieldValue.serverTimestamp(),
        message_read_time: firebase.firestore.FieldValue.serverTimestamp(),
        read_status: 0,
        side: 1,
        user_id: userData.user_id,
        user_name: userData.first_name,
        photoURL: userData.profile_image,
        chatId: chatId,
      });
      const messageDoc = firestore.collection("threads").doc(chatId);
      await messageDoc.set(
        {
          last_message_created_time:
            firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
      notifyUser(formValue);
      setFormValue("");
      setAttachment(null);
      dummy.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleUpload = () => {
      const storageRef = ref(storage, `/files/${Date.now() + file.name}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          ); // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFile(null);
            setAttachment({ url: url, type: "image" });
          });
        }
      );
    };

    const clearFile = () => {
      setFile(null);
    };

    return (
      <>
        <main>
          {messages &&
            messages.map((msg) => <ChatMessage key={msg.id} message={msg} />)}

          <span ref={dummy}></span>
        </main>
        {file && (
          <span className="file-name-container">
            <label className="file-name">{file.name}</label>
            <i class="fa fa-times" aria-hidden="true" onClick={clearFile}></i>
          </span>
        )}
        <form onSubmit={handleSend}>
          <input
            style={{
              display: "none",
            }}
            accept="image/*" // specify the file type that you wanted to accept
            id="choose-file"
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="choose-file" className="attachment-div">
            {/* <i className="chat-attachment"></i> */}
            <i
              class="fa fa-paperclip"
              aria-hidden="true"
              style={{
                cursor: "pointer",
              }}
            ></i>
          </label>

          <input
            value={formValue}
            onChange={(e) => setFormValue(e.target.value)}
            placeholder="Type here"
          />

          <button type="submit" disabled={!formValue && !file}>
            <i className="fas fa-arrow-right"></i>
          </button>
        </form>
      </>
    );
  }

  function ChatMessage(props) {
    const {
      messsage_text,
      user_id,
      photoURL,
      attachment,
      user_name,
      message_send_time,
    } = props.message;
    const messageClass = user_id === userData.user_id ? "sent" : "received";
    return (
      <>
        <div className={`message ${messageClass}`}>
          <img
            src={
              photoURL ||
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
            }
            className="avatar"
          />
          <div className="message-image-container">
            {attachment && attachment.type == "image" && (
              <img src={attachment.url} className="chat-image" />
            )}
            <div className="message-container">
              {user_id != userData.user_id && (
                <span className="message-sender-name">
                  <p>{user_name}</p>
                </span>
              )}

              {messsage_text && <p>{messsage_text}</p>}
              <span className="message-send-time">
                <p>
                  {" "}
                  {Moment(
                    new Date(message_send_time?.seconds * 1000)
                  ).calendar()}{" "}
                </p>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Chat;
