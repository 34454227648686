import React, { useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

const AssistanceCard = (props) => {
  const { assistanceCard } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleClick = (obj) => {
    props?.history?.push({
      pathname: "/admin/roadside_assistance",
      state: { id: "rsa_service_id", morni_id: obj?.morni_id },
    });
  };

  return (
    <Card className="dashboard-card-own assistsance-card-wrapper">
      <CardHeader className="card-header-own">
        <div className="font-15 color-primary weight-600">
          Roadside Assistance
        </div>
        <button
          className="listing-btn"
          onClick={() => props?.history?.push("/admin/roadside_assistance")}
        >
          View All <i className="fas fa-arrow-right"></i>
        </button>
      </CardHeader>
      <CardBody className="d-flex flex-column justify-content-between">
        {assistanceCard && assistanceCard.length > 0 ? (
          <>
            <div className="booking-card-name">
              <div className="d-flex justify-content-between font-12">
                <div>
                  <div
                    className="text-truncate pr-1"
                    style={{ maxWidth: "250px" }}
                  >
                    {assistanceCard[currentIndex]?.company_name}
                  </div>
                  <div
                    className="pl-1 text-truncate"
                    style={{ maxWidth: "250px" }}
                  >
                    {assistanceCard[currentIndex]?.user_name}
                  </div>
                </div>
              </div>

              <div
                className="text-truncate weight-600 color-primary font-12"
                style={{ maxWidth: "150px" }}
              >
                {assistanceCard[currentIndex]?.make_model}
              </div>
            </div>
            <div className="assistance-remarks-wrapper">
              <div className="title">Remarks</div>
              <div className="roadside-assistance-remarks">
                {assistanceCard[currentIndex]?.remarks}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between">
              <div className="d-flex align-items-end  font-12">
                <i
                  className={`fas fa-arrow-left pr-3 ${
                    currentIndex > 0 && "color-primary cursor-pointer"
                  }`}
                  onClick={() => {
                    if (currentIndex > 0) {
                      setCurrentIndex(currentIndex - 1);
                    }
                  }}
                ></i>
                <i
                  className={`fas fa-arrow-right pr-1 ${
                    currentIndex < assistanceCard.length - 1 &&
                    "color-primary cursor-pointer"
                  }`}
                  onClick={() => {
                    if (currentIndex < assistanceCard.length - 1) {
                      setCurrentIndex(currentIndex + 1);
                    }
                  }}
                ></i>

                <div className="text-muted font-12 px-2">
                  {currentIndex + 1} / {assistanceCard?.length}
                </div>
              </div>

              <button
                className="listing-btn"
                onClick={() => handleClick(assistanceCard[currentIndex])}
              >
                Take Action <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </>
        ) : (
          <div className="mt-5 pt-4 color-primary d-flex justify-content-center align-items-center">
            Data Not Available
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default AssistanceCard;
