import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { validEmail, validNumber } from "_shared/commonFunctions";

const VehicleAssignmentModal = (props) => {
  const [national_id, setNational_id] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [license, setLicense] = useState("");
  const [national_idErr, setNational_idErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [licenseErr, setLisenceErr] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const handleValidation = () => {
    let isValid = true;
    if (!national_id) {
      isValid = false;
      setNational_idErr(true);
    }
    if (national_id.length < 10) {
      isValid = false;
    }
    if (!name) {
      isValid = false;
      setNameErr(true);
    }
    if (!mobile) {
      isValid = false;
      setMobileErr(true);
    }
    if (mobile.length < 10) {
      isValid = false;
    }
    if (!email) {
      isValid = false;
      setEmailErr(true);
    }
    if (invalidEmail) {
      isValid = false;
    }
    if (!license) {
      isValid = false;
      setLisenceErr(true);
    }
    return isValid;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      let obj = {
        national_id: national_id,
        name: name,
        mobile: mobile,
        email: email,
        license: license,
      };
      props?.onCloseModal(obj);
    }
  };

  const emailValidation = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validEmail(value);
    if (isValid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
  };

  const idValidation = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setNational_id(value);
    }
  };

  const numberValidation = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setMobile(value);
    }
  };

  return (
    <>
      <Modal
        open={props?.open}
        center
        onClose={() => props?.onCloseModal()}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
      >
        <div className="px-3 pt-5">
          <Form>
            <div className="row">
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="national_id">
                    National ID<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="national_id"
                    id="national_id"
                    placeholder="National ID"
                    value={national_id}
                    onChange={(ev) => idValidation(ev)}
                    maxLength={10}
                  />
                  {!national_id && national_idErr && (
                    <div className="error-text">National ID is required</div>
                  )}
                  {national_id && national_id.length < 10 && (
                    <div className="error-text">National ID has 10 digits</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="name">
                    Name<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                  />
                  {!name && nameErr && (
                    <div className="error-text">Name is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="mobile">
                    Mobile<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    placeholder="Mobile"
                    value={mobile}
                    onChange={(ev) => numberValidation(ev)}
                    maxLength={10}
                  />
                  {!mobile && mobileErr && (
                    <div className="error-text">Mobile is required</div>
                  )}
                  {mobile && mobile.length < 10 && (
                    <div className="error-text">
                      Mobile number is less the 10 digits
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="email">
                    Email<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(ev) => emailValidation(ev)}
                  />
                  {!email && emailErr && (
                    <div className="error-text">Email is required</div>
                  )}
                  {email && invalidEmail && (
                    <div className="error-text">Invalid email</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="license">
                    License<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="license"
                    id="license"
                    placeholder="License"
                    value={license}
                    onChange={(ev) => setLicense(ev.target.value)}
                  />
                  {!license && licenseErr && (
                    <div className="error-text">License is required</div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center pt-4 col-12">
                <button
                  className="btn grey-bg"
                  onClick={() => props?.onCloseModal()}
                >
                  Cancel
                </button>
                <button className="btn primary-bg" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default VehicleAssignmentModal;
