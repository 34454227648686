export const tableDataColumn = (cell) => {
  const header = cell?.column?.Header;
  const id = cell?.column?.id;
  if (header === "Due Amount" || header === "Total Amount") return "text-right";
  if (id !== "action") {
    if (
      id === "workorder_description" ||
      id === "branch_desc" ||
      id === "cust_name"
    )
      return "table-width-xxl";

    if (
      id === "company_name" ||
      id === "bil_datetime" ||
      id === "cha_destinaton" ||
      id === "cha_start_location" ||
      id === "vehicle_description" ||
      id === "V_date_time"
    )
      return "table-width-xl";
    else if (
      id === "aut_desc" ||
      header === "Maintenance Status" ||
      header === "Driver Name" ||
      id === "cust_name" ||
      id === "rsa_rating_desc" ||
      id === "make_model" ||
      id === "rat_vehicle_desc" ||
      id === "aut_reason" ||
      id === "email"
    )
      return "table-width-lg";
    else if (
      header === "Name" ||
      id === "bil_categoryid" ||
      id === "designation" ||
      id === "maint_reason" ||
      id === "makemodel" ||
      id === "bil_docno" ||
      id === "gcc_country" ||
      id === "acc_locdesc_other" ||
      id === "acc_desc" ||
      id === "rsa_pick_location_desc" ||
      id === "job_type" ||
      id === "Workshop" ||
      id === "Name (Arabic)"
    )
      return "table-width-md";
    else if (
      header === "National ID" ||
      id === "mobile" ||
      id === "licence_no" ||
      id === "loc_phone" ||
      id === "rsa_mobile" ||
      id === "char_rate_type_id" ||
      id === "rsa_remark" ||
      id === "acc_carid" ||
      id === "cha_city_kingdon" ||
      id === "com_subject"
    )
      return "table-width-sm";
    else if (id === "dummy" || id === "dummy2") return "dummy-width";
    else if (
      id === "com_desc" ||
      id === "nti_desc" ||
      id === "V_desc" ||
      id === "nti_type" ||
      id === "dmg_desc" ||
      id === "loc_desc"
    ) {
      return "";
    } else return "table-width-xs";
  } else {
    return "";
  }
};

export const tableHeadColumn = (cell) => {
  const id = cell?.id;
  if (id === "Name (Arabic)") return "table-head-sm";
};
