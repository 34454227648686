import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { forgotPasswordApi } from "views/examples/_actions";
import { validEmail } from "_shared/commonFunctions";
import { toast } from "react-toastify";
import { callTokenApi } from "actions/commonActions";
import { globalLoader } from "actions/commonActions";
import Cookies from "js-cookie";

const ForgotPasswordModal = (props) => {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const callBasicApi = async () => {
    globalLoader(true);
    try {
      let res = await callTokenApi();
      if (res?.status === 200) {
        Cookies.set("access_token", res?.data?.access_token, {
          expires: res?.data?.expires_in,
          secure: true,
          sameSite: "strict",
        });
      } else {
        toast.error(res?.data?.message);
        props?.onCloseModal();
      }
    } catch (err) {
      toast.error("Could not set token. Please try again later");
      console.log(err);
    }
    globalLoader(false);
  };

  const handleValidation = () => {
    let isValid = true;
    if (!email) {
      setEmailErr(true);
      isValid = false;
    }
    if (invalidEmail) {
      isValid = false;
    }
    return isValid;
  };

  const validateEmail = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    const value = ev.target.value;
    let isValid = await validEmail(value);
    if (isValid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = { email: email };
    if (await handleValidation()) {
      callBasicApi();
      try {
        let res = await forgotPasswordApi(obj);
        if (res?.data?.status === 0) {
          setEmailSent(true);
        } else {
          toast.error(res?.data?.message);
          console.log(res);
        }
      } catch (err) {
        toast.error("Unauthorized");
        console.log(err);
      }
      Cookies.remove("access_token");
    }
  };
  return (
    <>
      <Modal
        open={props?.open}
        center
        onClose={() => props?.onCloseModal(false)}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ root: "form-modal-own modal-own modal-md-own" }}
      >
        <Form>
          <div className="row pt-5">
            {emailSent ? (
              <>
                <div className="col-12">
                  <FormGroup>
                    <div className="text-center">
                      Reset password link has been sent to your email
                    </div>
                  </FormGroup>
                </div>
                <div className="d-flex justify-content-center pt-4 col-12">
                  <button
                    className="btn primary-bg btn-own"
                    onClick={() => props?.onCloseModal(false)}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="col-12">
                  <div className="form-group-own">
                    <Label for="email">
                      Please enter your email address to reset your password
                    </Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email ID"
                      value={email}
                      onChange={(ev) => validateEmail(ev)}
                    />
                    {!email && emailErr && (
                      <div className="error-text">Email is required</div>
                    )}
                    {email && invalidEmail && (
                      <div className="error-text">Email is invalid</div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center pt-4 col-12">
                  <button
                    className="btn grey-bg btn-own"
                    onClick={() => props?.onCloseModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn primary-bg btn-own"
                    onClick={handleSubmit}
                  >
                    Send Link
                  </button>
                </div>
              </>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
