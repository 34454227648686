import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";

import UserHeader from "../../components/Headers/userHeader";
import MaintenanceHistoryTable from "../../components/tables/maintenanceHistoryTable";
import { globalLoader } from "actions/commonActions";

import { useMaintainanceHistoryData } from "hooks/useMaintainanceHistoryData";

import { downloadExcel } from "utils/exportUtils";

const MaintenanceHistory = (props) => {
  const { currentCompany } = props;

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: history,
    isFetching,
    isFetched,
    refetch,
  } = useMaintainanceHistoryData(currentCompany);

  globalLoader(isFetching);

  const downloadExcelHandler = () => {
    downloadExcel(history, "maintainance-history");
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Maintenance History</h3>
                  <div className="col text-right">
                    <button
                      className="btn btn-sm primary-bg"
                      onClick={downloadExcelHandler}
                    >
                      Download as Excel
                    </button>
                  </div>
                </Row>
              </CardHeader>
              {isFetched ? (
                history && history.length > 0 ? (
                  <MaintenanceHistoryTable tableData={history} />
                ) : (
                  <div className="px-3 pt-3 pb-3">
                    <div className="no-data-available color-primary">
                      Data Not Available
                    </div>
                  </div>
                )
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateProps)(MaintenanceHistory);
