import React, { useMemo, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { DateRangeColumnFilter } from "./_filters";
import DescModal from "components/Modals/descModal";
import TableContainer from "./_table";
import { SelectFilter } from "./_filters";
import { tableDropdownData } from "../../_shared/commonFunctions";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";

const AccidentTable = (props) => {
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { statusList } = props;

  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.sendNotification(cell?.row?.original)}
                  >
                    Send Notification
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "acc_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.acc_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },
      // {
      //   Header: "Customer",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   accessor: "acc_id",
      //   Cell: ({ row }) => (
      //     <span
      //       className="color-primary cursor-pointer"
      //       onClick={() => props?.customerModalOpen(row?.original?.acc_id)}
      //     >
      //       View
      //     </span>
      //   ),
      // },
      {
        Header: "Plate No",
        accessor: "acc_plate",
        Cell: ({ row }) => (
          <div className="color-primary cursor-pointer">
            {row?.original?.acc_plate}
          </div>
        ),
      },
      {
        Header: "Make Model",
        accessor: "makemodel",
      },
      {
        Header: "date & time",
        accessor: "acc_datetime",
        Cell: ({ row }) =>
          new Date(row?.original?.acc_datetime).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "location",
        accessor: "acc_locdesc_other",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.acc_locdesc_other.length > 15 ? "view-more" : ""
              }`}
            >
              <span>{row?.original?.acc_locdesc_other}</span>
            </div>
            {row?.original?.acc_locdesc_other.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Location", row?.original?.acc_locdesc_other)
                }
              >
                View More
              </div>
            )}
          </>
        ),
        disableSortBy: true,
      },
      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className="color-primary cursor-pointer"
            onClick={() => props?.customerModalOpen(row?.original, "company")}
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className="color-primary cursor-pointer"
            onClick={() => props?.customerModalOpen(row?.original, "customer")}
          >
            {row?.original?.national_id}
          </div>
        ),
      },
      {
        Header: "Police Report",
        accessor: "police_paper",
        Cell: ({ row }) =>
          row?.original?.police_paper[0] !== null && (
            <div className="d-flex justify-content-center">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Images",
        accessor: "images",
        Cell: ({ row }) =>
          row?.original?.images[0] !== null && (
            <div className="d-flex justify-content-center">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Accident Details",
        accessor: "acc_desc",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.acc_desc.length > 15 ? "view-more" : ""
              }`}
            >
              <span>{row?.original?.acc_desc}</span>
            </div>
            {row?.original?.acc_desc.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Accident Details", row?.original?.acc_desc)
                }
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
      },
      {
        Header: "tow",
        accessor: "acc_tow_requested",
        Cell: ({ row }) =>
          row?.original?.acc_tow_requested === "0" ? "No" : "Yes",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "replacement",
        accessor: "acc_repl_requested",
        Cell: ({ row }) =>
          row?.original?.acc_repl_requested === "0" ? "No" : "Yes",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ID",
        accessor: "acc_id",
      },
    ], // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);
  return (
    <>
      <TableContainer
        searchFilter={props?.searchFilter}
        searchId={props?.searchId}
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (
              data?.column?.Header === "Police Report" &&
              data?.row?.original?.police_paper[0]
            ) {
              props?.viewImage(data?.row?.original, "Police Report");
            }
            if (
              data?.column?.Header === "Images" &&
              data?.row?.original?.images[0]
            ) {
              props?.viewImage(data?.row?.original, "Accident Images");
            }
            if (data?.column?.id === "acc_plate") {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}

      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.acc_plate}
        />
      )}
    </>
  );
};

export default AccidentTable;
