import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navbar, Table } from "reactstrap";

const ListingSidebar = (props) => {
  const { listingTableData } = props;
  const [view, setView] = useState(false);
  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    let temp = props?.roleData?.user_menu;
    if (temp && temp.length > 0) {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]?.menu_id === "24") {
          setView(true);
        }
      }
    } // eslint-disable-next-line
  }, []);

  const handleClick = (data) => {
    props?.history?.push({
      pathname: "/admin/service_listing",
      state: {
        id: "maint_plate",
        maint_plate: data?.maint_plate,
        dropdownId: "maint_status",
        maint_status: "1",
      },
    });
  };

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };
  return (
    <>
      {view && (
        <Navbar
          className="navbar-vertical fixed-right navbar-listing"
          expand="sm"
          id="sidenav-main"
        >
          <div className="listing-content">
            <div className="listing-header">
              <span>Maintenance Requests</span>
              <button
                className="listing-btn cursor-pointer"
                onClick={() => props?.history?.push("/admin/service_listing")}
              >
                View All <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <Table
            className="align-items-center table-flush listing-table table-borderless"
            responsive
          >
            <thead className="table-head color-primary">
              <tr>
                <th scope="col">Plate</th>
                <th scope="col">Date</th>
                <th scope="col"></th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="font-11">
              {(!listingTableData || listingTableData.length == 0) && (
                <label className="font-12 text-muted">No Data Found</label>
              )}
              {listingTableData &&
                listingTableData.length > 0 &&
                listingTableData.map((data, index) => {
                  return (
                    <tr className="table-body" key={index}>
                      <td className="weight-600 color-primary cursor-pointer">
                        {/* <i className="fas fa-circle table-icon"></i> */}
                        <div
                          onClick={() => {
                            openVehicleDetails(data);
                          }}
                        >
                          {data?.maint_plate}
                        </div>
                      </td>
                      <td>
                        {new Date(data?.date).toLocaleString("en-Us", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </td>
                      <td className="pr-1">
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${data?.loc_lat},${data?.loc_lon}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className=" fas fa-map-marker-alt color-primary cursor-pointer"></i>
                        </a>
                      </td>
                      <td>
                        <button
                          className="listing-btn"
                          onClick={() => handleClick(data)}
                        >
                          Review
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Navbar>
      )}

      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.maint_plate}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  listingTableData: state?.common?.listingTableData,
});

export default connect(mapStateProps)(ListingSidebar);
