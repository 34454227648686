import React from "react";

const PrimaryButtonLg = ({ title, onClick }) => {
  return (
    <button
      className="btn btn-md primary-bg"
      onClick={onClick}
      style={{ borderRadius: "0px", width: "100%" }}
    >
      {title}
    </button>
  );
};

export default PrimaryButtonLg;
