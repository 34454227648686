import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";

import { globalLoader } from "actions/commonActions";
import UserHeader from "../../components/Headers/userHeader";
import ViewImageModal from "components/Modals/viewImageModal";
import AppUserTable from "components/tables/appUserTable";

import { useAppUserData } from "hooks/useAppUserData";

const AppUsers = (props) => {
  const { currentCompany } = props;

  const [imgType, setImgType] = useState("");
  const [viewImageModal, setViewImageModal] = useState(false);
  const [imgData, setImgData] = useState(null);

  const {
    data: appUsers,
    isLoading,
    isFetched,
    refetch,
  } = useAppUserData(currentCompany);

  if (isLoading) {
    globalLoader(true);
  }

  if (isFetched) {
    globalLoader(false);
  }

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const viewImage = (val = null, type = null) => {
    if (val) {
      let temp = [{ img_url: val }];
      setImgData(temp);
      setImgType(type);
      setViewImageModal(true);
    }
  };

  const closeImageModal = () => {
    setImgType("");
    setViewImageModal(false);
    setImgData(null);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">App Users</h3>
                </Row>
              </CardHeader>
              {isFetched ? (
                appUsers && appUsers?.length > 0 ? (
                  <AppUserTable tableData={appUsers} viewImage={viewImage} />
                ) : (
                  <div className="px-3 pt-3 pb-3">
                    <div className="no-data-available color-primary">
                      Data Not Available
                    </div>
                  </div>
                )
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
      {viewImageModal && (
        <ViewImageModal
          open={viewImageModal}
          onCloseModal={closeImageModal}
          data={imgData}
          imgType={imgType}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateProps)(AppUsers);
