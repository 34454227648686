import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const PdfViewerModal = ({ open, onCloseModal, fileUrl, currentCompany, type }) => {

  return (
    <Modal
      open={open}
      center
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={() => onCloseModal(null)}
      closeOnOverlayClick={true}
      classNames={{ modal: "pdf-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">{type} Invoice_{currentCompany}.pdf</label>
      </div>
      <div className="px-3 pt-3" >
        {console.log(fileUrl)}
        <iframe src={fileUrl} style={{ width: '100%', height: '500px' }} frameborder="0"></iframe>
      </div>

    </Modal>
  );
};

export default PdfViewerModal;
