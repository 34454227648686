import React, { useState } from "react";
import { connect } from "react-redux";

import { usePreContractData } from "hooks/usePreContractData";
import PreContractTable from "components/tables/preContractTable";
import { Card, CardHeader, Container, Row } from "reactstrap";

import UserHeader from "../../components/Headers/userHeader";

import { globalLoader } from "actions/commonActions";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";
import DriverDetailsModal from "components/Modals/driverDetailsModal";
import ContractDetailsModal from "components/Modals/contractDetailsModal";

function PreContractContainer({ currentCompany }) {
  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);

  const [contractItem, setContractItem] = useState(null);

  const { data: contracts, isLoading } = usePreContractData(currentCompany);

  globalLoader(isLoading);

  const openCustomer = (contract) => {
    setContractItem(contract);
    setShowCustomerModal(true);
  };

  const openVehicleDetails = (contract) => {
    setContractItem(contract);
    setVehicleDetailsModal(true);
  };

  const openId = (contract) => {
    setContractItem(contract);
    setShowContractModal(true);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          {!isLoading ? (
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <h3 className="mb-0 color-primary">Pre Contract</h3>
                    <div className="col text-right"></div>
                  </Row>
                </CardHeader>

                {contracts && contracts.length > 0 ? (
                  <div>
                    <PreContractTable
                      tableData={contracts}
                      openCustomer={openCustomer}
                      openPlate={openVehicleDetails}
                      openId={openId}
                    />
                  </div>
                ) : (
                  <div className="px-3 pt-3 pb-3">
                    <div className="no-data-available color-primary">
                      Data Not Available
                    </div>
                  </div>
                )}
              </Card>
            </div>
          ) : (
            <></>
          )}
        </Row>
      </Container>

      {vehicleDetailsModal && contractItem && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={() => {
            setVehicleDetailsModal(false);
            setContractItem(null);
          }}
          plateNo={contractItem?.plate_no}
        />
      )}

      {showCustomerModal && contractItem && (
        <DriverDetailsModal
          open={showCustomerModal}
          onCloseModal={() => {
            setShowCustomerModal(false);
            setContractItem(null);
          }}
          formData={contractItem.id_no}
        />
      )}

      {showContractModal && contractItem && (
        <ContractDetailsModal
          open={showContractModal}
          type="pre-contract"
          onCloseModal={() => {
            setShowContractModal(false);
            setContractItem(null);
          }}
          formData={contractItem}
        />
      )}
    </>
  );
}

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateProps)(PreContractContainer);
