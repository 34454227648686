import React from "react";
import { Media } from "reactstrap";
import TableContainer from "./_table";

const AppUserTable = (props) => {
  const sortItems = (prev, curr, columnId) => {
    if (
      prev.original[columnId].trim().toLowerCase() >
      curr.original[columnId].trim().toLowerCase()
    ) {
      return 1;
    } else if (
      prev.original[columnId].trim().toLowerCase() <
      curr.original[columnId].trim().toLowerCase()
    ) {
      return -1;
    } else {
      return 0;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "Name",
        accessor: (row) => `${row.first_name} ${row.last_name}`,
        Cell: ({ row }) => {
          return (
            <div className="table-text">{`${row?.original?.first_name.trim()} ${row?.original?.last_name.trim()}`}</div>
          );
        },
        filterMethod: (filter, row) =>
          row?.original.firstName.startsWith(filter.value) ||
          row?.original.lastName.startsWith(filter.value),

        sortType: (prev, curr) => {
          return sortItems(prev, curr, "first_name");
        },
      },
      {
        Header: "Name (Arabic)",
        accessor: (row) => `${row?.first_name_a} ${row?.last_name_a}`,
        Cell: ({ row }) => (
          <div className="table-text text-right">{`${row?.original?.last_name_a.trim()} ${row?.original?.first_name_a.trim()}`}</div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Image",
        accessor: "profile_image",
        Cell: ({ row }) =>
          row?.original?.profile_image !== "" && (
            <div className="table-text">
              <Media>
                <div className="avatar rounded-circle mr-3">
                  <img
                    alt="profile"
                    src={row?.original?.profile_image}
                    className="profile-img"
                  />
                </div>
              </Media>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "National ID",
        accessor: "national_id",
        Cell: ({ row }) => {
          if (row?.original?.national_id === "") {
            return "";
          } else {
            return (
              <span className="">
                {row?.original?.national_id}
                {row?.original?.national_id_status === "0" ? (
                  <i className="fas fa-exclamation-circle pl-1 color-orange"></i>
                ) : (
                  <i className="fas fa-check-circle pl-1 color-green"></i>
                )}
              </span>
            );
          }
        },
      },
      {
        Header: "Image",
        accessor: "id_proof_image",
        Cell: ({ row }) =>
          row?.original?.id_proof_image !== "" && (
            <div className="ml-3">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Driving License",
        accessor: "licence_no",
        disableFilters: true,
      },
      {
        Header: "Image",
        accessor: "license_image",
        Cell: ({ row }) =>
          row?.original?.license_image !== "" && (
            <div className="ml-2">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        Cell: ({ row }) => {
          if (row?.original?.mobile === "") {
            return "";
          } else {
            return (
              <>
                {row?.original?.mobile}
                {row?.original?.mobile_status === "0" ? (
                  <i className="fas fa-exclamation-circle pl-1 color-orange"></i>
                ) : (
                  <i className="fas fa-check-circle pl-1 color-green"></i>
                )}
              </>
            );
          }
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => {
          if (row?.original?.email === "") {
            return "";
          } else {
            return (
              <span className="table-text">
                {row?.original?.email}
                {row?.original?.email_status === "0" ? (
                  <i className="fas fa-exclamation-circle pl-1 color-orange"></i>
                ) : (
                  <i className="fas fa-check-circle pl-1 color-green"></i>
                )}
              </span>
            );
          }
        },
      },
      {
        Header: "Company Code",
        accessor: "company_code",
        disableFilters: true,
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.company_name}</div>
        ),
        disableFilters: true,
      },
      {
        Header: "Id",
        accessor: "user_id",
      },
    ],
    // eslint-disable-next-line
    []
  );
  // eslint-disable-next-line
  const data = React.useMemo(() => props?.tableData, []);

  return (
    <TableContainer
      columns={columns}
      data={data}
      getCellProps={({ column, value, row }) => ({
        onClick: () => {
          if (column.id === "id_proof_image") {
            props?.viewImage(value, "National ID");
          }
          if (column.id === "license_image") {
            props?.viewImage(value, "Driving License");
          }
        },
      })}
    />
  );
};

export default AppUserTable;
