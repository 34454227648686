import { useState, useEffect, useRef } from "react";
import DatePicker from "react-flatpickr";

const DateTimePicker = ({
  dateTime,
  initialDateTime,
  reset,
  enableTime,
  format,
  maxDate,
  minDate,
  is24HourFormat = false,
  onDateTimeSelect,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const fp = useRef(null);

  const clear = () => {
    if (!fp?.current?.flatpickr) return;
    fp.current.flatpickr.clear();
  };

  useEffect(() => {
    if (reset) {
      clear();
    }
  }, [reset]);

  useEffect(() => {
    if (dateTime) {
      setStartDate(new Date(dateTime));
    }
  }, [dateTime]);

  useEffect(() => {
    if (initialDateTime) {
      setStartDate(new Date(initialDateTime));
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <DatePicker
        ref={fp}
        onChange={(e) => {
          setStartDate(e);
        }}
        onClose={(e) => {
          onDateTimeSelect(e);
        }}
        value={startDate}
        className="form-control datepicker-own"
        options={{
          dateFormat: format,
          enableTime: enableTime,
          maxDate: maxDate ? maxDate : null,
          minDate: minDate ? minDate : null,
          defaultDate: maxDate,
          time_24hr: is24HourFormat,
        }}
      />
    </div>
  );
};

export default DateTimePicker;
