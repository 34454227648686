/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
// core components
import AdminNavbar from "../components/Navbars/adminNavbar";
import Sidebar from "../components/Sidebar/sidebar";
import ListingSidebar from "../components/Sidebar/listingSidebar";
import routes from "routes.js";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { logOut } from "../_shared/commonFunctions";
import { globalLoader } from "actions/commonActions";
import { toast } from "react-toastify";

const Admin = (props) => {
  const { roleData } = props;
  useEffect(() => {
    let token = Cookies.get("access_token");
    if (!token) {
      logOut(props?.history, "/auth/login");
      globalLoader(false);
      toast.warn("Session Expired. Please Login");
    }
  });

  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    let menu = roleData?.user_menu;
    let userRoute = [];
    routes.forEach((route) => {
      if (route?.module_type) {
        menu?.forEach((item) => {
          if (item?.menu_type_id === route?.module_type) {
            userRoute.push(route);
          }
        });
      } else {
        userRoute.push(route);
      }
    });
    return userRoute?.map((prop, key) => {
      if (prop?.layout === "/admin") {
        return (
          <Route
            path={prop?.layout + prop?.path}
            component={prop?.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      {/* <div className="content-own main-content" ref={mainContent}> */}
      <div
        className={`content-own main-content ${
          props?.location.pathname === "/admin/index" ? "dash-board" : ""
        }`}
      >
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />

        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        {props?.location?.pathname === "/admin/index" && (
          <ListingSidebar {...props} />
        )}
        {props?.location?.pathname === "/admin/dashboard" && (
          <ListingSidebar {...props} />
        )}
      </div>
    </>
  );
};

const matchPropState = (state) => ({
  roleData: state?.common?.roleData,
  listingTableData: state?.common?.listingTableData,
});

export default connect(matchPropState)(Admin);
