import React, { useState } from "react";
import { Helmet } from "react-helmet";

const FaqContent = (props) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>FAQs</title>
      </Helmet>
      <li className="faq-wrapper" onClick={() => setShow((c) => !c)}>
        <div
          className={`${show ? "faq-ques active" : "faq-ques"} ${
            props?.isArabic && "ar"
          }`}
        >
          <div>{props?.data?.faq_question}</div>
          <div>
            <i className="fas fa-chevron-down"></i>
          </div>
        </div>
        <div
          className={`${show ? "faq-ans active" : "faq-ans"} ${
            props?.isArabic && "ar"
          }`}
        >
          {props?.data?.faq_answer}
        </div>
      </li>
    </>
  );
};

export default FaqContent;
