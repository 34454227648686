import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import { maintenancePortalListApi } from "../views/examples/_actions";

export const useServiceData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["services", currentCompany, profile_email],
    () => maintenancePortalListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const services = data?.data?.data;
          if (services) {
            let sortedServices = sort(services).desc(
              (service) => new Date(service?.prefered_date_time)
            );
            return sortedServices;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    }
  );
};
