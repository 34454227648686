import DescModal from "components/Modals/descModal";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";
import React, { useMemo, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { tableDropdownData } from "_shared/commonFunctions";
import { DateRangeColumnFilter } from "./_filters";
import { SelectFilter } from "./_filters";
import { SelectColumnFilter } from "./_filters";
import TableContainer from "./_table";

const ViolationTable = (props) => {
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { statusList } = props;

  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.sendNotification(cell?.row?.original)}
                  >
                    Send Notification
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "V_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.V_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.company_code !== "N/A" &&
              props?.customerModalOpen(row?.original, "company")
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              props?.customerModalOpen(row?.original, "customer")
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },

      // {
      //   Header: "Bill id",
      //   accessor: "V_bill_id",
      // },
      // {
      //   Header: "Bill Available",
      //   accessor: "V_bill_availability",
      //   disableSortBy: true,
      //   Filter: SelectColumnFilter,
      //   filter: "includes",
      // },
      {
        Header: "Plate No",
        accessor: "V_plateno",
        Cell: ({ row }) => (
          <div className="color-primary cursor-pointer">
            {row?.original?.V_plateno}
          </div>
        ),
      },
      {
        Header: "date",
        accessor: "V_date_time",
        Cell: ({ row }) =>
          new Date(row?.original?.V_date_time).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: <div className="text-right">total</div>,
        accessor: "V_amount",
        Cell: ({ row }) => (
          <div className="text-right w-100">{row?.original?.V_amount}</div>
        ),
        disableFilters: true,
      },
      // {
      //   Header: <div className="text-right mr-1">paid</div>,
      //   accessor: "V_paidamount",
      //   Cell: ({ row }) => (
      //     <div className="text-right mr-1">{row?.original?.V_paidamount}</div>
      //   ),
      //   disableFilters: true,
      // },
      {
        Header: "ID",
        accessor: "V_id",
      },
      {
        Header: "Details",
        accessor: "V_desc",
        Cell: ({ row }) => {
          return (
            <div>
              <div
                className={`${
                  row?.original?.V_desc.trim().length > 60
                    ? "view-more-arabic"
                    : "text-right"
                }`}
              >
                <span>{row?.original?.V_desc.trim()}</span>
              </div>
              {row?.original?.V_desc.trim().length > 60 && (
                <div
                  className="font-12 color-primary cursor-pointer text-right"
                  onClick={() =>
                    handleMore("Details", row?.original?.V_desc.trim())
                  }
                >
                  View More
                </div>
              )}
            </div>
          );
        },
        disableFilters: true,
      },
      {
        Header: "",
        accessor: "dummy2",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);
  return (
    <>
      <TableContainer
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (data?.column?.id === "V_plateno") {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}
      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.V_plateno}
        />
      )}
    </>
  );
};

export default ViolationTable;
