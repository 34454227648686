import React, { useEffect, useRef, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { formatDate } from "utils/dateUtils";

import makeAnimated from "react-select/animated";
import Select from "react-select";
import { toast } from "react-toastify";
import { getAssignedUsers } from "views/examples/_actions";
import { globalLoader } from "actions/commonActions";

const AssignUsersModal = (props) => {
  const [userId, setUserId] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [IOTEnabled, setIOTEnabled] = useState(false);
  const [userIdErr, setUserIdErr] = useState(false);

  const animatedComponents = makeAnimated();

  useEffect(() => {
    console.log(props);
    if (props?.isEdit) {
      getDetails();
    } // eslint-disable-next-line
  }, []);

  const getDetails = async () => {
    globalLoader(true);
    const response = await getAssignedUsers();
    if (response.status === 200) {
      let matchingUsers = response.data.data.filter((user) => {
        return (
          user.pal_corp_code == props.formData.cust_code &&
          user.pal_plate == props.formData.plate_no
        );
      });
      console.log(matchingUsers);
      // if (matchingUsers && matchingUsers.length > 0)
      //   setIOTEnabled(matchingUsers[0].pal_iot_fitted == "1");
      let matchingUserIDs = matchingUsers.map((user) => user.pal_id) || [];
      matchingUsers =
        matchingUsers.map((user) => {
          return { pal_id: user.pal_id, pal_slno: user.pal_slno };
        }) || [];
      console.log(matchingUsers);
      setCurrentUsers(matchingUsers);
      setPropsToState(matchingUserIDs);
    }
    globalLoader(false);
  };

  const setPropsToState = (matchingUsers) => {
    let selectedUsers = matchingUsers || [];
    console.log(selectedUsers);
    if (selectedUsers && selectedUsers.length > 0)
      setUserId(
        props?.options.filter(
          (data) => selectedUsers.findIndex((item) => item == data?.value) >= 0
        )
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(userId);
    let selectedUsers = currentUsers || [];
    let removedUsers =
      selectedUsers.filter((user) => {
        return userId.findIndex((item) => item.value == user.pal_id) < 0;
      }) || [];
    let addedUsers =
      userId.filter((user) => {
        return selectedUsers.findIndex((item) => item.pal_id == user.value) < 0;
      }) || [];
    console.log(selectedUsers);
    console.log(removedUsers);
    console.log(addedUsers);
    let addedUserArray = addedUsers.map((user) => {
      return {
        pal_corp_code: props.formData.cust_code,
        pal_plate: props.formData.plate_no,
        pal_start_datetime: formatDate(new Date()),
        pal_end_datetime: null,
        pal_id: user.value,
        pal_iot_fitted: IOTEnabled ? null : null,
        pal_car_latest_lat: null,
        pal_car_latest_lon: null,
        pal_status: "1",
        pal_slno: null,
        pal_is_add_or_delete: "1",
      };
    });
    let removedUserArray = removedUsers.map((user) => {
      return {
        pal_corp_code: props.formData.cust_code,
        pal_plate: props.formData.plate_no,
        pal_start_datetime: null,
        pal_end_datetime: formatDate(new Date()),
        pal_id: user.pal_id.toString(),
        pal_iot_fitted: IOTEnabled ? null : null,
        pal_car_latest_lat: null,
        pal_car_latest_lon: null,
        pal_status: "1",
        pal_slno: user.pal_slno ? user.pal_slno : null,
        pal_is_add_or_delete: "0",
      };
    });
    console.log([...addedUserArray, ...removedUserArray]);
    props?.onCloseModal({
      PoolAllocationRequestDetails: [...addedUserArray, ...removedUserArray],
    });
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Assign Users</label>
      </div>
      <div className="px-3 pt-5" style={{ maxWidth: "510px" }}>
        <Form>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <Label for="userId">Plate Number</Label>
                <Input
                  type="text"
                  name="plate"
                  id="plate"
                  placeholder="Plate Number"
                  value={props.formData?.plate_no}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <Label for="userId">Users</Label>
                <Select
                  closeMenuOnSelect={false}
                  placeholder="Select Users"
                  options={props?.options}
                  onChange={(ev) => setUserId(ev)}
                  value={userId}
                  menuPlacement="bottom"
                  isSearchable={true}
                  isClearable={true}
                  isMulti
                  components={animatedComponents}
                  styles={{
                    multiValue: (base) => ({
                      ...base,
                      width: `max-content`,
                      maxWidth: `100%`,
                    }),
                  }}
                />

                {/* {!userId && userIdErr && (
                  <div className="error-text">User Id is empty</div>
                )} */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                {/* <input
                  type="checkbox"
                  checked={IOTEnabled}
                  onChange={() => setIOTEnabled((oldData) => !oldData)}
                  id="insurance"
                  value="insurance"
                  className="mr-1"
                />
                <label className="ml-1" htmlFor="insurance">
                  IoT Enabled
                </label> */}
              </div>
            </div>

            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AssignUsersModal;
