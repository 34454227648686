import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import _ from "lodash";
import { toast } from "react-toastify";
import { globalLoader } from "actions/commonActions";

import TransactionAddReceiptTable from "components/tables/transactionAddReceiptTable";
import imgToBase64 from "utils/fileUtils";
import { updateInvoiceReceiptApi } from "views/examples/_actions";

const AddInvoiceReceiptModal = ({
  open,
  selectedInvoices,
  statusList,
  onCloseModal,
}) => {
  const [invoices, setInvoices] = useState(selectedInvoices);
  const [receiptPdf, setReceiptPdf] = useState("");
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");

  const transactionSelectionHandler = (transaction, selected) => {
    let transactions = [...invoices];
    if (selected) {
      transactions.push(transaction);
    } else {
      _.remove(transactions, {
        bil_id: transaction.bil_id,
      });
    }
    setInvoices(transactions);
  };
  const receiptPdfUploadHandler = async (event) => {
    let file = event.target.files[0];
    let result = await imgToBase64(file);
    if (result) {
      setReceiptPdf(result);
    }
  };

  const updateReceiptHandler = async () => {
    globalLoader(true);
    let receipt = {
      desc: description,
      comments: comment,
      receipt_file: receiptPdf,
      receipt_filetype: "pdf",
      status: "1",
    };

    let invoiceIds = description;
    invoices.forEach((invoice) => {
      invoiceIds = description + invoice.bil_docno + ", ";
    });
    receipt.desc = invoiceIds;
    const response = await updateInvoiceReceiptApi(receipt);
    if (response.status === 200) {
      toast.success("Receipt uploaded successfully");

      onCloseModal();
    }

    globalLoader(false);
  };

  return (
    <Modal
      open={open}
      center
      onClose={() => onCloseModal()}
      closeOnEsc={false}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Add Fleet</label>
      </div>
      <div className="px-3 pt-5">
        {invoices && invoices.length > 0 && (
          <TransactionAddReceiptTable
            statusList={statusList}
            tableData={invoices}
            canEdit={false}
            onTransactionSelect={transactionSelectionHandler}
          />
        )}
      </div>

      <Form>
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <Label for="img">Upload Receipt</Label>
              <Input
                type="file"
                accept="application/pdf"
                name="img"
                id="img"
                placeholder="Upload Image"
                onChange={receiptPdfUploadHandler}
              />
            </FormGroup>
          </div>

          <div className="col-12">
            <FormGroup>
              <Label for="comment">Comments</Label>
              <Input
                type="textarea"
                name="comment"
                id="comment"
                placeholder="Comments"
                onChange={(event) => {
                  setComment(event?.target?.value);
                }}
                className="text-area"
              />
            </FormGroup>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-4">
          <Button color="btn grey-bg" onClick={() => onCloseModal()}>
            Cancel
          </Button>
          <Button color="btn primary-bg" onClick={updateReceiptHandler}>
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddInvoiceReceiptModal;
