import React, { useState } from "react";
import { connect } from "react-redux";

import CreateRentRequestLocationSelector from "./rent-request-location-selector";
import CreateRentRequestCarSelector from "./create-rent-request-car-selector";
import ExtraServiceContainer from "./extra-services/extra-service-container";
import BookingConfirmation from "./booking-confirmation";

export const CreateBookingContext = React.createContext();

let bookingObj = {
  booking_id: "",
  car_id: "",
  pickup_date_time: "",
  dropoff_date_time: "",
  pickup_station: "",
  dropoff_station: "",
  rate: "",
  rate_type: "",
  first_name: "",
  last_name: "",
  id_number: "",
  mobile_number: "",
  date_of_birth: "",
  nationality: "",
  cust_code: "",
  cust_name: "",
  rat_addtional_driver_text: "",
  rat_cdw_optional: "",
  rat_cdw_text: "",
  rat_cdw_optional: "",
  selectedCar: null,
  no_of_addtional_driver: 0,
  cdw_optional: "",
  cdw_daily: "",
};

const CreateRentRequest = (props) => {
  const { currentCompany, history } = props;

  const [booking, setBooking] = useState(bookingObj);
  const [locationSelected, setLocationSelected] = useState(false);
  const [carSelected, setCarSelected] = useState(false);
  const [extrasSelected, setExtrasSelected] = useState(false);
  const [personSelected, setPersonSelected] = useState(false);

  const bookingContextValue = {
    booking,
    setBooking,
    setLocationSelected,
    setCarSelected,
    setExtrasSelected,
  };

  return (
    <CreateBookingContext.Provider value={bookingContextValue}>
      <div className="container" style={{ maxWidth: "1400px" }}>
        <div style={{ marginLeft: "50px", marginTop: "100px" }}>
          {!locationSelected && <CreateRentRequestLocationSelector />}
        </div>

        {locationSelected && !carSelected && <CreateRentRequestCarSelector />}
        {carSelected && !extrasSelected && <ExtraServiceContainer />}
        {extrasSelected && !personSelected && (
          <BookingConfirmation
            currentCompany={currentCompany}
            history={history}
          />
        )}
      </div>
    </CreateBookingContext.Provider>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateProps)(CreateRentRequest);
