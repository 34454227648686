import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { getPaymentModesList } from "views/examples/_actions";

export const UsePaymentModesData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["payment-modes", profile_email],
    () => getPaymentModesList(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        const modeList = [];
        data?.data?.data.forEach((mode) => {
          let newMode = {
            value: mode?.pay_mode,
            label: mode?.pay_mode_desc,
          };
          modeList.push(newMode);
        });
        return modeList;
      },
    }
  );
};

export const UsePaymentModesTableData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["payment-modes-table", profile_email],
    () => getPaymentModesList(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        const modeList = [];
        data?.data?.data.forEach((mode) => {
          let newMode = {
            id: mode?.pay_mode,
            name: mode?.pay_mode_desc,
          };
          modeList.push(newMode);
        });
        return modeList;
      },
    }
  );
};
