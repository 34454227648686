import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";
import React, { useMemo, useState } from "react";
import { SelectColumnFilter, DateRangeColumnFilter } from "./_filters";

import TableContainer from "./_table";

const MaintenanceHistoryTable = (props) => {
  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Maintenance Status",
        accessor: "maint_status",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Plate No",
        accessor: "plate_no",
        Cell: ({ row }) => (
          <div className="color-primary cursor-pointer">
            {row?.original?.plate_no}
          </div>
        ),
      },

      {
        Header: "Order No",
        accessor: "work_order",
      },
      {
        Header: "Date",
        accessor: "create_date",
        Cell: ({ row }) =>
          new Date(row?.original?.create_date).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Type",
        accessor: "job_type",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Description",
        accessor: "vehicle_description",
        Cell: ({ row }) => (
          <div className="table-text pl-2">
            {row?.original?.vehicle_description}
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Workshop",
        accessor: "Workshop",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Created By",
        accessor: "createdby",
        disableFilters: true,
      },
      {
        Header: "Code",
        accessor: "cust_code",
      },
      {
        Header: "Work Order Description",
        accessor: "workorder_description",
        Cell: ({ row }) => (
          <div className="table-text">
            {row?.original?.workorder_description}
          </div>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "",
        accessor: "action",
        // Cell: (cell) => (
        //   <div className="text-left">
        //     <UncontrolledDropdown>
        //       <DropdownToggle
        //         className="btn-icon-only text-light"
        //         role="button"
        //         size="sm"
        //         color=""
        //         onClick={(e) => e.preventDefault()}
        //       >
        //         <i className="fas fa-ellipsis-v color-primary" />
        //       </DropdownToggle>
        //       <DropdownMenu className="dropdown-menu-arrow" right>
        //         <DropdownItem>Update Status</DropdownItem>
        //       </DropdownMenu>
        //     </UncontrolledDropdown>
        //   </div>
        // ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);

  return (
    <>
      <TableContainer
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (data?.column?.id === "plate_no") {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />
      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.plate_no}
        />
      )}
    </>
  );
};

export default MaintenanceHistoryTable;
