import React, { useState } from "react";

const StatementOfAccount = (props) => {
  const { currentCompany, invoices, viewInvoicePDFHandler } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    // currentCompany &&
    // invoices &&
    // invoices.length > 0 && (
    <button
      className="btn btn-fleet medium-bg mt-0 "
      onClick={() => {
        viewInvoicePDFHandler();
      }}
    >
      {/* <i className="fas fa-solid fa-file-pdf mr-2"></i> */}
      Statement of Account<i className="fas fa-arrow-right ml-2"></i>
    </button>
    // )
  );
};

export default StatementOfAccount;
