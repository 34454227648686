import React, { useMemo, useState } from "react";
import TableContainer from "./_table";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { DateRangeColumnFilter } from "./_filters";
import { SelectColumnFilter } from "./_filters";
import DescModal from "components/Modals/descModal";
import { SelectFilter } from "./_filters";
import { tableDropdownData } from "_shared/commonFunctions";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";

const AuthorizationTable = (props) => {
  const { searchId, searchFilter, statusList } = props;
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);

  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };

  const sortItems = (prev, curr, columnId) => {
    if (
      prev.original[columnId].trim().toLowerCase() >
      curr.original[columnId].trim().toLowerCase()
    ) {
      return 1;
    } else if (
      prev.original[columnId].trim().toLowerCase() <
      curr.original[columnId].trim().toLowerCase()
    ) {
      return -1;
    } else {
      return 0;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    onClick={() => props?.editData(cell?.row?.original)}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.sendNotification(cell?.row?.original)}
                  >
                    Send Notification
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "aut_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.aut_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },

      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.company_code !== "N/A" &&
              props?.customerModalOpen(row?.original, "company")
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              props?.customerModalOpen(row?.original, "customer")
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },
      {
        Header: "Plate No",
        accessor: "aut_plate",
        Cell: ({ row }) => (
          <div className="color-primary cursor-pointer">
            {row?.original?.aut_plate}
          </div>
        ),
      },
      {
        Header: "make model",
        accessor: "makemodel",
        disableFilters: true,
      },
      {
        Header: "start date",
        accessor: "aut_start_datetime",
        Cell: ({ row }) =>
          row?.original?.aut_start_datetime !== "" &&
          new Date(row?.original?.aut_start_datetime).toLocaleDateString(
            "default",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "end date",
        accessor: "aut_end_datetime",
        Cell: ({ row }) =>
          row?.original?.aut_end_datetime !== "" &&
          new Date(row?.original?.aut_end_datetime).toLocaleDateString(
            "default",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Requested date",
        accessor: "aut_req_datetime",
        Cell: ({ row }) =>
          row?.original?.aut_req_datetime !== "" &&
          new Date(row?.original?.aut_req_datetime).toLocaleDateString(
            "default",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Documents",
        accessor: "aut_file_url",
        Cell: ({ row }) =>
          row?.original?.aut_file_url !== "" && (
            <div className="d-flex justify-content-center">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "travel country",
        accessor: "gcc_country",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Reason",
        accessor: "aut_desc",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.aut_desc.length > 15 ? "view-more" : ""
              }`}
            >
              <span>{row?.original?.aut_desc}</span>
            </div>
            {row?.original?.aut_desc.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() => handleMore("Reason", row?.original?.aut_desc)}
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
      },

      {
        Header: "Approval Reason",
        accessor: "aut_reason",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.aut_reason.length > 15 ? "view-more" : ""
              }`}
            >
              <span>{row?.original?.aut_reason}</span>
            </div>
            {row?.original?.aut_reason.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Approval Reason", row?.original?.aut_reason)
                }
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "authorization letter",
        accessor: "aut_approval_file",
        Cell: ({ row }) =>
          row?.original?.aut_approval_file !== "" && (
            <div className="d-flex justify-content-center">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ID",
        accessor: "aut_id",
      },
    ], // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);
  return (
    <>
      <TableContainer
        searchFilter={searchFilter}
        searchId={searchId}
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (
              data?.column?.id === "aut_file_url" &&
              data?.row?.original?.aut_file_url !== ""
            ) {
              let obj = data?.row?.original?.aut_file_url;
              props?.viewImage(obj, "Document");
            }
            if (
              data?.column?.id === "aut_approval_file" &&
              data?.row?.original?.aut_approval_file !== ""
            ) {
              let obj = data?.row?.original?.aut_approval_file;
              props?.viewImage(obj, "Authorization");
            }

            if (data?.column?.id === "aut_plate") {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}

      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.aut_plate}
        />
      )}
    </>
  );
};

export default AuthorizationTable;
