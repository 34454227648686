import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Label } from "reactstrap";
import Flatpickr from "react-flatpickr";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { convertDateTime } from "_shared/commonFunctions";

const ServiceStatusModal = ({
  open,
  onCloseModal,
  formData,
  statusList,
  location,
}) => {
  const [status, setStatus] = useState("");
  const [datetime, setDatetime] = useState(null);
  const [newLocation, setNewLocation] = useState();
  const animatedComponents = makeAnimated();

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, []);

  const setPropsToState = () => {
    const {
      maint_status,
      prefered_date_time,
      schedule_date_time,
      preffered_location,
      loc_address,
    } = formData;
    setStatus(maint_status);
    if (schedule_date_time === "") {
      // new Date(prefered_date_time) >= new Date() &&
      setDatetime(new Date(prefered_date_time));
    } else {
      setDatetime(new Date(schedule_date_time));
    }
    if (preffered_location) {
      setNewLocation({ value: preffered_location, label: loc_address });
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    let newDt = convertDateTime(datetime);
    let obj = {
      status: status,
      datetime: newDt,
      type: "1",
      id: formData?.maint_id,
      workshop: newLocation.value,
    };
    onCloseModal(obj);
  };
  return (
    <Modal
      open={open}
      center
      onClose={() => onCloseModal(null)}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Update Status</label>
      </div>
      <div className="px-3 pt-3">
        <Form>
          <div className="row">
            <div className="col-12 ">
              <div className="form-group-own-status">
                <div className="py-2">
                  {statusList.map((list) => {
                    return (
                      <div key={list?.id}>
                        <input
                          type="radio"
                          name="status"
                          id={list?.name}
                          checked={status === list?.id}
                          onChange={() => setStatus(list?.id)}
                        />
                        <label className="pl-2" htmlFor={list?.name}>
                          {list?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {status === "3" && (
              <>
                <div className="col-6">
                  <div className="form-group-own">
                    <Label for="schedule-date">Schedule Date</Label>
                    <Flatpickr
                      id="schedule-date"
                      className="form-control datepicker-own"
                      options={{
                        minDate: "today",
                        altInput: true,
                        dateFormat: "Y-m-d H:i",
                        enableTime: true,
                      }}
                      onChange={(date) => setDatetime(date)}
                      value={datetime !== null && datetime}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group-own">
                    <Label for="newLocation">Service Center</Label>
                    <Select
                      placeholder="Service Center"
                      options={location}
                      onChange={(ev) => setNewLocation(ev)}
                      value={newLocation}
                      isSearchable={true}
                      isClearable={true}
                      className="select-dropdown-own"
                      components={animatedComponents}
                      menuPlacement="top"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="d-flex justify-content-center col-12 pt-2">
              <button
                className="btn grey-bg"
                onClick={() => onCloseModal(null)}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ServiceStatusModal;
