import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { validNumber } from "_shared/commonFunctions";
// import makeAnimated from "react-select/animated";

const AddServiceCenterModal = (props) => {
  const [code, setCode] = useState("");
  const [codeErr, setCodeErr] = useState(false);
  const [type, setType] = useState("");
  const [typeErr, setTypeErr] = useState(false);
  const [desc, setDesc] = useState("");
  const [descErr, setDescErr] = useState(false);
  const [address, setAddress] = useState("");
  const [addressErr, setAddressErr] = useState(false);
  const [lat, setLat] = useState("");
  const [latErr, setLatErr] = useState(false);
  const [long, setLong] = useState("");
  const [longErr, setLongErr] = useState(false);
  const [link, setLink] = useState("");
  const [linkErr, setLinkErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [descArabic, setDescArabic] = useState("");
  const [descArabicErr, setDescArabicErr] = useState(false);
  const [addressArabic, setAddressArabic] = useState("");
  const [addressArabicErr, setAddressArabicErr] = useState(false);
  const totalCount = 99;

  // const animatedComponents = makeAnimated();
  // const typeList = [
  //   {
  //     label: "Rental",
  //     value: "Rental",
  //   },
  //   { label: "Workshop", value: "Workshop" },
  //   { label: "Petromin", value: "Petromin" },
  // ];

  useEffect(() => {
    if (props?.isEdit) {
      mapPropstoState();
    }
    //eslint-disable-next-line
  }, []);

  const mapPropstoState = () => {
    setCode(props?.formData?.loc_code);
    setType(props?.formData?.loc_type);
    setDesc(props?.formData?.loc_desc);
    setAddress(props?.formData?.loc_address);
    setLat(props?.formData?.loc_lat);
    setLong(props?.formData?.loc_lon);
    setLink(props?.formData?.loc_google_adrs);
    setPhone(props?.formData?.loc_phone);
    setAddressArabic(props?.formData?.loc_address_ar);
    setDescArabic(props?.formData?.loc_desc_ar);
  };

  const handleValidation = () => {
    let isValid = true;
    if (!code) {
      isValid = false;
      setCodeErr(true);
    }
    if (!type) {
      isValid = false;
      setTypeErr(true);
    }
    if (!desc) {
      isValid = false;
      setDescErr(true);
    }
    if (!address) {
      isValid = false;
      setAddressErr(true);
    }
    if (!lat) {
      isValid = false;
      setLatErr(true);
    }
    if (!long) {
      isValid = false;
      setLongErr(true);
    }
    if (!link) {
      isValid = false;
      setLinkErr(true);
    }
    if (!phone) {
      isValid = false;
      setPhoneErr(true);
    }
    if (phone.length < 10) {
      isValid = false;
    }
    if (!descArabic) {
      isValid = false;
      setDescArabicErr(true);
    }
    if (!addressArabic) {
      isValid = false;
      setAddressArabicErr(true);
    }
    return isValid;
  };

  const validateNumber = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setPhone(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = {
        loc_code: code,
        loc_type: type,
        loc_desc: desc,
        loc_address: address,
        loc_lat: lat,
        loc_lon: long,
        loc_google_adrs: link,
        loc_phone: phone,
        loc_desc_ar: descArabic,
        loc_address_ar: addressArabic,
      };
      if (props?.isEdit) {
        obj.loc_id = props?.formData?.loc_id;
      }
      props?.onCloseModal(obj);
    }
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">
          {props?.isEdit ? "Edit " : "Add "} Location
        </label>
      </div>
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="code">
                  Location Code<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="code"
                  id="code"
                  placeholder="Location Code"
                  value={code}
                  onChange={(ev) => setCode(ev.target.value)}
                  className={`${!code && codeErr && "on-focus"}`}
                />
                {!code && codeErr && (
                  <div className="error-text">Location Code is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="type">
                  Location type<span className="color-primary">*</span>
                </Label>
                <select
                  id="type"
                  placeholder="Location TypeType"
                  value={type}
                  onChange={(ev) => setType(ev.target.value)}
                  className={`form-control ${!code && codeErr && "on-focus"}`}
                >
                  <option value="" disabled defaultValue hidden>
                    Location Type
                  </option>
                  <option value="Rental">Rental</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Petromin">Petromin</option>
                </select>
                {!type && typeErr && (
                  <div className="error-text">Type is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="desc">
                  Description<span className="color-primary">*</span>
                  <span className="ml-3 error-text">
                    {totalCount - desc.length} characters remaining
                  </span>
                </Label>
                <Input
                  type="text"
                  name="desc"
                  id="desc"
                  placeholder="Description"
                  value={desc}
                  onChange={(ev) => setDesc(ev.target.value)}
                  className={`${!desc && descErr && "on-focus"}`}
                  maxLength={totalCount}
                />
                {!desc && descErr && (
                  <div className="error-text">Description is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="desc">
                  Description (Arabic)<span className="color-primary">*</span>
                  <span className="ml-3 error-text">
                    {totalCount - descArabic.length} characters remaining
                  </span>
                </Label>
                <Input
                  type="text"
                  name="descArabic"
                  id="descArabic"
                  placeholder="Description (Arabic)"
                  value={descArabic}
                  onChange={(ev) => setDescArabic(ev.target.value)}
                  className={`${!descArabic && descArabicErr && "on-focus"}`}
                  style={{ textAlign: "right" }}
                  maxLength={totalCount}
                />
                {!descArabic && descArabicErr && (
                  <div className="error-text">
                    Description (Arabic) is required
                  </div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="address">
                  Address<span className="color-primary">*</span>
                  <span className="ml-3 error-text">
                    {totalCount - address.length} characters remaining
                  </span>
                </Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  value={address}
                  onChange={(ev) => setAddress(ev.target.value)}
                  className={`${!address && addressErr && "on-focus"}`}
                  maxLength={totalCount}
                />
                {!address && addressErr && (
                  <div className="error-text">Address is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="address">
                  Address (Arabic)<span className="color-primary">*</span>
                  <span className="ml-3 error-text">
                    {totalCount - addressArabic.length} characters remaining
                  </span>
                </Label>
                <Input
                  type="text"
                  name="addressArabic"
                  id="addressArabic"
                  placeholder="Address (Arabic)"
                  value={addressArabic}
                  onChange={(ev) => setAddressArabic(ev.target.value)}
                  className={`${
                    !addressArabic && addressArabicErr && "on-focus"
                  }`}
                  style={{ textAlign: "right" }}
                  maxLength={totalCount}
                />
                {!addressArabic && addressArabicErr && (
                  <div className="error-text">Address (Arabic) is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="lat">
                  Latitude<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="lat"
                  id="lat"
                  placeholder="Latitude"
                  value={lat}
                  onChange={(ev) => setLat(ev.target.value)}
                  className={`${!lat && latErr && "on-focus"}`}
                />
                {!lat && latErr && (
                  <div className="error-text">Latitude is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="long">
                  Longitude<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="long"
                  id="long"
                  placeholder="Longitude"
                  value={long}
                  onChange={(ev) => setLong(ev.target.value)}
                  className={`${!long && longErr && "on-focus"}`}
                />
                {!long && longErr && (
                  <div className="error-text">Longitude is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="link">
                  Map Link<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="link"
                  id="link"
                  placeholder="Map Link"
                  value={link}
                  onChange={(ev) => setLink(ev.target.value)}
                  className={`${!link && linkErr && "on-focus"}`}
                />
                {!link && linkErr && (
                  <div className="error-text">Map Link is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="phone">
                  Phone Number<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(ev) => validateNumber(ev)}
                  className={`${!phone && phoneErr && "on-focus"}`}
                  maxLength={10}
                />
                {!phone && phoneErr && (
                  <div className="error-text">Phone Number is required</div>
                )}
                {phone && phone.length < 10 && (
                  <div className="error-text">
                    Phone Number less than 10 digits
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddServiceCenterModal;
