import { globalLoader } from "actions/commonActions";
import { GLOBAL_API } from "actionTypes/types";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CryptoJs from "crypto-js";
import { Col, Container, Row } from "reactstrap";
import { TOKEN_USERNAME } from "actionTypes/types";
import { SECRET } from "actionTypes/types";
import { TOKEN_PASSWORD } from "actionTypes/types";
import { TOKEN_GRANT_TYPE } from "actionTypes/types";
import qs from "query-string";
import FaqContent from "../components/template/faqContent";

const FAQ = () => {
  const [view, setView] = useState(false);
  const [dataList, setDataList] = useState(null);

  useEffect(() => {
    callBasicApi();
  }, []);

  const callBasicApi = async () => {
    setView(false);
    globalLoader(true);
    try {
      let obj = {
        username: CryptoJs.AES.decrypt(TOKEN_USERNAME, SECRET).toString(
          CryptoJs.enc.Utf8
        ),
        password: CryptoJs.AES.decrypt(TOKEN_PASSWORD, SECRET).toString(
          CryptoJs.enc.Utf8
        ),
        grant_type: CryptoJs.AES.decrypt(TOKEN_GRANT_TYPE, SECRET).toString(
          CryptoJs.enc.Utf8
        ),
      };
      let res = await axios.post(`${GLOBAL_API}/token`, qs.stringify(obj));
      if (res?.status === 200) {
        const token = res?.data?.access_token;
        res = await axios.get(`${GLOBAL_API}/Portal/FrequentlyAskedQuestions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res?.data?.status === 0) {
          let data = res?.data?.data;
          data?.forEach((item) => {
            item.isActive = false;
          });
          setDataList(data);
          setView(true);
        } else {
          toast.error("Error retrieving data");
        }
      } else {
        toast.error(res?.data?.message);
      }
      globalLoader(false);
    } catch (err) {
      toast.error("Unauthorized");
      globalLoader(false);
    }
  };

  return (
    <>
      <div className="wrapper" style={{ backgroundColor: "#f1f1f1" }}>
        <div className="header primary-bg">
          <Container>
            <div className="header-body text-center mb-3">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="color-white header-body-txt">
                    Frequently Asked Questions
                  </h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {view && (
          <Container>
            <ul className="list-unstyled py-5 ">
              {dataList &&
                dataList.length > 0 &&
                dataList.map((data) => (
                  <FaqContent data={data} key={data?.faq_id} isArabic={false} />
                ))}
            </ul>
          </Container>
        )}
      </div>
    </>
  );
};

export default FAQ;
