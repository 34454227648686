import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { sort } from "fast-sort";

import {
  designationListApi,
  designationUserApi,
} from "../views/examples/_actions";

export const useDesignationsUserData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["designations-user", profile_email],
    () => designationUserApi(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        let designations = [];
        data?.data?.data.forEach((designation) => {
          let newDesignation = {
            label: designation?.pdg_desc,
            value: designation?.pdg_id,
          };
          designations.push(newDesignation);
        });
        return designations;
      },
    }
  );
};

export const useDesignationsListData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["designations-list", profile_email],
    () => designationListApi(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        let designations = data?.data?.data;
        let sortedDesignations = sort(designations).desc((u) => u?.pdg_id);
        return sortedDesignations;
      },
    }
  );
};
