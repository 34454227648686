import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { profileDetailsApi } from "../../views/examples/_actions";

import { globalLoader } from "actions/commonActions";
import { logOut } from "_shared/commonFunctions";
import { getRentRequestByID, sendNotificationApi } from "./_actions";
import { updateStatusApi } from "./_actions";
import { getPopupDetails } from "../../_shared/commonFunctions";

import ReplyNotification from "../../components/Modals/replyNotificationModal";
import UserHeader from "../../components/Headers/userHeader";
import RentTable from "../../components/tables/rentTable";
import StatusUpdateModal from "../../components/Modals/statusUpdateModal";
import DetailsModal from "../../components/Modals/detailsModal";
import PrimaryButton from "components/button/primary-button";
import ViewImageModal from "components/Modals/viewImageModal";
import { firestore } from "../../../src/firebase";

import { useRentRequestData } from "hooks/useMyBookingData";
import { useStatusData } from "hooks/useStatusData";
import { UsePaymentModesTableData } from "hooks/usepaymentModesData";
import { useIDTypeTableData } from "hooks/useIDTypeData";
import AppContext from "context/app.context";
import Chat from "components/Modals/chatModal";

const sourceFilter = [
  {
    name: "CORP CARE",
    id: "2",
  },
  {
    name: "AVIS CARE",
    id: "1",
  },
];

const RentRequest = (props) => {
  const { userRights, currentCompany, location } = props;

  const [canEdit, setCanEdit] = useState(false);

  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);
  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [chatContext, setChatContext] = useState(null);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [imgData, setImgData] = useState(null);
  const [imgType, setImgType] = useState("");

  const { booking, dispatch } = useContext(AppContext);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_13") {
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  useEffect(() => {
    if (location && location.pathname === "/admin/rent_request_corp") {
      setSearchId("rac_source");
      setSearchFilter(2);
    }
  }, [location]);

  const {
    data: rentRequest,
    isFetching,
    isFetched,
    refetch,
  } = useRentRequestData(currentCompany);

  const requestedAvailable = React.useMemo(() => {
    // return ["4", "3", "7", "6", "8"];
    if (rentRequest)
      return rentRequest.some((row) => {
        return row["rent_staus"] == "1";
      });
  }, [rentRequest]);

  const [searchId, setSearchId] = useState(
    requestedAvailable
      ? props?.location?.state
        ? props?.location?.state?.searchId
        : "rent_staus"
      : undefined
  );
  const [searchFilter, setSearchFilter] = useState(
    requestedAvailable
      ? props?.location?.state
        ? props?.location?.state?.searchFilter
        : "1"
      : undefined
  );

  useEffect(() => {
    if (requestedAvailable) {
      setSearchId("rent_staus");
      setSearchFilter("1");
    }
    setDataLoading(false);
  }, [requestedAvailable]);

  const markHighlightRequests = (threads, userData) => {
    let unreadThreads = [];
    threads.forEach((thread) => {
      let userLastSession = thread.users.find((user) => {
        return user.user_id == userData.user_id;
      });
      // unreadThreads[thread.id] = true;
      let unreadMessageCount = thread.messages.filter(
        (message) =>
          message.message_send_time.seconds >
          (userLastSession ? userLastSession.user_last_viewed_time.seconds : 0)
      ).length;
      if (unreadMessageCount > 0)
        unreadThreads.push({
          id: thread.id,
          count: unreadMessageCount,
        });
    });
    setUnreadMessages(unreadThreads);
  };

  const getProfileDetails = async () => {
    try {
      let res = await profileDetailsApi();
      let user = res?.data?.data[0];
      await fetchMessageInfo(user);
    } catch (err) {
      if (err?.response?.status === 401) {
        // toast.error("Error while fetching profile details");
      }
    }
  };

  const fetchMessageInfo = async (user) => {
    let threads = [];
    const threadsCollection = firestore.collection(`threads`);
    threadsCollection.get().then((querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        if (doc && doc.id) {
          let getMessages = new Promise((resolve, reject) => {
            let messageArray = [];
            firestore
              .collection("threads/" + doc.id + "/messages")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((messages) => {
                  const data = messages.data();
                  messageArray = [...messageArray, data];
                });
              })
              .finally(() => {
                resolve(messageArray);
              });
          });
          let getUsers = new Promise((resolve, reject) => {
            let userArray = [];
            firestore
              .collection("threads/" + doc.id + "/users")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((users) => {
                  const data = users.data();
                  userArray = [...userArray, data];
                });
              })
              .finally(() => {
                resolve(userArray);
              });
          });
          Promise.all([getMessages, getUsers])
            .then((values) => {
              let thread = {
                id: doc.id,
                messages: values[0],
                users: values[1],
                last_message_created_time: doc.data().last_message_created_time,
              };
              threads = [...threads, thread];
            })
            .finally(() => {
              markHighlightRequests(threads, user);
            });

          return doc.data();
        }
      });
    });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const { data: paymentModes } = UsePaymentModesTableData();
  const { data: idTypes } = useIDTypeTableData();

  globalLoader(isFetching);

  const { data: status } = useStatusData(6);

  useEffect(() => {
    window.history.replaceState(null, "");
  }, []);

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "6",
        status: status,
        id: formData?.booking_id,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        logOut(props?.history, "auth/login");
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setData(getPopupDetails(obj, type));
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle("");
  };

  const openNotificationModal = (obj) => {
    let notiData = {
      user_id: obj.userid,
      tag: obj?.company_name,
      type: "6",
      typeDesc: "Rent",
    };
    setFormData(notiData);
    setNotificationModalOpen(true);
  };

  const openChatModal = (obj) => {
    setChatContext(obj);
    setChatModalOpen(true);
  };

  const closeNotificationModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      await sendNotification(obj);
      globalLoader(false);
      refetch();
    }
    setFormData(null);
    setNotificationModalOpen(false);
  };

  const sendNotification = async (obj = null) => {
    try {
      let res = await sendNotificationApi(obj);
      if (res?.data?.status === 0) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      toast.error("Error while posting notification");
      console.log(err);
    }
  };

  const closeChatModal = async (obj = null) => {
    setChatModalOpen(false);
  };

  const viewImage = (val = null, type = null) => {
    if (val) {
      let temp = [{ img_url: val }];
      setImgData(temp);
      setImgType(type);
      setViewImageModal(true);
    }
  };

  const closeImageModal = () => {
    setImgType("");
    setViewImageModal(false);
    setImgData(null);
  };

  const fetchRentRequestByID = async (rentRequest) => {
    console.log(
      "🚀 ~ file: rentRequest.jsx:187 ~ fetchRentRequestByID ~ rentRequest:",
      rentRequest
    );
    const resp = await getRentRequestByID(rentRequest.booking_id);
    console.log(
      "🚀 ~ file: rentRequest.jsx:188 ~ fetchRentRequestByID ~ resp:",
      resp
    );
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Rent Request</h3>
                  <div className="col text-right">
                    <PrimaryButton
                      title="Create Rent Request"
                      onClick={() => {
                        dispatch({ type: "REMOVE_BOOKING" });
                        props?.history?.push({
                          pathname: "/admin/rent_request/new",
                        });
                      }}
                    />
                  </div>
                </Row>
              </CardHeader>
              {!isFetching && !dataLoading && (
                <div>
                  {isFetched ? (
                    rentRequest && rentRequest.length > 0 ? (
                      <RentTable
                        tableData={rentRequest}
                        highlitedIDs={unreadMessages}
                        updateStatus={openUpdateStatus}
                        statusList={status}
                        sourceList={sourceFilter}
                        paymentModes={paymentModes}
                        idTypes={idTypes}
                        canEdit={canEdit}
                        customerModalOpen={customerModalOpen}
                        searchFilter={searchFilter ? searchFilter : null}
                        searchId={searchId ? searchId : null}
                        sendNotification={openNotificationModal}
                        editRentRequest={(data) => {
                          dispatch({ type: "ADD_BOOKING", payload: data });
                          fetchRentRequestByID(data);
                          props?.history?.push({
                            pathname: "/admin/rent_request/new",
                          });
                        }}
                        chat={openChatModal}
                        viewImage={viewImage}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.rent_staus}
        />
      )}
      {userModal && (
        <DetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          data={data}
          title={detailsTitle}
        />
      )}
      {notificationModalOpen && (
        <ReplyNotification
          open={notificationModalOpen}
          onCloseModal={closeNotificationModal}
          formData={formData}
        />
      )}
      {chatModalOpen && (
        <Chat
          open={chatModalOpen}
          chatContext={chatContext}
          onCloseModal={closeChatModal}
          sendNotification={sendNotification}
        />
      )}

      {viewImageModal && (
        <ViewImageModal
          open={viewImageModal}
          onCloseModal={closeImageModal}
          data={imgData}
          imgType={imgType}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(RentRequest);
