import React, { useEffect, useState } from "react";

import { Card, CardHeader, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { globalLoader } from "actions/commonActions";
import { sendNotificationApi } from "./_actions";
import { updateStatusApi } from "./_actions";

import { logOut } from "_shared/commonFunctions";
import { getPopupDetails } from "../../_shared/commonFunctions";

import UserHeader from "../../components/Headers/userHeader";
import FeedbackTable from "../../components/tables/feedbackTable";

import AddNewFeedback from "components/Modals/addNewFeedBack";
import ViewImageModal from "components/Modals/viewImageModal";
import StatusUpdateModal from "components/Modals/statusUpdateModal";
import ReplyNotification from "../../components/Modals/replyNotificationModal";
import DriverDetailsModal from "components/Modals/driverDetailsModal";
import DetailsModal from "../../components/Modals/detailsModal";

import { useStatusData } from "hooks/useStatusData";
import { useFeedbackData } from "hooks/useFeedbackData";

const Feedback = (props) => {
  const { userRights, currentCompany } = props;
  const searchId = props?.location?.state?.searchId;
  const searchFilter = props?.location?.state?.searchFilter;
  const [canEdit, setCanEdit] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [imgData, setImgData] = useState("");
  const [viewImgModal, setViewImgModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);
  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [nationalId, setNationalId] = useState("");

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_7") {
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: feedbackList,
    isFetching,
    isFetched,
    refetch,
  } = useFeedbackData(currentCompany);

  globalLoader(isFetching);

  const { data: status } = useStatusData(5);

  useEffect(() => {
    window.history.replaceState(null, "");
  }, []);

  const editFeedbackData = (obj) => {
    setIsEdit(true);
    setFormData(obj);
    setOpenFeedbackModal(true);
  };

  const closeFeedbackModal = (obj = null) => {
    if (obj) {
    }
    setIsEdit(false);
    setFormData(null);
    setOpenFeedbackModal(false);
  };

  const openViewImage = (obj) => {
    setImgData(obj);
    setViewImgModal(true);
  };

  const closeImageModal = () => {
    setImgData(null);
    setViewImgModal(false);
  };

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "5",
        status: status,
        id: formData?.com_id,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }

        refetch();
      } catch (err) {
        logOut(props?.history, "auth/login");
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setData(getPopupDetails(obj, type));
      setNationalId(obj?.national_id);
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle("");
  };

  const openNotificationModal = (obj) => {
    let notiData = {
      user_id: obj.userid,
      tag: obj?.company_name,
      type: "5",
      typeDesc: "Feedback",
    };
    setFormData(notiData);
    setNotificationModalOpen(true);
  };

  const closeNotificationModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await sendNotificationApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (err) {
        toast.error("Error while posting notification");
        console.log(err);
      }
      globalLoader(false);
      refetch();
    }
    setFormData(null);
    setNotificationModalOpen(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">General Feedback</h3>
                  {/* <div className="col text-right">
                    <button
                      className="btn btn-sm primary-bg"
                      onClick={() => setOpenFeedbackModal(true)}
                    >
                      Add New Feedback
                    </button>
                  </div> */}
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    feedbackList && feedbackList.length > 0 ? (
                      <FeedbackTable
                        statusList={status}
                        tableData={feedbackList}
                        viewImage={openViewImage}
                        editData={editFeedbackData}
                        updateStatus={openUpdateStatus}
                        canEdit={canEdit}
                        searchFilter={searchFilter ? searchFilter : null}
                        searchId={searchId ? searchId : null}
                        customerModalOpen={customerModalOpen}
                        sendNotification={openNotificationModal}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {openFeedbackModal && (
        <AddNewFeedback
          open={openFeedbackModal}
          onCloseModal={closeFeedbackModal}
          isEdit={isEdit}
          formData={formData}
        />
      )}

      {viewImgModal && (
        <ViewImageModal
          open={viewImgModal}
          onCloseModal={closeImageModal}
          data={imgData}
          imgType="Feedback"
        />
      )}

      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.com_status}
        />
      )}
      {userModal && detailsTitle === "customer" && (
        <DriverDetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          formData={nationalId}
        />
      )}

      {userModal && detailsTitle === "company" && (
        <DetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          data={data}
          title={detailsTitle}
        />
      )}
      {notificationModalOpen && (
        <ReplyNotification
          open={notificationModalOpen}
          onCloseModal={closeNotificationModal}
          formData={formData}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(Feedback);
