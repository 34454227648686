import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import StarRatings from 'react-star-ratings';
import TableContainer from './_table';
import { SelectColumnFilter } from './_filters';
import { DateRangeColumnFilter } from './_filters';
// import { DefaultColumnFilter } from "./_filters";
import DescModal from 'components/Modals/descModal';
import { DefaultColumnFilter } from './_filters';
import { tableDropdownData } from '_shared/commonFunctions';
import { SelectFilter } from './_filters';
import VehicleDetailsModal from 'components/Modals/vehicleDetailsModal';

const MaintenanceTable = (props) => {
  const { searchId, searchFilter, statusList, dropdownFilter, dropdownId } =
    props;
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'dummy',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: 'action',
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#pablo"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    onClick={() => {
                      props?.changeStatus(cell?.row?.original);
                    }}
                  >
                    Change Status
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.sendNotification(cell?.row?.original)}
                  >
                    Send Notification
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'maint_status',
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.maint_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: 'includes',
        disableSortBy: true,
      },

      {
        Header: 'Plate No',
        accessor: 'maint_plate',
        Cell: ({ row }) => (
          <div className="color-primary cursor-pointer">
            {row?.original?.maint_plate}
          </div>
        ),
      },
      {
        Header: 'Make Model',
        accessor: 'makemodel',
      },
      {
        Header: 'Company',
        accessor: 'company_code',
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === 'N/A' ? '' : 'cursor-pointer'
            }`}
            onClick={() =>
              row?.original?.company_code !== 'N/A' &&
              props?.customerModalOpen(row?.original, 'company')
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: 'User',
        accessor: 'national_id',
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === 'N/A' ? '' : 'cursor-pointer'
            }`}
            onClick={() =>
              row?.original?.national_id !== 'N/A' &&
              props?.customerModalOpen(row?.original, 'customer')
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },
      {
        Header: 'Preferred Date',
        accessor: 'prefered_date_time',
        Cell: ({ row }) =>
          row?.original?.prefered_date_time !== '' &&
          new Date(row?.original?.prefered_date_time).toLocaleString('en-Us', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
          }),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: 'Scheduled Date',
        accessor: 'schedule_date_time',
        Cell: ({ row }) =>
          row?.original?.schedule_date_time !== '' &&
          new Date(row?.original?.schedule_date_time).toLocaleString('en-Us', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
          }),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: 'Odometer',
        accessor: 'maint_odo',
        Cell: ({ row }) =>
          row?.original?.maint_odo === '' ? (
            'N/A'
          ) : (
            <div className="text-right">{row?.original?.maint_odo}</div>
          ),
      },
      {
        Header: 'Odometer Image',
        accessor: 'odo_images',
        Cell: ({ row }) =>
          row?.original?.odo_images[0] !== null && (
            <div className="d-flex justify-content-center">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'maint_id',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Service Center',
        accessor: 'loc_address',
        Cell: ({ row }) => (
          <span className="table-text">
            {row?.original?.loc_address}, {row?.original?.loc_city}
          </span>
        ),
      },
      {
        Header: 'Reason',
        accessor: 'maint_reason',
        Cell: ({ row }) => {
          const text = row?.original?.maint_reason.trim();
          return (
            <>
              <div className={`${text?.length > 15 ? 'view-more' : ''}`}>
                {text}
              </div>
              {text.length > 15 && (
                <div
                  className="font-12 color-primary cursor-pointer"
                  onClick={() => handleMore('Reason', text)}
                >
                  View More
                </div>
              )}
            </>
          );
        },
        disableFilters: true,
      },
      {
        Header: 'Type',
        accessor: 'service_type_id',
        Cell: ({ row }) =>
          row?.original?.service_type_id === '1'
            ? 'Breakdown'
            : row?.original?.service_type_id === '2'
            ? 'Preventive'
            : row?.original?.service_type_id === '3'
            ? 'Mishandling'
            : row?.original?.service_type_id === '4'
            ? 'Overhaul'
            : row?.original?.service_type_id === '5'
            ? 'User Request'
            : 'Planned',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
      },
      {
        Header: 'Center No',
        accessor: 'loc_phone',
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.loc_phone}</div>
        ),
        disableFilters: true,
      },
      {
        Header: 'Images',
        accessor: 'images',
        Cell: ({ row }) =>
          row?.original?.images[0] !== null && (
            <div className="d-flex justify-content-center">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Rating',
        accessor: 'maint_ratng',
        Cell: ({ row }) =>
          row?.original.maint_rating !== '' && (
            <StarRatings
              rating={parseInt(row?.original.maint_rating)}
              starRatedColor="rgb(212,0,42)"
              starDimension="10px"
              starSpacing="1px"
            />
          ),
        disableFilters: true,
      },
      {
        Header: 'Comment',
        accessor: 'maint_rating_comment',
        Cell: ({ row }) => {
          const text = row?.original?.maint_rating_comment.trim();
          return (
            <>
              <div className={`${text?.length > 15 ? 'view-more' : ''}`}>
                {text}
              </div>
              {text.length > 15 && (
                <div
                  className="font-12 color-primary cursor-pointer"
                  onClick={() => handleMore('Comment', text)}
                >
                  View More
                </div>
              )}
            </>
          );
        },
        disableFilters: true,
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = React.useMemo(() => props?.tableData, []);
  return (
    <>
      <TableContainer
        dropdownFilter={dropdownFilter}
        dropdownId={dropdownId}
        searchFilter={searchFilter}
        searchId={searchId}
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (
              data?.column?.Header === 'Images' &&
              data?.row?.original?.images[0]
            ) {
              props?.openImage(data?.row?.original?.images, 'Images');
            }
            if (
              data?.column?.Header === 'Odometer Image' &&
              data?.row?.original?.odo_images[0]
            ) {
              props?.openImage(
                data?.row?.original?.odo_images,
                'Odometer Image'
              );
            }

            if (data?.column?.id === 'maint_plate') {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}

      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          formData={formData}
          plateNo={formData?.maint_plate}
        />
      )}
    </>
  );
};

export default MaintenanceTable;
