import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form } from "reactstrap";

const ConfirmModal = (props) => {
  return (
    <>
      <Modal
        open={props?.open}
        center
        onClose={() => props?.onCloseModal(false)}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
      >
        <div className="px-3 pt-3">
          <Form>
            <div className="row">
              <div className="col-12 color-primary text-center">
                ID: {props?.id}
              </div>
              {props?.type === "User" && (
                <div className="col-12 color-primary text-center">
                  NAME: {props?.name}
                </div>
              )}
              <div className="col-12 text-center py-3">
                <h3>Are you sure you want to delete?</h3>
              </div>
              <div className="d-flex justify-content-center pt-4 col-12">
                <button
                  className="btn grey-bg"
                  onClick={() => props?.onCloseModal(false)}
                >
                  No
                </button>
                <button
                  className="btn primary-bg"
                  onClick={() => props?.onCloseModal(true)}
                >
                  Yes
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
