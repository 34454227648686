import React, { useState, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import Flatpickr from "react-flatpickr";

const DamageListModal = (props) => {
  const imgRef = useRef();
  const [plate, setPlate] = useState("");
  const [plateErr, setPlateErr] = useState(false);
  const [datetime, setDatetime] = useState(null);
  const [datetimeErr, setDatetimeErr] = useState(false);
  const [type, setType] = useState("");
  const [typeErr, setTypeErr] = useState(false);
  const [area, setArea] = useState("");
  const [areaErr, setAreaErr] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesErr, setImagesErr] = useState(false);
  const [details, setDetails] = useState("");
  const [detailsErr, setDetailsErr] = useState(false);

  const handleValidation = () => {
    let isValid = true;
    if (!plate) {
      isValid = false;
      setPlateErr(true);
    }
    if (!datetime) {
      isValid = false;
      setDatetimeErr(true);
    }
    if (!type) {
      isValid = false;
      setTypeErr(true);
    }
    if (!area) {
      isValid = false;
      setAreaErr(true);
    }
    if (images.length === 0) {
      isValid = false;
      setImagesErr(true);
    }
    if (!details) {
      isValid = false;
      setDetailsErr(true);
    }
    return isValid;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      let obj = {
        plate: plate,
        datetime: datetime,
        type: type,
        area: area,
        images: images,
        details: details,
      };
      props?.onCloseModal(obj);
    }
  };

  const handleMultiFileUpload = (ev) => {
    let file = ev.target.files;
    let obj = [];
    for (let i = 0; i < file.length; i++) {
      let val = {
        img_datetime: file[i].lastModifiedDate,
        img_id: Math.floor(Math.random() * 1000),
        img_name: file[i].name,
        img_type: "Damage",
        img_type_id: Math.floor(Math.random() * 1000),
        img_url: URL.createObjectURL(file[i]),
      };
      obj.push(val);
    }
    setImages(obj);
  };

  return (
    <>
      <Modal
        open={props?.open}
        center
        onClose={() => props?.onCloseModal()}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
      >
        <div className="px-3 pt-5">
          <Form>
            <div className="row">
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="plate">Plate Number</Label>
                  <Input
                    type="text"
                    id="plate"
                    placeholder="Plate Number"
                    value={plate}
                    onChange={(ev) => setPlate(ev.target.value)}
                  />
                  {!plate && plateErr && (
                    <div className="error-text">Plate Number is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="datetime">Date & Time</Label>
                  <Flatpickr
                    className="form-control datepicker-own"
                    options={{
                      altInput: true,
                      dateFormat: "Y-m-d H:i",
                      enableTime: true,
                    }}
                    onChange={(date) => setDatetime(date)}
                    value={datetime !== null && datetime}
                  />
                  {!datetime && datetimeErr && (
                    <div className="error-text">Date & Time is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="type">Damage Type</Label>
                  <Input
                    type="text"
                    id="type"
                    placeholder="Damage Type"
                    value={type}
                    onChange={(ev) => setType(ev.target.value)}
                  />
                  {!type && typeErr && (
                    <div className="error-text">Damage Type is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="area">Damage Area</Label>
                  <Input
                    type="text"
                    id="area"
                    placeholder="Damage Area"
                    value={area}
                    onChange={(ev) => setArea(ev.target.value)}
                  />
                  {!area && areaErr && (
                    <div className="error-text">Damage Area is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="image">Images</Label>
                  <div className="file-upload-wrapper">
                    <input
                      ref={imgRef}
                      type="file"
                      id="image"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg"
                      onChange={handleMultiFileUpload}
                      multiple
                    />
                    <input
                      type="text"
                      name="images_name"
                      id="images_name"
                      className="form-own-input-filename form-control"
                      value={
                        images.length > 0
                          ? images.map((obj) => obj.img_name)
                          : ""
                      }
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        imgRef.current.click();
                      }}
                      disabled={props?.isEdit ? true : false}
                    >
                      {images.length > 0 ? "Change" : "Upload"}
                    </button>
                  </div>
                  {images.length === 0 && imagesErr && (
                    <div className="error-text">Images is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="details">Details</Label>
                  <Input
                    type="text"
                    id="details"
                    placeholder="Details"
                    value={details}
                    onChange={(ev) => setDetails(ev.target.value)}
                  />
                  {!details && detailsErr && (
                    <div className="error-text">Details is required</div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center pt-4 col-12">
                <button
                  className="btn grey-bg"
                  onClick={() => props?.onCloseModal()}
                >
                  Cancel
                </button>
                <button className="btn primary-bg" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default DamageListModal;
