import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const BookingSuccessModal = ({ bookingId, open, onCloseModal }) => {
  return (
    <>
      <Modal
        open={open}
        center
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        onClose={onCloseModal}
        classNames={{ modal: "form-modal-own" }}
      >
        <div className="px-3 pt-3">
          <div className="row">
            <div className="col-12 text-center py-3">
              <h3>Success : Request created successfully</h3>
            </div>

            <div className="col-12 color-primary text-center">
              Booking Ref : {bookingId}
            </div>

            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn primary-bg"
                onClick={() => onCloseModal(true)}
              >
                Go to Booking List
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BookingSuccessModal;
