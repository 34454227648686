import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ViewModal = (props) => {
  return (
    <>
      <Modal
        open={props?.open}
        onClose={() => props?.onCloseModal()}
        center
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
      >
        <div className="popup-header-bar">
          <label className="text-center weight-800">{props?.type}</label>
        </div>
        <div className="py-3 row">
          <ul className="list-unstyled pt-3 col-12">
            {props?.data.map((l, i) => (
              <li key={i} className="py-1 pl-5 ml-5">
                <span className="weight-600 pr-2 color-primary">
                  {l?.company_code}
                </span>
                <span className="pl-2">{l?.company_name}</span>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default ViewModal;
