import React, { useState, useEffect } from "react";

import { Card, CardHeader, Container, Row, Table } from "reactstrap";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import UserHeader from "../../components/Headers/userHeader";
import AddRoleModal from "components/Modals/addRoleModal";

import { globalLoader } from "actions/commonActions";
import { logOut } from "_shared/commonFunctions";

import { userRightsUpdate } from "./_actions";

import { useRolesData } from "hooks/useRolesData";
import { useUserRightsData } from "hooks/useRolesData";

const PermissionManagement = (props) => {
  const animatedComponents = makeAnimated();
  const [openRole, setOpenRole] = useState(false);
  const [change, setChange] = useState(false);

  const [roleCode, setRoleCode] = useState(null);
  const [permissionList, setPermissionList] = useState([]);

  const { data: roleList, isFetching, isFetched, refetch } = useRolesData();

  globalLoader(isFetching);

  const {
    data: rightsList,
    isLoading: rightsLoading,
    refetch: rightsRefetch,
  } = useUserRightsData(roleCode?.value);

  useEffect(() => {
    if (rightsList && rightsList.length > 0) {
      setPermissionList(rightsList);
      globalLoader(false);
    }
  }, [rightsList]);

  if (rightsLoading) {
    globalLoader(true);
  }

  const closeRoleModal = async (obj) => {
    if (obj) {
      globalLoader(true);

      refetch();
    }

    setOpenRole(false);
  };

  const handleRoleChange = async (event) => {
    setPermissionList([]);
    let newRole = event;
    setRoleCode(newRole);
    rightsRefetch(newRole.value);
  };

  const handlePermissionChange = (id, name) => {
    let list = permissionList;
    list.forEach((item) => {
      if (item?.menu_name === name) {
        if (id === "read") {
          // item[id] === "1" ? (item[id] = "0") : (item[id] = "1");
          if (item.read === "1") {
            item.create = "0";
            item.read = "0";
            item.update = "0";
            item.delete = "0";
          } else {
            item.read = "1";
          }
          setPermissionList([...list]);
          setChange(true);
        } else {
          if (item?.read === "0") {
            toast.error("Please grant Read permission first.");
          } else {
            item[id] === "1" ? (item[id] = "0") : (item[id] = "1");
            setPermissionList([...list]);
            setChange(true);
          }
        }
      }
    });
  };

  const handleSubmit = async () => {
    let obj = {
      role_id: roleCode?.value,
      permission_status: [],
    };
    permissionList.forEach((item) => {
      let moduleList = {
        module_name: item?.pmh_menuid,
        operations: {
          create: item?.create === "1" ? true : false,
          read: item?.read === "1" ? true : false,
          update: item?.update === "1" ? true : false,
          delete: item?.delete === "1" ? true : false,
        },
      };
      obj.permission_status.push(moduleList);
    });
    try {
      globalLoader(true);
      let res = await userRightsUpdate(obj);
      if (res?.data?.status === 0) {
        toast.success("Permissions changed");
        refetch();
      } else {
      }
      globalLoader(false);
    } catch (err) {
      if (err?.response?.status === 401) {
        logOut(props?.history, "auth/login");
        globalLoader(false);
      } else {
        globalLoader(false);
      }
    }
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8 pb-3" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Permission Management</h3>
                  <div className="col text-right">
                    <button
                      className="btn btn-sm primary-bg"
                      onClick={() => setOpenRole(true)}
                    >
                      Add New Role
                    </button>
                  </div>
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched && roleList && roleList?.length > 0 && (
                    <>
                      <div className="col-4 pt-4 pb-4">
                        <Select
                          components={animatedComponents}
                          placeholder="Select Role"
                          options={roleList}
                          onChange={handleRoleChange}
                          value={roleCode}
                          isSearchable={true}
                          isClearable={true}
                          className="select-dropdown-own"
                        />
                      </div>
                      <div className="col-12 pb-4 d-flex justify-content-end">
                        <button
                          className={`btn btn-sm primary-bg ${
                            change ? "visible" : "invisible"
                          }`}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                      {roleCode &&
                        permissionList &&
                        permissionList.length > 0 && (
                          <div className="col-12 table-responsive">
                            <Table className="align-items-center table-flush table  pb-4 px-2">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Module Name</th>
                                  <th scope="col">Read</th>
                                  <th scope="col">Create</th>
                                  <th scope="col">Update</th>
                                  <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                {permissionList.map((data) => {
                                  return (
                                    <tr key={data?.pur_id}>
                                      <td>{data?.menu_name}</td>
                                      <td>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={
                                              data?.read === "1" ? true : false
                                            }
                                            onChange={() =>
                                              handlePermissionChange(
                                                "read",
                                                data?.menu_name
                                              )
                                            }
                                          ></input>
                                          <span className="slider"></span>
                                        </label>
                                      </td>
                                      <td>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={
                                              data?.create === "1"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handlePermissionChange(
                                                "create",
                                                data?.menu_name
                                              )
                                            }
                                          ></input>
                                          <span className="slider"></span>
                                        </label>
                                      </td>

                                      <td>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={
                                              data?.update === "1"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handlePermissionChange(
                                                "update",
                                                data?.menu_name
                                              )
                                            }
                                          ></input>
                                          <span className="slider"></span>
                                        </label>
                                      </td>
                                      <td>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={
                                              data?.delete === "1"
                                                ? true
                                                : false
                                            }
                                            onChange={() =>
                                              handlePermissionChange(
                                                "delete",
                                                data?.menu_name
                                              )
                                            }
                                          ></input>
                                          <span className="slider"></span>
                                        </label>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        )}
                    </>
                  )}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {openRole && (
        <AddRoleModal open={openRole} onCloseModal={closeRoleModal} />
      )}
    </>
  );
};

export default PermissionManagement;
