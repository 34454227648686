import React from "react";
import Modal from "react-responsive-modal";

const DescModal = (props) => {
  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">{props?.title}</label>
      </div>
      <div className="row p-5">
        <div className="col-12   text-center">
          <p>{props?.desc}</p>
        </div>
      </div>
    </Modal>
  );
};

export default DescModal;
