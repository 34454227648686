import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import { maintenanceHistoryListApi } from "../views/examples/_actions";

export const useMaintainanceHistoryData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["maintainance-history", currentCompany, profile_email],
    () => maintenanceHistoryListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const historyList = data?.data?.data;
          if (historyList && historyList.length > 0) {
            let sortedHistoryList = sort(historyList).desc(
              (history) => new Date(history?.create_date)
            );
            return sortedHistoryList;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    }
  );
};
