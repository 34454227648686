import React, { useMemo } from "react";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import TableContainer from "./_table";
import { SelectFilter } from "./_filters";
import { tableDropdownData } from "../../_shared/commonFunctions";

const ChauffeurRateTable = (props) => {
	const { statusList, rateTypeList } = props;
	const rateList = rateTypeList?.map((list) => {
		return { id: list.value, name: list.label };
	});
	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "dummy",
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: () => <div className="color-primary">Actions</div>,
				accessor: "action",
				Cell: (cell) =>
					props?.canEdit && (
						<div className="text-left">
							<UncontrolledDropdown>
								<DropdownToggle
									className="btn-icon-only text-light"
									role="button"
									size="sm"
									color=""
									onClick={(e) => e.preventDefault()}
								>
									<i className="fas fa-ellipsis-v color-primary" />
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-arrow">
									<DropdownItem
										onClick={() => props?.editData(cell?.row?.original)}
									>
										Edit
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					),
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: "Status",
				accessor: "char_status",
				Cell: ({ row }) =>
					tableDropdownData(row?.original?.char_status, statusList),
				Filter: (props) => SelectFilter(props, statusList),
				filter: "includes",
				disableSortBy: true,
			},
			{
				Header: "Vehicle Group",
				accessor: "chr_veh_group",
			},
			{
				Header: "Rate",
				accessor: "chr_rate",
				rightAlign: true,
				Cell: ({ row }) => (
					<div className="text-right w-100">{row?.original?.chr_rate}</div>
				),

				disableFilters: true,
			},
			{
				Header: "Tariff",
				accessor: "char_rate_type_id",
				Cell: ({ row }) =>
					rateTypeList &&
					rateTypeList.length > 0 &&
					rateTypeList
						.filter((a) => a.value === row?.original?.char_rate_type_id)
						.map((b) => b.label),
				Filter: (props) => SelectFilter(props, rateList),
				filter: "includes",
				disableSortBy: true,
			},
			{
				Header: "Body Type",
				accessor: "chr_bdy_desc",
				disableFilters: true,
			},
			{
				Header: "Passenger",
				accessor: "chr_passenger",
				disableFilters: true,
			},
			{
				Header: "Year",
				accessor: "chr_year",
				disableFilters: true,
			},
			{
				Header: "Image",
				accessor: "chr_bdy_img_link",
				Cell: ({ row }) =>
					row?.original?.chr_bdy_img_link !== "" && (
						<div
						// className="avatar rounded-circle"
						>
							<img
								alt="profile"
								// className="profile-img"
								style={{ width: "100%" }}
								src={row?.original?.chr_bdy_img_link}
							/>
						</div>
					),
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: "ID",
				accessor: "chr_id",
			},
		],
		// eslint-disable-next-line
		[props?.canEdit, statusList]
	);
	// eslint-disable-next-line
	const data = useMemo(() => props?.tableData, []);

	return <TableContainer columns={columns} data={data} />;
};

export default ChauffeurRateTable;
