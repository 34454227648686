import { useEffect, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar } from "reactstrap";

import IconButton from "components/button/icon-button";

export const CounterButton = ({ driverCount, counterChanged }) => {
  const [counter, setCounter] = useState(driverCount);

  useEffect(() => {
    console.log(
      "🚀 ~ file: counter-button.jsx:12 ~ CounterButton ~ driverCount:",
      driverCount
    );
    setCounter(driverCount);
  }, [driverCount]);

  const subtractCounterHandler = () => {
    if (counter > 0) {
      setCounter(counter - 1);
      counterChanged(counter - 1);
    }
  };

  const addCounterHandler = () => {
    if (counter < 2) {
      setCounter(counter + 1);
      counterChanged(counter + 1);
    }
  };

  return (
    <>
      <ButtonToolbar>
        <ButtonGroup className="me-2">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <IconButton
              iconName="fa-minus"
              iconPosition="left"
              onClick={subtractCounterHandler}
            />

            <Button>{counter}</Button>
            <IconButton
              iconName="fa-plus"
              iconPosition="right"
              onClick={addCounterHandler}
            />
          </div>
        </ButtonGroup>
      </ButtonToolbar>
    </>
  );
};
