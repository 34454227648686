import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { sort } from "fast-sort";

import {
  appUserDropdownListApi,
  authorizationPortalListApi,
} from "../views/examples/_actions";

export const useAuthorizationData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["authorization-list", currentCompany, profile_email],
    () => authorizationPortalListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const list = data?.data?.data;
          let sortedList = sort(list).desc((u) => parseInt(u?.aut_id));
          return sortedList;
        } else {
          return null;
        }
      },
    }
  );
};

export const useUserDropdownData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["user-dropdpown-list", profile_email],
    () => appUserDropdownListApi(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        const list = data?.data?.data;
        const userArr = [];
        list.forEach((user) => {
          let temp = {
            label: `${user?.user_id}: ${user?.first_name} ${user?.last_name}`,
            value: user?.user_id,
          };
          userArr.push(temp);
        });

        return userArr;
      },
    }
  );
};
