import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { sort } from "fast-sort";

import { locationsApi } from "../views/examples/_actions";

export const useServiceCenterData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["service-centre-list", currentCompany, profile_email],
    () => locationsApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const list = data?.data?.data;
          let sortedList = sort(list).desc((u) => u?.loc_id);
          return sortedList;
        } else {
          return null;
        }
      },
    }
  );
};
