import PrimaryButtonBordered from "components/button/primary-button-bordered";
import React, { useEffect, useState } from "react";
import CarBadge from "./car-badge";

const Car = ({ car, isSelected = false, onSelectCar }) => {
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    prepareBadges(car);
  }, [car]);

  const prepareBadges = (car) => {
    const seatBadge = {
      icon: "../../assets/car-seat-icon.svg",
      title: `${car.no_of_seats} Seats`,
    };

    const doorsBadge = {
      icon: "../../assets/car-door-icon.svg",
      title: `${car.no_of_doors} Doors`,
    };

    const gearBadge = {
      icon: "../../assets/transmission-icon.svg",
      title: car.gear_type,
    };

    const fuelBadge = {
      icon: "../../assets/fuel-icon.svg",
      title: car.rat_fuel,
    };

    setBadges([seatBadge, doorsBadge, gearBadge, fuelBadge]);
  };

  return (
    <div style={{ padding: "10px" }} className="col-4">
      <div
        style={{
          padding: "10px",
          border: isSelected ? "4px solid red" : "1px solid #dedede",
        }}
      >
        <div className="row">
          <div className="col-12 font-18  weight-600">{car.rat_fleet_desc}</div>
        </div>

        <div className="col-12 row">
          <div className="font-16  weight-400 h-6">{car.rat_vehicle_desc}</div>
        </div>

        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          {badges.map((badge) => (
            <CarBadge title={badge.title} icon={badge.icon} key={badge.title} />
          ))}
        </div>

        <div
          style={{ margin: "10px", display: "flex", justifyContent: "center" }}
        >
          <img
            style={{ width: "80%", height: "150px" }}
            src={car.image_url}
            alt="..."
          />
        </div>

        <div className="row col-12 font-24 weight-800">
          SAR {car.rate_daily}
          <span style={{ marginLeft: "5px" }}>
            / {car.rate_monthly}{" "}
            <span className="font-12" style={{ fontWeight: "bold" }}>
              (Daily / Monthly)
            </span>
          </span>
        </div>
        <div className="row col-12 font-12 weight-800">
          CDW : SAR {car.cdw_daily}
          <span style={{ marginLeft: "5px" }}>
            / {car.cdw_monthly}{" "}
            <span className="font-6" style={{ fontWeight: "bold" }}>
              (Daily / Monthly)
            </span>
          </span>
        </div>
        <p style={{ color: "#32325d9c" }}>{car.rat_display_text}</p>

        <div className="row col-12">
          Excess Kilometer Charge: SAR {car?.excess_km_charge}
        </div>

        <div className="row col-12">
          Accident Deduct: SAR {car?.accident_deduct}
        </div>
        <div
          className="col-12"
          style={{ marginBottom: "20px", marginTop: " 10px" }}
        >
          <PrimaryButtonBordered
            title="Choose This"
            onClick={(e) => {
              e.preventDefault();
              onSelectCar(car);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Car;
