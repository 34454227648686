import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { getRentalStationsApi } from "views/examples/_actions";

export const useRentalStationsData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["rental-stations", profile_email],
    () => getRentalStationsApi(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data?.data?.data;
      },
    }
  );
};
