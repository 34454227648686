import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { maintenanceLocationApi } from "../views/examples/_actions";

export const useMaintainanceLocationData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["maintainance-locations", profile_email],
    () => maintenanceLocationApi(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        let locations = [];
        data?.data?.data?.forEach((location) => {
          let newLocation = {
            value: location?.loc_id,
            label: location?.loc_desc,
          };
          locations.push(newLocation);
        });
        return locations;
      },
    }
  );
};
