import React from "react";

const TaskContent = (props) => {
  const {
    count = null,
    label = null,
    pathname = null,
    searchId = null,
    searchFilter = null,
    type = null,
  } = props;
  return (
    <div className="task-content-wrapper">
      <div className="d-flex align-items-center">
        <div className="color-primary weight-600 pr-2">{count}</div>
        <div>{label}</div>
      </div>
      {count !== "0" && (
        <div
          className="color-primary weight-800 cursor-pointer"
          onClick={() =>
            props?.history?.push({
              pathname: pathname,
              state:
                searchFilter && searchId
                  ? { searchId: searchId, searchFilter: searchFilter }
                  : null,
            })
          }
        >
          View {type} <i className="fas fa-arrow-right"></i>
        </div>
      )}
    </div>
  );
};

export default TaskContent;
