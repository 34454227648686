import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import {
  companyListApi,
  employeeCompanyList,
} from "../views/examples/_actions";

export const useCompaniesData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["companies", profile_email], () => companyListApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      let companies = [];
      data?.data?.data?.forEach((company) => {
        let newCompany = {
          value: company?.company_code,
          label: company?.company_name,
        };
        companies.push(newCompany);
      });
      return companies;
    },
  });
};

export const useCompaniesFilterData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["filtered-companies", profile_email],
    () => employeeCompanyList(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        let companies = [{ value: null, label: "All" }];
        data?.data.data.forEach((company) => {
          let newCompany = {
            value: company?.company_code,
            label: company?.company_name,
          };
          companies.push(newCompany);
        });
        return companies;
      },
    }
  );
};
