import { PriceItem } from "./price-item";

export const TotalPrice = ({ price }) => {
  return (
    <>
      {" "}
      {price && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "20px 0 0 0",
            minWidth: "350px",
          }}
        >
          {price?.duration_days && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div style={{ flex: "1" }}>
                <p style={{ marginBottm: "8px" }}>{price?.duration_text}</p>
              </div>
              <div>
                <p>:</p>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  whiteSpace: "nowrap",
                }}
              >
                <p>{price?.duration_days}</p>
              </div>
            </div>
          )}

          {/*  */}
          {price?.rental_charge && price?.rental_charge !== "0" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div style={{ flex: "1" }}>
                <p style={{ whiteSpace: "nowrap" }}>{price?.rental_text}</p>
              </div>
              <div>
                <p>:</p>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  whiteSpace: "nowrap",
                  alignItems: "flex-start",
                }}
              >
                <p>{price?.rental_charge} SAR</p>
              </div>
            </div>
          )}

          {/*  */}

          {price?.cdw_charge && price?.cdw_charge !== "0" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div style={{ flex: "1" }}>
                <p style={{ whiteSpace: "nowrap" }}>{price?.cdw_text}</p>
              </div>
              <div>
                <p>:</p>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  whiteSpace: "nowrap",
                  alignItems: "flex-start",
                }}
              >
                <p>{price?.cdw_charge} SAR</p>
              </div>
            </div>
          )}

          {/*  */}

          {price?.drop_of_charge && price?.drop_of_charge !== "0" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div style={{ flex: "1" }}>
                <p style={{ whiteSpace: "nowrap" }}>{price?.drop_of_text}</p>
              </div>
              <div>
                <p>:</p>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  whiteSpace: "nowrap",
                  alignItems: "flex-start",
                }}
              >
                <p>{price?.drop_of_charge} SAR</p>
              </div>
            </div>
          )}

          {/*  */}

          {price?.additional_driver_charge &&
            price?.additional_driver_charge !== "0.00" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <div style={{ flex: "1" }}>
                  <p style={{ whiteSpace: "nowrap" }}>
                    {price?.additional_driver_text}
                  </p>
                </div>
                <div>
                  <p>:</p>
                </div>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    whiteSpace: "nowrap",
                    alignItems: "flex-start",
                  }}
                >
                  <p>{price?.additional_driver_charge} SAR</p>
                </div>
              </div>
            )}

          {/*  */}

          {price?.tamm_charge && price?.tamm_charge !== "0" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div style={{ flex: "1" }}>
                <p style={{ whiteSpace: "nowrap" }}>{price?.tamm_text}</p>
              </div>
              <div>
                <p>:</p>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  whiteSpace: "nowrap",
                  alignItems: "flex-start",
                }}
              >
                <p>{price?.tamm_charge} SAR</p>
              </div>
            </div>
          )}
          {price?.travel_permit_charge &&
            price?.travel_permit_charge !== "0" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <div style={{ flex: "1" }}>
                  <p>{price?.travel_permit_text}</p>
                </div>
                <div>
                  <p>:</p>
                </div>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    whiteSpace: "nowrap",
                    alignItems: "flex-start",
                  }}
                >
                  <p>{price?.travel_permit_charge} SAR</p>
                </div>
              </div>
            )}

          {/*  */}

          <div style={{ marginTop: "-30px" }}>
            <hr />
          </div>

          {/*  */}

          {price?.total && price?.total !== "0" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "-20px",
              }}
            >
              <div style={{ flex: "1" }}>
                <p>Total</p>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <p>{price?.total} SAR </p>
              </div>
            </div>
          )}

          {/*  */}

          {price?.vat_charge && price?.vat_charge !== "0" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "1" }}>
                <p>{price?.vat_text}</p>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <p>{price?.vat_charge} SAR</p>
              </div>
            </div>
          )}

          {price?.grand_total && price?.grand_total !== "0" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "1" }}>{price?.grand_total_text}</div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                {price?.grand_total} SAR
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
