import Axios from "axios";
import { https } from "follow-redirects";
import Cookies from "js-cookie";
import { GLOBAL_API } from "./types";
import { globalLoader } from "actions/commonActions";
import { roleApiData } from "actions/commonActions";
import { profileApiData } from "actions/commonActions";
import { listingTableData } from "actions/commonActions";
import { companyApiData } from "actions/commonActions";
import { currentCompanyData } from "actions/commonActions";

const BASE_URL = GLOBAL_API;

const axiosInstance = Axios.create({
  baseURL: BASE_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.status === 401 || response?.status === 401) {
      logOut(null, "/auth/login");
      globalLoader(false);
      return Promise.reject(null);
    } else {
      return response;
    }
  },
  (error) => {
    globalLoader(false);

    return Promise.reject(error);
  }
);

export const setAuthorizationToken = () => {
  let token = Cookies.get("access_token");
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

export const postRequest = (url, data) => {
  setAuthorizationToken();
  return axiosInstance({
    method: "post",
    url,
    data,
  });
};

export const getRequest = (url) => {
  setAuthorizationToken();
  return axiosInstance({
    method: "get",
    url,
  });
};

export const logOut = async (value, link) => {
  roleApiData(null);
  profileApiData(null);
  listingTableData(null);
  companyApiData(null);
  currentCompanyData(null);
  Cookies.remove("access_token");
  Cookies.remove("profile_email");
  value?.push(link);
};
