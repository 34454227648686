import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import TableContainer from "./_table";
import { DateRangeColumnFilter } from "./_filters";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";

const TransactionTable = (props) => {
  const { statusList } = props;
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="text-center" style={{ padding: "5px" }}>
            <input
              type="checkbox"
              onClick={(event) => {
                props?.onTransactionSelect(
                  row?.original,
                  event?.target?.checked
                );
              }}
            />
          </div>
        ),
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        disableFilters: true,
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  {/* <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem> */}
                  <DropdownItem
                    onClick={() => props?.sendNotification(cell?.row?.original)}
                  >
                    Send Notification
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
      },
      {
        Header: "Status",
        accessor: "bil_statusid",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.bil_statusid, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Invoice Number",
        accessor: "bil_docno",
        Cell: ({ row }) => (
          <span
            style={{
              color: row?.original?.bil_pdf_avlble === "1" ? "red" : undefined,
              textDecoration:
                row?.original?.bil_pdf_avlble === "1" ? "underline" : undefined,
              cursor:
                row?.original?.bil_pdf_avlble === "1" ? "pointer" : "auto",
            }}
          >
            {row?.original?.bil_docno}
          </span>
        ),
      },
      {
        Header: "Due Date",
        accessor: "bil_datetime",
        Cell: ({ row }) =>
          new Date(row?.original?.bil_datetime).toLocaleDateString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Category",
        accessor: "bil_cat_desc",
        Cell: ({ row }) => row?.original?.bil_cat_desc,

        disableSortBy: true,
      },
      {
        Header: <div className="text-right">Due Amount</div>,
        accessor: "bil_amount_due",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            {row?.original?.bil_amount_due}
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: <div className="text-right">Total Amount</div>,
        accessor: "bil_amount_tot",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            {row?.original?.bil_amount_tot}
          </div>
        ),
        disableFilters: true,
      },

      {
        Header: "",
        accessor: "dummy2",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ID",
        accessor: "bil_id",
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = React.useMemo(() => props?.tableData, []);

  return (
    <TableContainer
      showHeader={false}
      columns={columns}
      data={data}
      getCellProps={(data) => ({
        onClick: () => {
          console.log("click", data);
          if (
            data?.column?.id === "bil_docno" &&
            data?.row?.original?.bil_docno &&
            data?.row?.original?.bil_pdf_avlble === "1"
          ) {
            props?.openImage &&
              props.openImage(data?.row?.original?.bil_docno, "Invoice File");
          }
        },
      })}
    />
  );
};

export default TransactionTable;
