import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import {
  portalUserRightsListApi,
  userRoleApi,
} from "../views/examples/_actions";

export const useRolesData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["roles", profile_email], () => userRoleApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      let roles = [];
      let sortedRoles = data?.data?.data;
      sortedRoles.forEach((role) => {
        let newRole = {
          value: role?.role_id,
          label: role?.role_name,
          status: role?.role_status,
        };
        roles.push(newRole);
      });
      return roles;
    },
  });
};

export const useUserRightsData = (role) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["user-rights", role, profile_email],
    () => portalUserRightsListApi(role),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          let list = data?.data?.data;

          return list;
        } else {
          return [];
        }
      },
    }
  );
};
