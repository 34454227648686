import { getRequest, postRequest } from "../../actionTypes/axiosUtility";
import { GLOBAL_API } from "../../actionTypes/types";

/*Login by Email*/
export const loginByEmailApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/LoginByEmail`, obj);
};

/* Login by OTP */
export const loginByOtpApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/LoginByOTP`, obj);
};

export const verifyLoginOtpApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/VerifyLoginOTP`, obj);
};

/*Logout*/
export const portalLogoutApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/PortalLogout`);
};

/*Driver Management*/
export const vehicleContractDriverDetailsApi = async (
  params = null,
  record_type
) => {
  return getRequest(
    `${GLOBAL_API}/Portal/VehicleContractDriverDetails?${
      params && `company_code=${params}&`
    }record_type=${localStorage.getItem("record_type")}`
  );
};

/*vehicle details*/
export const vehicleDetailsApi = async (plateNo = null) => {
  return getRequest(`${GLOBAL_API}/Portal/VehicleDetails?plate_no=${plateNo}`);
};

/*User List*/
export const userPortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/UserPortalList?${params && `company_code=${params}`}`
  );
};

export const createUserApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/CreateUserPortal`, obj);
};

export const updateUserApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/UpdateUserPortal`, obj);
};
export const updateAutoInvoiceApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/UpdateUserPortal`, obj);
};
export const designationListApi = (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/DesignationList?${params && `company_code=${params}`}`
  );
};

export const designationUserApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/DesignationList?status=1`);
};

export const companyListApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/PortalCompanyList`);
};

export const usageListApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/PortalUsageList`);
};
/*Forgot Password*/
export const forgotPasswordApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/ForgotPassword`, obj);
};

/*Invoice Management*/
export const autoInvoiceListApi = async (params = null) => {
  return getRequest(`${GLOBAL_API}/Portal/AutoInvoiceCustomerList`);
};
export const billPortalListApi = async (params = null) => {
  console.log("testq123");
  return getRequest(
    `${GLOBAL_API}/Portal/BillPortalList?${params && `company_code=${params}`}`
  );
};
/*Maintenance Management*/
export const maintenancePortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/MaintenancePortalList?${
      params && `company_code=${params}`
    }`
  );
};

export const maintenanceLocationApi = async () => {
  return getRequest(`${GLOBAL_API}/MaintLocations`);
};

/*Feedback Management*/
export const complaintPortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/ComplaintPortalList?${
      params && `company_code=${params}`
    }`
  );
};

/* Authorization Management */
export const authorizationPortalListApi = async (
  code = null,
  status = null
) => {
  return getRequest(
    `${GLOBAL_API}/Portal/AuthorizationPortalList?${
      code && `company_code=${code}`
    }`
  );
};

export const createPortalAuthorizationApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/CreatePortalAuthorization`, obj);
};

export const cancelPortalAuthorizationApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/CancelPortalAuthorization`, obj);
};

export const approvalAuthorizationApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/ApprovalAuthorization`, obj);
};

export const updatePortalAuthorizationApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/UpdatePortalAuthorization`, obj);
};

export const appUserDropdownListApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/AppUserDropdownList`);
};

/* Damage Management */
export const damagePortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/DamagePortalList?${
      params && `company_code=${params}`
    }`
  );
};

/* Accident Report */
export const accidentPortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/AccidentPortalList?${
      params && `company_code=${params}`
    }`
  );
};

/*service center management */
export const locationsApi = async (params = null) => {
  return getRequest(`${GLOBAL_API}/Portal/Locations?loc_type=Rental`);
};

export const createPortalLocation = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/CreatePortalLocation`, obj);
};

export const updatePortalLocation = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/UpdatePortalLocation`, obj);
};
export const addAutoInvoiceApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/UpdateCustomertoAutoInvoice`, obj);
};

/* traffic violation list */
export const trafficViolationPortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/TrafficViolationPortalList?${
      params && `company_code=${params}`
    }`
  );
};

/*Assign User management */
export const getAssignedUsers = async () => {
  return getRequest(`${GLOBAL_API}/Portal/PoolAllocationList`);
};

export const updateAssignUserApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/PoolAllocation`, obj);
};

/* roadside assistance */
export const roadsideAssistanceApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/MorniPortalList?${params && `company_code=${params}`}`
  );
};

/* rent request */
export const myBookingPortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/MyBookingPortalList?${
      params && `company_code=${params}`
    }`
  );
};

/* chauffeur request */
export const chauffeurPortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/ChauffeurPortalList?${
      params && `company_code=${params}`
    }`
  );
};

/* update status */
export const updateStatusApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/Portalstatusupdate`, obj);
};

/* chauffeur rate */
export const chauffeurRateApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/ChauffeurPortalCarList?${
      params && `company_code=${params}`
    }`
  );
};

export const createChauffeurRateApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/CreateChauffeurRateRequest`, obj);
};

export const updateChauffeurRateApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/ChauffeurRateUpdate`, obj);
};

/*chauffeur service*/

export const chauffeurPortalServiceListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/ChauffeurPortalRatetypeList?${
      params && `company_code=${params}`
    }`
  );
};

export const createChauffeurServiceApi = async (obj) => {
  return postRequest(
    `${GLOBAL_API}/Portal/CreateChauffeurRateTypeRequest`,
    obj
  );
};

export const updateChauffeurServiceApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/ChauffeurRateTypeUpdate`, obj);
};

/*rent rate */
export const portalMyBookingCarListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/PortalMyBookingCarList?${
      params && `company_code=${params}`
    }`
  );
};

export const rateCategoryApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/RateCategory`);
};

export const rateHireTypeApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/RateHireType`);
};

export const createRentRateApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/RacRateRequest`, obj);
};

export const updateRentRateApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/RacRateUpdate`, obj);
};

/*role*/
export const userRoleApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/RoleMasterList?${params && `company_code=${params}`}`
  );
};

export const userRolesApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/RoleMasterList?status=1`);
};

export const roleMasterRequestApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/RoleMasterRequest`, obj);
};

//user profile
export const profileDetailsApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/ProfileDetails`);
};

export const resetPasswordPortalUserApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/ResetPasswordPortalUser`, obj);
};
/* maintenance history */
export const maintenanceHistoryListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/MaintenanceHistoryList?${
      params && `company_code=${params}`
    }`
  );
};

/* notification */
export const userNotificationPortalListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/UserNotificationPortalList?${
      params && `company_code=${params}`
    }`
  );
};

export const sendNotificationApi = async (obj = null) => {
  if (obj) {
    // return postRequest(`${GLOBAL_API}/AppNotification`, obj);
    return postRequest(`${GLOBAL_API}/AppNotification`, obj);
  }
};

export const notificationListApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/NotificationList`);
};

/*replacement car */
export const replacementCarListApi = async (params = null) => {
  return getRequest(
    // `${GLOBAL_API}/Portal/ReplacementCarList?${params && `cust_code=${params}`}`
    `${GLOBAL_API}/Portal/PoolReplacementCarList?${
      params && `cust_code=${params}`
    }`
  );
};

/*app users */
export const appUserListApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/AppUserList?${params && `company_code=${params}`}`
  );
};

/*driver details*/
export const driverDetailsApi = async (driverID = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/FleetContractDriverDetails?driver_id=${driverID}`
  );
};

/*join avis*/
export const countryListApi = async () => {
  return getRequest(`${GLOBAL_API}/CountryList`);
};

export const joinAvisApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/JoinAVISProfileUpdatePortal`, obj);
};

/* permission management */
export const portalUserRightsListApi = async (obj = null) => {
  return getRequest(`${GLOBAL_API}/Portal/PortalUserRightsList?roleid=${obj}`);
};

export const userRightsUpdate = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/UserRightsUpdate`, obj);
};

/* Dashboard */

export const portalDashboardApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/PortalDashBoard?${params && `company_code=${params}`}`
  );
};

export const employeeCompanyList = async () => {
  return getRequest(`${GLOBAL_API}/Portal/CompanyFilterList`);
};

/*Image Document Management*/
export const vehicleDocumentListApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/PortalVehicleDocumentList`);
};

export const vehicleDocumentRequestApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/VehicleDocumentRequest`, obj);
};

/*Status Update */
export const portalActivityStatusList = async (obj) => {
  return getRequest(
    `${GLOBAL_API}/Portal/PortalActivityStatusList?activity_id=${obj}`
  );
};

export const createMaintainance = async (obj) => {
  return postRequest(`${GLOBAL_API}/CreateMaintenance`, obj);
};

export const maintainanceTypesApi = async (obj) => {
  return getRequest(`${GLOBAL_API}/MaintenanceType`);
};

export const createBooking = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/PortalCreateMyBooking`, obj);
};

export const updateBookingApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/PortalUpdateMyBooking`, obj);
};

export const updateInvoiceReceiptApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/UpdateBillReceipt`, obj);
};

export const updateInvoiceStatus = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/UpdateBillStatus`, obj);
};

export const downloadInvoiceDoc = async (docNo) => {
  return getRequest(`${GLOBAL_API}/Portal/GetInvoiceDocument?doc_no=${docNo}`);
};

export const downloadInvoicePdf = async (custNo) => {
  return getRequest(
    `${GLOBAL_API}/Portal/CustomerStatement?cust_code=${custNo}`
  );
};
export const validatePlateNo = async (plateNo) => {
  return getRequest(`${GLOBAL_API}/Portal/ValidatePlateNo?plate_no=${plateNo}`);
};

export const getRegionsApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/RegionList`);
};

export const getRentalTermsApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/RentalTerms`);
};

export const bookingTotalCalculateApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/PortalCalculateRate`, obj);
};

export const getRentalStationsApi = async (obj) => {
  return getRequest(`${GLOBAL_API}/Portal/RentalStations`);
};

export const getFileTypesApi = async () => {
  return getRequest(`${GLOBAL_API}/Portal/UserDocumentImageList`);
};

export const getIdTypeList = async (obj) => {
  return getRequest(`${GLOBAL_API}/Portal/IDTypeList`, obj);
};

export const getPaymentModesList = async (obj) => {
  return getRequest(`${GLOBAL_API}/Portal/PayModeList`, obj);
};

export const getPreContract = async (obj) => {
  return getRequest(`${GLOBAL_API}/Portal/PreLeaseCarList`);
};

export const preContractApi = async (params = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/PreLeaseCarList?${params && `company_code=${params}`}`
  );
};

/* rent request */
export const getRentRequestByID = async (id = null) => {
  return getRequest(
    `${GLOBAL_API}/Portal/MyBookingPortalList?${id && `booking_id=${id}`}`
  );
};

export const getDriverByID = async (id = null) => {
  return getRequest(`${GLOBAL_API}/Portal/DriverDetails?driver_id=${id}`);
};
