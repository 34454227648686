import DescModal from "components/Modals/descModal";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";
import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";
import { DateRangeColumnFilter } from "./_filters";
import TableContainer from "./_table";

const DamageTable = (props) => {
  const { statusList } = props;
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.sendNotification(cell?.row?.original)}
                  >
                    Send Notification
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "dmg_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.dmg_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.company_code !== "N/A" &&
              props?.customerModalOpen(row?.original, "company")
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              props?.customerModalOpen(row?.original, "customer")
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },
      {
        Header: "Plate No",
        accessor: "dmg_plate",
        Cell: ({ row }) => (
          <div className="color-primary cursor-pointer">
            {row?.original?.dmg_plate}
          </div>
        ),
      },
      {
        Header: "Date & Time",
        accessor: "dmg_datetime",
        Cell: ({ row }) => (
          <div className="table-text">
            {new Date(row?.original?.dmg_datetime).toLocaleString("en-Us", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        ),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Damage Type & Area",
        accessor: "damage_details",
        Cell: ({ row }) => {
          const options = row?.original?.damage_details;
          return (
            <>
              <div className="table-text">
                <span className="weight-600">{options[0]?.dmg_area_desc}:</span>{" "}
                {options[0]?.dmg_type_desc}
              </div>
              {options?.length > 1 && (
                <span className="font-12 color-primary cursor-pointer">
                  View More
                </span>
              )}
            </>
          );
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Images",
        accessor: "images",
        Cell: ({ row }) =>
          row?.original?.images[0] !== null && (
            <div className="pl-3">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Damage ID", accessor: "dmg_id" },

      {
        Header: "Damage Description",
        accessor: "dmg_desc",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.dmg_desc.length > 130 ? "view-more" : ""
              }`}
            >
              {row?.original?.dmg_desc}
            </div>
            {row?.original?.dmg_desc.length > 130 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Damage Description", row?.original?.dmg_desc)
                }
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = React.useMemo(() => props?.tableData, []);
  return (
    <>
      <TableContainer
        searchFilter={props?.searchFilter}
        searchId={props?.searchId}
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            data?.column?.Header === "Images" &&
              props?.openImage(data?.row?.original);
            if (
              data?.column?.Header === "Damage Type & Area" &&
              data?.row?.original?.damage_details.length > 1
            ) {
              props?.viewMore(data?.row?.original, data?.column?.Header);
            }

            if (data?.column?.id === "dmg_plate") {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}

      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.dmg_plate}
        />
      )}
    </>
  );
};

export default DamageTable;
