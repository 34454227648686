import React from "react";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import AvisForm from "layouts/avisForm";
import FAQ from "layouts/faq";
import FAQArabic from "layouts/faqArabic";
import Dashboard from "views/dashboard";

function RouteComponent() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            path="/admin/dashboard"
            render={(props) => <Dashboard {...props} />}
          />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route
            path="/join-avis"
            render={(props) => <AvisForm {...props} />}
          />
          <Route path="/FAQS" exact render={(props) => <FAQ {...props} />} />
          <Route
            path="/FAQS-ar"
            exact
            render={(props) => <FAQArabic {...props} />}
          />
          <Route exact path="/admin">
            <Redirect to="/admin/index" />
          </Route>
          <Route exact path="/">
            <Redirect to="/auth/login" />
          </Route>
          <Route exact path="/auth">
            <Redirect to="/auth/login" />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default RouteComponent;
