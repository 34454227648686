import React, { useRef, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { toBase64 } from "../../actions/commonActions";
import { toast } from "react-toastify";

const AuthorizationStatusModal = ({
  open,
  onCloseModal,
  formData,
  statusList,
}) => {
  const [status, setStatus] = useState(formData);
  const fileRef = useRef();
  const [file, setFile] = useState(null);
  const [type, setType] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [reason, setReason] = useState("");
  const [fileErr, setFileErr] = useState(false);
  const totalCount = 150;
  const totalSize = 1024000;

  const handleSingleFileUpload = async (ev, type) => {
    let file = ev.target.files[0];
    if (file?.size > totalSize) {
      toast.error("Upload file should be less than 1MB.");
    } else {
      if (
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        let obj = {
          img_datetime: file.lastModifiedDate,
          img_id: Math.floor(Math.random() * 1000),
          img_name: file.name,
          img_type: type,
          img_url: URL.createObjectURL(file),
        };
        const fileType = file?.name.split(/[#?]/)[0].split(".").pop().trim();
        setFile(obj);
        setType(fileType);
        const result = await toBase64(file).catch((e) => Error(e));
        if (result instanceof Error) {
          console.log("Error: ", result.message);
          return;
        }
        setDocUrl(result);
      } else {
        toast.error(
          "Unsupported file. Please upload pdf, png, jpg/jpeg files only"
        );
      }
    }
  };

  const handleValidation = () => {
    let isValid = true;
    if (!file) {
      setFileErr(true);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = null;
    if (status === "2") {
      if (await handleValidation()) {
        obj = {
          aut_file_type: type,
          aut_file: docUrl,
          aut_reason: reason,
        };
        onCloseModal(status, obj);
      }
    }
    if (status === "4") {
      obj = {
        aut_file_type: type,
        aut_file: docUrl,
        aut_reason: reason,
      };
      onCloseModal(status, obj);
    }
    if (status === "3") {
      onCloseModal(status, obj);
    }
    if (status === "5") {
      onCloseModal(status, obj);
    }
  };

  return (
    <Modal
      open={open}
      center
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      onClose={() => onCloseModal(null)}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Update Status</label>
      </div>
      <div className="px-3 pt-3">
        <Form>
          <div className="row">
            <div className="col-12 ">
              <div className="form-group-own-status">
                <div className="py-2">
                  {statusList.map(
                    (list) =>
                      list?.id !== "1" && (
                        <div key={list?.id}>
                          <input
                            type="radio"
                            name="status"
                            id={list?.name}
                            checked={status === list?.id}
                            onChange={() => setStatus(list?.id)}
                          />
                          <label className="pl-2" htmlFor={list?.name}>
                            {list?.name}
                          </label>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <div className="form-group-own py-2 col-6">
              <Label htmlFor="file">Authorization Letter (pdf/jpeg/png)</Label>
              <div className="file-upload-wrapper">
                <input
                  ref={fileRef}
                  disabled={status === "2" ? false : true}
                  type="file"
                  name="file"
                  id="file"
                  className="form-own-input"
                  accept=".png,.jpg,.jpeg,.pdf"
                  onChange={(ev) =>
                    handleSingleFileUpload(ev, "Authorization Document")
                  }
                />
                <input
                  type="text"
                  disabled={status === "2" ? false : true}
                  name="file_name"
                  id="file_name"
                  className="form-own-input-filename form-control"
                  value={file ? file.img_name : ""}
                  readOnly
                />
                <button
                  className="btn primary-bg file-upload-btn"
                  disabled={status === "2" ? false : true}
                  onClick={(ev) => {
                    ev.preventDefault();
                    fileRef.current.click();
                  }}
                >
                  {file === null ? "Upload" : "Change"}
                </button>
              </div>
              {!file && fileErr && status === "2" && (
                <div className="error-text">
                  Authorization Letter is required
                </div>
              )}
            </div>
            <div className="form-group-own-check py-2 col-6">
              <Label htmlFor="desc">
                Approval Reason{" "}
                <span className="error-text">
                  {totalCount - reason.length} characters remaining
                </span>
              </Label>
              <Input
                type="text"
                disabled={status === "2" || status === "4" ? false : true}
                name="desc"
                id="desc"
                placeholder="Reason"
                value={reason}
                onChange={(ev) => setReason(ev.target.value)}
                maxLength={totalCount}
              />
            </div>

            <div className="d-flex justify-content-center col-12 pt-5">
              <button
                className="btn grey-bg"
                onClick={() => onCloseModal(null)}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AuthorizationStatusModal;
