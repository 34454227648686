import React, { useState, useRef, useEffect } from "react";
import StarRatings from "react-star-ratings";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";

const AddNewFeedback = (props) => {
  const imgRef = useRef();
  const [id, setId] = useState("");
  const [userId, setUserId] = useState("");
  const [userIdErr, setUserIdErr] = useState(false);
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [plate_no, setPlate_no] = useState("");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [images, setImages] = useState([]);
  const [rating, setRating] = useState(0);
  const [idErr, setIdErr] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);

  useEffect(() => {
    if (props?.isEdit) {
      setPropsToState();
    }
    // eslint-disable-next-line
  }, []);

  const setPropsToState = () => {
    setId(props?.formData?.com_id);
    setDate(new Date(props?.formData?.com_datetime));
    setCategory(props?.formData?.com_category_id);
    setPlate_no(props?.formData?.com_plate);
    setSubject(props?.formData?.com_subject);
    setDetails(props?.formData?.com_desc);
    setImages(props?.formData?.images);
    props?.formData?.rating_no === ""
      ? setRating(1)
      : setRating(props?.formData?.rating_no);
  };

  const handleValidation = () => {
    let isValid = true;
    if (!id) {
      isValid = false;
      setIdErr(true);
    }
    if (!userId) {
      isValid = false;
      setUserIdErr(true);
    }
    if (!date) {
      isValid = false;
      setDateErr(true);
    }
    if (!category) {
      isValid = false;
      setCategoryErr(true);
    }
    return isValid;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      let obj = {
        com_id: id,
        userId: userId,
        com_datetime: date,
        com_category_id: category,
        com_plate: plate_no,
        com_subject: subject,
        com_desc: details,
        images: images,
        rating_no: rating,
      };
      props?.onCloseModal(obj);
    }
  };

  const handleMultiFileUpload = (ev) => {
    let file = ev.target.files;
    let obj = [];
    for (let i = 0; i < file.length; i++) {
      let val = {
        img_id: Math.floor(Math.random() * 1000),
        img_type: "Maintenance",
        img_type_id: Math.floor(Math.random() * 1000),
        img_url: URL.createObjectURL(file[i]),
      };
      obj.push(val);
    }
    setImages(obj);
  };
  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="id">Feedback ID</Label>
                <Input
                  type="text"
                  name="id"
                  id="id"
                  placeholder="Feedback ID"
                  value={id}
                  onChange={(ev) => setId(ev.target.value)}
                />
                {!id && idErr && (
                  <div className="error-text">Feedback ID is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="userId">User ID</Label>
                <Input
                  type="text"
                  name="userId"
                  id="userId"
                  placeholder="User ID"
                  value={userId}
                  onChange={(ev) => setUserId(ev.target.value)}
                />
                {!userId && userIdErr && (
                  <div className="error-text">User ID is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="date">Date</Label>
                <Input
                  type="date"
                  name="date"
                  id="date"
                  placeholder="Date"
                  value={date}
                  onChange={(ev) => setDate(ev.target.value)}
                />
                {!date && dateErr && (
                  <div className="error-text">Date is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="subject">Subject</Label>
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  value={subject}
                  onChange={(ev) => setSubject(ev.target.value)}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="details">Details</Label>
                <Input
                  type="text"
                  name="details"
                  id="details"
                  placeholder="Details"
                  value={details}
                  onChange={(ev) => setDetails(ev.target.value)}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="images">Image</Label>
                <div className="file-upload-wrapper">
                  <input
                    ref={imgRef}
                    type="file"
                    name="images"
                    id="images"
                    className="form-own-input"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleMultiFileUpload}
                    multiple
                  />
                  <input
                    type="text"
                    name="images_name"
                    id="images_name"
                    className="form-own-input-filename form-control"
                    value={
                      images[0] === null ? "N/A" : images.map((x) => x.img_id)
                    }
                    readOnly
                  />
                  <button
                    className="btn primary-bg file-upload-btn"
                    onClick={(ev) => {
                      ev.preventDefault();
                      imgRef.current.click();
                    }}
                  >
                    {props?.isEdit
                      ? "Change"
                      : images.length > 0
                      ? "Change"
                      : "Upload"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="rating">Rating</Label>
                <div>
                  <StarRatings
                    id="rating"
                    rating={parseInt(rating)}
                    starRatedColor="rgb(212,0,42)"
                    changeRating={(val) => setRating(val)}
                    numberOfStars={5}
                    name="rating"
                    starDimension="25px"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="category">Category</Label>
                <Input
                  type="select"
                  name="category"
                  id="category"
                  placeholder="Service Center"
                  value={category}
                  onChange={(ev) => setCategory(ev.target.value)}
                  disabled={props?.isEdit ? true : false}
                >
                  <option>Select</option>
                  <option value="1">General</option>
                  <option value="2">Vehicle</option>
                  <option value="3">Service</option>
                  <option value="4">AvisCare App</option>
                  <option value="5">Others</option>
                </Input>
                {!category && categoryErr && (
                  <div className="error-text">Category is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="plate_no">Plate No</Label>
                <Input
                  type="text"
                  name="plate_no"
                  id="plate"
                  placeholder="Plate No"
                  value={plate_no}
                  onChange={(ev) => setPlate_no(ev.target.value)}
                  disabled={category === "2" ? false : true}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddNewFeedback;
