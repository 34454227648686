import React, { useMemo } from "react";
import TableContainer from "./_table";

const PreContractTable = ({ tableData, openCustomer, openPlate, openId }) => {
  const initState = {
    pageIndex: 0,
    pageSize: 20,
  };
  const columns = useMemo(() => [
    {
      Header: "",
      accessor: "dummy",
      disableFilters: true,
      disableSortBy: true,
    },

    {
      Header: "Status",
      accessor: "reservation_status",
    },
    {
      Header: "Reservation No",
      accessor: "reservation_no",
      Cell: ({ row }) => (
        <div className="color-primary cursor-pointer">
          {row?.original?.reservation_no}
        </div>
      ),
    },
    {
      Header: "Start Date",
      accessor: "start_date",
    },
    {
      Header: "End Date",
      accessor: "end_date",
    },
    {
      Header: "ID No",
      accessor: "id_no",
      Cell: ({ row }) => (
        <div className="color-primary cursor-pointer">
          {row?.original?.id_no}
        </div>
      ),
    },
    {
      Header: "Full Name",
      accessor: "full_name",
    },
    {
      Header: "Plate No",
      accessor: "plate_no",
      Cell: ({ row }) => (
        <div className="color-primary cursor-pointer">
          {row?.original?.plate_no}
        </div>
      ),
    },
    {
      Header: "Model",
      accessor: "model_desc",
    },
    {
      Header: "Nationality",
      accessor: "nationality",
    },
    {
      Header: "Branch Code",
      accessor: "branch",
    },
    {
      Header: "Branch Name",
      accessor: "branch_desc",
    },
    {
      Header: "Customer Code",
      accessor: "cust_code",
    },
    {
      Header: "Customer Name",
      accessor: "cust_name",
    },

    {
      Header: "Car Start Date",
      accessor: "car_start_date",
    },
    {
      Header: "Car end Date",
      accessor: "car_end_date",
    },

    {
      Header: "",
      accessor: "dummy2",
      disableFilters: true,
      disableSortBy: true,
    },
  ]);
  // eslint-disable-next-line
  const data = useMemo(() => tableData, []);

  return (
    <TableContainer
      columns={columns}
      data={data}
      state={initState}
      getCellProps={(data) => ({
        onClick: () => {
          if (
            data?.column?.id === "id_no" &&
            data?.row?.original?.id_no !== ""
          ) {
            openCustomer(data?.row?.original);
          }
          if (data?.column?.id === "plate_no") {
            openPlate(data?.row?.original);
          }

          if (data?.column?.id === "reservation_no") {
            openId(data?.row?.original);
          }
        },
      })}
    />
  );
};

export default PreContractTable;
