import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import StatusUpdateModal from "../../components/Modals/statusUpdateModal";
import ChauffeurRateModal from "../../components/Modals/chauffeurRateModal";

import ChauffeurRateTable from "../../components/tables/chauffeurRateTable";

import UserHeader from "../../components/Headers/userHeader";

import { updateStatusApi } from "./_actions";
import { globalLoader } from "../../actions/commonActions";
import { YEAR_LIST, logOut } from "../../_shared/commonFunctions";

import { useStatusData } from "hooks/useStatusData";
import {
  useBodyTypeData,
  useChaufferRateTypeData,
  useChaufferRateData,
} from "hooks/useChaufferRateData";

import { createChauffeurRateApi, updateChauffeurRateApi } from "./_actions";

const ChauffeurRate = (props) => {
  const { userRights, currentCompany } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);
  const [canCreate, setCanCreate] = useState(false);
  const [chauffeurRateModalOpen, setChauffeurRateModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const yearList = YEAR_LIST();

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_15") {
        if (item?.create === "1") {
          setCanCreate(true);
        }
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: rates,
    isFetched,
    refetch,
    isFetching,
  } = useChaufferRateData(currentCompany);

  globalLoader(isFetching);

  const { data: rateTypes } = useChaufferRateTypeData();

  const { data: bodyTypes } = useBodyTypeData();

  const { data: status } = useStatusData(12);

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "12",
        status: status,
        id: formData?.chr_id,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        logOut(props?.history, "auth/login");
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const openChauffeurRateModal = (obj = null) => {
    if (obj) {
      setFormData(obj);
      setIsEdit(true);
    }
    setChauffeurRateModalOpen(true);
  };

  const closeChauffeurRateModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      let res;
      if (isEdit) {
        res = await updateChauffeurRateApi(obj);
      } else {
        res = await createChauffeurRateApi(obj);
      }
      if (res?.data?.status === 0) {
        toast.success(res?.data?.message);
        refetch();
      } else {
        toast.error(res?.data?.message);
        globalLoader(false);
      }
    }
    setFormData(null);
    setChauffeurRateModalOpen(false);
    setIsEdit(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Chauffeur Rate</h3>
                  {canCreate && (
                    <div className="col text-right">
                      <button
                        className="btn btn-sm primary-bg"
                        onClick={(ev) => {
                          ev.preventDefault();
                          openChauffeurRateModal();
                        }}
                        type="button"
                      >
                        Add Chauffeur Rate
                      </button>
                    </div>
                  )}
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    rates && rates.length > 0 ? (
                      <ChauffeurRateTable
                        tableData={rates}
                        updateStatus={openUpdateStatus}
                        statusList={status}
                        editData={openChauffeurRateModal}
                        canEdit={canEdit}
                        rateTypeList={rateTypes}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.char_status}
        />
      )}
      {chauffeurRateModalOpen && (
        <ChauffeurRateModal
          open={chauffeurRateModalOpen}
          onCloseModal={closeChauffeurRateModal}
          data={formData}
          rateTypeList={rateTypes}
          bodyTypeList={bodyTypes}
          yearList={yearList}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(ChauffeurRate);
