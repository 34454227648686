import React, { useState } from "react";
import UserHeader from "../../components/Headers/userHeader";
import { Card, CardHeader, Container, Row } from "reactstrap";
import ImgDocTable from "../../components/tables/imgDocTable";
import { logOut } from "../../_shared/commonFunctions";
import { globalLoader } from "../../actions/commonActions";
import { vehicleDocumentRequestApi } from "./_actions";
import UploadDocModal from "../../components/Modals/uploadDocModal";
import { toast } from "react-toastify";
import { useVehicleDocumentsData } from "hooks/useVehicleDocumentsData";

const ImgDocManagement = (props) => {
  const [openUploadDocModal, setOpenUploadDocModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const {
    data: documents,
    isFetching,
    isFetched,
    refetch,
  } = useVehicleDocumentsData();

  globalLoader(isFetching);

  const uploadDocModalOpen = (obj = null) => {
    if (obj) {
      setFormData(obj);
      setOpenUploadDocModal(true);
    }
  };

  const uploadDocModalClose = async (obj = null) => {
    if (obj && obj?.DocumentDetails?.length > 0) {
      globalLoader(true);
      try {
        let res = await vehicleDocumentRequestApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
        refetch();
        globalLoader(false);
      } catch (err) {
        setFormData(null);
        setOpenUploadDocModal(false);
        logOut(props?.history, "/auth/login");
        globalLoader(false);
      }
    }
    setFormData(null);
    setOpenUploadDocModal(false);
  };
  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">
                    Image/Document Management
                  </h3>
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    documents && documents?.length > 0 ? (
                      <ImgDocTable
                        data={documents}
                        uploadDoc={uploadDocModalOpen}
                      />
                    ) : (
                      <div className="px-3 pt-3">
                        <div className="no-data-available">
                          No data available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {openUploadDocModal && (
        <UploadDocModal
          open={openUploadDocModal}
          onCloseModal={uploadDocModalClose}
          formData={formData}
        />
      )}
    </>
  );
};

export default ImgDocManagement;
