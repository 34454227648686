import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import {
  myBookingPortalListApi,
  portalMyBookingCarListApi,
  rateCategoryApi,
  rateHireTypeApi,
} from "../views/examples/_actions";

export const useMyBookingData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["my-booking-rent-rate", currentCompany, profile_email],
    () => portalMyBookingCarListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const list = data?.data?.data;
          let sortedList = sort(list).desc((u) => u?.rat_id);
          return sortedList;
        } else {
          return null;
        }
      },
    }
  );
};

export const useRentRequestData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["rent-request", currentCompany, profile_email],
    () => myBookingPortalListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const list = data?.data?.data;
          let sortedList = sort(list).desc((u) => u?.booking_id);
          return sortedList;
        } else {
          return null;
        }
      },
    }
  );
};

export const useHireTypeData = () => {
  return useQuery(["hire-type"], () => rateHireTypeApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      const list = data?.data?.data;
      let arr = list.map((item) => {
        return { value: item?.hir_status, label: item?.hir_desc };
      });
      return arr;
    },
  });
};

export const useRateCategoryData = () => {
  return useQuery(["rate-categories"], () => rateCategoryApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      const list = data?.data?.data;
      let arr = list.map((item) => {
        return {
          value: item?.cat_code,
          label: `${item?.cat_desc} (${item?.cat_code})`,
        };
      });
      return arr;
    },
  });
};
