import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { CounterButton } from "components/counter-button/counter-button";
import { Button, ButtonGroup } from "reactstrap";

const ExtraServiceCard = ({ title, travelPermit, selected }) => {
  const [cSelected, setCSelected] = useState(0);

  const onRadioBtnClick = (rSelected) => {
    setCSelected(rSelected);
    travelPermit(rSelected);
  };

  useEffect(() => {
    setCSelected(parseInt(selected));
  }, [selected]);

  return (
    <Card className="my-1" color="danger" outline>
      <CardBody style={{ padding: "8px 16px 35px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: "1",
            }}
          >
            <CardTitle tag="h3">{title}</CardTitle>
            <CardSubtitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <h1 className="mb-1 weight-600" style={{ fontSize: "30px" }}>
                    SAR 100 <span style={{ fontSize: "16px" }}>per day</span>
                  </h1>
                </div>
                <div style={{ flex: "1", textAlign: "center" }}>
                  <ButtonGroup>
                    <Button
                      size={"sm"}
                      color="secondary"
                      onClick={() => onRadioBtnClick(1)}
                      active={cSelected === 1}
                    >
                      Yes
                    </Button>
                    <Button
                      size={"sm"}
                      color="secondary"
                      onClick={() => onRadioBtnClick(0)}
                      active={cSelected === 0}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </CardSubtitle>

            {/* <h5 style={{ color: "#B5B7BB" }}>{description}</h5> */}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ExtraServiceCard;
