import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import UserHeader from '../../components/Headers/userHeader';
import { globalLoader } from 'actions/commonActions';
import { createMaintainance, sendNotificationApi } from './_actions';
import MaintenanceTable from '../../components/tables/maintenanceTable';
import { updateStatusApi } from './_actions';
import { getPopupDetails } from '../../_shared/commonFunctions';

import ViewImageModal from 'components/Modals/viewImageModal';
import ServiceListModal from 'components/Modals/serviceListModal';
import ServiceStatusModal from '../../components/Modals/serviceStatusModal';
import ReplyNotification from '../../components/Modals/replyNotificationModal';
import AddMaintenanceModal from '../../components/Modals/addMaintenanceModal';
import DriverDetailsModal from '../../components/Modals/driverDetailsModal';
import DetailsModal from '../../components/Modals/detailsModal';

import { useStatusData } from 'hooks/useStatusData';
import { useServiceData } from 'hooks/useServiceData';
import { useMaintainanceLocationData } from 'hooks/useMaintainanceLocationData';
import { is } from 'date-fns/locale';

const MaintenanceManagement = (props) => {
  const { userRights, currentCompany } = props;

  const [viewImage, setViewImage] = useState(false);
  const [imgType, setImgType] = useState('');
  const [imageData, setImageData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [openServiceList, setOpenServiceList] = useState(false);
  const [formData, setFormData] = useState(null);
  const [changeStatus, setChangeStatus] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const searchFilter = props?.location?.state?.maint_plate;
  const searchId = props?.location?.state?.id;
  const dropdownFilter = props?.location?.state?.maint_status;
  const dropdownId = props?.location?.state?.dropdownId;
  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState('');
  const [nationalId, setNationalId] = useState('');

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  const [openAddMaintainance, setOpenAddMaintainance] = useState(false);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === 'module_1') {
        if (item?.update === '1') {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: services,
    isFetching,
    isFetched,
    refetch,
  } = useServiceData(currentCompany);

  globalLoader(isFetching);

  if (isFetched) {
    globalLoader(false);
  }

  const { data: locations } = useMaintainanceLocationData();

  const { data: status } = useStatusData(1);

  const openImageModal = (val, type) => {
    setImageData(val);
    setImgType(type);
    setViewImage(true);
  };

  const closeViewImageModal = () => {
    setViewImage(false);
    setImageData(null);
  };

  const openServiceListModal = (val = false, obj = null) => {
    setIsEdit(val);
    setFormData(obj);
    setOpenServiceList(true);
  };

  const closeServiceListModal = (obj = null) => {
    setOpenServiceList(false);
    setIsEdit(false);
    setFormData(null);
  };

  const openChangeStatus = (obj) => {
    setFormData(obj);
    setChangeStatus(true);
  };

  const closeStatus = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.message);
        }
        refetch();
      } catch (err) {
        globalLoader(false);
      }
    }
    setFormData(null);
    setChangeStatus(false);
  };

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setNationalId(obj?.national_id);
      setData(getPopupDetails(obj, type));
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle('');
  };

  const openNotificationModal = (obj) => {
    let notiData = {
      user_id: obj.userid,
      tag: obj?.company_name,
      type: '1',
      typeDesc: 'Service',
    };
    setFormData(notiData);
    setNotificationModalOpen(true);
  };

  const closeNotificationModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await sendNotificationApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (err) {
        toast.error('Error while posting notification');
        console.log(err);
      }
      globalLoader(false);
      refetch();
    }
    setFormData(null);
    setNotificationModalOpen(false);
  };

  const addMaintaiananceHandler = async (data) => {
    setOpenAddMaintainance(false);
    if (data) {
      globalLoader(true);
      try {
        let res = await createMaintainance(data);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (err) {
        toast.error('Error while posting notification');
        console.log(err);
      }
      globalLoader(false);
      refetch();
    }
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Repair and Maintenance</h3>
                  <div className="col text-right">
                    <button
                      className="btn btn-sm primary-bg"
                      onClick={() => setOpenAddMaintainance(true)}
                    >
                      Add Maintenance
                    </button>
                  </div>
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    services && services.length > 0 ? (
                      <>
                        <MaintenanceTable
                          tableData={services}
                          statusList={status}
                          isEdit={isEdit}
                          searchFilter={searchFilter}
                          searchId={searchId}
                          dropdownFilter={dropdownFilter}
                          dropdownId={dropdownId}
                          canEdit={canEdit}
                          customerModalOpen={customerModalOpen}
                          sendNotification={openNotificationModal}
                          openServiceListModal={openServiceListModal}
                          changeStatus={openChangeStatus}
                          openImage={openImageModal}
                        />
                      </>
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {viewImage && (
        <ViewImageModal
          open={viewImage}
          onCloseModal={closeViewImageModal}
          data={imageData}
          imgType={imgType}
        />
      )}
      {openServiceList && (
        <ServiceListModal
          open={openServiceList}
          onCloseModal={closeServiceListModal}
        />
      )}
      {openAddMaintainance && (
        <AddMaintenanceModal
          open={openAddMaintainance}
          onCloseModal={addMaintaiananceHandler}
        />
      )}
      {changeStatus && (
        <ServiceStatusModal
          open={changeStatus}
          onCloseModal={closeStatus}
          statusList={status}
          formData={formData}
          location={locations}
        />
      )}
      {userModal && detailsTitle === 'customer' && (
        <DriverDetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          formData={nationalId}
        />
      )}

      {userModal && detailsTitle === 'company' && (
        <DetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          data={data}
          title={detailsTitle}
        />
      )}
      {notificationModalOpen && (
        <ReplyNotification
          open={notificationModalOpen}
          onCloseModal={closeNotificationModal}
          formData={formData}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(MaintenanceManagement);
