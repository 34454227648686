import React, { useEffect, useRef, useState } from "react";
import "react-responsive-modal/styles.css";
import Flatpickr from "react-flatpickr";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { COUNTRY_LIST } from "actionTypes/countryList";
import { toBase64 } from "actions/commonActions";
import { convertDateTime } from "_shared/commonFunctions";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { toast } from "react-toastify";

const AddAuthorizationModal = (props) => {
  const fileRef = useRef();
  const [userId, setUserId] = useState("");
  const [userIdErr, setUserIdErr] = useState(false);
  const [plate, setPlate] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reqDate, setReqDate] = useState("");
  const [country, setCountry] = useState("");
  const [desc, setDesc] = useState("");
  const [plateErr, setPlateErr] = useState(false);
  const [startDateErr, setStartDateErr] = useState(false);
  const [endDateErr, setEndDateErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [type, setType] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const animatedComponents = makeAnimated();
  const totalCount = 199;
  const [fileName, setFileName] = useState("");
  const totalSize = 1024000;

  useEffect(() => {
    if (props?.isEdit) {
      setPropsToState();
    } // eslint-disable-next-line
  }, []);

  const setPropsToState = () => {
    let obj = props?.formData;
    setUserId(props?.options.find((data) => data?.value === obj?.aut_userid));
    setPlate(obj?.aut_plate);
    let startDt = new Date(obj?.aut_start_datetime);
    if (startDt < new Date()) {
      startDt = new Date();
    }
    setStartDate(startDt);
    let endDt = new Date(obj?.aut_end_datetime);
    if (endDt < new Date()) {
      endDt = new Date();
    }
    setEndDate(endDt);
    setReqDate(new Date(obj?.aut_req_datetime));
    setCountry(
      COUNTRY_LIST.find((data) => data?.value === obj?.aut_travel_country)
    );
    setDesc(obj?.aut_desc);
    setType(obj?.aut_file_type);
    // eslint-disable-next-line
    setFileName(obj?.aut_file_url.replace(/^.*[\\\/]/, ""));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = {
        aut_userid: userId.value,
        aut_plate: plate,
        aut_start_datetime: convertDateTime(startDate),
        aut_end_datetime: convertDateTime(endDate),
        aut_travel_country: country.value,
        aut_desc: desc,
        aut_file_type: type,
        aut_file: docUrl,
      };
      if (props?.isEdit) {
        obj.aut_id = props?.formData?.aut_id;
        obj.aut_req_datetime = convertDateTime(reqDate);
      }
      props?.onCloseModal(obj);
    }
  };

  const handleValidation = () => {
    let isValid = true;
    if (!userId) {
      setUserIdErr(true);
      isValid = false;
    }
    if (!plate) {
      setPlateErr(true);
      isValid = false;
    }
    if (!startDate) {
      setStartDateErr(true);
      isValid = false;
    }
    if (!endDate) {
      setEndDateErr(true);
      isValid = false;
    }
    if (!country) {
      setCountryErr(true);
      isValid = false;
    }

    return isValid;
  };

  const handleSingleFileUpload = async (ev) => {
    let file = ev.target.files[0];
    if (file?.size > totalSize) {
      toast.error("Upload file should be less than 1MB.");
    } else {
      if (
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        const fileType = file?.name.split(/[#?]/)[0].split(".").pop().trim();
        setType(fileType);
        const result = await toBase64(file).catch((e) => Error(e));
        if (result instanceof Error) {
          console.log("Error: ", result.message);
          return;
        }
        setFileName(file.name);
        setDocUrl(result);
      } else {
        toast.error("Unsupported file type.");
      }
    }
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">
          {props?.isEdit ? "Edit Authorization" : "Add Authorization"}
        </label>
      </div>
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="plate">
                  Authorization Plate<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="plate"
                  id="plate"
                  placeholder="Authorization Plate"
                  value={plate}
                  onChange={(ev) => setPlate(ev.target.value)}
                  maxLength={8}
                  className={`${!plate && plateErr && "on-focus"}`}
                />
                {!plate && plateErr && (
                  <div className="error-text">Plate No is empty</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="userId">
                  User Id<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Select Designation"
                  options={props?.options}
                  onChange={(ev) => setUserId(ev)}
                  value={userId}
                  menuPlacement="bottom"
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !userId && userIdErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!userId && userIdErr && (
                  <div className="error-text">User Id is empty</div>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-group-own">
                <Label for="startDate">
                  Start Date<span className="color-primary">*</span>
                </Label>
                <div className={`${!startDate && startDateErr && "on-focus"}`}>
                  <Flatpickr
                    onChange={(e) => {
                      setStartDate(e);
                      if (new Date(e) > new Date(endDate)) setEndDate(e);
                    }}
                    value={startDate}
                    className="form-control datepicker-own"
                    options={{
                      minDate: "today",
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                  />
                </div>
                {!startDate && startDateErr && (
                  <div className="error-text">Start Date is empty</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="endDate">
                  End Date<span className="color-primary">*</span>
                </Label>
                <div className={`${!endDate && endDateErr && "on-focus"}`}>
                  <Flatpickr
                    onChange={(e) => setEndDate(e)}
                    value={endDate}
                    className="form-control datepicker-own"
                    options={{
                      minDate: startDate ? new Date(startDate) : "today",
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                  />
                </div>
                {!endDate && endDateErr && (
                  <div className="error-text">End Date is empty</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="country">
                  Travel Country<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Select Destination"
                  options={COUNTRY_LIST}
                  onChange={(ev) => setCountry(ev)}
                  value={country}
                  menuPlacement="bottom"
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !userId && userIdErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!country && countryErr && (
                  <div className="error-text">Country not selected</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="desc">
                  Reason{" "}
                  <span className="error-text">
                    {totalCount - desc.length} characters remaining
                  </span>
                </Label>
                <Input
                  type="text"
                  name="desc"
                  id="desc"
                  placeholder="Reason"
                  value={desc}
                  onChange={(ev) => setDesc(ev.target.value)}
                  maxLength={totalCount}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="file">Upload Documents (pdf/jpeg/png)</Label>
                <div className="file-upload-wrapper">
                  <input
                    ref={fileRef}
                    type="file"
                    name="file"
                    id="file"
                    className="form-own-input"
                    accept=".png,.jpg,.jpeg,.pdf"
                    onChange={(ev) => handleSingleFileUpload(ev)}
                  />
                  <input
                    type="text"
                    name="file_name"
                    id="file_name"
                    className="form-own-input-filename form-control"
                    value={fileName ? fileName : ""}
                    readOnly
                  />
                  <button
                    className="btn primary-bg file-upload-btn"
                    onClick={(ev) => {
                      ev.preventDefault();
                      fileRef.current.click();
                    }}
                  >
                    {/* {file ? "Change" : "Upload"} */}Upload
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddAuthorizationModal;
