import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import { autoInvoiceListApi } from "../views/examples/_actions";

export const useAutoInvoiceData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["invoices", currentCompany, profile_email],
    () => autoInvoiceListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
};
