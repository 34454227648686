import React, { useMemo } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";
import { DateRangeColumnFilter } from "./_filters";
import TableContainer from "./_table";

const RentTable = (props) => {
  const {
    searchId,
    searchFilter,
    statusList,
    sourceList,
    paymentModes,
    idTypes,
    highlitedIDs,
  } = props;

  const initState = {
    sortBy: [
      {
        id: "start_date",
        desc: true,
      },
    ],
    pageIndex: 0,
    pageSize: 20,
  };
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  {cell?.row?.original.rent_staus === "1" && (
                    <DropdownItem
                      onClick={() =>
                        props?.editRentRequest(cell?.row?.original)
                      }
                    >
                      Edit
                    </DropdownItem>
                  )}

                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.sendNotification(cell?.row?.original)}
                  >
                    Send Notification
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      if (
                        highlitedIDs &&
                        Array.isArray(highlitedIDs) &&
                        highlitedIDs.find(
                          (item) => item.id === cell.row.original.booking_id
                        )
                      )
                        highlitedIDs.find(
                          (item) => item.id === cell.row.original.booking_id
                        ).count = 0;
                      props?.chat(cell?.row?.original);
                    }}
                  >
                    Chat
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "rent_staus",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.rent_staus, statusList),
        Filter: (props) => {
          return SelectFilter(props, statusList);
        },
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Source",
        accessor: "rac_source",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.rac_source, sourceList),
        Filter: (props) => SelectFilter(props, sourceList),
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.company_code !== "N/A" &&
              props?.customerModalOpen(row?.original, "company")
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              props?.customerModalOpen(row?.original, "customer")
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },

      {
        Header: "Payment Mode",
        accessor: "pay_mode",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.pay_mode, paymentModes),
        Filter: (props) => SelectFilter(props, paymentModes),
        filter: "includes",
        disableSortBy: true,
      },

      {
        Header: "Start date and time",
        accessor: "start_date",
        Cell: ({ row }) =>
          new Date(row?.original?.start_date).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "End date and time",
        accessor: "end_date",
        Cell: ({ row }) =>
          new Date(row?.original?.end_date).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Pick up",
        accessor: "start_location_address",
        Cell: ({ row }) => (
          <span className="table-text">
            {row?.original?.start_location_address}
          </span>
        ),
      },
      {
        Header: "Drop off",
        accessor: "end_location_address",
        Cell: ({ row }) => (
          <span className="table-text">
            {row?.original?.end_location_address}
          </span>
        ),
      },

      {
        Header: "Makemodel",
        accessor: "make_model",
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.make_model}</div>
        ),
        disableFilters: true,
      },

      {
        Header: "Rate",
        rightAlign: true,
        accessor: "rate",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.rate}</span>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ({ row }) => (
          <div className="d-flex justify-content-end pr-2">
            <span>{row?.original?.duration}</span>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Total",
        rightAlign: true,
        accessor: "total",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.total}</span>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "ID",
        accessor: "booking_id",
      },
      {
        Header: "Additional Driver",
        rightAlign: true,
        accessor: "addition_driver_charge",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.addition_driver_charge}</span>
          </div>
        ),
      },
      {
        Header: "Dropp off charges",
        rightAlign: true,

        accessor: "drop_off_charge",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.drop_off_charge}</span>
          </div>
        ),
      },
      {
        Header: "Tamm",
        rightAlign: true,
        accessor: "tamm_charge",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.tamm_charge}</span>
          </div>
        ),
      },
      {
        Header: "Travel Permit",
        accessor: "travel_permit",
      },
      {
        Header: "Vat",
        rightAlign: true,
        accessor: "vat_charge",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.vat_charge}</span>
          </div>
        ),
      },
      {
        Header: "Total",
        rightAlign: true,
        accessor: "grand_total",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.grand_total}</span>
          </div>
        ),
      },
      {
        Header: "CDW Daily",
        rightAlign: true,
        accessor: "cdw_daily",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.cdw_daily}</span>
          </div>
        ),
      },
      {
        Header: "CDW Monthly",
        rightAlign: true,
        accessor: "cdw_monthly",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.cdw_monthly}</span>
          </div>
        ),
      },
      {
        Header: "Supporting Documents",
        accessor: "support_documents",
        Cell: ({ row }) =>
          row?.original?.support_documents &&
          row?.original?.support_documents.length > 0 &&
          row?.original?.support_documents[0] && (
            <div className="ml-2">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ID Type",
        accessor: "id_type",
        Cell: ({ row }) => tableDropdownData(row?.original?.id_type, idTypes),
        Filter: (props) => SelectFilter(props, idTypes),
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "dummy2",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);

  return (
    <TableContainer
      searchFilter={searchFilter}
      searchId={searchId}
      highlightNewMessage={true}
      highlitedIDs={highlitedIDs}
      columns={columns}
      data={data}
      state={initState}
      getCellProps={({ column, value, row }) => ({
        onClick: () => {
          if (column.id === "support_documents" && value && value.length > 0) {
            props?.viewImage(value[0].img_url, "Supporting Documents");
          }
        },
      })}
    />
  );
};

export default RentTable;
