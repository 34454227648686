import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import TableContainer from "./_table";

const AutoInvoiceTable = (props) => {
  const { statusList } = props;

  // Filter out items without cust_code and sort by cust_code in ascending order
  const filteredAndSortedData = React.useMemo(() => {
    return props?.tableData
      ?.filter((item) => item.cust_code) // Filter out items without cust_code
      ?.sort((a, b) => (a.cust_code > b.cust_code ? 1 : -1)); // Sort by cust_code in ascending order
  }, [props?.tableData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  <DropdownItem
                    onClick={() => props?.editData(cell?.row?.original)}
                  >
                    Edit
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
      },
      {
        Header: "Customer Code",
        accessor: "cust_code",
      },
      {
        Header: "Exclusive",
        accessor: "exclusive_status",
        Cell: ({ value }) => (value === 1 ? "true" : "false"),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (value === 1 ? "true" : "false"),
      },
      {
        Header: "Customer Emails",
        accessor: "cust_emails",
        disableSortBy: true,
      },
      {
        Header: "Attachment",
        accessor: "file_path",
        disableSortBy: true,
        Cell: ({ row }) => (
          <span
            onClick={() => {
              let file_path = row?.original?.file_path;
              if (file_path.slice(-3) !== "N/A") {
                const anchor = document.createElement("a");
                anchor.href = file_path;
                anchor.download = ""; // Optionally specify a filename
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
              }
            }}
          >
            <i
              class="fa fa-download"
              style={{
                cursor:
                  row?.original?.file_path.slice(-3) === "N/A"
                    ? "not-allowed"
                    : "pointer",
                opacity:
                  row?.original?.file_path.slice(-3) === "N/A" ? "0.5" : "1",
              }}
              aria-hidden="true"
            ></i>
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Documents",
        accessor: "doc_list",
        disableSortBy: true,
      },
      {
        Header: "Date of upload",
        accessor: "date_of_upload",
        Cell: ({ row }) =>
          row?.original?.date_of_upload && row?.original?.date_of_upload != ""
            ? new Date(row?.original?.date_of_upload).toLocaleDateString(
                "en-Us",
                {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            : "",

        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
        disableFilters: true,
      },
    ],
    [props?.canEdit, statusList]
  );

  return (
    <>
      <TableContainer
        columns={columns}
        data={filteredAndSortedData} // Use the filtered and sorted data
      />
    </>
  );
};

export default AutoInvoiceTable;
