import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { countryListApi } from "../views/examples/_actions";

export const useCountriesData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["countries-list", profile_email], () => countryListApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      let countriesList = [];
      data?.data?.data.forEach((item) => {
        if (item) {
          countriesList.push({
            label: item.cou_name,
            value: item.cou_id,
          });
        }
      });
      return countriesList;
    },
  });
};
