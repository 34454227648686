import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import { vehicleContractDriverDetailsApi } from "../views/examples/_actions";

export const useFleetManagementData = (currentCompany, category) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["fleet-management", currentCompany, category, profile_email], // Include category in the query key
    () => vehicleContractDriverDetailsApi(currentCompany, category), // Pass category to the API function
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const drivers = data?.data?.data;
          if (drivers && drivers.length > 0) {
            let sortedDriver = sort(drivers).desc((driver) =>
              parseInt(driver?.from_dt)
            );
            return sortedDriver;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    }
  );
};
