import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import { chauffeurPortalServiceListApi } from "../views/examples/_actions";

export const useChaufferServiceData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["chauffer-service-list", currentCompany, profile_email],
    () => chauffeurPortalServiceListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          let sortedChauffers = sort(data?.data?.data).desc(
            (chauffer) => chauffer?.rate_type
          );

          return sortedChauffers;
        } else {
          return null;
        }
      },
    }
  );
};
