import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { Form, Input, Label } from "reactstrap";
import makeAnimated from "react-select/animated";

const SendNotificationModal = (props) => {
  const animatedComponents = makeAnimated();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState(null);
  const [type, setType] = useState("");
  const [userId, setUserId] = useState([]);
  const [companyId, setCompanyId] = useState([]);
  const [titleErr, setTitleErr] = useState(false);
  const [messageErr, setMessageErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [typeErr, setTypeErr] = useState(false);
  const [userIdErr, setUserIdErr] = useState(false);
  const [companyIdErr, setCompanyIdErr] = useState(false);
  const userOptions = props?.userOptions;
  const companyOptions = props?.companyOptions;
  const typeOptions = props?.notificationList;
  const catOptions = [
    { label: "User", value: 0 },
    { label: "Company", value: 1 },
  ];
  const dropdownStyles = {
    option: (styles, { provided }) => {
      const whiteSpace = "nowrap";
      const overflow = "hidden";
      const textOverflow = "ellipsis";
      return {
        ...provided,
        whiteSpace,
        overflow,
        textOverflow,
        ...styles,
      };
    },
  };

  const handleValidation = () => {
    let flag = true;
    if (!title) {
      setTitleErr(true);
      flag = false;
    }
    if (!message) {
      setMessageErr(true);
      flag = false;
    }
    if (!type) {
      setTypeErr(true);
      flag = false;
    }
    if (category === null) {
      setCategoryErr(true);
      flag = false;
    }
    if (category?.value === 0 && !userId) {
      setUserIdErr(true);
      flag = false;
    }
    if (category?.value === 1 && !companyId) {
      setCompanyIdErr(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      let obj = {
        message: message,
        tag: title,
        type: type?.value,
        user_id: [],
        company_code: [],
      };
      if (userId) {
        userId.forEach((item) => {
          obj.user_id.push(item.value);
        });
      }
      if (companyId) {
        companyId.forEach((item) => {
          obj.company_code.push(item.value);
        });
      }
      props?.onCloseModal(obj);
    }
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Send Notification</label>
      </div>
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col">
              <div className="form-group-own">
                <Label for="title">
                  Title<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Title"
                  value={title}
                  onChange={(ev) => setTitle(ev.target.value)}
                  className={`${!title && titleErr && "on-focus"}`}
                />
                {!title && titleErr && (
                  <div className="error-text">Title is required</div>
                )}
              </div>
            </div>
            <div className="col">
              <div className="form-group-own">
                <Label for="type">
                  Type<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Type"
                  options={typeOptions}
                  onChange={(ev) => setType(ev)}
                  value={type}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    type?.length === 0 && typeErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {type?.length === 0 && typeErr && (
                  <div className="error-text">Type is required</div>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group-own">
                <Label for="message">
                  Message<span className="color-primary">*</span>
                </Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder="Message"
                  value={message}
                  onChange={(ev) => setMessage(ev.target.value)}
                  className={`${!message && messageErr && "on-focus"}`}
                />
                {!message && messageErr && (
                  <div className="error-text">Message is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="category">
                  Category<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Category"
                  options={catOptions}
                  onChange={(ev) => {
                    setCategory(ev);
                    setUserId("");
                    setCompanyId("");
                  }}
                  value={category}
                  menuPlacement="top"
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !category && categoryErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!category && categoryErr && (
                  <div className="error-text">Category is required</div>
                )}
              </div>
            </div>
            {/* {category && (
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="id">
                    {category?.label} ID<span className="color-primary">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="id"
                    id="id"
                    placeholder={`${category?.label} ID`}
                    value={id}
                    onChange={(ev) => setId(ev.target.value)}
                    className={`${!id && idErr && "on-focus"}`}
                  />
                  {!id && idErr && (
                    <div className="error-text">
                      {category?.label} ID is required
                    </div>
                  )}
                </div>
              </div>
            )} */}
            {category?.value === 0 && (
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="user">
                    User ID<span className="color-primary">*</span>
                  </Label>
                  <Select
                    styles={dropdownStyles}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="User ID"
                    options={userOptions}
                    onChange={(ev) => {
                      setUserId(ev);
                    }}
                    value={userId}
                    menuPlacement="top"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      !userId && userIdErr && "on-focus"
                    }`}
                    components={animatedComponents}
                  />
                  {!userId && userIdErr && (
                    <div className="error-text">User ID is required</div>
                  )}
                </div>
              </div>
            )}
            {category?.value === 1 && (
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="company">
                    Company ID<span className="color-primary">*</span>
                  </Label>
                  <Select
                    styles={dropdownStyles}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Company ID"
                    options={companyOptions}
                    onChange={(ev) => {
                      setCompanyId(ev);
                    }}
                    value={companyId}
                    menuPlacement="top"
                    isSearchable={true}
                    isClearable={true}
                    className={`select-dropdown-own ${
                      !companyId && companyIdErr && "on-focus"
                    }`}
                    components={animatedComponents}
                  />
                  {!companyId && companyIdErr && (
                    <div className="error-text">Company ID is required</div>
                  )}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                {props?.isEdit ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default SendNotificationModal;
