import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { getIdTypeList } from "../views/examples/_actions";

export const useIDTypeData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["id-types", profile_email], () => getIdTypeList(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      const idsList = [];
      data?.data?.data.forEach((id) => {
        let newMode = {
          value: id?.id_type,
          label: id?.id_type_desc,
        };
        idsList.push(newMode);
      });
      return idsList;
    },
  });
};

export const useIDTypeTableData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["id-types", profile_email], () => getIdTypeList(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      const idsList = [];
      data?.data?.data.forEach((id) => {
        let newMode = {
          id: id?.id_type,
          name: id?.id_type_desc,
        };
        idsList.push(newMode);
      });
      return idsList;
    },
  });
};
