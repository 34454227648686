import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { globalLoader } from "actions/commonActions";
import { toast } from "react-toastify";
import { logOut } from "_shared/commonFunctions";
import { resetPasswordPortalUserApi } from "views/examples/_actions";
import CryptoJs from "crypto-js";

const ChangePasswordModal = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordErr, setOldPasswordErr] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [toggleOld, setToggleOld] = useState(true);
  const [toggleNew, setToggleNew] = useState(true);
  const [toggleCur, setToggleCur] = useState(true);

  const handleValidation = () => {
    let isValid = true;
    if (!oldPassword) {
      setOldPasswordErr(true);
      isValid = false;
    }
    if (!newPassword) {
      setNewPasswordErr(true);
      isValid = false;
    }
    if (!confirmPassword) {
      setConfirmPasswordErr(true);
      isValid = false;
    }
    if (newPassword !== confirmPassword) {
      isValid = false;
    }
    if (oldPassword === newPassword || oldPassword === confirmPassword) {
      isValid = false;
      toast.error("New password same as old password");
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      try {
        let obj = {
          user_id: props?.user_id,
          old_pword: CryptoJs.MD5(oldPassword).toString(),
          new_pword: CryptoJs.MD5(newPassword).toString(),
        };
        let res = await resetPasswordPortalUserApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
          props?.onCloseModal();
        } else {
        }
        globalLoader(false);
      } catch (err) {
        if (err?.response?.status === 401) {
          toast.error("Unauthorized");
          logOut(props?.history, "auth/login");
          globalLoader(false);
        } else {
          globalLoader(false);
        }
      }
      // props?.onCloseModal(oldPassword, newPassword);
    }
  };
  return (
    <>
      <Modal
        open={props?.open}
        center
        onClose={() => props?.onCloseModal()}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own", root: "modal-md-own" }}
      >
        <div className="px-3 pt-5">
          <Form>
            <div className="row">
              <div className="col-12">
                <div className="form-group-own">
                  <Label for="old-password">Old Password</Label>
                  <div className="d-flex">
                    <Input
                      type={toggleOld ? "password" : "text"}
                      name="old-password"
                      id="old-password"
                      placeholder="Current Password"
                      value={oldPassword}
                      className="password-input"
                      onChange={(ev) => setOldPassword(ev.target.value)}
                      maxLength={12}
                    />
                    <span>
                      <i
                        className={`fas fa-eye password-toggle ${
                          !toggleOld && "fa-eye-slash"
                        }`}
                        onClick={() => setToggleOld((toggle) => !toggle)}
                      ></i>
                    </span>
                  </div>
                  {!oldPassword && oldPasswordErr && (
                    <div className="error-text">
                      Current Password is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group-own">
                  <Label for="new-password">New Password</Label>
                  <div className="d-flex">
                    <Input
                      type={toggleNew ? "password" : "text"}
                      name="new-password"
                      id="new-password"
                      placeholder="New Password"
                      value={newPassword}
                      className="password-input"
                      onChange={(ev) => setNewPassword(ev.target.value)}
                      maxLength={12}
                    />
                    <span>
                      <i
                        className={`fas fa-eye password-toggle ${
                          !toggleNew && "fa-eye-slash"
                        }`}
                        onClick={() => setToggleNew((toggle) => !toggle)}
                      ></i>
                    </span>
                  </div>
                  {!newPassword && newPasswordErr && (
                    <div className="error-text">New Password is required</div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group-own">
                  <Label for="confirm-password">Confirm Password</Label>
                  <div className="d-flex">
                    <Input
                      type={toggleCur ? "password" : "text"}
                      name="confirm-password"
                      id="confirm-password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(ev) => setConfirmPassword(ev.target.value)}
                      maxLength={12}
                      className="password-input"
                    />
                    <span>
                      <i
                        className={`fas fa-eye password-toggle ${
                          !toggleCur && "fa-eye-slash"
                        }`}
                        onClick={() => setToggleCur((toggle) => !toggle)}
                      ></i>
                    </span>
                  </div>
                  {!confirmPassword && confirmPasswordErr && (
                    <div className="error-text">
                      Confirm Password is required
                    </div>
                  )}
                  {newPassword &&
                    confirmPassword &&
                    newPassword !== confirmPassword && (
                      <div className="error-text">Passwords not matching</div>
                    )}
                </div>
              </div>
              <div className="d-flex justify-content-center col-12">
                <button
                  className="btn grey-bg btn-own"
                  onClick={() => props?.onCloseModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn primary-bg btn-own"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
