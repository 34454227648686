import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

import { CounterButton } from "components/counter-button/counter-button";

const ExtraServiceCard = ({
  title,
  description,
  price,
  cdwOptional,
  showCounter = false,
  counterChanged,
  cdwSelection,
  driverCount,
  cdwSelected,
}) => {
  const [cdw, setCdw] = useState(false);
  const handleCDW = () => {
    if (!cdw) {
      cdwSelection(1);
    } else {
      cdwSelection(0);
    }
    setCdw((oldData) => !oldData);
  };

  useEffect(() => {
    if (cdwSelected) {
      setCdw(parseInt(cdwSelected) ? true : false);
    }
  }, [cdwSelected]);
  return (
    <Card className="my-1" color="danger" outline>
      <CardBody style={{ padding: "8px 16px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: "1",
            }}
          >
            <CardTitle tag="h3">
              {title === "Collision Damage Waiver" && cdwOptional === "1" && (
                <input
                  type="checkbox"
                  checked={cdw}
                  onChange={handleCDW}
                  id="istimara"
                  value="cdw"
                  className="mr-1"
                />
              )}
              {title}
            </CardTitle>
            <CardSubtitle>
              <h1 className="mb-1 weight-600" style={{ fontSize: "30px" }}>
                SAR {price} <span style={{ fontSize: "16px" }}>per day</span>
              </h1>
            </CardSubtitle>
            <h5 style={{ color: "#B5B7BB" }}>{description}</h5>
          </div>
          {showCounter && (
            <CounterButton
              driverCount={driverCount}
              counterChanged={counterChanged}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ExtraServiceCard;
