import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { toast } from "react-toastify";

import { logOut } from "../../_shared/commonFunctions";
import { currentCompanyData } from "actions/commonActions";
import { globalLoader } from "actions/commonActions";

import { useCompaniesFilterData } from "hooks/useCompaniesData";

const AdminNavbar = (props) => {
  const { currentCompany } = props;
  const [selectedCompany, setSelectedCompany] = useState(currentCompany);
  const animatedComponents = makeAnimated();
  const colourStyles = {
    menu: (provided) => ({
      ...provided,
      width: "400px",
      right: "1px",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
    }),
    option: (styles, { isDisabled, isSelected, isFocused, provided }) => {
      const whiteSpace = "nowrap";
      const overflow = "hidden";
      const textOverflow = "ellipsis";
      return {
        ...provided,
        whiteSpace,
        overflow,
        textOverflow,
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#d4002a"
          : isFocused
          ? "#d4002a17"
          : "white",
        color:
          isFocused && isSelected
            ? "white"
            : isDisabled
            ? "#ccc"
            : isFocused
            ? "#d4002a"
            : isSelected
            ? "white"
            : "#d4002a",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#d4002a" : "black"),
        },
      };
    },
  };

  const { data: companies } = useCompaniesFilterData();

  const handleClick = () => {
    props?.history?.push("/admin/index");
    window.location.reload();
  };

  const handleCompanyChange = (val) => {
    currentCompanyData(val);
    setSelectedCompany(val);
  };
  return (
    <>
      <header className="header-own fixed-top">
        <div className="branding">
          <div className="cursor-pointer" onClick={handleClick}>
            AVIS
          </div>
          {props?.location?.pathname === "/admin/index" && (
            <div> Dashboard Overview</div>
          )}
        </div>

        {props?.profileData && (
          <div className="control-panel">
            {companies ? (
              <Select
                components={animatedComponents}
                options={companies}
                value={selectedCompany}
                isSearchable={true}
                isClearable={true}
                className={
                  props?.location?.pathname === "/admin/user_profile"
                    ? "invisible"
                    : "company-dropdown"
                }
                styles={colourStyles}
                defaultValue={{
                  value: companies[0]?.value,
                  label: companies[0]?.label,
                }}
                onChange={(val) => handleCompanyChange(val)}
              />
            ) : null}

            <UncontrolledDropdown nav className="pl-3">
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="profile"
                      className="profile-img"
                      src={props?.profileData?.profile_image}
                    />
                  </span>
                  <Media className="ml-2 d-none d-md-block">
                    <span className="mb-0 text-sm font-weight-bold text-white">
                      {`${props?.profileData?.first_name} ${props?.profileData?.last_name}`}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0 text-red">Welcome!</h6>
                </DropdownItem>
                <DropdownItem
                  to="/admin/user-profile"
                  onClick={() => props?.history?.push("/admin/user_profile")}
                  className="header-dropdown"
                >
                  <i className="ni ni-single-02" />
                  <span>My Profile</span>
                </DropdownItem>
                {/* <DropdownItem
                  to="/admin/user-profile"
                  tag={Link}
                  className="header-dropdown"
                >
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem> */}
                {/* <DropdownItem
                  to="/admin/user-profile"
                  tag={Link}
                  className="header-dropdown"
                >
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem> */}
                {/* <DropdownItem
                  to="/admin/user-profile"
                  tag={Link}
                  className="header-dropdown"
                >
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    logOut(props?.history, "/auth/login");
                    globalLoader(false);
                    toast.success("Logged Out");
                  }}
                  className="header-dropdown"
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </header>
    </>
  );
};

const mapStateProps = (state) => ({
  profileData: state?.common?.profileData,
  currentCompany: state?.common?.currentCompany,
});

export default connect(mapStateProps)(AdminNavbar);
