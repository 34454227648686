import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Form, Input, Label, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import queryString from "query-string";
import { validEmail, validNumber } from "_shared/commonFunctions";
import { toBase64 } from "actions/commonActions";
import CryptoJs from "crypto-js";
import { globalLoader } from "actions/commonActions";
import { convertDateTime } from "_shared/commonFunctions";
import { TOKEN_USERNAME } from "actionTypes/types";
import { SECRET } from "actionTypes/types";
import { TOKEN_PASSWORD } from "actionTypes/types";
import { TOKEN_GRANT_TYPE } from "actionTypes/types";
import axios from "axios";
import { GLOBAL_API } from "actionTypes/types";
import qs from "query-string";

const AvisForm = (props) => {
  const idRef = useRef();
  const licenseRef = useRef();
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [mobile, setMobile] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [dob, setDob] = useState("");
  const [idFileName, setIdFileName] = useState("");
  const [id_proof_image, setId_proof_image] = useState("");
  const [id_proof_image_type, setId_proof_image_type] = useState("");
  const [idUrl, setIdUrl] = useState("");
  const [license_image, setLicense_image] = useState("");
  const [license_image_type, setLicense_image_type] = useState("");
  const [licenseUrl, setLicenseUrl] = useState("");
  const [licenseFileName, setLicenseFileName] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [first_nameErr, setFirst_nameErr] = useState(false);
  const [last_nameErr, setLast_nameErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [nationalIdErr, setNationalIdErr] = useState(false);
  const [countryList, setCountryList] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeErr, setCountryCodeErr] = useState(false);
  const [email, setEmail] = useState(null);
  const animatedComponents = makeAnimated();
  const [view, setView] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [os, setOs] = useState("");
  const [token, setToken] = useState(null);
  const totalSize = 1024000;

  useEffect(() => {
    callBasicApi();
  }, []);

  useEffect(() => {
    detectView(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkEmail(); // eslint-disable-next-line
  }, []);

  const checkEmail = () => {
    let paramEmail = queryString.parse(props?.location?.search, {
      ignoreQueryPrefix: true,
    }).email;
    if (validEmail) {
      if (validEmail(paramEmail)) {
        setEmail(paramEmail);
      } else {
        toast.error("Invalid Email");
      }
    } else {
      toast.error("No email found");
    }
  };

  const callBasicApi = async () => {
    setView(false);
    globalLoader(true);
    try {
      let obj = {
        username: CryptoJs.AES.decrypt(TOKEN_USERNAME, SECRET).toString(
          CryptoJs.enc.Utf8
        ),
        password: CryptoJs.AES.decrypt(TOKEN_PASSWORD, SECRET).toString(
          CryptoJs.enc.Utf8
        ),
        grant_type: CryptoJs.AES.decrypt(TOKEN_GRANT_TYPE, SECRET).toString(
          CryptoJs.enc.Utf8
        ),
      };
      let res = await axios.post(`${GLOBAL_API}/token`, qs.stringify(obj));
      if (res?.status === 200) {
        const tempToken = res?.data?.access_token;
        setToken(tempToken);
        res = await axios.get(`${GLOBAL_API}/CountryList`, {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        });
        if (res?.data?.status === 0) {
          let arr = res?.data?.data;
          let tempArr = [];
          arr.forEach((data) => {
            let temp = {
              value: data?.cou_id,
              label: `${data?.cou_code} ${data?.cou_dialcode}`,
            };
            tempArr.push(temp);
          });
          setCountryList(tempArr);
          setView(true);
          globalLoader(false);
        } else {
          toast.error(res?.data?.message);
          globalLoader(false);
        }
        globalLoader(false);
      } else {
        toast.error(res?.data?.message);
      }
      globalLoader(false);
    } catch (err) {
      toast.error(err?.response?.message);
      console.error(err);
      globalLoader(false);
    }
  };

  const detectView = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobile(true);
    }
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"];

    if (macosPlatforms.indexOf(platform) !== -1) {
      setOs("Mac OS");
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      setOs("iOS");
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      setOs("Windows");
    } else if (/Android/.test(userAgent)) {
      setOs("Android");
    } else if (!os && /Linux/.test(platform)) {
      setOs("Linux");
    }
  };
  const numberValidation = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setMobile(value);
    }
  };

  const idValidation = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setNationalId(value);
    }
  };

  const handleSingleFileUpload = async (ev, type) => {
    let file = ev.target.files[0];
    if (file?.size > totalSize) {
      toast.error("Upload file should be less than 1MB.");
    } else {
      if (
        file.type === "image/png" ||
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        let obj = {
          img_datetime: file.lastModifiedDate,
          img_id: Math.floor(Math.random() * 1000),
          img_name: file.name,
          img_type: type,
          img_url: URL.createObjectURL(file),
        };
        const fileType = file?.name.split(/[#?]/)[0].split(".").pop().trim();
        const result = await toBase64(file).catch((e) => Error(e));
        if (result instanceof Error) {
          console.log("Error: ", result.message);
          return;
        }
        if (type === "National ID") {
          setIdFileName(file.name);
          setId_proof_image(obj);
          setId_proof_image_type(fileType);
          setIdUrl(result);
        }
        if (type === "License Number") {
          setLicenseFileName(file.name);
          setLicense_image(obj);
          setLicense_image_type(fileType);
          setLicenseUrl(result);
        }
      } else {
        toast.error("Unsupported file type.");
      }
    }
  };

  const handleValidation = () => {
    let isValid = true;
    if (!email) {
      isValid = false;
      toast.error("Email id is required");
    }
    if (!first_name) {
      setFirst_nameErr(true);
      isValid = false;
    }
    if (!last_name) {
      setLast_nameErr(true);
      isValid = false;
    }
    if (!mobile) {
      setMobileErr(true);
      isValid = false;
    }
    if (mobile.length < 9) {
      isValid = false;
    }
    if (!nationalId) {
      setNationalIdErr(true);
      isValid = false;
    }
    if (nationalId.length < 10) {
      isValid = false;
    }
    if (licenseNo && licenseNo.length < 6) {
      isValid = false;
    }
    if (!countryCode) {
      setCountryCodeErr(true);
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (await handleValidation()) {
        globalLoader(true);
        let date_of_birth = convertDateTime(dob);
        let obj = {
          mobile_country: countryCode?.value,
          mobile_no: mobile,
          first_name: first_name,
          last_name: last_name,
          email_id: email,
          national_id: nationalId,
          license_no: licenseNo,
          id_proof_image: idUrl,
          id_proof_image_type: id_proof_image_type,
          license_image: licenseUrl,
          license_image_type: license_image_type,
          date_of_birth: dob === "" ? null : date_of_birth,
        };
        let res = await axios.post(
          `${GLOBAL_API}/Portal/JoinAVISProfileUpdatePortal`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // let res = { data: { status: 0 } };
        if (res?.data?.status === 0) {
          toast.success(
            "User successfully registered. Redirecting to store...."
          );
          setTimeout(() => {
            if (os === "Windows" || os === "Android" || os === "Linux") {
              window.location.href =
                "https://play.google.com/store/apps/details?id=com.avis.aviscare";
            }
            if (os === "Mac OS" || os === "iOS") {
              window.location.href =
                "https://apps.apple.com/us/app/aviscare-saudi-arabia/id1579056599";
            }
          }, 5000);
        } else {
          toast.error(res?.data?.message);
        }
        globalLoader(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Unauthorized");
      } else {
        console.log(err);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Join Avis</title>
      </Helmet>
      <div className="wrapper">
        <div className="header primary-bg">
          <Container>
            <div className="header-body text-center mb-3">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="color-white header-body-txt">AVIS CARE</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="pt-3 pb-5 px-5">
          {view && (
            <Form className="px-0 px-md-5">
              <div className="row">
                <div className="col-12 text-center pb-5">
                  {email ? (
                    <>
                      <p className="font-weight-bold font-20 color-primary">
                        Join Avis
                      </p>
                      <p className="color-primary">
                        If you already have an Avis vehicle, please use your
                        ID/Mobile/Plate Number to login
                      </p>
                    </>
                  ) : (
                    <p className="font-weight-bold">Invalid Email</p>
                  )}
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group-own">
                    <Label
                      for="first_name"
                      className={`${isMobile && "font-12"}`}
                    >
                      First Name <span className="color-primary">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder="First Name"
                      value={first_name}
                      className="form-control-own"
                      onChange={(ev) => setFirst_name(ev.target.value)}
                    />
                    {!first_name && first_nameErr && (
                      <div className="error-text">First name is required</div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group-own">
                    <Label
                      for="last_name"
                      className={`${isMobile && "font-12"}`}
                    >
                      Last Name <span className="color-primary">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder="Last Name"
                      value={last_name}
                      className="form-control-own"
                      onChange={(ev) => setLast_name(ev.target.value)}
                    />
                    {!last_name && last_nameErr && (
                      <div className="error-text">Last name is required</div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group-own">
                    <Label for="mobile" className={`${isMobile && "font-12"}`}>
                      Mobile<span className="color-primary">*</span>
                    </Label>
                    <div className="form-group-phone d-flex">
                      <Select
                        placeholder="Country Code"
                        options={countryList}
                        onChange={(ev) => setCountryCode(ev)}
                        value={countryCode}
                        isSearchable={true}
                        isClearable={true}
                        className="select-dropdown-own select-country-code"
                        components={animatedComponents}
                      />
                      <Input
                        className="form-mobile form-control-own"
                        type="text"
                        name="mobile"
                        id="mobile"
                        placeholder="Mobile"
                        value={mobile}
                        onChange={(ev) => numberValidation(ev)}
                        maxLength={10}
                      />
                    </div>
                    {!mobile && mobileErr && !countryCode && countryCodeErr && (
                      <div className="error-text">
                        Mobile and country code is required
                      </div>
                    )}
                    {mobile && !countryCode && countryCodeErr && (
                      <div className="error-text">Country Code is required</div>
                    )}
                    {!mobile && mobileErr && countryCode && (
                      <div className="error-text">Mobile is required</div>
                    )}
                    {mobile && mobile.length < 9 && (
                      <div className="error-text">
                        Mobile number should be 9 or more digits
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group-own">
                    <Label
                      for="startDate"
                      className={`${isMobile && "font-12"}`}
                    >
                      Date of Birth
                    </Label>
                    <Flatpickr
                      onChange={(e) => setDob(e)}
                      value={dob}
                      className="form-control form-control-own datepicker-own"
                      options={{
                        maxDate: new Date().setFullYear(
                          new Date().getFullYear() - 18
                        ),
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        defaultDate: new Date().setFullYear(
                          new Date().getFullYear() - 18
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group-own">
                    <Label
                      for="nationalId"
                      className={`${isMobile && "font-12"}`}
                    >
                      National Id<span className="color-primary">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="nationalId"
                      id="nationalId"
                      placeholder="National Id"
                      value={nationalId}
                      onChange={(ev) => idValidation(ev)}
                      className="form-mobile form-control-own"
                      maxLength={10}
                    />
                    {!nationalId && nationalIdErr && (
                      <div className="error-text">National Id is required</div>
                    )}
                    {nationalId && nationalId.length < 10 && (
                      <div className="error-text">
                        National Id should be 10 digits
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group-own">
                    <Label
                      for="id_proof_image "
                      className={`${isMobile && "font-12"}`}
                    >
                      Upload National ID Image (pdf/png/jpg/jpeg)
                    </Label>
                    <div className="file-upload-wrapper">
                      <input
                        ref={idRef}
                        type="file"
                        name="id_proof_image"
                        id="id_proof_image"
                        className="form-own-input"
                        accept=".png,.jpg,.jpeg,.pdf"
                        onChange={(ev) =>
                          handleSingleFileUpload(ev, "National ID")
                        }
                      />
                      <input
                        type="text"
                        name="id_proof_image_name"
                        id="id_proof_image_name"
                        className="form-own-input-filename form-control form-control-own"
                        value={idFileName ? idFileName : ""}
                        readOnly
                      />
                      <button
                        className="btn primary-bg file-upload-btn"
                        onClick={(ev) => {
                          ev.preventDefault();
                          idRef.current.click();
                        }}
                      >
                        {id_proof_image ? "Change" : "Upload"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group-own">
                    <Label
                      for="licenseNo"
                      className={`${isMobile && "font-12"}`}
                    >
                      License Number
                    </Label>
                    <Input
                      type="text"
                      name="licenseNo"
                      id="licenseNo"
                      placeholder="License Number"
                      value={licenseNo}
                      onChange={(ev) => setLicenseNo(ev.target.value)}
                      className="form-mobile form-control-own"
                      maxLength={10}
                    />
                    {licenseNo && licenseNo.length < 6 && (
                      <div className="error-text">
                        License Number should be more than 6 digits
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group-own">
                    <Label
                      for="license_image"
                      className={`${isMobile && "font-12"}`}
                    >
                      Upload License Number Image(pdf/png/jpg/jpeg)
                    </Label>
                    <div className="file-upload-wrapper">
                      <input
                        ref={licenseRef}
                        type="file"
                        name="license_image"
                        id="license_image"
                        className="form-own-input"
                        accept=".png,.jpg,.jpeg,.pdf"
                        onChange={(ev) =>
                          handleSingleFileUpload(ev, "License Number")
                        }
                      />
                      <input
                        type="text"
                        name="license_image_name"
                        id="license_image_name"
                        className="form-own-input-filename form-control form-control-own"
                        value={licenseFileName ? licenseFileName : ""}
                        readOnly
                      />
                      <button
                        className="btn primary-bg file-upload-btn"
                        onClick={(ev) => {
                          ev.preventDefault();
                          licenseRef.current.click();
                        }}
                      >
                        {license_image ? "Change" : "Upload"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center pt-4 col-12">
                  <button
                    className={`${email ? "" : "disabled"} btn primary-bg`}
                    onClick={handleSubmit}
                    disabled={email ? false : true}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Container>
      </div>
    </>
  );
};

export default AvisForm;
