import React, { useMemo } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";
import { SelectColumnFilter } from "./_filters";
import TableContainer from "./_table";

const sortItems = (prev, curr, columnId) => {
  if (
    prev.original[columnId].trim().toLowerCase() >
    curr.original[columnId].trim().toLowerCase()
  ) {
    return 1;
  } else if (
    prev.original[columnId].trim().toLowerCase() <
    curr.original[columnId].trim().toLowerCase()
  ) {
    return -1;
  } else {
    return 0;
  }
};

const RentRateTable = (props) => {
  const { statusList } = props;
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  <DropdownItem
                    onClick={() => props?.editData(cell?.row?.original)}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "rac_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.rac_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "group",
        accessor: "rat_vehicle_desc",
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.rat_vehicle_desc}</div>
        ),
      },
      {
        Header: "type",
        accessor: "rat_bdy_desc",
        disableFilters: true,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Rate daily",
        rightAlign: true,
        disableFilters: true,
        accessor: "rate_daily",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.rate_daily}</span>
          </div>
        ),
      },
      {
        Header: "Rate monthly",
        disableFilters: true,
        rightAlign: true,
        accessor: "rate_monthly",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.rate_monthly}</span>
          </div>
        ),
      },
      {
        Header: "CDW daily",
        disableFilters: true,
        rightAlign: true,
        accessor: "cdw_daily",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.cdw_daily}</span>
          </div>
        ),
      },
      {
        Header: "CDW monthly",
        disableFilters: true,
        rightAlign: true,
        accessor: "cdw_monthly",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.cdw_monthly}</span>
          </div>
        ),
      },
      {
        Header: "Excess KM",
        disableFilters: true,
        rightAlign: true,
        accessor: "excess_km_charge",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.excess_km_charge}</span>
          </div>
        ),
      },
      {
        Header: "Accident deduct",
        disableFilters: true,
        rightAlign: true,
        accessor: "accident_deduct",
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.accident_deduct}</span>
          </div>
        ),
      },
      {
        Header: "Rate type",

        accessor: "rate_hire_type_desc",

        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Gear type",
        accessor: "gear_type",

        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Fuel",
        accessor: "rat_fuel",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Seats",
        accessor: "no_of_seats",
      },
      {
        Header: "Year",
        disableFilters: true,
        accessor: "year",
      },
      {
        Header: "Image",
        accessor: "image_url",
        Cell: ({ row }) =>
          row?.original?.image_url !== "" && (
            <div
            // className="avatar rounded-circle"
            >
              <img
                alt="profile"
                // className="profile-img"
                style={{ width: "100%" }}
                src={row?.original?.image_url}
              />
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ID",
        accessor: "rat_id",
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);

  return <TableContainer columns={columns} data={data} />;
};

export default RentRateTable;
