import React, { useState, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form } from "reactstrap";
import { MAX_IMG_SIZE } from "actionTypes/types";
import { toast } from "react-toastify";
import { toBase64 } from "actions/commonActions";

const UploadDocModal = (props) => {
  const { formData } = props;
  const [istimaraChecked, setIstimaraChecked] = useState(false);
  const [istimaraFilename, setIstimaraFilename] = useState("");
  const [istimara, setIstimara] = useState("");
  const istimaraRef = useRef();
  const [insuranceChecked, setInsuranceChecked] = useState(false);
  const [insuranceFilename, setInsuranceFilename] = useState("");
  const [insurance, setInsurance] = useState("");
  const insuranceRef = useRef();
  const [mvpiChecked, setMvpiChecked] = useState(false);
  const [mvpiFilename, setMvpiFilename] = useState("");
  const [mvpi, setMvpi] = useState("");
  const mvpiRef = useRef();
  const [motChecked, setMotChecked] = useState(false);
  const [motFilename, setMotFilename] = useState("");
  const [mot, setMot] = useState("");
  const motRef = useRef();
  const [imageChecked, setImageChecked] = useState(false);
  const [imageFilename, setImageFilename] = useState("");
  const [image, setImage] = useState("");
  const [imageType, setImageType] = useState("");
  const imageRef = useRef();
  const [istimaraErr, setIstimaraErr] = useState(false);
  const [insuranceErr, setInsuranceErr] = useState(false);
  const [mvpiErr, setMvpiErr] = useState(false);
  const [motErr, setMotErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);

  const handleSingleFileUpload = async (ev, type) => {
    let file = ev.target.files[0];
    if (file?.size > MAX_IMG_SIZE) {
      toast.error("Upload file should be less than 1MB.");
    } else {
      if (type === "image") {
        if (
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg"
        ) {
          const result = await toBase64(file).catch((e) => Error(e));
          const fileType = file?.name.split(/[#?]/)[0].split(".").pop().trim();
          if (result instanceof Error) {
            console.log("Error: ", result.message);
            return;
          }
          setImageFilename(file.name);
          setImage(result);
          setImageType(fileType);
        } else {
          toast.error("Image upload can be jpeg, jpg or png");
        }
      } else {
        if (file.type === "application/pdf") {
          const result = await toBase64(file).catch((e) => Error(e));
          if (result instanceof Error) {
            console.log("Error: ", result.message);
            return;
          }
          if (type === "istimara") {
            setIstimaraFilename(file.name);
            setIstimara(result);
          }
          if (type === "insurance") {
            setInsuranceFilename(file.name);
            setInsurance(result);
          }
          if (type === "mvpi") {
            setMvpiFilename(file.name);
            setMvpi(result);
          }
          if (type === "mot") {
            setMotFilename(file.name);
            setMot(result);
          }
          if (type === "image") {
            setImageFilename(file.name);
            setImage(result);
          }
        } else {
          toast.error("Please upload PDF files.");
        }
      }
    }
  };

  const handleValidation = () => {
    let flag = true;
    if (!istimara && istimaraChecked) {
      flag = false;
      setIstimaraErr(true);
    }
    if (!insurance && insuranceChecked) {
      flag = false;
      setInsuranceErr(true);
    }
    if (!mvpi && mvpiChecked) {
      flag = false;
      setMvpiErr(true);
    }
    if (!mot && motChecked) {
      flag = false;
      setMotErr(true);
    }
    if (!image && imageChecked) {
      flag = false;
      setImageErr(true);
    }
    return flag;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (handleValidation()) {
      let DocumentDetails = [];
      if (istimaraChecked) {
        DocumentDetails.push({
          img_type: "pdf",
          doc_type_id: "1",
          img_string: istimara,
        });
      }
      if (insuranceChecked) {
        DocumentDetails.push({
          img_type: "pdf",
          doc_type_id: "2",
          img_string: insurance,
        });
      }
      if (mvpiChecked) {
        DocumentDetails.push({
          img_type: "pdf",
          doc_type_id: "3",
          img_string: mvpi,
        });
      }
      if (motChecked) {
        DocumentDetails.push({
          img_type: "pdf",
          doc_type_id: "4",
          img_string: mot,
        });
      }
      if (imageChecked) {
        DocumentDetails.push({
          img_type: imageType,
          doc_type_id: "9",
          img_string: image,
        });
      }
      let obj = {
        plateno: formData?.plate_no,
        DocumentDetails: DocumentDetails,
      };
      props?.onCloseModal(obj);
    }
  };

  return (
    <Modal
      open={props?.open}
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      center
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">
          Plate Number {formData?.plate_no}
        </label>
      </div>
      <main className="py-4">
        <Form className="mt-3">
          <div className="row px-5">
            {/* ISTIMARA */}
            <div className="col-12">
              <div className="form-group-own">
                <div className=" d-flex justify-content-between align-items-center">
                  <div>
                    <input
                      type="checkbox"
                      checked={istimaraChecked}
                      onChange={() => setIstimaraChecked((oldData) => !oldData)}
                      id="istimara"
                      value="istimara"
                      className="mr-1"
                    />
                    <label className="ml-1" htmlFor="istimara">
                      Istimara <span className="error-text">(PDF)</span>
                    </label>
                  </div>

                  <div className="file-upload-wrapper w-70-perc">
                    <input
                      ref={istimaraRef}
                      type="file"
                      name="istimaraUpload"
                      id="istimaraUpload"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg,.pdf"
                      onChange={(ev) => handleSingleFileUpload(ev, "istimara")}
                    />
                    <input
                      type="text"
                      name="istimaraUpload"
                      id="istimaraUpload"
                      className={`form-own-input-filename form-control ${
                        !istimara &&
                        istimaraChecked &&
                        istimaraErr &&
                        "on-focus"
                      }`}
                      value={istimaraFilename ? istimaraFilename : ""}
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        istimaraRef.current.click();
                      }}
                      disabled={istimaraChecked ? false : true}
                    >
                      {istimara ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
                {!istimara && istimaraChecked && istimaraErr && (
                  <div className="error-img-doc-text">
                    Istimara document is required
                  </div>
                )}
              </div>
            </div>

            {/* INSURANCE */}
            <div className="col-12">
              <div className="form-group-own">
                <div className=" d-flex justify-content-between align-items-center">
                  <div>
                    <input
                      type="checkbox"
                      checked={insuranceChecked}
                      onChange={() =>
                        setInsuranceChecked((oldData) => !oldData)
                      }
                      id="insurance"
                      value="insurance"
                      className="mr-1"
                    />
                    <label className="ml-1" htmlFor="insurance">
                      Insurance <span className="error-text">(PDF)</span>
                    </label>
                  </div>

                  <div className="file-upload-wrapper w-70-perc">
                    <input
                      ref={insuranceRef}
                      type="file"
                      name="insuranceUpload"
                      id="insuranceUpload"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg,.pdf"
                      onChange={(ev) => handleSingleFileUpload(ev, "insurance")}
                    />
                    <input
                      type="text"
                      name="insuranceUpload"
                      id="insuranceUpload"
                      className={`form-own-input-filename form-control ${
                        !insurance &&
                        insuranceChecked &&
                        insuranceErr &&
                        "on-focus"
                      }`}
                      value={insuranceFilename ? insuranceFilename : ""}
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        insuranceRef.current.click();
                      }}
                      disabled={insuranceChecked ? false : true}
                    >
                      {insurance ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
                {!insurance && insuranceChecked && insuranceErr && (
                  <div className="error-img-doc-text">
                    Insurance document is required
                  </div>
                )}
              </div>
            </div>

            {/* MVPI */}
            <div className="col-12">
              <div className="form-group-own">
                <div className=" d-flex justify-content-between align-items-center">
                  <div>
                    <input
                      type="checkbox"
                      checked={mvpiChecked}
                      onChange={() => setMvpiChecked((oldData) => !oldData)}
                      id="mvpi"
                      value="mvpi"
                      className="mr-1"
                    />
                    <label className="ml-1" htmlFor="mvpi">
                      MVPI <span className="error-text">(PDF)</span>
                    </label>
                  </div>

                  <div className="file-upload-wrapper w-70-perc">
                    <input
                      ref={mvpiRef}
                      type="file"
                      name="mvpiUpload"
                      id="mvpiUpload"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg,.pdf"
                      onChange={(ev) => handleSingleFileUpload(ev, "mvpi")}
                    />
                    <input
                      type="text"
                      name="mvpiUpload"
                      id="mvpiUpload"
                      className={`form-own-input-filename form-control ${
                        !mvpi && mvpiChecked && mvpiErr && "on-focus"
                      }`}
                      value={mvpiFilename ? mvpiFilename : ""}
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        mvpiRef.current.click();
                      }}
                      disabled={mvpiChecked ? false : true}
                    >
                      {mvpi ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
                {!mvpi && mvpiChecked && mvpiErr && (
                  <div className="error-img-doc-text">
                    MVPI document is required
                  </div>
                )}
              </div>
            </div>

            {/* MOT */}
            <div className="col-12">
              <div className="form-group-own">
                <div className=" d-flex justify-content-between align-items-center">
                  <div>
                    <input
                      type="checkbox"
                      checked={motChecked}
                      onChange={() => setMotChecked((oldData) => !oldData)}
                      id="mot"
                      value="mot"
                      className="mr-1"
                    />
                    <label className="ml-1" htmlFor="mot">
                      MOT <span className="error-text">(PDF)</span>
                    </label>
                  </div>

                  <div className="file-upload-wrapper w-70-perc">
                    <input
                      ref={motRef}
                      type="file"
                      name="motUpload"
                      id="motUpload"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg,.pdf"
                      onChange={(ev) => handleSingleFileUpload(ev, "mot")}
                    />
                    <input
                      type="text"
                      name="motUpload"
                      id="motUpload"
                      className={`form-own-input-filename form-control ${
                        !mot && motChecked && motErr && "on-focus"
                      }`}
                      value={motFilename ? motFilename : ""}
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        motRef.current.click();
                      }}
                      disabled={motChecked ? false : true}
                    >
                      {mot ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
                {!mot && motChecked && motErr && (
                  <div className="error-img-doc-text">
                    MOT document is required
                  </div>
                )}
              </div>
            </div>

            {/* IMAGE */}
            <div className="col-12">
              <div className="form-group-own">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <input
                      type="checkbox"
                      checked={imageChecked}
                      onChange={() => setImageChecked((oldData) => !oldData)}
                      id="image"
                      value="image"
                      className="mr-1"
                    />
                    <label className="ml-1" htmlFor="image">
                      Image <span className="error-text">(JPG, JPEG, PNG)</span>
                    </label>
                  </div>

                  <div className="file-upload-wrapper w-70-perc">
                    <input
                      ref={imageRef}
                      type="file"
                      name="imageUpload"
                      id="imageUpload"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg,.pdf"
                      onChange={(ev) => handleSingleFileUpload(ev, "image")}
                    />
                    <input
                      type="text"
                      name="imageUpload"
                      id="imageUpload"
                      className={`form-own-input-filename form-control ${
                        !image && imageChecked && imageErr && "on-focus"
                      }`}
                      value={imageFilename ? imageFilename : ""}
                      readOnly
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        imageRef.current.click();
                      }}
                      disabled={imageChecked ? false : true}
                    >
                      {image ? "Change" : "Upload"}
                    </button>
                  </div>
                </div>
                {!image && imageChecked && imageErr && (
                  <div className="error-img-doc-text">
                    Image document is required
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center col-12 pt-5">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal(null)}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </main>
    </Modal>
  );
};

export default UploadDocModal;
