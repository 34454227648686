export const selectFilter = (options, id, Header) => {
  let obj = [];
  switch (id) {
    case "com_status":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Registered";
        }
        if (option === "2") {
          temp.value = "Archived";
        }
        if (option === "3") {
          temp.value = "Cancelled";
        }
        obj.push(temp);
      });
      break;
    case "com_category_id":
      options?.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "General";
        }
        if (option === "2") {
          temp.value = "Vehicle";
        }
        if (option === "3") {
          temp.value = "Service";
        }
        if (option === "4") {
          temp.value = "AVIS Care App";
        }
        if (option === "5") {
          temp.value = "Others";
        }
        obj.push(temp);
      });
      break;

    case "aut_status":
      options?.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Requested";
        }
        if (option === "2") {
          temp.value = "Approved";
        }
        if (option === "3") {
          temp.value = "Cancelled";
        }
        if (option === "4") {
          temp.value = "Rejected";
        }
        obj.push(temp);
      });
      break;

    case "rsa_status":
      options?.forEach((option) => {
        let temp = { id: option };
        option === "1" ? (temp.value = "Completed") : (temp.value = "Pending");
        obj.push(temp);
      });
      break;

    case "cha_city_kingdon":
      options?.forEach((option) => {
        let temp = { id: option };
        if (option === "0") {
          temp.value = "City";
        }
        if (option === "1") {
          temp.value = "Within Kingdom";
        }
        obj.push(temp);
      });
      break;

    case "nti_type":
      options?.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Service";
        } else if (option === "2") {
          temp.value = "Accident";
        } else if (option === "3") {
          temp.value = "Traffic Violation";
        } else if (option === "4") {
          temp.value = "Self Inspection";
        } else if (option === "5") {
          temp.value = "Complaint";
        } else if (option === "6") {
          temp.value = "Rent";
        } else if (option === "7") {
          temp.value = "Bill";
        } else if (option === "8") {
          temp.value = "Vehicle Doc";
        } else if (option === "9") {
          temp.value = "Authorization";
        } else {
          temp.value = "Others";
        }
        obj.push(temp);
      });
      break;
    case "nti_read_flag":
      options?.forEach((option) => {
        let temp = { id: option };
        if (option === "0") {
          temp.value = "False";
        }
        if (option === "1") {
          temp.value = "True";
        }
        obj.push(temp);
      });
      break;
    case "status":
      if (Header === "Maintenance Status" || Header === "Replacement Status") {
        options?.forEach((option) => {
          let temp = { id: option, value: option };
          obj.push(temp);
        });
      }
      if (Header === "Status" && id === "status") {
        options?.forEach((option) => {
          let temp = { id: option, value: option };
          obj.push(temp);
        });
      }

      break;
    case "dmg_status":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Requested";
        }
        if (option === "2") {
          temp.value = "Completed";
        }
        if (option === "3") {
          temp.value = "Cancelled";
        }
        if (option !== "0") {
          obj.push(temp);
        }
      });
      break;

    case "bil_categoryid":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Rental Invoices";
        }
        if (option === "2") {
          temp.value = "Traffic Violation Invoices";
        }
        if (option === "3") {
          temp.value = "Damage Invoices";
        }
        if (option === "4") {
          temp.value = "Mishandling Invoices";
        }
        if (option === "5") {
          temp.value = "Insurance Excess";
        }
        if (option === "6") {
          temp.value = "Chauffeur Invoices";
        }
        obj.push(temp);
      });
      break;

    case "bil_statusid":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Due";
        }
        if (option === "2") {
          temp.value = "Processing";
        }
        if (option === "3") {
          temp.value = "Cancelled";
        }
        if (option === "4") {
          temp.value = "Paid";
        }
        obj.push(temp);
      });
      break;

    case "service_type_id":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Breakdown";
        }
        if (option === "2") {
          temp.value = "Preventive";
        }
        if (option === "3") {
          temp.value = "Mishandling";
        }
        if (option === "4") {
          temp.value = "Overhaul";
        }
        if (option === "5") {
          temp.value = "User Request";
        }
        if (option === "6") {
          temp.value = "Planned";
        }
        obj.push(temp);
      });
      break;

    case "maint_status":
      if (Header === "Maintenance Status") {
        options?.forEach((option) => {
          let temp = { id: option, value: option };
          obj.push(temp);
        });
      } else {
        options.forEach((option) => {
          let temp = { id: option };
          if (option === "0") {
            temp.value = "Planned";
          }
          if (option === "1") {
            temp.value = "Requested";
          }
          if (option === "2") {
            temp.value = "Completed";
          }
          if (option === "3") {
            temp.value = "Scheduled";
          }
          if (option === "4") {
            temp.value = "Cancelled";
          }
          obj.push(temp);
        });
      }

      break;

    case "acc_status":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Requested";
        }
        if (option === "2") {
          temp.value = "Completed";
        }
        if (option === "3") {
          temp.value = "Cancelled";
        }
        obj.push(temp);
      });
      break;

    case "V_status":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Due";
        }
        if (option === "2") {
          temp.value = "Paid";
        }
        if (option === "4") {
          temp.value = "Cancelled";
        }
        obj.push(temp);
      });
      break;
    case "rent_staus":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Requested";
        }
        if (option === "2") {
          temp.value = "Active";
        }
        if (option === "3") {
          temp.value = "Past";
        }
        if (option === "4") {
          temp.value = "Cancelled";
        }
        if (option === "5") {
          temp.value = "Completed";
        }
        if (option !== "0") obj.push(temp);
      });
      break;

    case "char_status":
    case "loc_status":
    case "role_status":
    case "rate_status":
    case "user_status":
    case "service_status":
    case "pdg_status":
    case "rac_status":
      options.forEach((option) => {
        let temp = { id: option };
        if (option === "0") {
          temp.value = "Inactive";
        }
        if (option === "1") {
          temp.value = "Active";
        }
        obj.push(temp);
      });
      break;

    case "chr_rate_type_id":
    case "char_rate_type_id":
      options?.forEach((option) => {
        let temp = { id: option };
        if (option === "1") {
          temp.value = "Hourly/Airport";
        }
        if (option === "2") {
          temp.value = "6 Hours";
        }
        if (option === "3") {
          temp.value = "12 Hours";
        }
        if (option === "4") {
          temp.value = "16 Hours";
        }
        if (option === "5") {
          temp.value = "Monthly";
        }
        if (option === "6") {
          temp.value = "Annual";
        }
        obj.push(temp);
      });
      break;

    case "V_bill_availability":
    case "Workshop":
    case "job_type":
    case "rat_fuel":
    case "gear_type":
    case "gcc_country":
    case "rsa_service_type":
    case "loc_type":
    case "replace_status":
    case "record_type":
    case "rate_hire_type_desc":
      options?.forEach((option) => {
        let temp = { id: option, value: option };
        obj.push(temp);
      });
      break;

    default:
      break;
  }
  return obj;
};
