import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import StarRatings from "react-star-ratings";
import TableContainer from "./_table";
import { DateRangeColumnFilter } from "./_filters";
import { SelectColumnFilter } from "./_filters";
import DescModal from "components/Modals/descModal";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";

const FeedbackTable = (props) => {
  const { searchId, searchFilter, statusList } = props;
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#pablo"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {/* <DropdownItem
                  onClick={() => props?.editData(cell?.row?.original)}
                >
                  Edit
                </DropdownItem> */}
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.sendNotification(cell?.row?.original)}
                  >
                    Reply
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "com_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.com_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },

      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.company_code !== "N/A" &&
              props?.customerModalOpen(row?.original, "company")
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              props?.customerModalOpen(row?.original, "customer")
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: "com_datetime",
        Cell: ({ row }) => {
          return new Date(row?.original?.com_datetime).toLocaleDateString(
            "default",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          );
        },
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Category",
        accessor: "com_category_id",
        Cell: ({ row }) =>
          row?.original.com_category_id === "1"
            ? "General"
            : row?.original.com_category_id === "2"
            ? "Vehicle"
            : row?.original.com_category_id === "3"
            ? "Service"
            : row?.original.com_category_id === "4"
            ? "AVIS Care App"
            : "Others",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Plate No",
        accessor: "com_plate",
        Cell: ({ row }) =>
          row?.original.com_plate !== "" && row?.original?.com_plate,
      },
      {
        Header: "Subject",
        disableFilters: true,
        accessor: "com_subject",
        Cell: ({ row }) => {
          let text = row?.original?.com_subject.trim();
          return (
            <>
              <div className={`${text?.length > 13 ? "view-more" : ""}`}>
                {text}
              </div>

              {row?.original?.com_subject.length > 13 && (
                <div
                  className="font-12 color-primary cursor-pointer"
                  onClick={() =>
                    handleMore("Subject", row?.original?.com_subject)
                  }
                >
                  View More
                </div>
              )}
            </>
          );
        },
      },

      {
        Header: "Images",
        accessor: "images",
        Cell: ({ row }) =>
          row?.original?.images[0] !== null && (
            <div className="d-flex justify-content-center">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Ratings",
        accessor: "rating_no",
        Cell: ({ row }) =>
          row?.original?.rating_no !== "" && (
            <StarRatings
              rating={parseInt(row?.original?.rating_no)}
              starRatedColor="rgb(212,0,42)"
              starDimension="10px"
              starSpacing="1px"
            />
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ID",
        accessor: "com_id",
      },
      {
        Header: "Details",
        accessor: "com_desc",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.com_desc.length > 140 ? "view-more" : ""
              }`}
            >
              {row?.original?.com_desc}
            </div>
            {row?.original?.com_desc.length > 140 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() => handleMore("Details", row?.original?.com_desc)}
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = React.useMemo(() => props?.tableData, []);

  return (
    <>
      <TableContainer
        searchFilter={searchFilter}
        searchId={searchId}
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (data?.column?.Header === "Images") {
              props?.viewImage(data?.row?.original?.images);
            }
          },
        })}
      />
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}
    </>
  );
};

export default FeedbackTable;
