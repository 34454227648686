import React from "react";

const PrimaryButton = ({
  title,
  onClick,
  iconPosition,
  iconName,
  withIcon,
}) => {
  return (
    <button
      className="btn btn-sm primary-bg"
      onClick={onClick}
      style={{ borderRadius: "0px" }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {withIcon && iconPosition === "left" && (
          <i className={`fas  pr-2 ${iconName}`}></i>
        )}
        {title}
        {withIcon && iconPosition === "right" && (
          <i className={`fas  pl-2 ${iconName}`}></i>
        )}
      </div>
    </button>
  );
};

export default PrimaryButton;
