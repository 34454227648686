import DescModal from "components/Modals/descModal";
import React, { useMemo, useState } from "react";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";
import { DateRangeColumnFilter, SelectColumnFilter } from "./_filters";
import TableContainer from "./_table";

const NotificationTable = (props) => {
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { statusList } = props;

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Read Status",
        accessor: "nti_read_flag",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.nti_read_flag, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },

      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.company_code !== "N/A" &&
              props?.customerModalOpen(row?.original, "company")
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              props?.customerModalOpen(row?.original, "customer")
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },
      {
        Header: "Date & Time",
        accessor: "nti_datetime",
        Cell: ({ row }) =>
          new Date(row?.original?.nti_datetime).toLocaleString("en-Us", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Category",
        accessor: "nti_type",
        Cell: ({ row }) =>
          row?.original?.nti_type === "1"
            ? "Service"
            : row?.original?.nti_type === "2"
            ? "Accident"
            : row?.original?.nti_type === "3"
            ? "Traffic Violation"
            : row?.original?.nti_type === "4"
            ? "Self Inspection"
            : row?.original?.nti_type === "5"
            ? "Complaint"
            : row?.original?.nti_type === "6"
            ? "Rent"
            : row?.original?.nti_type === "7"
            ? "Bill"
            : row?.original?.nti_type === "8"
            ? "Vehicle Doc"
            : row?.original?.nti_type === "9"
            ? "Authorization"
            : "Others",

        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "id",
        accessor: "nti_descid",
        disableFilters: true,
      },
      {
        Header: "Notification",
        accessor: "nti_desc",
        Cell: ({ row }) => (
          // <span className="table-text">{row?.original?.nti_desc}</span>
          <>
            <div
              className={`${
                row?.original?.nti_desc.length > 190 ? "view-more" : ""
              }`}
            >
              {row?.original?.nti_desc}
            </div>
            {row?.original?.nti_desc.length > 190 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Center Adderess", row?.original?.nti_desc)
                }
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line
    [statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);

  return (
    <>
      <TableContainer columns={columns} data={data} />
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}
    </>
  );
};

export default NotificationTable;
