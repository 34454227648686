import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";
import TableContainer from "./_table";
// import { SelectColumnFilter } from "./_filters";

const sortItems = (prev, curr, id) => {
  if (
    prev.original[id].trim().toLowerCase() >
    curr.original[id].trim().toLowerCase()
  ) {
    return 1;
  }
  if (
    prev.original[id].trim().toLowerCase() <
    curr.original[id].trim().toLowerCase()
  ) {
    return -1;
  }
  return 0;
};

const UserTable = (props) => {
  const { statusList } = props;
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Verify
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.editUser(cell?.row?.original)}
                  >
                    Edit
                  </DropdownItem>
                  {/* <DropdownItem
                  onClick={() => props?.deleteUser(cell?.row?.original)}
                >
                  Delete
                </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "user_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.user_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },

      {
        Header: "Designation",
        accessor: "designation",
      },
      {
        Header: "Image",
        accessor: "profile_image",
        Cell: ({ row }) =>
          row?.original?.profile_image !== "" && (
            <div className="avatar rounded-circle">
              <img
                alt="profile"
                className="profile-img"
                src={row?.original?.profile_image}
              />
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: (row) => `${row?.first_name} ${row?.last_name}`,
        Cell: ({ row }) => (
          <div className="table-text">{`${row?.original?.first_name} ${row?.original?.last_name}`}</div>
        ),
        sortType: (prev, curr) => sortItems(prev, curr, "first_name"),
      },
      {
        Header: "Name (Arabic)",
        accessor: (row) =>
          `${row?.original?.first_name_a} ${row?.original?.last_name_a}}`,
        Cell: ({ row }) => (
          <div className="table-text text-right">{`${row?.original?.last_name_a} ${row?.original?.first_name_a}`}</div>
        ),
        disableFilters: true,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.email}</div>
        ),
        sortType: (prev, curr) => sortItems(prev, curr, "email"),
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        disableFilters: true,
      },
      {
        Header: "Company",
        accessor: "company",
        Cell: ({ row }) => {
          const options = row?.original?.company;
          return (
            options && (
              <span className="font-12 color-primary pl-1 cursor-pointer">
                View All
              </span>
            )
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "National ID",
        accessor: "national_id",
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original?.national_id}
              {row?.original?.national_id_status === "0" ? (
                <i className="fas fa-exclamation-circle pl-1 color-orange"></i>
              ) : (
                <i className="fas fa-check-circle pl-1 color-green"></i>
              )}
            </span>
          );
        },
        disableFilters: true,
      },
      {
        Header: "Image",
        accessor: "id_proof_image",
        Cell: ({ row }) =>
          row?.original?.id_proof_image && (
            <div className="ml-2">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Id",
        accessor: "user_id",
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = React.useMemo(() => props?.tableData, []);

  return (
    <TableContainer
      columns={columns}
      data={data}
      getCellProps={(data) => ({
        onClick: () => {
          if (
            data?.column?.id === "id_proof_image" &&
            data?.row?.original?.id_proof_image
          ) {
            props?.openImage(
              data?.row?.original?.id_proof_image,
              "National ID"
            );
          }
          if (data?.column?.id === "company") {
            props?.viewCompany(data?.row?.original?.company, "Company List");
          }
        },
      })}
    />
  );
};

export default UserTable;
