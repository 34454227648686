import React, { useState, useRef } from "react";
import StarRatings from "react-star-ratings";
import Flatpickr from "react-flatpickr";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { validNumber } from "_shared/commonFunctions";

const ServiceListModal = (props) => {
  const singleRef = useRef();
  const multiRef = useRef();
  const [service_center, setService_center] = useState("");
  const [service_type, setService_type] = useState("");
  const [maint_plate, setMaint_plate] = useState("");
  const [prefered_date_time, setPrefered_date_time] = useState(null);
  const [scheduled_date_time, setScheduled_data_time] = useState(null);
  const [maint_odo, setMaint_odo] = useState("");
  const maint_status = "Scheduled";
  const [maint_reason, setMaint_reason] = useState("");
  const [maint_rating, setMaint_rating] = useState(1);
  const [maint_rating_comment, setMaint_rating_comment] = useState("");
  const [images, setImages] = useState([]);
  const [odo_images, setOdo_images] = useState(null);
  const [category, setCategory] = useState("");
  const [service_centerErr, setService_centerErr] = useState(false);
  const [service_typeErr, setService_typeErr] = useState(false);
  const [maint_plateErr, setMaint_plateErr] = useState(false);
  const [prefered_date_timeErr, setPrefered_date_timeErr] = useState(false);
  const [scheduled_date_timeErr, setScheduled_date_timeErr] = useState(false);
  const [maint_odoErr, setMaint_odoErr] = useState(false);
  const [maint_rating_commentErr, setMaint_rating_commentErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = {
        service_center: service_center,
        service_type: service_type,
        maint_plate: maint_plate,
        prefered_date_time: prefered_date_time,
        scheduled_date_time: scheduled_date_time,
        maint_odo: maint_odo,
        maint_reason: maint_reason,
        maint_rating: maint_rating,
        maint_rating_comment: maint_rating_comment,
        images: images,
        odo_images: odo_images,
        category: category,
        maint_status: maint_status,
      };
      props?.onCloseModal(obj);
    }
  };

  const handleValidation = () => {
    let isValid = true;
    if (!maint_plate) {
      setMaint_plateErr(true);
      isValid = false;
    }
    if (!prefered_date_time) {
      setPrefered_date_timeErr(true);
      isValid = false;
    }
    if (!scheduled_date_time) {
      setScheduled_date_timeErr(true);
      isValid = false;
    }
    if (!category) {
      setCategoryErr(true);
      isValid = false;
    }
    if (!maint_odo) {
      setMaint_odoErr(true);
      isValid = false;
    }
    if (!service_center) {
      setService_centerErr(true);
      isValid = false;
    }
    if (!service_type) {
      setService_typeErr(true);
      isValid = false;
    }
    if (!maint_rating_comment) {
      setMaint_rating_commentErr(true);
      isValid = false;
    }
    return isValid;
  };

  const odometerValidation = async (ev) => {
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setMaint_odo(value);
    }
  };

  const handleSingleUpload = (e) => {
    e.preventDefault();
    singleRef.current.click();
  };

  const handleMultiUpload = (e) => {
    e.preventDefault();
    multiRef.current.click();
  };

  const handleSingleFileUpload = (ev) => {
    if (
      ev.target.files[0].type === "image/jpeg" ||
      ev.target.files[0].type === "image/jpg" ||
      ev.target.files[0].type === "image/png"
    ) {
      let file = ev.target.files[0];
      let obj = {
        img_datetime: file.lastModifiedDate,
        img_id: Math.floor(Math.random() * 1000),
        img_name: file.name,
        img_type: "Odometer",
        img_type_id: Math.floor(Math.random() * 1000),
        img_url: URL.createObjectURL(file),
      };
      setOdo_images(obj);
    }
  };

  const handleMultiFileUpload = (ev) => {
    let file = ev.target.files;
    let obj = [];
    for (let i = 0; i < file.length; i++) {
      let val = {
        img_datetime: file[i].lastModifiedDate,
        img_id: Math.floor(Math.random() * 1000),
        img_name: file[i].name,
        img_type: "Maintenance",
        img_type_id: Math.floor(Math.random() * 1000),
        img_url: URL.createObjectURL(file[i]),
      };
      obj.push(val);
    }
    setImages(obj);
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="maint_plate">
                  Plate Number<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="maint_plate"
                  id="maint_plate"
                  placeholder="Plate Number"
                  value={maint_plate}
                  onChange={(ev) => setMaint_plate(ev.target.value)}
                  disabled={props?.isEdit ? true : false}
                />
                {!maint_plate && maint_plateErr && (
                  <div className="error-text">Plate Number is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="prefered_date_time">
                  Prefered Date & Time<span className="color-primary">*</span>
                </Label>
                <Flatpickr
                  className="form-control datepicker-own"
                  options={{
                    minDate: "today",
                    altInput: true,
                    dateFormat: "Y-m-d H:i",
                    enableTime: true,
                  }}
                  onChange={(date) => setPrefered_date_time(date)}
                  value={prefered_date_time !== null && prefered_date_time}
                />
                {!prefered_date_time && prefered_date_timeErr && (
                  <div className="error-text">
                    Preffered Date & Time is required
                  </div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="scheduled_date_time">
                  Scheduled Date & Time<span className="color-primary">*</span>
                </Label>
                <Flatpickr
                  className="form-control datepicker-own"
                  options={{
                    minDate: "today",
                    altInput: true,
                    dateFormat: "Y-m-d H:i",
                    enableTime: true,
                  }}
                  onChange={(date) => setScheduled_data_time(date)}
                  value={scheduled_date_time !== null && scheduled_date_time}
                />
                {!scheduled_date_time && scheduled_date_timeErr && (
                  <div className="error-text">
                    Scheduled Date & Time is required
                  </div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="category">
                  Category<span className="color-primary">*</span>
                </Label>
                <select
                  className="form-control"
                  id="category"
                  value={category}
                  onChange={(ev) => setCategory(ev.target.value)}
                  disabled={props?.isEdit ? true : false}
                >
                  <option>Select a Category</option>
                  <option value="Planned">Planned</option>
                  <option value="Unplanned">Unplanned</option>
                </select>
                {!category && categoryErr && (
                  <div className="error-text">Category is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="maint_odo">
                  Odometer<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="maint_odo"
                  id="maint_odo"
                  placeholder="Odometer"
                  value={maint_odo}
                  onChange={(ev) => odometerValidation(ev)}
                  disabled={props?.isEdit ? true : false}
                />
                {!maint_odo && maint_odoErr && (
                  <div className="error-text">Odometer reading is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="odo_images">Odometer Image</Label>
                <div className="file-upload-wrapper">
                  <input
                    ref={singleRef}
                    type="file"
                    name="odo_images"
                    id="odo_images"
                    className="form-own-input"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleSingleFileUpload}
                  />
                  <input
                    type="text"
                    name="odo_images_name"
                    id="odo_images_name"
                    className="form-own-input-filename form-control"
                    value={odo_images ? odo_images.img_name : ""}
                    readOnly
                  />
                  <button
                    className="btn primary-bg file-upload-btn"
                    onClick={handleSingleUpload}
                    disabled={props?.isEdit ? true : false}
                  >
                    {odo_images ? "Change" : "Upload"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="service_center">
                  Service Center<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="service_center"
                  id="service_center"
                  placeholder="Service Center"
                  value={service_center}
                  onChange={(ev) => setService_center(ev.target.value)}
                  disabled={props?.isEdit ? true : false}
                />
                {!service_center && service_centerErr && (
                  <div className="error-text">
                    Prefered Service Center is requried
                  </div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="service_type">
                  Service Type<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="service_type"
                  id="service_type"
                  placeholder="Service Type"
                  value={service_type}
                  onChange={(ev) => setService_type(ev.target.value)}
                  disabled={props?.isEdit ? true : false}
                />
                {!service_type && service_typeErr && (
                  <div className="error-text">Service Type is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="maint_reason">Reason</Label>
                <Input
                  type="text"
                  name="maint_reason"
                  id="maint_reason"
                  placeholder="Service Center"
                  value={maint_reason}
                  onChange={(ev) => setMaint_reason(ev.target.value)}
                  disabled={props?.isEdit ? true : false}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="images">Image</Label>
                <div className="file-upload-wrapper">
                  <input
                    ref={multiRef}
                    type="file"
                    name="images"
                    id="images"
                    className="form-own-input"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleMultiFileUpload}
                    multiple
                  />
                  <input
                    type="text"
                    name="images_name"
                    id="images_name"
                    className="form-own-input-filename form-control"
                    value={
                      images.length > 0 ? images.map((obj) => obj.img_name) : ""
                    }
                    readOnly
                  />
                  <button
                    className="btn primary-bg file-upload-btn"
                    onClick={handleMultiUpload}
                    disabled={props?.isEdit ? true : false}
                  >
                    {images.length > 0 ? "Change" : "Upload"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="maint_rating">
                  Rating<span className="color-primary">*</span>
                </Label>
                <div>
                  {props?.isEdit ? (
                    <StarRatings
                      id="maint_rating"
                      rating={maint_rating}
                      starRatedColor="rgb(212,0,42)"
                      numberOfStars={5}
                      name="maint_rating"
                      starDimension="25px"
                    />
                  ) : (
                    <StarRatings
                      id="maint_rating"
                      rating={maint_rating}
                      starRatedColor="rgb(212,0,42)"
                      changeRating={(val) => setMaint_rating(val)}
                      numberOfStars={5}
                      name="maint_rating"
                      starDimension="25px"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="maint_rating_comment">
                  Comment<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="maint_rating_comment"
                  id="maint_rating_comment"
                  placeholder="Comment"
                  value={maint_rating_comment}
                  onChange={(ev) => setMaint_rating_comment(ev.target.value)}
                  disabled={props?.isEdit ? true : false}
                />
                {!maint_rating_comment && maint_rating_commentErr && (
                  <div className="error-text">Comment is required</div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ServiceListModal;
