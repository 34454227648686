import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import { globalLoader } from "actions/commonActions";
import { updateStatusApi, roleMasterRequestApi } from "./_actions";
import { logOut } from "_shared/commonFunctions";

import UserHeader from "../../components/Headers/userHeader";
import RoleTable from "../../components/tables/roleTable";

import StatusUpdateModal from "components/Modals/statusUpdateModal";
import AddRoleModal from "components/Modals/addRoleModal";

import { useRolesData } from "hooks/useRolesData";
import { useStatusData } from "hooks/useStatusData";

const Role = (props) => {
  const { userRights } = props;
  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [openRole, setOpenRole] = useState(false);

  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_1") {
        if (item?.create === "1") {
          setCanCreate(true);
        }
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  const { data: roles, isFetching, isFetched, refetch } = useRolesData();

  globalLoader(isFetching);

  const { data: status } = useStatusData(14);

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "14",
        status: status,
        id: formData?.role_id,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        logOut(props?.history, "auth/login");
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const closeRoleModal = async (obj) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await roleMasterRequestApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        if (err?.response?.status === 401) {
          logOut(props?.history, "/auth/login");
          globalLoader(false);
        } else {
          globalLoader(false);
        }
      }
    }
    setOpenRole(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Role</h3>
                  {canCreate && (
                    <div className="col text-right">
                      <button
                        className="btn btn-sm primary-bg"
                        onClick={() => setOpenRole(true)}
                      >
                        Add New Role
                      </button>
                    </div>
                  )}
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    roles && roles.length > 0 ? (
                      <RoleTable
                        statusList={status}
                        tableData={roles}
                        updateStatus={openUpdateStatus}
                        canEdit={canEdit}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.role_status}
        />
      )}
      {openRole && (
        <AddRoleModal open={openRole} onCloseModal={closeRoleModal} />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(Role);
