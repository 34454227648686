import React, { Fragment, useState } from "react";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import _ from "lodash";
import { DefaultColumnFilter } from "./_filters";
import { Filter } from "./_filters";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";

const defaultPropGetter = () => ({});

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
}) => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (value !== initialValue) {
      updateMyData(index, id, value);
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Input
      className="table-edit mr-1"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

function TableInstance({
  columns,
  data,
  updateMyData,
  skipPageReset,
  renderRowSubComponent,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      autoResetFilters: false,
      autoResetSortBy: false,
      defaultColumn: {
        Cell: EditableCell,
        Filter: DefaultColumnFilter,
      },
      initialState: { pageIndex: 0, pageSize: 20 },
      updateMyData,
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <i className="fas fa-sort-down  color-primary"></i>
      ) : (
        <i className="fas fa-sort-up  color-primary"></i>
      )
    ) : (
      ""
    );
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <div className="pagination-own">
        <div className="pagination-item mr-3">
          <button
            className="btn btn-sm primary-bg"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
          <button
            className="btn btn-sm primary-bg"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>
        </div>
        <div className="pagination-item">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </div>
        <div className="pagination-item mx-3">
          <Input
            type="select"
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
            className="dropdown-own"
          >
            {_.range(0, pageOptions.length).map((val) => (
              <option key={val} value={val + 1}>
                {val + 1}
              </option>
            ))}
          </Input>
        </div>
        <div className="pagination-item mx-3">
          <Input
            type="select"
            value={pageSize}
            onChange={onChangeInSelect}
            className="dropdown-own"
          >
            {[20, 40, 60, 80, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </div>
        <div className="pagination-item ">
          <button
            className="btn btn-sm primary-bg"
            onClick={nextPage}
            disabled={!canNextPage}
          >
            {">"}
          </button>
          <button
            className="btn btn-sm primary-bg"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
      </div>
      <div className="table-responsive edit-own-wrapper pb-4">
        <Table
          className="align-items-center table-flush   table px-3 "
          {...getTableProps()}
        >
          <thead className="thead-light ">
            {headerGroups.map((headerGroup) => (
              <tr
                className="table-header-wrapper"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    style={{ padding: "0.5rem" }}
                    className="table-header"
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                      getColumnProps(column),
                      getHeaderProps(column),
                    ])}
                  >
                    <div className="table-header-content">
                      <div {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      <Filter column={column} />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          style={{ padding: "0.5rem" }}
                          className={`${
                            cell?.column?.Header === ""
                              ? "action-cell"
                              : cell?.column?.Header === "Ratings" ||
                                cell?.column?.Header === "Status" ||
                                cell?.column?.Header === "ID"
                              ? "rating-cell"
                              : "data-cell"
                          }`}
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent(row)}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
}

const ImgDocTable = (props) => {
  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) => (
          <div className="text-left">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v color-primary" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  onClick={() => props?.uploadDoc(cell?.row?.original)}
                >
                  Upload Document
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Car Plate",
        accessor: "plate_no",
        Cell: (cell) => (
          <div className="ml-1 font-12 color-primary cursor-pointer">
            {cell.value}
          </div>
        ),
      },
      {
        Header: "Make Model",
        accessor: "makemodel",
        Cell: (cell) => <div>{cell.value}</div>,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Image",
        accessor: "image",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Istimara",
        accessor: "istimara",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Insurance",
        accessor: "insurance",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "MVPI",
        accessor: "mvpi",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "MOT",
        accessor: "mot",
        disableFilters: true,
        disableSortBy: true,
      },
    ], // eslint-disable-next-line
    []
  );
  const [data, setData] = React.useState(() => props?.data);
  const [originalData] = React.useState(data);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);
  const resetData = () => setData(originalData);
  const saveData = () => setData(originalData);
  return (
    <Fragment>
      <div className="d-flex justify-content-end pr-4 pb-3">
        <>
          <button
            className="btn btn-sm primary-bg"
            disabled={data !== originalData ? false : true}
            onClick={resetData}
          >
            Reset Data
          </button>
          <button
            className="btn btn-sm primary-bg"
            disabled={data !== originalData ? false : true}
            onClick={saveData}
          >
            Save Data
          </button>
        </>
      </div>
      <TableInstance
        columns={columns}
        data={data}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        getCellProps={(data) => ({
          onClick: () => {
            if (data?.column?.id === "plate_no") {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />

      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.plate_no}
        />
      )}
    </Fragment>
  );
};

export default ImgDocTable;
