import React from "react";

const PrimaryButtonBordered = ({ title, onClick }) => {
  return (
    <button
      className="btn btn-md font-18 weight-600"
      style={{ border: "1px solid #d4002a", width: "100%", color: "#d4002a" }}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default PrimaryButtonBordered;
