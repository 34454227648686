import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Card, CardBody, CardHeader } from "reactstrap";

const colors = [
  "rgb(13 201 163)",
  "rgb(17, 103, 177)",
  "rgb(255 136 0)",
  "rgb(212 0 42)",
];

const FleetCard = (props) => {
  const { fleetBookingCard } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState(null);

  const [allValue, setAllValue] = useState(null);
  const [remainingValue, setRemainingValue] = useState(null);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setGraphData(); // eslint-disable-next-line
  }, [currentIndex]);

  const setGraphData = () => {
    if (fleetBookingCard) {
      const tempArr = fleetBookingCard.slice(1, fleetBookingCard.length);
      setRemainingValue(tempArr);
      setAllValue(fleetBookingCard[0]);
      const labelsArray = [];
      const dataArray = [];
      tempArr.forEach((element) => {
        labelsArray.push(element.label);
        dataArray.push(element.value);
      });
      const currData = {
        labels: labelsArray,
        datasets: [
          {
            data: dataArray,
            backgroundColor: colors,
            borderWidth: 1,
          },
        ],
      };
      setData(currData);
    }
  };

  const clickHandler = (item) => {
    // if (item.clickable === "Yes") {
    //   props?.history?.push("/admin/pre-contract");
    // } else {
    if (item.srl == "1") {
      localStorage.setItem("record_type", "a");
      props?.history?.push("/admin/vehicle_contract_driver");
    } else if (item.srl == "2") {
      localStorage.removeItem("record_type");
      props?.history?.push("/admin/service_listing");
    } else if (item.srl == "3") {
      localStorage.removeItem("record_type");
      props?.history?.push("/admin/car_replacement");
    } else if (item.srl == "4") {
      localStorage.removeItem("record_type");
      props?.history?.push("/admin/car_replacement");
    } else if (item.srl == "5") {
      localStorage.setItem("record_type", "u");
      props?.history?.push("/admin/vehicle_contract_driver");
    } else if (item.srl == "6") {
      props?.history?.push("/admin/pre-contract");
    }
    // }
  };

  return (
    <Card className="dashboard-card-own fleet-card-wrapper">
      <CardHeader className="card-header-own">
        <span className="font-15 color-primary weight-600">Current Fleet</span>
        <button
          className="btn btn-fleet medium-bg"
          onClick={() => {
            localStorage.setItem("record_type", "F");
            props?.history?.push("/admin/vehicle_contract_driver");
          }}
        >
          Manage Fleet <i className="fas fa-arrow-right"></i>
        </button>
      </CardHeader>
      <CardBody>
        {fleetBookingCard && fleetBookingCard.length > 0 ? (
          <>
            <div className="graph-content-wrapper">
              <div className="graph-wrapper">
                {data && <Pie data={data} options={options} />}
              </div>
              <div className="table-responsive fleet-table-wrapper pl-1">
                <table className="fleet-table">
                  <thead>
                    <tr className="color-primary weight-60">
                      {/* <th>{allValue?.label}</th> */}
                      <th>Total</th>
                      <th className="text-right">{allValue?.value}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {remainingValue &&
                      remainingValue.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td
                              onClick={() => clickHandler(item)}
                              style={{
                                // textDecoration:
                                //   item.clickable === "Yes" ? "underline" : "none",
                                // cursor:
                                //   item.clickable === "Yes" ? "pointer" : "auto",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className="fas fa-circle color-blue pr-2"
                                style={{ color: colors[i] }}
                              ></i>
                              {/* <button
                                className="btn btn-fleet light-bg"
                                onClick={() =>
                                  props?.history?.push(
                                    "/admin/vehicle_contract_driver"
                                  )
                                }
                              > */}
                              <span className="hover-highlight">
                                {item?.label}
                              </span>
                              {/* </button> */}
                            </td>
                            <td className="text-right">{item?.value}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between pt-4 font-12">
              <div className="pt-1"></div>
              {/* <div
                className="color-primary weight-800 cursor-pointer font-13 pt-1"
                onClick={() => props?.history?.push("/admin/car_replacement")}
              >
                Replacement/Pool Vehicles <i className="fas fa-arrow-right"></i>
              </div> */}
            </div>
          </>
        ) : (
          <div className="mt-5 pt-4 color-primary d-flex justify-content-center align-items-center">
            Data Not Available
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default FleetCard;
