import React from "react";
import TableContainer from "./_table";
import { DateRangeColumnFilter } from "./_filters";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";

const TransactionAddReceiptTable = (props) => {
  const { statusList } = props;
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cell) => (
          <div className="text-center" style={{ padding: "5px" }}>
            <input
              type="checkbox"
              checked="true"
              onClick={(event) => {
                props?.onTransactionSelect(
                  cell?.row?.original,
                  event?.target?.checked
                );
              }}
            />
          </div>
        ),
      },

      {
        Header: "Status",
        accessor: "bil_statusid",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.bil_statusid, statusList),
        disableFilters: true,
      },
      {
        Header: "Invoice Number",
        accessor: "bil_docno",
        disableFilters: true,
      },
      {
        Header: "Due Date",
        accessor: "bil_datetime",
        Cell: ({ row }) =>
          new Date(row?.original?.bil_datetime).toLocaleDateString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),

        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
        disableFilters: true,
      },

      {
        Header: "Due Amount",
        accessor: "bil_amount_due",

        disableFilters: true,
      },

      {
        Header: "ID",
        accessor: "bil_id",
        disableFilters: true,
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = React.useMemo(() => props?.tableData, []);

  return <TableContainer columns={columns} data={data} />;
};

export default TransactionAddReceiptTable;
