import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { sort } from "fast-sort";

import { billPortalListApi } from "../views/examples/_actions";

export const useInvoiceData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["invoices", currentCompany, profile_email],
    () => billPortalListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const invoicesList = data?.data?.data;
          if (invoicesList && invoicesList.length > 0) {
            let sortedInvoicesList = sort(invoicesList).desc(
              (invoice) => new Date(invoice?.bil_datetime)
            );
            return sortedInvoicesList;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    }
  );
};
