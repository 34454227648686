import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { validNumber } from "_shared/commonFunctions";

const AddTransactionModal = (props) => {
  const [bil_id, setBil_id] = useState("");
  const [bil_idErr, setBil_idErr] = useState(false);
  const [bil_plate, setBil_plate] = useState("");
  const [bil_plateErr, setBil_plateErr] = useState(false);
  const [bil_datetime, setDatetime] = useState("");
  const [bil_datetimeErr, setDatetimeErr] = useState(false);
  const [bil_cat_desc, setBil_cat_desc] = useState("");
  const [bil_cat_descErr, setBil_cat_descErr] = useState(false);
  const [bil_amount_due, setBil_amount_due] = useState("");
  const [bil_amount_dueErr, setBil_amount_dueErr] = useState(false);
  const [bil_amount_tot, setBil_amount_tot] = useState("");
  const [bil_amount_totErr, setBil_amount_totErr] = useState(false);
  const [make, setMake] = useState("");
  const [makeErr, setMakeErr] = useState(false);
  const [model, setModel] = useState("");
  const [modelErr, setModelErr] = useState(false);
  const [makemode, setMakeMode] = useState("");
  const [makemodeErr, setMakeModeErr] = useState(false);
  const bil_statusid = 1;

  const handleValidation = () => {
    let isValid = true;
    if (!bil_id) {
      isValid = false;
      setBil_idErr(true);
    }
    if (!bil_plate) {
      isValid = false;
      setBil_plateErr(true);
    }
    if (!bil_datetime) {
      isValid = false;
      setDatetimeErr(true);
    }
    if (!bil_cat_desc) {
      isValid = false;
      setBil_cat_descErr(true);
    }
    if (!bil_amount_due) {
      isValid = false;
      setBil_amount_dueErr(true);
    }
    if (!bil_amount_tot) {
      isValid = false;
      setBil_amount_totErr(true);
    }
    if (!make) {
      isValid = false;
      setMakeErr(true);
    }
    if (!model) {
      isValid = false;
      setModelErr(true);
    }
    if (!makemode) {
      isValid = false;
      setMakeModeErr(true);
    }
    return isValid;
  };

  const amountValidation = async (ev) => {
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      if (ev.target.name === "bil_amount_due") {
        setBil_amount_due(value);
      } else {
        setBil_amount_tot(value);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = {
        bil_id: bil_id,
        bil_plate: bil_plate,
        bil_datetime: bil_datetime,
        bil_cat_desc: bil_cat_desc,
        bil_amount_due: bil_amount_due,
        bil_amount_tot: bil_amount_tot,
        make: make,
        model: model,
        makemode: makemode,
        bil_statusid: bil_statusid,
      };
      props?.onCloseModal(obj);
    }
  };

  return (
    <>
      <Modal
        open={props?.open}
        center
        onClose={() => props?.onCloseModal()}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
      >
        <div className="px-3 pt-5">
          <Form>
            <div className="row">
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="bil_id">Invoice Number</Label>
                  <Input
                    type="text"
                    name="bil_id"
                    id="bil_id"
                    placeholder="Invoice Number"
                    value={bil_id}
                    onChange={(ev) => setBil_id(ev.target.value)}
                  />
                  {!bil_id && bil_idErr && (
                    <div className="error-text">Invoice Number is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="bil_plate">Plate Number</Label>
                  <Input
                    type="text"
                    name="bil_plate"
                    id="bil_plate"
                    placeholder="Plate Number"
                    value={bil_plate}
                    onChange={(ev) => setBil_plate(ev.target.value)}
                  />
                  {!bil_plate && bil_plateErr && (
                    <div className="error-text">Plate Number is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="bil_datetime">Due Date</Label>
                  <Input
                    type="date"
                    name="bil_datetime"
                    id="bil_datetime"
                    placeholder="Due Date"
                    value={bil_datetime}
                    onChange={(ev) => setDatetime(ev.target.value)}
                  />
                  {!bil_datetime && bil_datetimeErr && (
                    <div className="error-text">Due Date is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="bil_cat_desc">Category</Label>
                  <Input
                    type="text"
                    name="bil_cat_desc"
                    id="bil_cat_desc"
                    placeholder="Category"
                    value={bil_cat_desc}
                    onChange={(ev) => setBil_cat_desc(ev.target.value)}
                  />
                  {!bil_cat_desc && bil_cat_descErr && (
                    <div className="error-text">Category is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="bil_amount_due">Due Amount</Label>
                  <Input
                    type="text"
                    name="bil_amount_due"
                    id="bil_amount_due"
                    placeholder="Due Amount"
                    value={bil_amount_due}
                    onChange={(ev) => amountValidation(ev)}
                  />
                  {!bil_amount_due && bil_amount_dueErr && (
                    <div className="error-text">Due Amount is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="bil_amount_tot">Total Amount</Label>
                  <Input
                    type="text"
                    name="bil_amount_tot"
                    id="bil_amount_tot"
                    placeholder="Total Amount"
                    value={bil_amount_tot}
                    onChange={(ev) => amountValidation(ev)}
                  />
                  {!bil_amount_tot && bil_amount_totErr && (
                    <div className="error-text">Total Amount is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="make">Car Make</Label>
                  <Input
                    type="text"
                    name="make"
                    id="make"
                    placeholder="Car Make"
                    value={make}
                    onChange={(ev) => setMake(ev.target.value)}
                  />
                  {!make && makeErr && (
                    <div className="error-text">Car Make is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="model">Car Model</Label>
                  <Input
                    type="text"
                    name="model"
                    id="model"
                    placeholder="Car Model"
                    value={model}
                    onChange={(ev) => setModel(ev.target.value)}
                  />
                  {!model && modelErr && (
                    <div className="error-text">Car Model is required</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group-own">
                  <Label for="makemode">Make Model</Label>
                  <Input
                    type="text"
                    name="makemode"
                    id="makemode"
                    placeholder="Make Model"
                    value={makemode}
                    onChange={(ev) => setMakeMode(ev.target.value)}
                  />
                  {!makemode && makemodeErr && (
                    <div className="error-text">Make Model is required</div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center pt-4 col-12">
                <button
                  className="btn grey-bg"
                  onClick={() => props?.onCloseModal()}
                >
                  Cancel
                </button>
                <button className="btn primary-bg" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddTransactionModal;
