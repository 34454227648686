import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";

const ReplyNotification = (props) => {
  const { formData, onCloseModal, open } = props;
  const [message, setMessage] = useState("");
  const [messageErr, setMessageErr] = useState(false);

  const handleValidation = () => {
    let flag = true;
    if (!message) {
      setMessageErr(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      let obj = {
        message: message,
        tag: `AVIS CARE - ${formData?.typeDesc}`,
        type: formData?.type,
        user_id: [formData?.user_id],
      };
      onCloseModal(obj);
    }
  };

  return (
    <Modal
      open={open}
      center
      onClose={() => onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Send Notification</label>
      </div>
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="title">Title</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={`AVIS CARE - ${formData?.typeDesc}`}
                  readOnly
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="type">Type</Label>
                <Input
                  type="text"
                  name="type"
                  id="type"
                  value={formData?.typeDesc}
                  readOnly
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="category">Category</Label>
                <Input
                  type="text"
                  name="category"
                  id="category"
                  value="User"
                  readOnly
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="user_id">User Id</Label>
                <Input
                  type="text"
                  name="user_id"
                  id="user_id"
                  value={formData?.user_id}
                  readOnly
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group-own">
                <Label for="message">
                  Message<span className="color-primary">*</span>
                </Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder="Message"
                  value={message}
                  onChange={(ev) => setMessage(ev.target.value)}
                  className={`${!message && messageErr && "on-focus"}`}
                />
                {!message && messageErr && (
                  <div className="error-text">Message is required</div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 col-12">
              <button className="btn grey-bg" onClick={() => onCloseModal()}>
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ReplyNotification;
