import React, { useEffect, useState } from "react";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import UserHeader from "../../components/Headers/userHeader";
import ViolationTable from "../../components/tables/violationTable";

import { logOut } from "_shared/commonFunctions";
import { getPopupDetails } from "_shared/commonFunctions";

import { globalLoader } from "actions/commonActions";
import { sendNotificationApi } from "./_actions";
import { updateStatusApi } from "./_actions";

import AddViolationModal from "components/Modals/addViolationModal";
import ViolationStatusModal from "components/Modals/violationStatusModal";
import DetailsModal from "../../components/Modals/detailsModal";

import ReplyNotification from "../../components/Modals/replyNotificationModal";
import { useStatusData } from "hooks/useStatusData";
import { useTrafficViolationData } from "hooks/useTrafficViolationData";

const TrafficViolation = (props) => {
  const { userRights, currentCompany } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [openViolationModal, setOpenViolationModal] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);

  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_11") {
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  const {
    data: violationList,
    isFetching,
    isFetched,
    refetch,
  } = useTrafficViolationData(currentCompany);

  globalLoader(isFetching);

  const { data: status } = useStatusData(3);

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "3",
        status: status,
        id: formData?.V_bill_id,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        logOut(props?.history, "auth/login");
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const closeViolationModal = (obj = null) => {
    if (obj) {
    }
    setOpenViolationModal(false);
  };

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setData(getPopupDetails(obj, type));
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle("");
  };

  const openNotificationModal = (obj) => {
    let notiData = {
      user_id: obj.userid,
      tag: obj?.company_name,
      type: "3",
      typeDesc: "Violation",
    };
    setFormData(notiData);
    setNotificationModalOpen(true);
  };

  const closeNotificationModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await sendNotificationApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (err) {
        toast.error("Error while posting notification");
        console.log(err);
      }
      globalLoader(false);
      refetch();
    }
    setFormData(null);
    setNotificationModalOpen(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Traffic Violation</h3>
                  {/* <div className="col text-right">
                    <button
                      className="btn btn-sm primary-bg"
                      onClick={() => setOpenViolationModal(true)}
                    >
                      Add New Violation
                    </button>
                  </div> */}
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    violationList && violationList.length > 0 ? (
                      <ViolationTable
                        statusList={status}
                        tableData={violationList}
                        updateStatus={openUpdateStatus}
                        canEdit={canEdit}
                        customerModalOpen={customerModalOpen}
                        sendNotification={openNotificationModal}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {statusUpdate && (
        <ViolationStatusModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData}
        />
      )}

      {openViolationModal && (
        <AddViolationModal
          open={openViolationModal}
          onCloseModal={closeViolationModal}
        />
      )}
      {userModal && (
        <DetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          data={data}
          title={detailsTitle}
        />
      )}
      {notificationModalOpen && (
        <ReplyNotification
          open={notificationModalOpen}
          onCloseModal={closeNotificationModal}
          formData={formData}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(TrafficViolation);
