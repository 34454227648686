import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import AddAutoInvoiceModal from "../../components/Modals/addAutoInvoiceModal";
import UserHeader from "../../components/Headers/userHeader";
import AutoInvoiceTable from "../../components/tables/autoInvoiceTable";
import { globalLoader } from "actions/commonActions";

import {
	updateStatusApi,
	addAutoInvoiceApi,
	downloadInvoiceDoc,
	autoInvoiceListApi,
} from "./_actions";
import { useStatusData } from "hooks/useStatusData";
import { useAutoInvoiceData } from "hooks/useAutoInvoiceData";

import { downloadExcel } from "utils/exportUtils";
import { downloadFile } from "actions/commonActions";

const AutoInvoices = (props) => {
	const { userRights, currentCompany } = props;
	const [canEdit, setCanEdit] = useState(false);
	const [formData, setFormData] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const [chauffeurRateModalOpen, setChauffeurRateModalOpen] = useState(false);
	const [reloadDataFlag, setReloadDataFlag] = useState(false); // Flag to trigger data reload

	const editData = (rowData) => {
		// Implement your logic for editing here
		setIsEdit(true);
		setFormData(rowData);
		setOpenAddMaintainance(true);
	};

	useEffect(() => {
		userRights?.forEach((item) => {
			if (item?.menu_type_id === "module_1") {
				if (item?.update === "1") {
					setCanEdit(true);
				}
			}
		});
	}, [userRights]);

	const {
		data: invoices,
		isFetching,
		isFetched,
		refetch,
	} = useAutoInvoiceData(currentCompany, reloadDataFlag); // Pass reloadDataFlag to trigger data reload

	const { data: status } = useStatusData(7);
	useEffect(() => {
		refetch();
	}, [currentCompany]);
	const openInvoiceImage = async (val, _type) => {
		const res = await downloadInvoiceDoc(val);
		const fileUrl = res?.data.data[0].doc_url;
		if (fileUrl !== "" && fileUrl !== null) {
			downloadFile(fileUrl, `${val}.pdf`);
		} else {
			toast.error("Invoice File not found");
		}
	};

	const openChauffeurRateModal = (obj = null) => {
		console.log("obj", obj);
		if (obj) {
			setFormData(obj);
			setIsEdit(true);
		}
		setOpenAddMaintainance(true);
	};

	const [openAddMaintainance, setOpenAddMaintainance] = useState(false);
	const userCreateHandler = async (obj = null) => {
			globalLoader(true);
			try {
				let res;
					res = await autoInvoiceListApi(obj);
				if (res?.data?.status === 0) {
					globalLoader(false);
					refetch();
					// toast.success(res?.data?.message);
				} else {
					console.log(res);
					toast.error("Update unsuccessful. Please try again");
				}
			} catch (err) {
				globalLoader(false);
			}
		
		setOpenAddMaintainance(false)
	


		// setOpenAddMaintainance(false)
		// if (data) {
		// 	globalLoader(true);
		// 	try {
		// 		await autoInvoiceListApi(data);
		// 		toast.success("Invoice successfully added/updated");
		// 		setOpenAddMaintainance(false); // Close the modal
		// 	} catch (err) {
		// 		toast.error("Error occurred while adding/updating invoice");
		// 		console.log(err);
		// 	}
		// 	globalLoader(false);
		// }
		// setOpenAddMaintainance(false)
	};
	return (
		<>
			<UserHeader />

			<Container className="mt--8" fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-0">
								<Row className="align-items-center">
									<h3 className="mb-0 color-primary">Auto Invoices</h3>
								
								</Row>
							</CardHeader>
							{!isFetching && (
								<div>
									{isFetched ? (
										invoices && invoices?.data?.data?.length > 0 ? (
											<AutoInvoiceTable
												statusList={status}
												tableData={invoices.data.data}
												canEdit={canEdit}
												editData={openChauffeurRateModal}
												sendNotification={(obj) => console.log("")}
												openImage={openInvoiceImage}
												onCloseModal={userCreateHandler}
											/>
										) : (
											<div className="px-3 pt-3 pb-3">
												<div className="no-data-available color-primary">
													Data Not Available
												</div>
											</div>
										)
									) : null}
								</div>
							)}
						</Card>
					</div>
				</Row>

				{openAddMaintainance && (
					<AddAutoInvoiceModal
						open={openAddMaintainance}
						onCloseModal={userCreateHandler}
						data={formData}
						reloadData={() => setReloadDataFlag(!reloadDataFlag)} // Pass a function to reload data
					/>
				)}
			</Container>
		</>
	);
};

const mapStateProps = (state) => ({
	currentCompany: state?.common?.currentCompany?.value,
	userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(AutoInvoices);
