import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const Details = ({ open, onCloseModal, data, title }) => {
  return (
    <Modal
      open={open}
      onClose={() => onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      center
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">
          <span style={{ textTransform: "capitalize", whiteSpace: "pre" }}>
            {title}{" "}
          </span>{" "}
          Details
        </label>
      </div>

      <ul className="details-wrapper list-unstyled" style={{ width: "500px" }}>
        {data &&
          data.length > 0 &&
          data.map(
            (item, index) =>
              item.value && (
                <li className="row pb-3" key={index}>
                  <div className="col-6 color-primary text-right weight-600">
                    {item?.label}
                  </div>
                  <div className="col-6">{item?.value}</div>
                </li>
              )
          )}
      </ul>
    </Modal>
  );
};

export default Details;
