import React from "react";
import Stepper from "react-stepper-horizontal";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
  width: "100%",
  backgroundColor: "rgba(212, 0, 42, 0.05)",
  padding: "0 10px 10px 10px",
};

const HorizontalStepper = ({ activeStep }) => {
  return (
    <div style={styles}>
      <div>
        <Stepper
          steps={[
            {
              title: "Locations",
            },
            { title: "Choose Vehicle" },
            { title: "Extra Services" },
            { title: "Personal Information" },
          ]}
          activeStep={activeStep}
          activeColor="#d4002a"
          completeColor="#d4002a"
          activeTitleColor="#6B87A4"
          completeTitleColor="#6B87A4"
          circleFontColor="#FFF"
          defaultBorderColor="#d4002a"
          defaultBorderStyle="#d4002a"
          completeBarColor="#d4002a"
        />
      </div>
    </div>
  );
};

export default HorizontalStepper;
