import React from "react";

const CarBadge = ({ icon, title }) => {
  return (
    <div
      style={{
        border: ".02px solid #ddd",
        color: "#5e72e4",
        padding: "3px",
        margin: "5px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={icon}
        height="20"
        width="20"
        alt="badge"
        style={{ padding: "3px", marginRight: "5px" }}
      />
      <p className="font-13 weight-600 mb-2px" style={{ color: "#333" }}>
        {title}
      </p>
    </div>
  );
};

export default CarBadge;
