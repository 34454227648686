import React, { useEffect, useRef, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { downloadFile } from "actions/commonActions";
import { Document, pdfjs, Page } from "react-pdf";

const ViewImageModal = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const imgRef = useRef();
  const [imgArr, setImgArr] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [finalIndex, setFinalIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [view, setView] = useState(false);

  useEffect(() => {
    if (props?.data) {
      setPropsToData();
    }
    // eslint-disable-next-line
  }, []);

  const setPropsToData = () => {
    setImgArr(props?.data);
    setFinalIndex(props?.data.length - 1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    if (!view) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  };

  const changePage = (offset) => {
    let newPage = pageNumber + offset;
    setPageNumber(newPage);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleDownload = () => {
    if (props?.imgType === "Invoice File") {
      const url = props?.data;
      downloadFile(url, `${props?.imgType}.pdf`);
    } else {
      const url = imgArr[currentIndex]?.img_url;
      const type = url.split(/[#?]/)[0].split(".").pop().trim();
      downloadFile(url, `${props?.imgType}.${type}`);
    }
  };

  const getType = (obj) => {
    if (props?.imgType !== "Invoice File") {
      const type = obj.split(/[#?]/)[0].split(".").pop().trim();
      return type;
    } else {
      return "pdf";
    }
  };

  const handleImgErr = (ev) => {
    ev.target.src = require("../../assets/img/icons/common/broken.png").default;
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "img-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">View Image</label>
      </div>
      <div className="py-5">
        <div className="color-primary text-center weight-800">
          {props?.imgType}
        </div>
        <div className="image-container pb-5">
          <div className="pr-3">
            <button
              className={`btn btn-sm primary-bg ${
                currentIndex === 0 ? "invisible" : "visible"
              }`}
              onClick={() => setCurrentIndex(currentIndex - 1)}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
          </div>
          {imgArr &&
            imgArr.length > 0 &&
            (getType(imgArr[currentIndex]?.img_url) === "PDF" ||
            getType(imgArr[currentIndex]?.img_url) === "pdf" ? (
              <div className="document-container mx-2">
                <Document
                  crossOrigin="anonymous"
                  className="modal-image"
                  file={{ url: ` ${imgArr[currentIndex]?.img_url}` }}
                  onLoadSuccess={(ev) => {
                    if (!view) {
                      onDocumentLoadSuccess(ev);
                      setView(true);
                    }
                  }}
                >
                  <Page className="modal-image" pageNumber={pageNumber} />
                </Document>
                <div className="d-flex justify-content-around align-items-center pt-5">
                  <button
                    className="btn primary-bg width-135 btn-sm"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    Previous Page
                  </button>
                  <span className="color-primary px-3">
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </span>
                  <button
                    className="btn primary-bg width-135 btn-sm"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    Next Page
                  </button>
                </div>
              </div>
            ) : (
              <div className="modal-image-container mx-2">
                <img
                  crossOrigin="anonymous"
                  ref={imgRef}
                  id="displayImg"
                  className="modal-image"
                  src={`${imgArr[currentIndex]?.img_url}`}
                  alt={`${props?.imgType}`}
                  onError={handleImgErr}
                />
              </div>
            ))}
          <div className="pl-3">
            <button
              className={`btn btn-sm primary-bg ${
                currentIndex === finalIndex ? "invisible" : "visible"
              }`}
              onClick={() => setCurrentIndex(currentIndex + 1)}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="text-center">
          {currentIndex + 1}/{finalIndex + 1}
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button className="btn primary-bg" onClick={handleDownload}>
            Download
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewImageModal;
