import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { preContractApi } from "../views/examples/_actions";

export const usePreContractData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["pre-contract", currentCompany, profile_email],
    () => preContractApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data?.data?.data;
      },
    }
  );
};
