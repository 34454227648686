import React, { useState } from "react";
import "react-responsive-modal/styles.css";

import { Modal } from "react-responsive-modal";
import VehicleAssignmentModal from "./vehicleAssignmentModal";
import ViewImageModal from "./viewImageModal";

import { globalLoader } from "actions/commonActions";

import { useVehicleDetailsData } from "hooks/useVehicleDetailsData";

const VehicleDetailsModal = ({ plateNo, open, onCloseModal }) => {
  const [vehicleAssign, setVehicleAssign] = useState(false);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [imgData, setImgData] = useState(null);
  const imgType = "Vehicle Document";

  const {
    data: vehicle,
    isLoading,
    isFetched,
  } = useVehicleDetailsData(plateNo);

  if (isLoading) {
    globalLoader(true);
  }

  if (!isLoading && isFetched) {
    globalLoader(false);
  }

  const closeVehicleAssign = (obj = null) => {
    if (obj) {
    }
    setVehicleAssign(false);
  };

  const viewImage = () => {
    let arr = [];
    vehicle?.documents?.forEach((img) => {
      let temp = { img_url: img?.doc_url };
      arr.push(temp);
    });
    setImgData(arr);
    setViewImageModal(true);
  };
  const closeImageModal = () => {
    setViewImageModal(false);
    setImgData(null);
  };
  return (
    <>
      <Modal
        open={open}
        center
        onClose={() => onCloseModal()}
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
        styles={{ minWidth: "500px" }}
      >
        {isFetched && vehicle ? (
          <div>
            <div className="popup-header-bar">
              <label className="text-center weight-800">Vehicle Details</label>
            </div>
            <ul className="details-wrapper list-unstyled pt-3">
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Plate Number
                </div>
                <div className="col-6">{vehicle?.plate_no}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Make
                </div>
                <div className="col-6">{vehicle?.maker_desc}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Model
                </div>{" "}
                <div className="col-6">{vehicle?.makertype_desc}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Variant
                </div>
                <div className="col-6">{vehicle?.model_desc}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Body Type
                </div>
                <div className="col-6">{vehicle?.body_type}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Transmission
                </div>
                <div className="col-6">{vehicle?.transmission}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Number of Doors
                </div>
                <div className="col-6">{vehicle?.no_of_doors}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Number of Seats
                </div>
                <div className="col-6">{vehicle?.no_of_seats}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Fuel Type
                </div>
                <div className="col-6">{vehicle?.fuel_desc}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Number of Gears
                </div>
                <div className="col-6">{vehicle?.gear}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  MVPI Date
                </div>
                <div className="col-6">{vehicle?.mvpi_date}</div>
              </li>{" "}
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Year
                </div>
                <div className="col-6">{vehicle?.year}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  VIN Number
                </div>
                <div className="col-6">{vehicle?.vin_no}</div>
              </li>
              <li className="row pb-1">
                <div className="col-6 color-primary text-right weight-600">
                  Color
                </div>
                <div className="col-6">{vehicle?.color_desc}</div>
              </li>
              {vehicle?.documents.length > 0 &&
                vehicle?.documents[0] !== null && (
                  <li className="d-flex justify-content-center my-3">
                    {/* <div className="col-12 color-primary text-center weight-600 cursor-pointer pt-3">
                View Documents
              </div> */}

                    <button
                      onClick={viewImage}
                      className="btn btn-sm primary-bg"
                    >
                      View Documents
                    </button>
                  </li>
                )}
            </ul>
            <div className="d-flex justify-content-center my-5">
              <img
                alt="Vehicle"
                src={vehicle?.image_url}
                style={{ width: "80%", height: "250px" }}
              />
            </div>
          </div>
        ) : (
          <div>
            {isFetched && !vehicle && (
              <h3 style={{ textAlign: "center", color: "red" }}>
                Vehicle Details Not Found
              </h3>
            )}
          </div>
        )}
        {/* <div className="d-flex justify-content-center pt-3 mt-3">
          <div
            className="btn btn-sm primary-bg"
            onClick={() => setVehicleAssign(true)}
          >
            New Vehicle Assignment
          </div>
        </div> */}
      </Modal>
      {viewImageModal && (
        <ViewImageModal
          open={viewImageModal}
          onCloseModal={closeImageModal}
          data={imgData}
          imgType={imgType}
        />
      )}
      {vehicleAssign && (
        <VehicleAssignmentModal
          open={vehicleAssign}
          onCloseModal={closeVehicleAssign}
        />
      )}
    </>
  );
};

export default VehicleDetailsModal;
