import DescModal from "components/Modals/descModal";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";
import React, { useMemo, useState } from "react";
import StarRatings from "react-star-ratings";
import { SelectColumnFilter } from "./_filters";
import { DateRangeColumnFilter } from "./_filters";
import TableContainer from "./_table";

const RoadAssistanceTable = (props) => {
  const { searchId, searchFilter, customerModalOpen } = props;
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const handleMore = (a, b) => {
    setTitle(a);
    setDesc(b);
    setOpenModal(true);
  };

  const closeView = () => {
    setTitle(null);
    setDesc(null);
    setOpenModal(false);
  };

  const sortItems = (prev, curr, columnId) => {
    if (
      prev.original[columnId].trim().toLowerCase() >
      curr.original[columnId].trim().toLowerCase()
    ) {
      return 1;
    } else if (
      prev.original[columnId].trim().toLowerCase() <
      curr.original[columnId].trim().toLowerCase()
    ) {
      return -1;
    } else {
      return 0;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "type",
        accessor: "rsa_service_type",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "rsa_status",
        Cell: ({ row }) =>
          row?.original?.rsa_status === "1" ? "Completed" : "Pending",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              customerModalOpen(row?.original, "company")
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              customerModalOpen(row?.original, "customer")
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },
      {
        Header: "Plate No",
        accessor: "rsa_plate_no",
        Cell: ({ row }) => (
          <div className="color-primary cursor-pointer">
            {row?.original?.rsa_plate_no}
          </div>
        ),
      },
      {
        Header: "Requested at",
        accessor: "rsa_requested_date",
        Cell: ({ row }) =>
          new Date(row?.original?.rsa_requested_date).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Location",
        accessor: "rsa_pick_location_desc",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.rsa_pick_location_desc.length > 15
                  ? "view-more"
                  : ""
              }`}
            >
              <span>{row?.original?.rsa_pick_location_desc}</span>
            </div>
            {row?.original?.rsa_pick_location_desc.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Location", row?.original?.rsa_pick_location_desc)
                }
              >
                View More
              </div>
            )}
          </>
        ),
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Mobile",
        accessor: "rsa_mobile",
        disableFilters: true,
      },
      {
        Header: "Remarks",
        accessor: "rsa_remark",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.rsa_remark.length > 15 ? "view-more" : ""
              }`}
            >
              <span>{row?.original?.rsa_remark}</span>
            </div>
            {row?.original?.rsa_remark.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() => handleMore("Remarks", row?.original?.rsa_remark)}
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Rating",
        accessor: "rsa_rating",
        Cell: ({ row }) =>
          row?.original?.rsa_rating !== "" && (
            <StarRatings
              rating={parseInt(row?.original?.rsa_rating)}
              starRatedColor="rgb(212,0,42)"
              starDimension="10px"
              starSpacing="1px"
            />
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ID",
        accessor: "rsa_service_id",
      },
      {
        Header: "Comments",
        accessor: "rsa_rating_desc",
        Cell: ({ row }) => (
          <>
            <div
              className={`${
                row?.original?.rsa_rating_desc.length > 15 ? "view-more" : ""
              }`}
            >
              <span>{row?.original?.rsa_rating_desc}</span>
            </div>
            {row?.original?.rsa_rating_desc.length > 15 && (
              <div
                className="font-12 color-primary cursor-pointer"
                onClick={() =>
                  handleMore("Coments", row?.original?.rsa_rating_desc)
                }
              >
                View More
              </div>
            )}
          </>
        ),
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line
    []
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);

  return (
    <>
      <TableContainer
        columns={columns}
        data={data}
        searchFilter={searchFilter}
        searchId={searchId}
        getCellProps={(data) => ({
          onClick: () => {
            if (data?.column?.id === "rsa_plate_no") {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />
      {openModal && (
        <DescModal
          open={openModal}
          onCloseModal={closeView}
          title={title}
          desc={desc}
        />
      )}

      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.rsa_plate_no}
        />
      )}
    </>
  );
};

export default RoadAssistanceTable;
