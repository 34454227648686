import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import { userPortalListApi } from "../views/examples/_actions";

export const useUserData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["users", currentCompany, profile_email],
    () => userPortalListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const users = data?.data?.data;
          if (users) {
            let sortedUsers = sort(users).desc((user) =>
              parseInt(user?.user_id)
            );
            return sortedUsers;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    }
  );
};
