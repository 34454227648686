import { postRequest } from "../actionTypes/axiosUtility";
import { GLOBAL_LOADER } from "actionTypes/types";
import { GLOBAL_API } from "../actionTypes/types";
import axios from "axios";
import qs from "query-string";
import { appStore } from "store";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import CryptoJs from "crypto-js";
import {
  TOKEN_USERNAME,
  TOKEN_PASSWORD,
  TOKEN_GRANT_TYPE,
  SECRET,
  ROLE_API_DATA,
  PROFILE_API_DATA,
  LISTING_TABLE_DATA,
} from "actionTypes/types";
import { COMPANY_API_DATA } from "actionTypes/types";
import { CURRENT_COMPANY } from "actionTypes/types";
import { getRequest } from "../actionTypes/axiosUtility";

export const callTokenApi = async () => {
  let obj = {
    username: CryptoJs.AES.decrypt(TOKEN_USERNAME, SECRET).toString(
      CryptoJs.enc.Utf8
    ),
    password: CryptoJs.AES.decrypt(TOKEN_PASSWORD, SECRET).toString(
      CryptoJs.enc.Utf8
    ),
    grant_type: CryptoJs.AES.decrypt(TOKEN_GRANT_TYPE, SECRET).toString(
      CryptoJs.enc.Utf8
    ),
  };
  return postRequest(`${GLOBAL_API}/token`, qs.stringify(obj));
};

export const globalLoader = (value) => {
  appStore.dispatch({
    type: GLOBAL_LOADER,
    payload: value,
  });
};

export const roleApiData = (value) => {
  appStore.dispatch({
    type: ROLE_API_DATA,
    payload: value,
  });
};

export const profileApiData = (value) => {
  appStore.dispatch({
    type: PROFILE_API_DATA,
    payload: value,
  });
};

export const companyApiData = (value) => {
  appStore.dispatch({
    type: COMPANY_API_DATA,
    payload: value,
  });
};
export const listingTableData = (value) => {
  appStore.dispatch({
    type: LISTING_TABLE_DATA,
    payload: value,
  });
};

export const currentCompanyData = (value) => {
  appStore.dispatch({
    type: CURRENT_COMPANY,
    payload: value,
  });
};

export const downloadFile = async (url, filename) => {
  let res = await axios.get(url, { responseType: "blob" });
  if (res?.status === 200) {
    fileDownload(res?.data, filename);
  } else {
    toast.error("Failed to download");
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // reader.onload = () => resolve(reader.result);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });

export const getCountryList = () => {
  return getRequest(`${GLOBAL_API}/CountryList`);
};

export const joinAvisApi = (obj) => {
  return postRequest(`${GLOBAL_API}/Portal/JoinAVISProfileUpdatePortal`, obj);
};

export const getRateTypeApi = () => {
  return getRequest(`${GLOBAL_API}/Portal/ChauffeurPortalRatetypeList`);
};

export const getBodyTypeApi = () => {
  return getRequest(`${GLOBAL_API}/Portal/BodyCarList`);
};
