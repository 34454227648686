import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { FaTimes } from "react-icons/fa"; // Import the X icon from react-icons/fa

import { addAutoInvoiceApi, autoInvoiceListApi } from "views/examples/_actions";

const AddAutoInvoiceModal = (props) => {
  const [customerCodes, setCustomerCodes] = useState([]);
  const [customerEmails, setCustomerEmails] = useState([]);
  const [status, setStatus] = useState("1");
  const [exclusiveStatus, setExclusiveStatus] = useState("0");

  useEffect(() => {
    // Check if data prop exists and set the state variables accordingly
    if (props.data) {
      const { cust_code, cust_emails, status, exclusive_status } = props.data;
      setCustomerCodes(cust_code.split(","));
      setCustomerEmails(cust_emails.split(","));
      setStatus(status || "1");
      setExclusiveStatus(exclusive_status || "0");
    }
  }, [props.data]);

  const addNewEmailInput = () => {
    setCustomerEmails([...customerEmails, ""]);
  };

  const handleCustomerEmailChange = (index, value) => {
    const updatedEmails = [...customerEmails];
    updatedEmails[index] = value;
    setCustomerEmails(updatedEmails);
  };

  const handleDeleteEmail = (index) => {
    const updatedEmails = [...customerEmails];
    updatedEmails.splice(index, 1);
    setCustomerEmails(updatedEmails);
  };
  const submitHandler = async (e) => {
    props.onCloseModal();
    e.preventDefault();
    try {
        const combinedEmails = customerEmails.join(",");
        const res = await addAutoInvoiceApi({
            cust_code: customerCodes.join(","),
            cust_emails: combinedEmails,
            status: status,
            exclusive_status: exclusiveStatus,
        });

        // Assuming `res.data` contains the newly added or modified item
        const newItem = res.data;

        // Update the table data locally without reloading
        // Append the new item or replace the modified item in the table data
        // Ensure the table data maintains its original order

        // For example, if you have a state variable `tableData` to store the table data
        const updatedTableData = [...props.tableData];

        // Find the index of the modified item or append the new item
        const index = updatedTableData.findIndex(item => item.id === newItem.id);
        if (index !== -1) {
            // Replace the modified item
            updatedTableData[index] = newItem;
        } else {
            // Append the new item
            updatedTableData.push(newItem);
        }

        // Update the table data state
        props.setTableData(updatedTableData); // Use the appropriate function to set table data

        // Close the modal
        props.onCloseModal();

        // Show success message
    } catch (err) {
        console.error("Error adding auto invoice:", err);
    }
};

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Post Request</label>
      </div>
      <div className="px-3 pt-5">
        <Form onSubmit={submitHandler}>
          {customerEmails.map((email, index) => (
            <FormGroup key={index} className="d-flex align-items-center">
              <Label for={`customerEmail${index + 1}`} className="flex-grow-1">
                Customer Email {index + 1}
              </Label>
              <Input
                type="text"
                name={`customerEmail${index + 1}`}
                id={`customerEmail${index + 1}`}
                value={email}
                onChange={(e) => handleCustomerEmailChange(index, e.target.value)}
                required
              />
              <Button
                color="link"
                className="ml-2"
                onClick={() => handleDeleteEmail(index)}
              >
                <FaTimes />
              </Button>
            </FormGroup>
          ))}
          <Button color="btn primary-bg" onClick={addNewEmailInput}>
            Add Email
          </Button>
          <FormGroup style={{ marginTop: 20 }}>
            <Label for="status">Status</Label>
            <Input
              type="select"
              name="status"
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="exclusiveStatus">Exclusive Status</Label>
            <Input
              type="select"
              name="exclusiveStatus"
              id="exclusiveStatus"
              value={exclusiveStatus}
              onChange={(e) => setExclusiveStatus(e.target.value)}
            >
              <option value="0">Non-Exclusive</option>
              <option value="1">Exclusive</option>
            </Input>
          </FormGroup>
          <div className="d-flex justify-content-center pt-4">
            <Button color="btn grey-bg" onClick={() => props?.onCloseModal()}>
              Cancel
            </Button>
            <Button color="btn primary-bg" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddAutoInvoiceModal;
