import { intervalToDuration, format } from "date-fns";

export const getNoOfDaysFromCurrentDate = (date) => {
  const duration = intervalToDuration({
    start: date,
    end: new Date(),
  });
  if (duration.months > 0) {
    return 8;
  } else {
    return duration.days;
  }
};

export const formatDate = (date, dateFormat = "yyyy-dd-MM HH:mm:ss") => {
  console.log("🚀 ~ file: dateUtils.jsx:16 ~ formatDate ~ date:", date);

  return format(new Date(date), dateFormat);
};
