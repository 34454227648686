import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { listingTableData } from "actions/commonActions";
import { globalLoader } from "actions/commonActions";

import TaskCard from "../components/Cards/dashboard/taskCard";
import ReportCard from "../components/Cards/dashboard/reportCard";
import BookingCard from "../components/Cards/dashboard/bookingCard";
import FleetCard from "../components/Cards/dashboard/fleetCard";
import ChauffeurCard from "../components/Cards/dashboard/chaufeurCard";
import AssistanceCard from "../components/Cards/dashboard/assistanceCard";
import GraphCard from "../components/Cards/dashboard/graphCard";
import StatsCard from "../components/Cards/dashboard/statsCard";
import InvoiceCard from "../components/Cards/dashboard/invoiceCard";

import { useDashboardData } from "hooks/useDashboardData";

const Dashboard = (props) => {
  const { userMenu = null, currentCompany } = props;

  const [accident, setAccident] = useState(false);
  const [damage, setDamage] = useState(false);
  const [violation, setViolation] = useState(false);
  const [authorization, setAuthorization] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [notification, setNotification] = useState(false);
  const [rent, setRent] = useState(false);
  const [fleet, setFleet] = useState(false);
  const [chauffeur, setChauffeur] = useState(false);
  const [road, setRoad] = useState(false);
  const [transc, setTransc] = useState(false);
  const [listTable, setListTable] = useState(false);

  useEffect(() => {
    setPropToState(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    userMenu?.forEach((type) => {
      if (type?.menu_id === "24") setListTable(true);
    });
  }, [userMenu]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: dashboard,
    isLoading,
    refetch,
  } = useDashboardData(currentCompany);

  if (isLoading) {
    globalLoader(true);
  }

  if (dashboard && dashboard.length > 0) {
    globalLoader(false);
  }

  useEffect(() => {
    if (dashboard && dashboard.length > 0) {
      listingTableData(dashboard[0]?.maintenance_request);
    }
  }, [dashboard]);

  const setPropToState = () => {
    userMenu?.forEach((menu) => {
      if (menu?.menu_id === "6") setTransc(true);
      if (menu?.menu_id === "7") setDamage(true);
      if (menu?.menu_id === "8") setFeedback(true);
      if (menu?.menu_id === "10") setAuthorization(true);
      if (menu?.menu_id === "11") setAccident(true);
      if (menu?.menu_id === "12") setViolation(true);
      if (menu?.menu_id === "13") setRoad(true);
      if (menu?.menu_id === "14") setRent(true);
      if (menu?.menu_id === "15") setChauffeur(true);
      if (menu?.menu_id === "22") setNotification(true);
      if (menu?.menu_id === "24") setListTable(true);
      if (menu?.menu_id === "27") setFleet(true);
    });
  };

  return (
    <>
      <div
        className={`dashboard-main-banner ${!listTable ? "main-full" : ""}`}
      ></div>
      <div
        className={`dashboard-main-wrapper ${!listTable ? "main-full" : ""}`}
      >
        {dashboard && dashboard.length > 0 ? (
          userMenu ? (
            <>
              <div className="row pb-3">
                {authorization || feedback || notification ? (
                  <div className="col-12 col-xl-5 pb-2 pr-xl-1">
                    <TaskCard
                      authorization={authorization}
                      feedback={feedback}
                      notification={notification}
                      {...props}
                      data={
                        dashboard && dashboard[0]
                          ? dashboard[0]?.report_cards[0]
                          : null
                      }
                    />
                  </div>
                ) : null}
                {accident && (
                  <div className="col-4 col-xl pb-2 px-xl-1">
                    <ReportCard
                      {...props}
                      flag={accident}
                      count={
                        dashboard
                          ? dashboard[0]?.report_cards[0]?.accident_count
                          : null
                      }
                      type="Accidents"
                      label={
                        dashboard
                          ? dashboard[0]?.report_cards[0]?.accident_label
                          : null
                      }
                      imgSrc={
                        require("../assets/img/icons/common/2Accident.svg")
                          .default
                      }
                    />
                  </div>
                )}
                {damage && (
                  <div className="col-4 col-xl pb-2 px-xl-1">
                    <ReportCard
                      {...props}
                      count={
                        dashboard
                          ? dashboard[0]?.report_cards[0]?.damage_count
                          : null
                      }
                      label={
                        dashboard
                          ? dashboard[0]?.report_cards[0]?.damage_label
                          : null
                      }
                      type="Self Inspection"
                      flag={damage}
                      imgSrc={
                        require("../assets/img/icons/common/2Damage.svg")
                          .default
                      }
                    />
                  </div>
                )}
                {violation && (
                  <div className="col-4 col-xl pb-2 pl-xl-1">
                    <ReportCard
                      {...props}
                      count={
                        dashboard
                          ? dashboard[0]?.report_cards[0]?.violation_count
                          : null
                      }
                      label={
                        dashboard
                          ? dashboard[0]?.report_cards[0]?.violation_label
                          : null
                      }
                      type="Traffic Violations"
                      flag={violation}
                      imgSrc={
                        require("../assets/img/icons/common/2TrafficViolation.svg")
                          .default
                      }
                    />
                  </div>
                )}
              </div>
              <div className="row py-2">
                {rent && (
                  <div className="col-12 col-xl-7 py-2 pr-xl-1">
                    <BookingCard
                      {...props}
                      carBookingCard={
                        dashboard ? dashboard[0]?.car_booking_card : null
                      }
                      flag={rent}
                      searchId="rent_staus"
                      searchFilter="1"
                    />
                  </div>
                )}
                {fleet && (
                  <div
                    className={`col-12 col-xl-5 py-2 ${
                      rent ? "pl-xl-1" : "pl-xl-3"
                    }`}
                  >
                    <FleetCard
                      {...props}
                      fleetBookingCard={
                        dashboard ? dashboard[0]?.current_fleet : null
                      }
                    />
                  </div>
                )}
              </div>
              <div className="row">
                {chauffeur && (
                  <div className="col-12 col-xl-7 py-2 pr-xl-1">
                    <ChauffeurCard
                      {...props}
                      carBookingCard={
                        dashboard ? dashboard[0]?.chauffeur_booking_card : null
                      }
                      searchId="cha_status"
                      searchFilter="1"
                    />
                  </div>
                )}
                {road && (
                  <div
                    className={`col-12 col-xl-5 py-2 ${
                      chauffeur ? "pl-xl-1" : "pl-xl-3"
                    }`}
                  >
                    <AssistanceCard
                      {...props}
                      assistanceCard={
                        dashboard ? dashboard[0]?.roadside_assistance : null
                      }
                    />
                  </div>
                )}
              </div>
              <div className="row pt-3">
                <div className="col-12 color-primary weight-600 font-16">
                  Insights
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-2">
                  <GraphCard
                    graphCard={dashboard ? dashboard[0]?.insight_details : null}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-xl py-2 pr-1">
                  <StatsCard
                    imgSrc="contracts"
                    statsCard={
                      dashboard ? dashboard[0]?.contracts_details : null
                    }
                    type="Live Contracts"
                  />
                </div>
                <div className="col-4 col-xl py-2 px-1">
                  <StatsCard
                    imgSrc="vehicle"
                    statsCard={
                      dashboard ? dashboard[0]?.vehicles_details : null
                    }
                    type="Vehicles"
                  />
                </div>
                <div className="col-4 col-xl py-2 pl-1 pr-xl-1">
                  <StatsCard
                    imgSrc="driver"
                    statsCard={dashboard ? dashboard[0]?.drivers_details : null}
                    type="Drivers"
                  />
                </div>
                <div className="col-4 col-xl py-2 pr-1 pl-xl-1">
                  <StatsCard
                    imgSrc="accidents"
                    statsCard={
                      dashboard ? dashboard[0]?.accidents_details : null
                    }
                    type="Accidents"
                  />
                </div>
                <div className="col-4 col-xl py-2 pl-1">
                  <StatsCard
                    imgSrc="money"
                    statsCard={dashboard ? dashboard[0]?.revenue_details : null}
                    type="Revenue"
                  />
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-12 ">
                  <InvoiceCard
                    {...props}
                    type="Pending"
                    invoices={
                      dashboard
                        ? dashboard[0]?.pending_invoice?.slice(0, 20)
                        : null
                    }
                    transc={transc}
                    //currentCompany={currentCompany}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="px-3 pt-3 pb-3">
              <div
                className=" no-data-available"
                style={{ border: "none", color: "white" }}
              >
                User doesn't have any permission
              </div>
            </div>
          )
        ) : null}
      </div>
    </>
  );
};

const mapStateProps = (state) => ({
  userMenu: state?.common?.roleData?.user_menu,
  userRights: state?.common?.roleData?.user_rights,
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateProps)(Dashboard);
