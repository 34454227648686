import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { getBodyTypeApi } from "actions/commonActions";
import { getRateTypeApi } from "actions/commonActions";
import { sort } from "fast-sort";

import { chauffeurRateApi } from "../views/examples/_actions";

export const useChaufferRateData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["chauffer-rate", currentCompany, profile_email],
    () => chauffeurRateApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const rates = data?.data?.data;
          let sortedRates = sort(rates).desc((u) => u?.chr_id);
          return sortedRates;
        } else {
          return null;
        }
      },
    }
  );
};

export const useChaufferRateTypeData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["chauffer-rate-type", profile_email],
    () => getRateTypeApi(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        const ratesTypes = data?.data?.data;
        let mappedTypes = ratesTypes.map((item) => {
          return { value: item?.rate_type, label: item?.rate_type_desc };
        });
        return mappedTypes;
      },
    }
  );
};

export const useBodyTypeData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["body-type", profile_email], () => getBodyTypeApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      const bodyTypes = data?.data?.data;
      let mappedBodies = bodyTypes.map((item) => {
        return { value: item?.bdy_id, label: item?.bdy_desc };
      });
      return mappedBodies;
    },
  });
};
