import React, { useState, useRef } from "react";
import { matchSorter } from "match-sorter";
import { selectFilter } from "./_selectFilter";
import Flatpickr from "react-flatpickr";

export const Filter = ({ column }) => {
  return (
    <div className="filter-wrapper">
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export const EditTableFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <input
      className="table-filter-own form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter, id },
}) => {
  return (
    <input
      className={`table-filter-own form-control ${
        id === "user_id" ||
        id === "booking_id" ||
        id === "rat_id" ||
        id === "cha_id" ||
        id === "cha_userid" ||
        id === "aut_id" ||
        id === "aut_userid" ||
        id === "acc_id" ||
        id === "acc_userid" ||
        id === "acc_carid" ||
        id === "V_bill_id" ||
        id === "seats" ||
        id === "fuel" ||
        id === "rat_id" ||
        id === "no_of_seats" ||
        id === "rate_type"
          ? "form-input-sm"
          : id === "cust_name"
          ? "form-input-lg"
          : ""
      }`}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

export const SelectFilter = (
  { column: { filterValue, setFilter, preFilteredRows, id, Header } },
  statusList = null
) => {
  const options = React.useMemo(() => {
    // if (Header == "Status") return ["4", "3", "7", "6", "8"];
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  // Render a multi-select box

  return (
    <select
      className={`text-capitalize custom-select ${
        Header === "Maintenance Status" ? "w-80-perc" : ""
      }  table-filter-own custom-select-sm`}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options?.map((option) => (
        <option key={option} value={option}>
          {statusList &&
            statusList.map((item) => item?.id === option && item?.name)}
        </option>
      ))}
    </select>
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  // Render a multi-select box
  let obj = selectFilter(options, id, Header);
  return (
    <select
      className={`custom-select ${
        Header === "Maintenance Status" ? "w-80-perc" : ""
      }  table-filter-own custom-select-sm`}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {/* {obj.map((opt) => {
        if (opt?.id !== "" && opt?.name !== "")
          return (
            <option key={opt?.id} value={opt.id}>
              {opt?.name}
            </option>
          );
      })} */}
      {obj?.map(
        (opt) =>
          opt?.id !== "" &&
          opt?.name !== "" && (
            <option key={opt?.id} value={opt?.id}>
              {opt?.value}
            </option>
          )
      )}
    </select>
  );
};

export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [value, setValue] = useState([]);
  let [min, max] = React.useMemo(() => {
    let min = new Date(preFilteredRows[0]?.values[id]);
    let max = new Date(preFilteredRows[0]?.values[id]);
    preFilteredRows.forEach((row) => {
      min = new Date(row.values[id]) <= min ? new Date(row.values[id]) : min;
      max = new Date(row.values[id]) >= max ? new Date(row.values[id]) : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);
  const refComp = useRef(null);
  return (
    <div className="date-filter">
      <Flatpickr
        className="form-control table-filter-own form-input-date"
        onChange={(date) => {
          if (date.length === 2) {
            setFilter([date[0], date[1]]);
            setValue([date[0], date[1]]);
          }
        }}
        value={value}
        options={{
          dateFormat: "d-m-Y",
          enable: [
            {
              from: new Date(min).fp_incr(0),
              to: new Date(max).fp_incr(0),
            },
          ],
          mode: "range",
        }}
        ref={refComp}
        placeholder="From - To"
      />
      <div
        className="clear-date-filter"
        onClick={() => {
          setFilter([]);
          setValue([]);
          refComp.current.flatpickr.clear();
        }}
      >
        Clear
      </div>
    </div>
  );
}
