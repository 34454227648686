import { toBase64 } from "actions/commonActions";
import { toast } from "react-toastify";

const totalSize = 1024000;

const imgToBase64 = async (file) => {
  if (file?.size > totalSize) {
    toast.error("Upload file should be less than 1MB.");
    return null;
  } else {
    const result = await toBase64(file).catch((e) => Error(e));
    if (result instanceof Error) {
      console.log("Error: ", result.message);
      return;
    }
    return result;
  }
};

export const getFileType = (type) => {
  if (type === "image/png") {
    return "png";
  } else if (type === "application/pdf") {
    return "pdf";
  } else if (type === "image/jpeg") {
    return "jpg";
  } else return type;
};

export default imgToBase64;
