import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Button, Form, FormGroup, Input, InputGroup, Label } from "reactstrap";

import { maintenanceLocationApi } from "views/examples/_actions";
import { maintainanceTypesApi } from "views/examples/_actions";
import { validatePlateNo } from "views/examples/_actions";
import imgToBase64 from "utils/fileUtils";

import DateTimePicker from "components/date-picker/date-time-picker";

const AddMaintenanceModal = (props) => {
  const [serviceType, setServiceType] = useState("");
  const [carPlate, setCarPlate] = useState("");
  const [prefDate, setPrefDate] = useState(new Date());
  const [location, setLocation] = useState("");
  const [odometer, setOdometer] = useState("");
  const [odometerError, setOdometerError] = useState(false);

  const [reason, setReason] = useState("");
  const [reservationNo, setReservationNo] = useState("");

  const [odoImage, setOdoImage] = useState("");
  const [images, setImages] = useState([]);

  const [maintainanceLocations, setMaintainanceLocations] = useState([]);
  const [maintainanceTypes, setMaintainanceTypes] = useState([]);
  const [isPlateValid, setIsPlateValid] = useState(true);

  useEffect(() => {
    fetchMaintainanceLocations();
    fetchMaintainanceTypes(); // eslint-disable-next-line
  }, []);

  const fetchMaintainanceLocations = async () => {
    try {
      let res = await maintenanceLocationApi();
      if (res?.data?.status === 0) {
        setMaintainanceLocations(res.data.data);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: addMaintenanceModal.jsx ~ line 29 ~ fetchMaintainanceLocations ~ err",
        err
      );
    }
  };

  const fetchMaintainanceTypes = async () => {
    try {
      let res = await maintainanceTypesApi();
      if (res?.data?.status === 0) {
        setMaintainanceTypes(res.data.data);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: addMaintenanceModal.jsx ~ line 29 ~ fetchMaintainanceLocations ~ err",
        err
      );
    }
  };

  const odoImageUploadHandler = async (ev) => {
    let file = ev.target.files[0];
    let result = await imgToBase64(file);
    if (result) {
      setOdoImage(result);
    }
  };

  const imageUploadHandler = async (ev) => {
    const files = Object.values(ev.target.files);

    let images = [];

    files.map(async (file) => {
      let result = await imgToBase64(file);

      if (result) {
        images.push({
          img_string: result,
          img_type: "png",
        });
        setImages(images);
      }
    });
  };

  const submitBtnHandler = (e) => {
    e.preventDefault();
    if (isPlateValid) {
      if (odometer !== "") {
        let obj;
        obj = {
          car_plate: carPlate,
          service_type: serviceType,
          prefered_date: prefDate,
          preffered_location: location,
          odometer_reading: odometer,
          reason: reason,
          reservation_no: reservationNo,
          odometer_image: [
            {
              img_string: odoImage,
              img_type: "png",
            },
          ],
          image: images,
        };

        props?.onCloseModal(obj);
      } else {
        setOdometerError(true);
      }
    }
  };

  const plateNoChangeHandler = (event) => {
    setCarPlate(event?.target?.value);
  };

  useEffect(() => {
    if (carPlate !== "") {
      const timeOutId = setTimeout(() => validatePlateNumber(carPlate), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [carPlate]);

  const validatePlateNumber = async (plateNo) => {
    const response = await validatePlateNo(plateNo);
    if (response?.data?.data) {
      let data = response?.data?.data;
      if (data && data.length > 0 && data[0] != null) {
        setIsPlateValid(true);
      } else {
        setIsPlateValid(false);
      }
    }
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">Add Maintenance</label>
      </div>
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <FormGroup>
                <Label for="carPlate">Car Plate Number</Label>
                <Input
                  type="text"
                  name="carPlate"
                  id="carPlate"
                  placeholder="Car Plate Number"
                  onChange={plateNoChangeHandler}
                  value={carPlate}
                />
                {!isPlateValid && (
                  <div className="error-text">Plate is invalid</div>
                )}
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup>
                <Label for="prefDate">Prefered Date / Time</Label>
                <InputGroup size="md">
                  <DateTimePicker
                    enableTime={true}
                    format="Y-m-d H:i"
                    onDateTimeSelect={(selectedDateTime) => {
                      if (selectedDateTime && selectedDateTime.length > 0) {
                        setPrefDate(selectedDateTime[0].toString());
                      }
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup>
                <Label for="serviceType">Maintainance Type</Label>
                <Input
                  type="select"
                  name="maintainance_type"
                  id="maintainance_type"
                  onChange={(ev) => setServiceType(ev.target.value)}
                  value={serviceType}
                >
                  <option value={null}>Select Type</option>
                  {maintainanceTypes.map((maintainanceType) => {
                    return (
                      <option
                        key={maintainanceType.maint_type_id}
                        value={maintainanceType.maint_type_id}
                      >
                        {maintainanceType.maint_type_desc}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup>
                <Label for="location">
                  Preferred Repair / Maintainance Center*
                </Label>

                <Input
                  type="select"
                  name="location"
                  id="location"
                  onChange={(ev) => setLocation(ev.target.value)}
                  value={location}
                >
                  <option value={null}>Select Location</option>
                  {maintainanceLocations.map((location) => {
                    return (
                      <option key={location.loc_id} value={location.loc_id}>
                        {location.loc_desc}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup>
                <Label for="odometer">
                  Odometer Reading<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="odometer"
                  id="odometer"
                  placeholder="Odometer Reading"
                  required={true}
                  onChange={(ev) => setOdometer(ev.target.value)}
                  value={odometer}
                  className={`${odometerError ? "on-focus" : ""}`}
                />
              </FormGroup>
            </div>

            <div className="col-6">
              <FormGroup>
                <Label for="img">Upload Odometer Image</Label>
                <Input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  name="img"
                  id="img"
                  placeholder="Upload Image"
                  onChange={odoImageUploadHandler}
                />
              </FormGroup>
            </div>

            <div className="col-6">
              <FormGroup>
                <Label for="reservation_no">Reservation No</Label>
                <Input
                  type="text"
                  name="reservation_no"
                  id="reservation_no"
                  placeholder="Reservation No"
                  onChange={(ev) => setReservationNo(ev.target.value)}
                  value={reservationNo}
                />
              </FormGroup>
            </div>

            <div className="col-6">
              <FormGroup>
                <Label for="img">Upload Images</Label>
                <Input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  name="img"
                  id="img"
                  placeholder="Upload Images"
                  onChange={imageUploadHandler}
                  multiple
                />
              </FormGroup>
            </div>

            <div className="col-12">
              <FormGroup>
                <Label for="reason">Description</Label>
                <Input
                  type="textarea"
                  name="reason"
                  id="reason"
                  placeholder="Description"
                  onChange={(ev) => setReason(ev.target.value)}
                  value={reason}
                  className="text-area"
                />
              </FormGroup>
            </div>
          </div>
          <div className="d-flex justify-content-center pt-4">
            <Button color="btn grey-bg" onClick={() => props?.onCloseModal()}>
              Cancel
            </Button>

            <Button
              color="btn primary-bg"
              onClick={submitBtnHandler}
              disabled={!isPlateValid}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddMaintenanceModal;
