import { useEffect } from "react";
import { useState } from "react";

import { useRentalStationsData } from "hooks/useRentalStationsData";

export const SelectedLocation = ({ booking }) => {
  const [pickUp, setPickup] = useState("");
  const [dropOff, setDropOff] = useState("");

  const { data: locations } = useRentalStationsData();

  useEffect(() => {
    if (booking && locations && locations.length > 0) {
      console.log(
        "🚀 ~ file: selected-location.jsx:14 ~ useEffect ~ booking",
        booking
      );

      locations.map((location) => {
        if (location.loc_id === booking.pickup_station) {
          setPickup(location.loc_desc);
        }
      });

      locations.map((location) => {
        if (location.loc_id === booking.dropoff_station) {
          setDropOff(location.loc_desc);
        }
      });
    }
  }, [booking, locations]);

  return (
    <>
      {booking && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div
            style={{ borderBottom: "2px solid #ddd", marginBottom: "10px" }}
          ></div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "1" }}>
                Pick Up : <span style={{ marginLeft: "5px" }}>{pickUp}</span>
              </div>
              <div style={{ flex: "1" }}>
                Drop Off : <span style={{ marginLeft: "5px" }}>{dropOff}</span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "1" }}>
                Pick Up Time :
                <span style={{ marginLeft: "5px" }}>
                  {booking.pickup_date_time}
                </span>
              </div>
              <div style={{ flex: "1" }}>
                Drop Off Time :
                <span style={{ marginLeft: "5px" }}>
                  {booking.dropoff_date_time}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
