import AppContext from "context/app.context";
import React, { useEffect, useReducer } from "react";
import { ToastContainer } from "react-toastify";
import RouteComponent from "route";
import appReducer from "store/app-reducer";

const App = () => {
  const mobileContent =
    "width=device-width,width=1500px, initial-scale=1, shrink-to-fit=no";
  const webContent = "width=device-width, initial-scale=1, shrink-to-fit=no";

  const [{ selectedBooking }, dispatch] = useReducer(appReducer, {
    selectedBooking: undefined,
  });

  useEffect(() => {
    isMobileView();
  }, []);

  const isMobileView = () => {
    let metaTag = document.querySelector("#metaTag");
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      metaTag.setAttribute("content", mobileContent);
    } else {
      metaTag.setAttribute("content", webContent);
    }
  };

  return (
    <>
      <AppContext.Provider value={{ selectedBooking, dispatch }}>
        <>
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />

          <RouteComponent />
        </>
      </AppContext.Provider>
    </>
  );
};

export default App;
