import IconButton from "components/button/icon-button";
import PrimaryButton from "components/button/primary-button";
import HorizontalStepper from "components/stepper/horizontal-stepper";
import React, { useState } from "react";

const BookingHeader = ({
  activeStep,
  backClicked,
  continueClicked,
  showContinue,
}) => {
  const [stepVisible, setStepVisible] = useState(true);
  return (
    <>
      <div className="col-12">
        <h2 style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flex: 0,
              alignItems: "center",
              justifyContent: "flex-start",
              paddingRight: "15px",
            }}
          >
            <PrimaryButton
              title="Previous"
              iconName="fa-arrow-left"
              iconPosition="left"
              withIcon="true"
              onClick={backClicked}
            />
          </div>
          <span
            style={{ flex: "0", whiteSpace: "nowrap", marginRight: "10px" }}
          >
            Your Booking
          </span>
          <div
            style={{ flex: "1", height: "1pt", border: "1pt solid #ddd" }}
          ></div>

          <i
            className=" fas fa-angle-down  cursor-pointer"
            style={{
              flex: "0",
              color: "#333",
              opacity: "0.8",
              fontSize: "20px",
              marginLeft: "10px",
            }}
            onClick={() => setStepVisible(!stepVisible)}
          ></i>
          {showContinue && (
            <div
              style={{
                display: "flex",
                flex: 0,
                alignItems: "center",
                justifyContent: "flex-start",
                paddingLeft: "15px",
              }}
            >
              <PrimaryButton
                title="Continue"
                iconName="fa-arrow-right"
                iconPosition="right"
                withIcon="true"
                onClick={continueClicked}
              />
            </div>
          )}
        </h2>
      </div>
      {stepVisible && (
        <div className="col-12" style={{ width: "100%" }}>
          <HorizontalStepper activeStep={activeStep} />
        </div>
      )}
    </>
  );
};

export default BookingHeader;
