import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { validNumber } from "_shared/commonFunctions";

const AddViolationModal = (props) => {
  const [plate, setPlate] = useState("");
  const [violation, setViolation] = useState("");
  const [date, setDate] = useState("");
  const [details, setDetails] = useState("");
  const [amount_total, setAmount_total] = useState("");
  const [amount_paid, setAmount_paid] = useState("");
  const [status, setStatus] = useState("");
  const [plateErr, setPlateErr] = useState(false);
  const [violationErr, setViolationErr] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [detailsErr, setDetailsErr] = useState(false);
  const [amount_totalErr, setAmount_totalErr] = useState(false);
  const [amount_paidErr, setAmount_paidErr] = useState(false);
  const [statusErr, setStatusErr] = useState(false);

  const handleValidation = () => {
    let isValid = true;
    if (!plate) {
      isValid = false;
      setPlateErr(true);
    }
    if (!violation) {
      isValid = false;
      setViolationErr(true);
    }
    if (!date) {
      isValid = false;
      setDateErr(true);
    }
    if (!details) {
      isValid = false;
      setDetailsErr(true);
    }
    if (!amount_total) {
      isValid = false;
      setAmount_totalErr(true);
    }
    if (!amount_paid) {
      isValid = false;
      setAmount_paidErr(true);
    }
    if (!status) {
      isValid = false;
      setStatusErr(true);
    }
    return isValid;
  };

  const amountValidation = async (ev) => {
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      if (ev.target.name === "amount_total") {
        setAmount_total(value);
      } else {
        setAmount_paid(value);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = {
        plate: plate,
        violation: violation,
        date: date,
        details: details,
        amount_total: amount_total,
        amount_paid: amount_paid,
        status: status,
      };
      props?.onCloseModal(obj);
    }
  };
  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="px-3 pt-5">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="plate">Plate Number</Label>
                <Input
                  type="text"
                  name="plate"
                  id="plate"
                  placeholder="Plate Number"
                  value={plate}
                  onChange={(ev) => setPlate(ev.target.value)}
                />
                {!plate && plateErr && (
                  <div className="error-text">Plate Number is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="violation">Violation ID</Label>
                <Input
                  type="text"
                  name="violation"
                  id="violation"
                  placeholder="Violation ID"
                  value={violation}
                  onChange={(ev) => setViolation(ev.target.value)}
                />
                {!violation && violationErr && (
                  <div className="error-text">Violation ID is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="date">Date</Label>
                <Input
                  type="date"
                  name="date"
                  id="date"
                  placeholder="Date"
                  value={date}
                  onChange={(ev) => setDate(ev.target.value)}
                />
                {!date && dateErr && (
                  <div className="error-text">Date is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="details">Details</Label>
                <Input
                  type="text"
                  name="details"
                  id="details"
                  placeholder="Details"
                  value={details}
                  onChange={(ev) => setDetails(ev.target.value)}
                />
                {!details && detailsErr && (
                  <div className="error-text">Details is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="amount_total">Total Amount</Label>
                <Input
                  type="text"
                  name="amount_total"
                  id="amount_total"
                  placeholder="Total Amount"
                  value={amount_total}
                  onChange={(ev) => amountValidation(ev)}
                />
                {!amount_total && amount_totalErr && (
                  <div className="error-text">Total Amount is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="amount_paid">Amount Paid</Label>
                <Input
                  type="text"
                  name="amount_paid"
                  id="amount_paid"
                  placeholder="Amount Paid"
                  value={amount_paid}
                  onChange={(ev) => amountValidation(ev)}
                />
                {!amount_paid && amount_paidErr && (
                  <div className="error-text">Amount Paid is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  placeholder="Status"
                  value={status}
                  onChange={(ev) => setStatus(ev.target.value)}
                >
                  <option>Select</option>
                  <option value="0">Due</option>
                  <option value="1">Processing</option>
                  <option value="2">Paid</option>
                </Input>
                {!status && statusErr && (
                  <div className="error-text">Status is required</div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddViolationModal;
