import React, { useMemo } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { tableDropdownData } from "_shared/commonFunctions";
import { SelectFilter } from "./_filters";
import TableContainer from "./_table";

const ChauffeurServiceTable = (props) => {
  const { statusList } = props;
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  <DropdownItem
                    onClick={() => props?.updateTable(cell?.row?.original)}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "rate_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.rate_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },

      {
        Header: "ID",
        accessor: "rate_type",
      },
      {
        Header: "Service Type",
        accessor: "rate_type_desc",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);
  return <TableContainer columns={columns} data={data} />;
};

export default ChauffeurServiceTable;
