import ForgotPasswordModal from "components/Modals/forgotPasswordModal";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import TimerComp from "_shared/timer";
import CryptoJs from "crypto-js";
import {
  globalLoader,
  roleApiData,
  profileApiData,
} from "../../actions/commonActions";

// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import { validNumber, validEmail } from "../../_shared/commonFunctions";
import { verifyLoginOtpApi, loginByEmailApi, loginByOtpApi } from "./_actions";
import { profileDetailsApi } from "./_actions";
import { callTokenApi } from "../../actions/commonActions";

const Login = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);

  useEffect(() => {
    // let token = Cookies.get("access_token");
    // if (token) {
    //   props?.history?.push("/admin/index");
    // } // eslint-disable-next-line
  }, []);

  const closeForgotPasswordModal = () => {
    setForgotPasswordModal(false);
  };

  const setTimerOver = () => {
    setResendOtp(false);
  };

  const handleOtpLoginValidation = () => {
    let isValid = true;
    if (!otp) {
      isValid = false;
      setOtpErr(true);
    }
    if (invalidEmail) {
      isValid = false;
    }
    return isValid;
  };

  const handleEmailLoginValidation = () => {
    let isValid = true;
    if (!email) {
      setEmailErr(true);
      isValid = false;
    }
    if (invalidEmail) {
      isValid = false;
    }
    if (!password) {
      setPasswordErr(true);
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    globalLoader(true);
    let obj, res;
    try {
      if (isOtp) {
        if (await handleOtpLoginValidation()) {
          obj = { email: email.toLowerCase(), otp: otp };
          res = await verifyLoginOtpApi(obj);
        }
      } else {
        res = await callTokenApi();
        if (res?.status === 200) {
          Cookies.set("access_token", res?.data?.access_token, {
            expires: res?.data?.expires_in,
            secure: true,
            sameSite: "strict",
          });
        } else {
        }
        if (await handleEmailLoginValidation()) {
          obj = {
            email: email.toLowerCase(),
            password: CryptoJs.MD5(password).toString(),
          };
          res = await loginByEmailApi(obj);
        }
      }
      if (res?.data?.status === 0) {
        roleApiData(res?.data?.data[0]);
        Cookies.set("profile_email", email.toLowerCase());
        toast.success(res?.data?.message);
        getProfileDetails();
        history.push("/admin/index");
      } else if (res?.data?.status === 404) {
        isOtp
          ? toast.error("Incorrect OTP")
          : toast.error("Email Id or password is incorrect");
        globalLoader(false);
        Cookies.remove("access_token");
      } else {
        if (isOtp) {
          toast.error("Incorrect OTP");
          setOtp("");
          globalLoader(false);
        } else {
          globalLoader(false);
          toast.error("Email Id or password is incorrect");
        }
      }
      globalLoader(false);
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Unauthorized");
      }
      globalLoader(false);
    }
  };

  const getProfileDetails = async () => {
    let profileArr;
    try {
      let res = await profileDetailsApi();
      if (res?.data?.status === 0) {
        res?.data?.data?.forEach((data) => {
          if (data?.email === email) {
            profileArr = data;
          }
        });
        profileApiData(profileArr);
      } else {
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Error while fetching profile details");
      }
    }
  };

  const sendOtpFunc = async (ev) => {
    ev.preventDefault();
    let isValid = true;
    let res;
    if (!email) {
      setEmailErr(true);
      isValid = false;
    }
    if (isValid) {
      let obj = { email: email };
      globalLoader(true);
      try {
        res = await callTokenApi();
        if (res?.status === 200) {
          Cookies.set("access_token", res?.data?.access_token, {
            expires: res?.data?.expires_in,
            secure: true,
            sameSite: "strict",
          });
        } else {
        }
        res = await loginByOtpApi(obj);
        if (res?.data?.status === 0) {
          toast.success("OTP sent to your email");
          setOtpSent(true);
          setResendOtp(true);
        } else if (res?.data?.status === 404) {
          toast.error("Email not found");
        }
        globalLoader(false);
      } catch (err) {
        toast.error("Unauthorized");
      }
    }
  };

  const validateEmail = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validEmail(value);
    if (isValid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
  };

  const validateOtp = async (ev) => {
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let value = ev.target.value;
    let isValid = await validNumber(value);
    if (isValid) {
      setOtp(value);
    } else if (!otp) {
      setOtp("");
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        {isOtp ? (
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center mb-4 color-primary">
                <small>Sign in with OTP</small>
              </div>
              <Form role="form">
                <FormGroup className="form-login-own">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      value={email}
                      onChange={(ev) => validateEmail(ev)}
                      disabled={otpSent ? true : false}
                      autoComplete="true"
                    />
                  </InputGroup>
                  {!email && emailErr && (
                    <div className="error-text">Email is required</div>
                  )}
                  {email && invalidEmail && (
                    <div className="error-text">Email is invalid</div>
                  )}
                </FormGroup>
                <FormGroup className="form-login-own">
                  {otpSent && (
                    <>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          placeholder="One Time Password"
                          type="password"
                          value={otp}
                          onChange={(ev) => validateOtp(ev)}
                        />
                      </InputGroup>
                      {!otp && otpErr && (
                        <div className="error-text">OTP is required</div>
                      )}
                    </>
                  )}
                </FormGroup>

                {otpSent ? (
                  resendOtp ? (
                    <TimerComp setTimerOver={setTimerOver} />
                  ) : (
                    <span
                      className="color-primary font-13 cursor-pointer"
                      onClick={sendOtpFunc}
                    >
                      Resend OTP
                    </span>
                  )
                ) : null}
                <div className="text-center">
                  {otpSent ? (
                    <button
                      className="mt-4 mb-2 btn primary-bg"
                      onClick={handleSubmit}
                    >
                      Sign in
                    </button>
                  ) : (
                    <button
                      className="mt-4 mb-2 btn primary-bg"
                      onClick={sendOtpFunc}
                    >
                      Send OTP
                    </button>
                  )}
                </div>
                <div
                  className="font-12 text-center cursor-pointer"
                  onClick={() => setIsOtp((state) => !state)}
                >
                  Sign in by Email
                </div>
              </Form>
            </CardBody>
          </Card>
        ) : (
          <>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-4 color-primary">
                  <small>Sign in with credentials</small>
                </div>
                <Form role="form">
                  <FormGroup className="form-login-own">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        value={email}
                        onChange={(ev) => validateEmail(ev)}
                        autoComplete="true"
                      />
                    </InputGroup>
                    {!email && emailErr && (
                      <div className="error-text">Email is required</div>
                    )}
                    {email && invalidEmail && (
                      <div className="error-text">Email is invalid</div>
                    )}
                  </FormGroup>
                  <FormGroup className="form-login-own">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(ev) => setPassword(ev.target.value)}
                        autoComplete="true"
                      />
                    </InputGroup>
                    {!password && passwordErr && (
                      <div className="error-text">Password is required</div>
                    )}
                  </FormGroup>
                  <FormGroup check className="pl-0">
                    <Label check className="custom-check-box position-relative">
                      Remember me
                      <Input type="checkbox" className="checkbox-own" />
                      <span className="checkmark">
                        <span className="check" />
                        <span className="font-14 pl-1"> </span>
                      </span>
                    </Label>
                  </FormGroup>
                  <div className="text-center">
                    <button
                      className="mt-4 mb-2 btn primary-bg"
                      onClick={handleSubmit}
                    >
                      Sign in
                    </button>
                  </div>
                  <div
                    className="font-12 text-center cursor-pointer"
                    onClick={() => setIsOtp((state) => !state)}
                  >
                    Sign in by OTP
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
        <Row className="mt-3">
          <Col xs="6">
            {!isOtp && (
              <div
                className="color-primary cursor-pointer"
                onClick={() => {
                  setForgotPasswordModal(true);
                }}
              >
                <small>Forgot password?</small>
              </div>
            )}
          </Col>
        </Row>
      </Col>
      {forgotPasswordModal && (
        <ForgotPasswordModal
          open={forgotPasswordModal}
          onCloseModal={closeForgotPasswordModal}
        />
      )}
    </>
  );
};

export default Login;
