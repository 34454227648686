export const ActionTypes = {
  ADD_BOOKING: "ADD_BOOKING",
  REMOVE_BOOKING: "REMOVE_BOOKING",
  ADD_USER: "ADD_USER",
};

export const initialState = {
  selectedBooking: undefined,
  user: undefined,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_BOOKING:
      return { ...state, selectedBooking: action.payload };
    case ActionTypes.ADD_USER:
      return { ...state, user: action.payload };
    case ActionTypes.REMOVE_BOOKING:
      return { ...state, selectedBooking: undefined };
    default:
      return state;
  }
};

export default appReducer;
