/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/dashboard";
import UserList from "./views/examples/userList";
import AuthorizationManagement from "./views/examples/authorization";
import ServiceList from "./views/examples/serviceList";
import DamageList from "./views/examples/damageList";
import InvoiceManagement from "./views/examples/invoiceManagement";
import AutoInvoices from "./views/examples/autoInvoices";
import ServiceCenterManagement from "./views/examples/serviceCenterManagement";
import ReportedAccident from "./views/examples/reportedAccident";
import TrafficViolation from "./views/examples/trafficViolation";
import Feedback from "./views/examples/feedback";
import PermissionManagement from "./views/examples/permissionManagement";
import VehicleContractDriver from "./views/examples/vehicleContractDriver";
import RoadsideAssistance from "./views/examples/roadsideAssistance";
import RentRequest from "./views/examples/rentRequest";
import ChauffeurRequest from "./views/examples/chauffeurRequest";
import ChauffeurRate from "./views/examples/chauffeurRate";
import Login from "./views/examples/login";
import CarReplacement from "./views/examples/carReplacement.jsx";
import MaintenanceHistory from "views/examples/maintenanceHistory.jsx";
import RentRate from "views/examples/rentRate.jsx";
import Designation from "views/examples/designation.jsx";
import Role from "views/examples/role.jsx";
import NotificationManagement from "views/examples/notification.jsx";
import ChauffeurService from "./views/examples/chauffeurService.jsx";
import AppUsers from "views/examples/appUsers.jsx";
import UserProfile from "./views/examples/profile";
import ImgDocManagement from "views/examples/imgDocManagement.jsx";
import CreateRentRequest from "views/rent-request/create-rent-request";
import CreateRentRequestCarSelector from "views/rent-request/create-rent-request-car-selector";
import ExtraServiceContainer from "views/rent-request/extra-services/extra-service-container";
import BookingConfirmation from "views/rent-request/booking-confirmation";
import PreContractContainer from "views/pre-contract/pre-contract-container";

var routes = [
	// {
	//   path: "/index",
	//   name: "Dashboard",
	//   component: Index,
	//   layout: "/admin",
	//   imgSrc: require("./assets/img/icons/common/Admin - Dashboard.svg").default,
	//   alt: "Dashboard",
	// },
	{
		path: "/index",
		name: "Dashboard",
		component: Dashboard,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Dashboard.svg").default,
		alt: "Dashboard",
	},
	{
		path: "/user_profile",
		name: "User Profile",
		component: UserProfile,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Users.svg").default,
		alt: "User Profile",
	},
	{
		path: "/user_list",
		name: "Users",
		component: UserList,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Users.svg").default,
		alt: "Users",
		module_type: "module_1",
	},
	{
		path: "/app_users",
		name: "App Users",
		component: AppUsers,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - App User.svg").default,
		alt: "App Users",
		module_type: "module_2",
	},
	{
		path: "/vehicle_contract_driver",
		name: "Fleet Management",
		component: VehicleContractDriver,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Fleet.svg").default,
		alt: "Drivers",
		module_type: "module_3",
	},

	{
		path: "/car_replacement",
		name: "Replacement/Pool Cars",
		component: CarReplacement,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Replacement.svg")
			.default,
		alt: "Car Replacement",
		module_type: "module_22",
	},
	{
		path: "/service_listing",
		name: "Repair & Maintenance",
		component: ServiceList,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Service.svg").default,
		alt: "Service Listing",
		module_type: "module_4",
	},
	{
		path: "/maintenance_history",
		name: "Maintenance History",
		component: MaintenanceHistory,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - History.svg").default,
		alt: "Maintenance History",
		module_type: "module_23",
	},
	{
		path: "/invoice_management",
		name: "Transaction",
		component: InvoiceManagement,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Payments.svg").default,
		alt: "Transaction",
		module_type: "module_5",
	},
	{
		path: "/auto_invoices",
		name: "Auto Invoices",
		component: AutoInvoices,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Payments.svg").default,
		alt: "Auto Invoices",
		module_type: "module_5",
	},
	{
		path: "/damage_listing",
		name: "Self Inspection",
		component: DamageList,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Damage.svg").default,
		alt: "Damage Listing",
		module_type: "module_6",
	},
	{
		path: "/general_feedback",
		name: "Feedback",
		component: Feedback,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Feedback.svg").default,
		alt: "Feedback",
		module_type: "module_7",
	},
	{
		path: "/permission_management",
		name: "Permissions",
		component: PermissionManagement,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Permissions.svg")
			.default,
		alt: "Permission Management",
		module_type: "module_24",
	},
	{
		path: "/service_center_management",
		name: "Location",
		component: ServiceCenterManagement,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Location.svg").default,
		alt: "Service Center",
		module_type: "module_8",
	},
	{
		path: "/authorization_management",
		name: "Authorization",
		component: AuthorizationManagement,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Authorization.svg")
			.default,
		alt: "Authorization Management",
		module_type: "module_9",
	},

	{
		path: "/reported_accidents",
		name: "Accidents",
		component: ReportedAccident,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Accidents.svg").default,
		alt: "Accidents",
		module_type: "module_10",
	},
	{
		path: "/traffic_violation",
		name: "Violation",
		component: TrafficViolation,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Violation.svg").default,
		alt: "Violation",
		module_type: "module_11",
	},
	{
		path: "/roadside_assistance",
		name: "Road Assistance",
		component: RoadsideAssistance,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Road Assistance.svg")
			.default,
		alt: "Road Assistance",
		module_type: "module_12",
	},
	{
		path: "/rent_request/new/booking-confirmation",
		name: "RentNew",
		component: BookingConfirmation,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Rent.svg").default,
		alt: "Rent Request",
	},
	{
		path: "/rent_request/new/extra-services",
		name: "RentNew",
		component: ExtraServiceContainer,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Rent.svg").default,
		alt: "Rent Request",
	},
	{
		path: "/rent_request/new/select-car",
		name: "RentNew",
		component: CreateRentRequestCarSelector,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Rent.svg").default,
		alt: "Rent Request",
	},
	{
		path: "/rent_request/new",
		name: "RentNew",
		component: CreateRentRequest,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Rent.svg").default,
		alt: "Rent Request",
	},
	{
		path: "/rent_request",
		name: "Rent",
		component: RentRequest,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Rent.svg").default,
		alt: "Rent Request",
		module_type: "module_13",
	},

	{
		path: "/rent_request_corp",
		name: "Rent",
		component: RentRequest,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Rent.svg").default,
		alt: "Rent Request",
	},

	{
		path: "/pre-contract",
		name: "Pre Contract",
		component: PreContractContainer,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Replacement.svg")
			.default,
		alt: "Pre Contract",
	},

	{
		path: "/rent_rate",
		name: "Rent Rate",
		component: RentRate,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Rent Rate.svg").default,
		alt: "Rent Rate",
		module_type: "module_16",
	},
	{
		path: "/chauffeur_request",
		name: "Chauffeur",
		component: ChauffeurRequest,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Chauffeur.svg").default,
		alt: "Chauffeur Request",
		module_type: "module_14",
	},
	{
		path: "/chauffeur_rate",
		name: "Chauffeur Rate",
		component: ChauffeurRate,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Chauffeur Rate.svg")
			.default,
		alt: "Chauffeur Rate",
		module_type: "module_15",
	},
	{
		path: "/image_document_management",
		name: "Image/Document Management",
		component: ImgDocManagement,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Fleet.svg").default,
		alt: "Image Document",
		module_type: "module_17",
	},
	{
		path: "/designation",
		name: "Designation",
		component: Designation,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Designation.svg")
			.default,
		alt: "Designation",
		module_type: "module_18",
	},
	{
		path: "/chauffeur_service",
		name: "Chauffeur Service",
		component: ChauffeurService,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Cheuffuer Service.svg")
			.default,
		alt: "chauffeur_service",
		module_type: "module_19",
	},
	{
		path: "/role",
		name: "Role",
		component: Role,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Roles.svg").default,
		alt: "role",
		module_type: "module_20",
	},
	{
		path: "/notification_management",
		name: "Notification",
		component: NotificationManagement,
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Admin - Notifications.svg")
			.default,
		alt: "Notification",
		module_type: "module_21",
	},
	{
		path: "/",
		name: "Logout",
		layout: "/admin",
		imgSrc: require("./assets/img/icons/common/Logout.svg").default,
		alt: "Users",
	},
	{
		path: "/",
		name: "Login",
		icon: "ni ni-single-02 text-yellow",
		component: Login,
		layout: "/auth",
	},
];
export default routes;
