import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { sort } from "fast-sort";

import {
  notificationListApi,
  userNotificationPortalListApi,
} from "../views/examples/_actions";

export const useNotificationsData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["notifications", profile_email],
    () => notificationListApi(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        const notifications = [];
        data?.data.data.forEach((notification) => {
          let newNotification = {
            value: notification?.nti_type_id,
            label: notification?.nti_type_desc,
          };
          notifications.push(newNotification);
        });
        return notifications;
      },
    }
  );
};

export const useUserNotificationsData = (currentCompany) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["user-notifications", currentCompany, profile_email],
    () => userNotificationPortalListApi(currentCompany),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.data?.status !== 404) {
          const notifications = data?.data?.data;
          if (notifications && notifications.length > 0) {
            let sortednotifications = sort(notifications).desc((notification) =>
              parseInt(notification?.nti_descid)
            );
            return sortednotifications;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
    }
  );
};
