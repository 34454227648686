import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ViewMoreModal = (props) => {
  const ulWrapper = {
    width: "80%",
  };
  const areaDesc = { width: "30%" };
  const typeDesc = { width: "70%" };
  return (
    <>
      <Modal
        open={props?.open}
        onClose={() => props?.onCloseModal()}
        center
        showCloseIcon={true}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: "form-modal-own" }}
      >
        <div
          className="py-5 d-flex flex-column align-items-center"
          style={{ minWidth: "500px" }}
        >
          <h3 className="color-primary ">{props?.desc}</h3>
          <ul className="list-unstyled p-3" style={ulWrapper}>
            {props?.list.map((l, i) => (
              <li key={i} className="d-flex justify-content-start my-3">
                <span className="weight-600" style={areaDesc}>
                  {l?.dmg_area_desc}
                </span>
                <span className="text-right text-muted ml-2" style={typeDesc}>
                  {l?.dmg_type_desc}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default ViewMoreModal;
