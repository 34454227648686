import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";
import Cookies from "js-cookie";

import UserHeader from "../../components/Headers/userHeader";
import { globalLoader } from "actions/commonActions";
import VehicleContractDriverTable from "../../components/tables/vehicleContractDriverTable";

import { useFleetManagementData } from "hooks/useFleetManagementData";

import { downloadExcel } from "utils/exportUtils";

const VehicleContractDriver = (props) => {
  const { currentCompany } = props;
  const [category, setCategory] = useState("u"); // Default category value
  const user_id = Cookies.get("user_id");

  useEffect(() => {
    console.log("Current company:", currentCompany);
  }, [currentCompany]);

  const {
    data: fleets,
    isLoading,
    isFetched,
    isFetching,
    refetch,
  } = useFleetManagementData(currentCompany || "", category, user_id);

  useEffect(() => {
    if (currentCompany) {
      refetch();
    }
  }, [currentCompany, category, refetch]);

  useEffect(() => {
    // console.log("isLoading", isLoading);
    // if (isLoading) {
    //   globalLoader(true);
    // } else {
    //   globalLoader(false);
    // }
  }, [isLoading]);

  useEffect(() => {
    if (isFetching) {
      globalLoader(true);
    } else {
      globalLoader(false);
    }
  }, [isFetching]);

  const downloadExcelHandler = () => {
    downloadExcel(fleets, "fleet-management");
  };

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  return (
    <>
      <UserHeader />

      <Container className="mt--8 pb-3" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Fleet Management</h3>
                  <div className="col text-right">
                    <button
                      className="btn btn-sm primary-bg"
                      onClick={downloadExcelHandler}
                    >
                      Download as Excel
                    </button>
                    <button
                      className="btn btn-sm primary-bg ml-2"
                      onClick={() => handleCategoryChange("a")} // Change category to 'a'
                    >
                      Contract
                    </button>
                  </div>
                </Row>
              </CardHeader>
              {!isFetching ? (
                fleets && fleets.length > 0 ? (
                  <VehicleContractDriverTable tableData={fleets} />
                ) : (
                  <div className="px-3 pt-3 pb-3">
                    <div className="no-data-available color-primary">
                      Data Not Available
                    </div>
                  </div>
                )
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
});

export default connect(mapStateToProps)(VehicleContractDriver);
