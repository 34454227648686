import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { getStatusArray } from "_shared/commonFunctions";

export const useStatusData = (statusNumber) => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(
    ["status", statusNumber, profile_email],
    () => getStatusArray(statusNumber),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
};
