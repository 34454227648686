import { globalLoader } from "actions/commonActions";
import UserHeader from "../../components/Headers/userHeader";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Container, Row, Media } from "reactstrap";
import { logOut } from "_shared/commonFunctions";
import { profileDetailsApi } from "./_actions";
import { designationListApi } from "./_actions";
import ViewImageModal from "components/Modals/viewImageModal";
import Cookies from "js-cookie";
import ChangePasswordModal from "components/Modals/changePasswordModal";
import ViewModal from "components/Modals/viewModal";

const ProfileDetails = (props) => {
  const [view, setView] = useState(false);
  const [dataList, setDataList] = useState(null);
  const [designationList, setDesignationList] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageDate, setImageData] = useState([]);
  const [imgType, setImgType] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [formData, setFormData] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [type, setType] = useState(null);

  useEffect(() => {
    callBasicApi();
    // eslint-disable-next-line
  }, []);
  const callBasicApi = async () => {
    setView(false);
    globalLoader(true);
    let profileArr;
    try {
      let res = await profileDetailsApi();
      if (res?.data?.status === 0) {
        let u_email = Cookies.get("profile_email");
        profileArr = res?.data?.data?.filter((data) => data?.email === u_email);
        setDataList(profileArr[0]);
      } else if (res?.data?.status === 401) {
        logOut(props?.history, "/auth/login");

        globalLoader(false);
      } else if (res?.data?.status === 404) {
        globalLoader(false);
        setView(true);
        setDataList(null);
      } else {
      }
      if (res?.data?.status !== 401) {
        res = await designationListApi();
        if (res?.data?.status === 0) {
          let arr = res?.data?.data.filter(
            (data) => data?.pdg_id === profileArr[0]?.designation_code
          );
          setDesignationList(arr[0]);
        } else if (res?.data?.status === 401) {
          logOut(props?.history, "/auth/login");
          globalLoader(false);
        } else {
        }
      }
      setView(true);
      globalLoader(false);
    } catch (err) {
      if (err?.response?.status === 401) {
        logOut(props?.history, "/auth/login");
        globalLoader(false);
      }
    }
  };

  const viewImage = () => {
    let img = [{ img_url: dataList?.id_image }];
    setImageData(img);
    setImgType("National ID");
    setOpenImageModal(true);
  };

  const closeImage = () => {
    setImageData(null);
    setImgType("");
    setOpenImageModal(false);
  };

  const handlePassword = () => {
    setChangePassword(false);
  };

  const viewCompany = (data, type) => {
    setFormData(data);
    setType(type);
    setOpenViewModal(true);
  };

  const closeViewCompany = () => {
    setFormData(null);
    setType(null);
    setOpenViewModal(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            {view ? (
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <h3 className="mb-0 color-primary">Profile Details</h3>
                  </Row>
                </CardHeader>
                <div className="col text-right">
                  <button
                    className="btn btn-sm primary-bg"
                    onClick={() => setChangePassword(true)}
                  >
                    Change Password
                  </button>
                </div>
                <div className="profile-wrapper">
                  <Media className="dflex justify-content-center mr-5 pr-5">
                    <div className="avatar rounded-circle mr-3">
                      <img
                        alt="profile"
                        className="profile-img"
                        src={dataList?.profile_image}
                      />
                    </div>
                  </Media>
                  <ul className="list-unstyled pb-5 pt-3">
                    <li className="row profile-details">
                      <div className="col-6 color-primary weight-600">Name</div>
                      <div className="col-6">{`${dataList?.first_name} ${dataList?.last_name}`}</div>
                    </li>
                    <li className="row profile-details">
                      <div className="col-6 color-primary weight-600">
                        Designation
                      </div>
                      <div className="col-6">{designationList?.pdg_desc}</div>
                    </li>
                    <li className="row profile-details">
                      <div className="col-6 color-primary weight-600">
                        National ID
                      </div>
                      <div className="col-6">
                        {dataList?.national_id}
                        {dataList?.id_image && dataList?.id_image !== "" && (
                          <i
                            className="fas fa-eye color-primary cursor-pointer px-1"
                            onClick={viewImage}
                          ></i>
                        )}
                        <span
                          className={`${
                            dataList?.id_verified === "0"
                              ? "color-primary"
                              : "color-green"
                          } font-10 px-1`}
                        >
                          {dataList?.id_verified === "0"
                            ? "Not Verified"
                            : "Verified"}
                        </span>
                      </div>
                    </li>
                    <li className="row profile-details">
                      <div className="col-6 color-primary weight-600">
                        Mobile
                      </div>
                      <div className="col-6">
                        {dataList?.mobile}
                        <span
                          className={`${
                            dataList?.mobile_verified === "0"
                              ? "color-primary"
                              : "color-green"
                          } font-10 px-1`}
                        >
                          {dataList?.mobile_verified === "0"
                            ? "Not Verified"
                            : "Verified"}
                        </span>
                      </div>
                    </li>
                    <li className="row profile-details">
                      <div className="col-6 color-primary weight-600">
                        Email
                      </div>
                      <div className="col-6">
                        {dataList?.email}
                        <span
                          className={`${
                            dataList?.email_verified === "0"
                              ? "color-primary"
                              : "color-green"
                          } font-10 px-1`}
                        >
                          {dataList?.email_verified === "0"
                            ? "Not Verified"
                            : "Verified"}
                        </span>
                      </div>
                    </li>
                    <li className="row profile-details">
                      <div className="col-6 color-primary weight-600">
                        Company
                      </div>
                      <div className="col-6">
                        {dataList?.company && dataList?.company?.length > 0 && (
                          <span
                            onClick={() =>
                              viewCompany(dataList?.company, "Company List")
                            }
                            className="weight-600 cursor-pointer color-primary"
                          >
                            View All
                          </span>
                        )}
                      </div>
                    </li>
                    <li className="row profile-details">
                      <div className="col-6 color-primary weight-600">
                        Last Login
                      </div>
                      <div className="col-6">
                        {new Date(dataList?.last_login).toLocaleString(
                          "en-Us",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour12: true,
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }
                        )}
                      </div>
                    </li>
                    <li className="row profile-details">
                      <div className="col-6 color-primary weight-600">
                        Status
                      </div>
                      <div className="col-6">
                        {dataList?.status === "0" ? "Inactive" : "Active"}
                      </div>
                    </li>
                  </ul>
                </div>
              </Card>
            ) : null}
          </div>
        </Row>
      </Container>
      {openImageModal && (
        <ViewImageModal
          open={openImageModal}
          onCloseModal={closeImage}
          data={imageDate}
          imgType={imgType}
        />
      )}
      {openViewModal && (
        <ViewModal
          open={openViewModal}
          onCloseModal={closeViewCompany}
          data={formData}
          type={type}
        />
      )}
      {changePassword && (
        <ChangePasswordModal
          user_id={dataList?.user_id}
          open={changePassword}
          onCloseModal={handlePassword}
        />
      )}
    </>
  );
};

export default ProfileDetails;
