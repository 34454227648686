import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import { globalLoader } from "actions/commonActions";

import { logOut } from "_shared/commonFunctions";
import { getPopupDetails } from "_shared/commonFunctions";

import {
  updatePortalAuthorizationApi,
  cancelPortalAuthorizationApi,
  approvalAuthorizationApi,
  createPortalAuthorizationApi,
  sendNotificationApi,
} from "./_actions";

import UserHeader from "../../components/Headers/userHeader";
import AuthorizationTable from "../../components/tables/authorizationTable";
import AddAuthorizationModal from "components/Modals/addAuthorizationModal";
import AuthorizationStatusModal from "../../components/Modals/authorizationStatusModal";
import ViewImageModal from "components/Modals/viewImageModal";
import ReplyNotification from "../../components/Modals/replyNotificationModal";
import DriverDetailsModal from "../../components/Modals/driverDetailsModal";
import DetailsModal from "../../components/Modals/detailsModal";

import { useAuthorizationData } from "hooks/useAuthorizationData";
import { useStatusData } from "hooks/useStatusData";
import { useUserDropdownData } from "hooks/useAuthorizationData";

const AuthorizationManagement = (props) => {
  const { userRights, currentCompany } = props;
  const searchId = props?.location?.state?.searchId;
  const searchFilter = props?.location?.state?.searchFilter;

  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);
  const [openViewImage, setOpenViewImage] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [imgType, setImgType] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_9") {
        if (item?.create === "1") {
          setCanCreate(true);
        }
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: authorizationList,
    isFetched,
    refetch,
    isFetching,
  } = useAuthorizationData(currentCompany);

  globalLoader(isFetching);

  const { data: userDropdownList } = useUserDropdownData(9);

  const { data: status } = useStatusData(9);

  useEffect(() => {
    window.history.replaceState(null, "");
  }, []);

  const editData = (val) => {
    setIsEdit(true);
    setFormData(val);
    setOpenAuthModal(true);
  };

  const openAddAuthModal = () => {
    setOpenAuthModal(true);
  };

  const closeAuthModal = async (val = null) => {
    if (val) {
      globalLoader(true);
      try {
        let res;
        if (isEdit) {
          res = await updatePortalAuthorizationApi(val);
        } else {
          res = await createPortalAuthorizationApi(val);
        }
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
          refetch();
        } else {
          globalLoader(false);
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          logOut(props?.history, "/auth/login");
          globalLoader(false);
        } else {
          globalLoader(false);
        }
      }
    }
    setIsEdit(false);
    setFormData(null);
    setOpenAuthModal(false);
  };

  const viewImage = (obj = null, docType = null) => {
    if (obj) {
      let imgArr = [{ img_url: obj }];
      setImgData(imgArr);
      setImgType(docType);
      setOpenViewImage(true);
    }
  };

  const closeViewImage = () => {
    setImgData(null);
    setImgType(null);
    setOpenViewImage(false);
  };

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status, val = null) => {
    if (status) {
      globalLoader(true);
      try {
        let obj = {};
        let res;
        if (status === "1" || status === "5") {
          obj = { aut_id: formData?.aut_id, aut_status: status };
          res = await approvalAuthorizationApi(obj);
        }
        if (status === "2") {
          obj = {
            aut_id: formData?.aut_id,
            aut_userid: formData?.userid,
            aut_status: status,
            aut_reason: val?.aut_reason,
            aut_file_type: val?.aut_file_type,
            aut_file: val?.aut_file,
          };
          res = await approvalAuthorizationApi(obj);
        }
        if (status === "3") {
          obj.aut_id = formData?.aut_id;
          res = await cancelPortalAuthorizationApi(obj);
        }
        if (status === "4") {
          obj = {
            aut_id: formData?.aut_id,
            aut_userid: formData?.userid,
            aut_status: status,
            aut_reason: val?.aut_reason,
            aut_file_type: val?.aut_file_type,
            aut_file: val?.aut_file,
          };
          res = await approvalAuthorizationApi(obj);
        }
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        if (err?.response?.status === 401) {
          logOut(props?.history, "/auth/login");
          globalLoader(false);
        } else {
          globalLoader(false);
        }
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const customerModalOpen = (obj, type) => {
    if (obj) {
      setData(getPopupDetails(obj, type));
      setNationalId(obj?.national_id);
      setDetailsTitle(type);
      setUserModal(true);
    }
  };

  const customerModalClose = () => {
    setData(null);
    setUserModal(false);
    setDetailsTitle("");
  };

  const openNotificationModal = (obj) => {
    let notiData = {
      user_id: obj.userid,
      tag: obj?.company_name,
      type: "9",
      typeDesc: "Authorization",
    };
    setFormData(notiData);
    setNotificationModalOpen(true);
  };

  const closeNotificationModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      try {
        let res = await sendNotificationApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (err) {
        toast.error("Error while posting notification");
        console.log(err);
      }
      globalLoader(false);
      refetch();
    }
    setFormData(null);
    setNotificationModalOpen(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Authorization</h3>
                  {canCreate && (
                    <div className="col text-right">
                      <button
                        className="btn primary-bg btn-sm"
                        onClick={openAddAuthModal}
                      >
                        Add Authorization
                      </button>
                    </div>
                  )}
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    authorizationList && authorizationList.length > 0 ? (
                      <AuthorizationTable
                        statusList={status}
                        tableData={authorizationList}
                        viewImage={viewImage}
                        editData={editData}
                        updateStatus={openUpdateStatus}
                        canEdit={canEdit}
                        searchFilter={searchFilter ? searchFilter : null}
                        searchId={searchId ? searchId : null}
                        customerModalOpen={customerModalOpen}
                        sendNotification={openNotificationModal}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {openAuthModal && (
        <AddAuthorizationModal
          open={openAuthModal}
          onCloseModal={closeAuthModal}
          isEdit={isEdit}
          formData={formData}
          options={userDropdownList}
        />
      )}

      {openViewImage && (
        <ViewImageModal
          open={openViewImage}
          onCloseModal={closeViewImage}
          data={imgData}
          imgType={imgType}
        />
      )}
      {statusUpdate && (
        <AuthorizationStatusModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.aut_status}
        />
      )}
      {userModal && detailsTitle === "customer" && (
        <DriverDetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          formData={nationalId}
        />
      )}

      {userModal && detailsTitle === "company" && (
        <DetailsModal
          open={userModal}
          onCloseModal={customerModalClose}
          data={data}
          title={detailsTitle}
        />
      )}
      {/* {notificationModalOpen && (
        <AuthorizationNotification
          open={notificationModalOpen}
          onCloseModal={closeNotificationModal}
          formData={formData}
        />
      )} */}
      {notificationModalOpen && (
        <ReplyNotification
          open={notificationModalOpen}
          onCloseModal={closeNotificationModal}
          formData={formData}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(AuthorizationManagement);
