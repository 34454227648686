import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardHeader, Container, Row } from "reactstrap";

import { globalLoader } from "actions/commonActions";

import UserHeader from "../../components/Headers/userHeader";
import ChauffeurServiceTable from "../../components/tables/chauffeurServiceTable";

import {
  updateStatusApi,
  createChauffeurServiceApi,
  updateChauffeurServiceApi,
} from "./_actions";

import StatusUpdateModal from "components/Modals/statusUpdateModal";
import AddChauffeurServiceModal from "components/Modals/addChauffeurServiceModal";

import { useStatusData } from "hooks/useStatusData";
import { useChaufferServiceData } from "hooks/useChaufferData";

const ChauffeurService = (props) => {
  const { userRights, currentCompany } = props;
  const [canEdit, setCanEdit] = useState(false);

  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);
  const [canCreate, setCanCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [chauffeurServiceModalOpen, setChauffeurServiceModalOpen] =
    useState(false);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_1") {
        if (item?.create === "1") {
          setCanCreate(true);
        }
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  useEffect(() => {
    refetch();
  }, [currentCompany]);

  const {
    data: chauffers,
    isFetching,
    isFetched,
    refetch,
  } = useChaufferServiceData(currentCompany);

  globalLoader(isFetching);

  const { data: status } = useStatusData(13);

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "13",
        status: status,
        id: formData?.rate_type,
      };
      globalLoader(true);
      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        globalLoader(false);
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  const openChauffeurServiceModal = (obj = null) => {
    if (obj) {
      setIsEdit(true);
      setFormData(obj);
    }
    setChauffeurServiceModalOpen(true);
  };

  const closeChauffeurServiceModal = async (obj = null) => {
    if (obj) {
      globalLoader(true);
      let res;
      if (isEdit) {
        res = await updateChauffeurServiceApi(obj);
      } else {
        res = await createChauffeurServiceApi(obj);
      }
      if (res?.data?.status === 0) {
        toast.success(res?.data?.message);
        window.location.reload();
        refetch();
      } else {
        toast.error(res?.data?.message);
        globalLoader(false);
      }
    }
    setIsEdit(false);
    setFormData(null);
    setChauffeurServiceModalOpen(false);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Chauffeur Service</h3>
                  {canCreate && (
                    <div className="col text-right">
                      <button
                        className="btn btn-sm primary-bg"
                        onClick={(ev) => {
                          ev.preventDefault();
                          openChauffeurServiceModal();
                        }}
                        type="button"
                      >
                        Add Chauffeur Service
                      </button>
                    </div>
                  )}
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    chauffers && chauffers.length > 0 ? (
                      <ChauffeurServiceTable
                        tableData={chauffers}
                        updateStatus={openUpdateStatus}
                        statusList={status}
                        canEdit={canEdit}
                        updateTable={openChauffeurServiceModal}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.rate_status}
        />
      )}

      {chauffeurServiceModalOpen && (
        <AddChauffeurServiceModal
          open={chauffeurServiceModalOpen}
          onCloseModal={closeChauffeurServiceModal}
          data={formData}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(ChauffeurService);
