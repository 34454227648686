import React, { useEffect, useState, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form, Input, Label } from "reactstrap";
import { validNumber, validDecimalNumber } from "../../_shared/commonFunctions";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import StarRatings from "react-star-ratings";
import { toBase64 } from "../../actions/commonActions";
import { MAX_IMG_SIZE } from "../../actionTypes/types";
import { toast } from "react-toastify";
import { UPLOAD_IMAGE } from "actionTypes/types";

const ChauffeurRateModal = (props) => {
  const animatedComponents = makeAnimated();
  const { data, rateTypeList, bodyTypeList, yearList } = props;
  const [group, setGroup] = useState("");
  const [rate, setRate] = useState("");
  const [rateType, setRateType] = useState("");
  const [passenger, setPassenger] = useState("");
  const [year, setYear] = useState("");
  const [bodyId, setBodyId] = useState("");
  const [rating, setRating] = useState(0);
  const [imgLink, setImgLink] = useState(null);
  const [groupErr, setGroupErr] = useState(false);
  const [rateErr, setRateErr] = useState(false);
  const [rateTypeErr, setRateTypeErr] = useState(false);
  const [passengerErr, setPassengerErr] = useState(false);
  const [yearErr, setYearErr] = useState(false);
  const [bodyIdErr, setBodyIdErr] = useState(false);
  const [ratingErr, setRatingErr] = useState(false);
  const [imgLinkErr, setImgLinkErr] = useState(false);
  const [displayImg, setDisplayImg] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const imgRef = useRef();

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = () => {
    if (data) {
      setGroup(data?.chr_veh_group);
      setRate(data?.chr_rate);
      setRateType(
        rateTypeList.find((item) => item?.value === data?.char_rate_type_id)
      );
      setPassenger(data?.chr_passenger);
      setYear(yearList.find((item) => item?.value === data?.chr_year));
      setBodyId(bodyTypeList.find((item) => item?.value === data?.chr_bdy_id));
      setRating(data?.chr_rating);
      const img = data?.chr_bdy_img_link;
      setUploadedImage(img);
      setImgLink(img);
      setFileName(img.replace(/^.*[\\/]/, ""));
      setDisplayImg(img.replace(/^.*[\\/]/, "").replace(/\.[^/.]+$/, ""));
    }
  };

  const numberValidation = async (ev) => {
    let isValid;
    const caret = ev.target.selectionStart;
    const element = ev.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
    let { name, value } = ev.target;
    if (name === "rate") {
      isValid = await validDecimalNumber(value);
    } else {
      isValid = await validNumber(value);
    }
    if (isValid) {
      if (name === "rate") setRate(value);
      if (name === "passenger") setPassenger(value);
      if (name === "year") setYear(value);
    }
  };

  const handleImgUpload = async (ev) => {
    let file = ev.target.files[0];
    if (file?.size > MAX_IMG_SIZE) {
      toast.error("Upload file should be less than 1MB.");
    } else {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        const fileType = file?.name.split(/[#?]/)[0].split(".").pop().trim();
        const result = await toBase64(file).catch((e) => Error(e));
        if (result instanceof Error) {
          console.log("Error: ", result.message);
          return;
        }
        const randomName =
          Math.floor(Math.random() * (99999 - 10000 + 1)) + 9999;
        setFileName(`${randomName}.${fileType}`);
        setImgLink(result);
        setDisplayImg(randomName);
        setUploadedImage(URL.createObjectURL(file));
      } else {
        toast.error("Unsupported file type.");
      }
    }
  };

  const handleImageNameChange = (ev) => {
    const { value } = ev.target;
    setDisplayImg(value);
    const fileType = fileName.split(/[#?]/)[0].split(".").pop().trim();
    setFileName(`${value}.${fileType}`);
  };

  const handleValidation = () => {
    let flag = true;
    if (!group) {
      setGroupErr(true);
      flag = false;
    }
    if (!rate) {
      setRateErr(true);
      flag = false;
    }
    if (!rateType) {
      setRateTypeErr(true);
      flag = false;
    }
    if (!passenger) {
      setPassengerErr(true);
      flag = false;
    }
    if (!year) {
      setYearErr(true);
      flag = false;
    }
    if (!bodyId) {
      setBodyIdErr(true);
      flag = false;
    }
    if (!rating) {
      setRatingErr(true);
      flag = false;
    }
    if (!fileName) {
      setImgLinkErr(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (await handleValidation()) {
      const obj = {
        chr_veh_group: group,
        chr_rate: rate,
        chr_rate_type: rateType.value,
        chr_passenger: passenger,
        chr_year: year.value,
        chr_bdy_id: bodyId.value,
        chr_rating: rating.toString(),
        chr_image_link: imgLink,
        chr_image_filename: fileName,
      };
      if (data) {
        obj.chr_id = data?.chr_id;
        obj.chr_active = data?.chr_active || "1";
      }
      props?.onCloseModal(obj);
    }
  };

  return (
    <Modal
      open={props?.open}
      center
      onClose={() => props?.onCloseModal()}
      showCloseIcon={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      classNames={{ modal: "form-modal-own" }}
    >
      <div className="popup-header-bar">
        <label className="text-center weight-800">
          {data ? "Edit Chauffeur Rate" : "Add Chauffeur Rate"}
        </label>
      </div>
      <div className="px-3 pt-3">
        <Form>
          <div className="row">
            <div className="col-6">
              <div className="form-group-own">
                <Label for="group">
                  Group<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="group"
                  id="group"
                  placeholder="Group"
                  value={group}
                  onChange={(ev) => setGroup(ev.target.value)}
                  className={`${!group && groupErr && "on-focus"}`}
                />
                {!group && groupErr && (
                  <div className="error-text">Group is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="rate">
                  Rate<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="rate"
                  id="rate"
                  placeholder="Rate"
                  value={rate}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!rate && rateErr && "on-focus"}`}
                />
                {!rate && rateErr && (
                  <div className="error-text">Rate is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="rateType">
                  Tariff<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Select Tariff"
                  options={rateTypeList}
                  onChange={(ev) => setRateType(ev)}
                  value={rateType}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !rateType && rateTypeErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!rateType && rateTypeErr && (
                  <div className="error-text">Tariff is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="passenger">
                  Passenger<span className="color-primary">*</span>
                </Label>
                <Input
                  type="text"
                  name="passenger"
                  id="passenger"
                  placeholder="Passenger"
                  value={passenger}
                  onChange={(ev) => numberValidation(ev)}
                  className={`${!passenger && passengerErr && "on-focus"}`}
                />
                {!passenger && passengerErr && (
                  <div className="error-text">Passenger is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="year">
                  Select Year<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Select Year"
                  options={yearList}
                  onChange={(ev) => setYear(ev)}
                  value={year}
                  menuPlacement={"top"}
                  isSearchable={true}
                  isClearable={true}
                  className={`select-dropdown-own ${
                    !year && year && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!year && yearErr && (
                  <div className="error-text">Year is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="bodyId">
                  Body Type<span className="color-primary">*</span>
                </Label>
                <Select
                  placeholder="Body Type"
                  options={bodyTypeList}
                  onChange={(ev) => setBodyId(ev)}
                  value={bodyId}
                  isSearchable={true}
                  isClearable={true}
                  menuPlacement={"top"}
                  className={`select-dropdown-own ${
                    !bodyId && bodyIdErr && "on-focus"
                  }`}
                  components={animatedComponents}
                />
                {!bodyId && bodyIdErr && (
                  <div className="error-text">Body Type is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="rating">
                  Rating<span className="color-primary">*</span>
                </Label>
                <div>
                  <StarRatings
                    id="rating"
                    rating={parseInt(rating)}
                    starRatedColor="rgb(212,0,42)"
                    changeRating={(val) => setRating(val)}
                    numberOfStars={5}
                    name="rating"
                    starDimension="25px"
                  />
                </div>
                {!rating && ratingErr && (
                  <div className="error-text mt-3">Rating is required</div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group-own">
                <Label for="imgLink">
                  Upload Image (jpg/jpeg/png)
                  <span className="color-primary">*</span>
                </Label>
                <div className="modal-image-wrapper">
                  <div
                    className="placeholder"
                    onClick={(ev) => {
                      ev.preventDefault();
                      imgRef.current.click();
                    }}
                  >
                    <img
                      src={uploadedImage ? uploadedImage : UPLOAD_IMAGE}
                      alt="chauffeur"
                    />
                  </div>
                  <div className="uploader">
                    <input
                      ref={imgRef}
                      type="file"
                      name="file"
                      id="file"
                      className="form-own-input"
                      accept=".png,.jpg,.jpeg,.pdf"
                      onChange={(ev) => handleImgUpload(ev)}
                    />
                    <input
                      type="text"
                      name="file_name"
                      id="file_name"
                      className={`form-own-input-filename form-control ${
                        !fileName && imgLinkErr && "on-focus"
                      }`}
                      value={displayImg ? displayImg : ""}
                      onChange={handleImageNameChange}
                    />
                    <button
                      className="btn primary-bg file-upload-btn"
                      onClick={(ev) => {
                        ev.preventDefault();
                        imgRef.current.click();
                      }}
                    >
                      {!fileName ? "Upload" : "Change"}
                    </button>
                  </div>
                </div>
                {!fileName && imgLinkErr && (
                  <div className="error-text">Image is required</div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center my-3 pt-4 col-12">
              <button
                className="btn grey-bg"
                onClick={() => props?.onCloseModal()}
              >
                Cancel
              </button>
              <button className="btn primary-bg" onClick={handleSubmit}>
                {data ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ChauffeurRateModal;
