import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { getRegionsApi } from "../views/examples/_actions";

export const useRegionsData = () => {
  let profile_email = Cookies.get("profile_email");
  return useQuery(["region-list", profile_email], () => getRegionsApi(), {
    retry: 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      let regions = [];
      data?.data?.data.forEach((region) => {
        let newRegion = {
          value: region?.region_id,
          label: region?.region_desc,
        };
        regions.push(newRegion);
      });
      return regions;
    },
  });
};
