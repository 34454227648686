import { dateOptions } from "actionTypes/types";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";
import React, { useMemo, useState } from "react";
import { SelectColumnFilter, DateRangeColumnFilter } from "./_filters";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import TableContainer from "./_table";

const CarReplacementTable = (props) => {
  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);

  const [formData, setFormData] = useState(null);

  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "action",
        Cell: (cell) => (
          <div className="text-left">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v color-primary" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  onClick={() => props?.assignUsers(cell?.row?.original)}
                >
                  Assign Users
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "Car Type",
        accessor: "record_type",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Company Code",
        accessor: "cust_code",
      },
      {
        Header: "Plate Number",
        accessor: "plate_no",
        Cell: ({ row }) => (
          <span className="color-primary cursor-pointer">
            {row?.original?.plate_no}
          </span>
        ),
      },
      {
        Header: "Branch",
        accessor: "branch_desc",
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.branch_desc}</div>
        ),
        disableSortBy: true,
      },
      {
        Header: "Reservation Number",
        accessor: "reservation_no",
      },
      {
        Header: "Start date",
        accessor: "start_date",
        Cell: ({ row }) =>
          new Date(row?.original?.start_date).toLocaleString("en-Us", {
            day: "numeric",
            year: "numeric",
            month: "short",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "End date",
        accessor: "end_date",
        Cell: ({ row }) =>
          row?.original?.end_date &&
          new Date(row?.original?.end_date).toLocaleString(
            "en-Us",
            dateOptions
          ),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Status",
        accessor: "replace_status",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
    ],
    []
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);

  return (
    <>
      <TableContainer
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (data?.column?.id === "plate_no") {
              openVehicleDetails(data?.row?.original);
            }
          },
        })}
      />
      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          formData={formData}
          plateNo={formData?.plate_no}
        />
      )}
    </>
  );
};

export default CarReplacementTable;
