import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import { logOut } from "_shared/commonFunctions";
import StatusUpdateModal from "components/Modals/statusUpdateModal";
import { updateStatusApi } from "./_actions";
import DesignationTable from "../../components/tables/designationTable";
import { globalLoader } from "actions/commonActions";
import UserHeader from "../../components/Headers/userHeader";

import { useStatusData } from "hooks/useStatusData";
import { useDesignationsListData } from "hooks/useDesignationsData";

const Designation = (props) => {
  const { userRights } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [formData, setFormData] = useState(null);

  const { data: status } = useStatusData(17);

  const {
    data: designationsList,
    isFetching,
    isFetched,
    refetch,
  } = useDesignationsListData();

  globalLoader(isFetching);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_18") {
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);

  const openUpdateStatus = (obj) => {
    setFormData(obj);
    setStatusUpdate(true);
  };

  const closeUpdateStatus = async (status) => {
    if (status) {
      let obj = {
        type: "22",
        status: status,
        id: formData?.pdg_id,
      };

      try {
        let res = await updateStatusApi(obj);
        if (res?.data?.status === 0) {
          toast.success(res?.data?.message);
        } else {
        }
        refetch();
      } catch (err) {
        logOut(props?.history, "auth/login");
      }
    }
    setStatusUpdate(false);
    setFormData(null);
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <h3 className="mb-0 color-primary">Designation</h3>
                </Row>
              </CardHeader>
              {!isFetching && (
                <div>
                  {isFetched ? (
                    designationsList && designationsList.length > 0 ? (
                      <DesignationTable
                        statusList={status}
                        tableData={designationsList}
                        updateStatus={openUpdateStatus}
                        canEdit={canEdit}
                      />
                    ) : (
                      <div className="px-3 pt-3 pb-3">
                        <div className="no-data-available color-primary">
                          Data Not Available
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {statusUpdate && (
        <StatusUpdateModal
          open={statusUpdate}
          onCloseModal={closeUpdateStatus}
          statusList={status}
          formData={formData?.pdg_status}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  currentCompany: state?.common?.currentCompany?.value,
  userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(Designation);
