import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import _ from "lodash";

import UserHeader from "../../components/Headers/userHeader";
import TransactionTable from "../../components/tables/transactionTable";
import { globalLoader } from "actions/commonActions";

import {
	updateStatusApi,
	sendNotificationApi,
	downloadInvoiceDoc,
} from "./_actions";

import ReplyNotification from "../../components/Modals/replyNotificationModal";
import AddTransactionModal from "components/Modals/addTransactionModal";
import StatusUpdateModal from "components/Modals/statusUpdateModal";
import AddInvoiceReceiptModal from "components/Modals/addInvoiceReceiptModal";

import { useStatusData } from "hooks/useStatusData";
import { useInvoiceData } from "hooks/useInvoiceData";

import { downloadExcel } from "utils/exportUtils";
import { downloadFile } from "actions/commonActions";

const InvoiceManagement = (props) => {
	const { userRights, currentCompany } = props;
	const [openTransactionModal, setOpenTransactionModal] = useState(false);
	const [statusUpdate, setStatusUpdate] = useState(false);
	const [formData, setFormData] = useState(null);
	const [canEdit, setCanEdit] = useState(false);
	const [notificationModalOpen, setNotificationModalOpen] = useState(false);
	const [openReceiptModal, setOpenReceiptModal] = useState(false);
	const [selectedInvoices, setSelectedInvoices] = useState([]);

	useEffect(() => {
		userRights?.forEach((item) => {
			if (item?.menu_type_id === "module_1") {
				if (item?.update === "1") {
					setCanEdit(true);
				}
			}
		});
	}, [userRights]);

	useEffect(() => {
		refetch();
	}, [currentCompany]);

	const {
		data: invoices,
		isFetching,
		isFetched,
		refetch,
	} = useInvoiceData(currentCompany);

	globalLoader(isFetching);

	const { data: status } = useStatusData(7);

	const closeTransactionModal = (obj = null) => {
		if (obj) {
		}
		setOpenTransactionModal(false);
	};

	const openUpdateStatus = (obj) => {
		setFormData(obj);
		setStatusUpdate(true);
	};

	const closeUpdateStatus = async (status) => {
		if (status) {
			let obj = {
				type: "7",
				status: status,
				id: formData?.bil_id,
			};
			globalLoader(true);
			try {
				let res = await updateStatusApi(obj);
				if (res?.data?.status === 0) {
					toast.success(res?.data?.message);
				} else {
				}
				refetch();
			} catch (err) {
				globalLoader(false);
			}
		}
		setStatusUpdate(false);
		setFormData(null);
	};

	const openNotificationModal = (obj) => {
		let notiData = {
			user_id: obj.bil_userid,
			tag: obj?.company_name,
			type: "7",
			typeDesc: "Transaction",
		};
		setFormData(notiData);
		setNotificationModalOpen(true);
	};

	const closeNotificationModal = async (obj = null) => {
		if (obj) {
			globalLoader(true);
			try {
				let res = await sendNotificationApi(obj);
				if (res?.data?.status === 0) {
					toast.success(res?.data?.message);
				} else {
					toast.error(res?.data?.message);
				}
			} catch (err) {
				toast.error("Error while posting notification");
				console.log(err);
			}
			globalLoader(false);
			refetch();
		}
		setFormData(null);
		setNotificationModalOpen(false);
	};

	const openUploadReceiptModal = () => {
		setOpenReceiptModal(!openReceiptModal);
	};

	let transactions = [];

	const transactionSelectionHandler = (transaction, selected) => {
		if (selected) {
			transactions.push(transaction);
		} else {
			_.remove(transactions, {
				bil_id: transaction.bil_id,
			});
		}
		setSelectedInvoices(transactions);
	};

	const downloadExcelHandler = () => {
		downloadExcel(invoices, "transactions");
	};

	const openInvoiceImage = async (val, _type) => {
		try {
			const res = await downloadInvoiceDoc(val);
			const fileUrl = res?.data.data[0].doc_url;
			if (fileUrl) {
				downloadFile(fileUrl, `${val}.pdf`);
			} else {
				toast.error("Invoice File not found");
			}
		} catch (error) {
			console.error("Error downloading invoice:", error);
			toast.error("Failed to download invoice");
		}
	};

	return (
		<>
			<UserHeader />
			<Container className="mt--8" fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-0">
								<Row className="align-items-center">
									<h3 className="mb-0 color-primary">Transactions</h3>

									<div className="col text-right">
										<button
											className="btn btn-sm primary-bg"
											onClick={downloadExcelHandler}
										>
											Download as Excel
										</button>

										{selectedInvoices && selectedInvoices.length > 0 && (
											<button
												className="btn primary-bg btn-sm"
												onClick={openUploadReceiptModal}
											>
												Add Receipt
											</button>
										)}
									</div>
								</Row>
							</CardHeader>
							{!isFetching && (
								<div>
									{isFetched ? (
										invoices && invoices.length > 0 ? (
											<TransactionTable
												statusList={status}
												tableData={invoices}
												canEdit={canEdit}
												updateStatus={openUpdateStatus}
												sendNotification={openNotificationModal}
												onTransactionSelect={transactionSelectionHandler}
												openImage={openInvoiceImage}
											/>
										) : (
											<div className="px-3 pt-3 pb-3">
												<div className="no-data-available color-primary">
													Data Not Available
												</div>
											</div>
										)
									) : null}
								</div>
							)}
						</Card>
					</div>
				</Row>
			</Container>
			{openTransactionModal && (
				<AddTransactionModal
					open={openTransactionModal}
					onCloseModal={closeTransactionModal}
				/>
			)}
			{statusUpdate && (
				<StatusUpdateModal
					open={statusUpdate}
					statusList={status}
					formData={formData?.bil_statusid}
					onCloseModal={closeUpdateStatus}
				/>
			)}
			{notificationModalOpen && (
				<ReplyNotification
					open={notificationModalOpen}
					formData={formData}
					onCloseModal={closeNotificationModal}
				/>
			)}
			{openReceiptModal && (
				<AddInvoiceReceiptModal
					open={openReceiptModal}
					selectedInvoices={selectedInvoices}
					statusList={status}
					onCloseModal={() => {
						setSelectedInvoices([]);
						setOpenReceiptModal(!openReceiptModal);
					}}
				/>
			)}
		</>
	);
};

const mapStateProps = (state) => ({
	currentCompany: state?.common?.currentCompany?.value,
	userRights: state?.common?.roleData?.user_rights,
});

export default connect(mapStateProps)(InvoiceManagement);
