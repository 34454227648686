import React, { Fragment, useState, useEffect } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
import { Table, Input } from 'reactstrap';
import { DefaultColumnFilter, fuzzyTextFilterFn, Filter } from './_filters';
import _ from 'lodash';
import { validDate } from '_shared/commonFunctions';
import { toast } from 'react-toastify';
import { tableDataColumn } from './_tableColumn';
// import { tableHeadColumn, tableDataColumn } from "./_tableColumn";

const defaultPropGetter = () => ({});

const dateBetweenFilterFn = (rows, id, filterValues) => {
  let sd = new Date(filterValues[0]);
  // sd = new Date(sd.setDate(sd.getDate() - 1));
  let temp = new Date(filterValues[1]);
  let ed = new Date(
    temp.getFullYear(),
    temp.getMonth(),
    temp.getDate(),
    23,
    59,
    59
  );
  if (validDate(ed) && validDate(sd)) {
    if (ed < sd) {
      toast.error('To date greater than from');
      return rows;
    } else {
      let filteredDate = rows.filter((row) => {
        let time = new Date(row?.values[id]);
        return time >= sd && time <= ed;
      });
      return filteredDate;
    }
  } else {
    return rows;
  }
};

dateBetweenFilterFn.autoRemove = (val) => !val;

const TableContainer = ({
  showHeader,
  columns,
  data,
  renderRowSubComponent,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  searchFilter,
  searchId,
  dropdownFilter,
  dropdownId,
  state,
  highlitedIDs,
  highlightNewMessage,
}) => {
  const [initialState, setIntitialState] = useState({
    pageIndex: 0,
    pageSize: 20,
    filters:
      searchId === 'maint_plate' ||
      searchId === 'rsa_service_id' ||
      searchId === 'aut_status' ||
      searchId === 'com_status' ||
      searchId === 'acc_status' ||
      searchId === 'dmg_status' ||
      searchId === 'maint_status' ||
      searchId === 'rent_staus' ||
      searchId === 'cha_status' ||
      searchId === 'rac_source'
        ? [
            {
              id: searchId,
              value: searchFilter,
            },
          ]
        : [],
  });

  if (data && data[0] == null) data = data.slice(1);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      dateBetween: dateBetweenFilterFn /*<- LIKE THIS*/,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  if (state) {
    // initialState = state;
    // setIntitialState(state);
  }
  useEffect(() => {
    if (searchId)
      setIntitialState({
        ...initialState,
        filters: [
          {
            id: searchId,
            value: searchFilter,
          },
        ],
      });
  }, [searchId]);

  useEffect(() => {
    if (dropdownId && dropdownId === 'maint_status') {
      setIntitialState({
        ...initialState,
        filters: [
          ...initialState.filters,
          {
            id: dropdownId,
            value: dropdownFilter,
          },
        ],
      });
    }
  }, [dropdownId]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        ...initialState,
        filters:
          window.location.pathname == '/admin/rent_request' &&
          data.some((row) => {
            return row['rent_staus'] == '1';
          })
            ? [{ id: 'rent_staus', value: '1' }]
            : [...initialState.filters],
      },
      filterTypes,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  let totalCount = rows?.length;

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <i className="fas fa-sort-down color-primary"></i>
      ) : (
        <i className="fas fa-sort-up color-primary"></i>
      )
    ) : (
      <i className="fas fa-sort-down color-primary invisible"></i>
    );
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      {showHeader !== false && (
        <div className="d-flex justify-content-between">
          <div className="pl-2 weight-600">
            Total Records: <span className="color-primary">{totalCount}</span>
          </div>
          <div className="pagination-own">
            <div className="pagination-item mr-3">
              <button
                className="btn btn-sm primary-bg"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </button>
              <button
                className="btn btn-sm primary-bg"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {'<'}
              </button>
            </div>
            <div className="pagination-item">
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </div>
            <div className="pagination-item mx-3">
              <Input
                type="select"
                // type="number"
                // min={1}
                // max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
                className="dropdown-own"
              >
                {_.range(0, pageOptions.length).map((val) => (
                  <option key={val} value={val + 1}>
                    {val + 1}
                  </option>
                ))}
              </Input>
            </div>
            <div className="pagination-item mx-3">
              <Input
                type="select"
                value={pageSize}
                onChange={onChangeInSelect}
                className="dropdown-own"
              >
                {[20, 40, 60, 80, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Input>
            </div>
            <div className="pagination-item ">
              <button
                className="btn btn-sm primary-bg"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {'>'}
              </button>
              <button
                className="btn btn-sm primary-bg"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="table-responsive table-own-wrapper">
        <Table
          className="align-items-center table-hover table-flush pl-1 table-own table"
          {...getTableProps()}
        >
          <thead
            className={`thead-light ${
              columns.length <= 4 && 'table-head-small'
            }`}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="table-header-wrapper"
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      style={
                        column?.id === 'dmg_datetime' ||
                        column?.id === 'cha_startdate_time' ||
                        column?.id === 'cha_enddate_time' ||
                        column?.id === 'start_date' ||
                        column?.id === 'end_date' ||
                        column?.id === 'rsa_requested_date' ||
                        column?.id === 'acc_datetime' ||
                        column?.id === 'aut_start_datetime' ||
                        column?.id === 'aut_end_datetime' ||
                        column?.id === 'aut_req_datetime' ||
                        column?.id === 'com_datetime' ||
                        column?.id === 'dmg_datetime' ||
                        column?.id === 'bil_datetime' ||
                        column?.id === 'create_date' ||
                        column?.id === 'prefered_date_time' ||
                        column?.id === 'schedule_date_time' ||
                        column?.id === 'from_dt' ||
                        column?.id === 'to_dt' ||
                        column?.id === 'nti_datetime'
                          ? { width: '210px' }
                          : { maxWidth: '180px' }
                      }
                      className={`table-header px-1 `}
                      // className={`table-header px-1 ${tableHeadColumn(column)}`}
                      {...column.getHeaderProps([
                        {
                          className: column.className,
                          style: column.style,
                        },
                        getColumnProps(column),
                        getHeaderProps(column),
                      ])}
                    >
                      <div
                        className={
                          column.rightAlign
                            ? 'table-header-content align-items-end'
                            : 'table-header-content'
                        }
                      >
                        <div
                          {...column.getSortByToggleProps()}
                          className="table-header-label"
                        >
                          {column.render('Header')}
                          {generateSortingIndicator(column)}
                        </div>
                        <Filter column={column} />
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className={` ${columns.length <= 4 && 'table-body-small'}`}
          >
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell, i) => {
                      let notificationCount = 0;
                      if (highlitedIDs && highlitedIDs.length)
                        notificationCount = highlitedIDs.find(
                          (item) => item.id === cell.row.original.booking_id
                        )?.count;

                      return (
                        <td
                          style={{ padding: '0.5rem' }}
                          className={`${tableDataColumn(cell)} td-own px-1`}
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          {' '}
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {cell.render('Cell')}
                            {highlightNewMessage &&
                              cell.column.id == 'action' &&
                              notificationCount > 0 && (
                                <div
                                  style={{
                                    marginRight: '10px',
                                    marginBottom: '15px',
                                    marginLeft: '-7px',
                                    position: 'relative',
                                  }}
                                >
                                  <img
                                    src="../../assets/unread-message.svg"
                                    height="20"
                                    width="20"
                                    alt="badge"
                                    style={{
                                      marginRight: '0px',
                                      filter:
                                        'invert(29%) sepia(56%) saturate(3174%) hue-rotate(93deg) brightness(96%) contrast(73%)',
                                      position: 'absolute',
                                      zIndex: '1',
                                    }}
                                  />
                                  <span
                                    style={{
                                      zIndex: '2',
                                      position: 'absolute',
                                      paddingLeft:
                                        notificationCount < 10 ? '6px' : '3px',
                                      fontWeight: '600',
                                      color: 'white',
                                      marginTop: '0px',
                                      fontSize: '11px',
                                    }}
                                  >
                                    {notificationCount}
                                  </span>
                                </div>
                                // <span
                                //   className="button-badge"
                                //   style={{ marginLeft: "-6px" }}
                                // >
                                //   {
                                //     highlitedIDs.find(
                                //       (item) =>
                                //         item.id === cell.row.original.booking_id
                                //     ).count
                                //   }
                                // </span>
                              )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent(row)}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default TableContainer;
