import React, { useState } from "react";
import TableContainer from "./_table";
import VehicleDetailsModal from "components/Modals/vehicleDetailsModal";
import DriverDetailsModal from "components/Modals/driverDetailsModal";
import ContractDetailsModal from "components/Modals/contractDetailsModal";
import { DateRangeColumnFilter } from "./_filters";

const VehicleContractDriverTable = (props) => {
  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const [contractDetailsModal, setContractDetailsModal] = useState(false);
  const [driverDetailsModal, setDriverDetailsModal] = useState(false);
  const [formData, setFormData] = useState(null);

  const sortItems = (prev, curr, columnId) => {
    if (
      prev.original[columnId].trim().toLowerCase() >
      curr.original[columnId].trim().toLowerCase()
    ) {
      return 1;
    } else if (
      prev.original[columnId].trim().toLowerCase() <
      curr.original[columnId].trim().toLowerCase()
    ) {
      return -1;
    } else {
      return 0;
    }
  };
  const openVehicleDetails = (obj) => {
    setFormData(obj);
    setVehicleDetailsModal(true);
  };

  const closeVehicleDetails = () => {
    setFormData(null);
    setVehicleDetailsModal(false);
  };
  const openContractDetails = (obj) => {
    setFormData(obj);
    setContractDetailsModal(true);
  };

  const closeContractDetails = () => {
    setFormData(null);
    setContractDetailsModal(false);
  };
  const openDriverDetails = (obj) => {
    setFormData(obj);
    setDriverDetailsModal(true);
  };

  const closeDriverDetails = () => {
    setFormData(null);
    setDriverDetailsModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Plate No",
        accessor: "plate_no",
        Cell: ({ row }) => (
          <span className="color-primary cursor-pointer">
            {row?.original?.plate_no}
          </span>
        ),
      },
      {
        Header: "Contract No",
        accessor: "con_no",
        Cell: ({ row }) => (
          <span className="color-primary cursor-pointer">
            {row?.original?.con_no}
          </span>
        ),
      },
      {
        Header: "Driver ID",
        accessor: "id_no",
        Cell: ({ row }) => (
          <span className="color-primary cursor-pointer">
            {row?.original?.id_no}
          </span>
        ),
      },
      {
        Header: "Type",
        accessor: "cust_type",
        disableFilters: true,
      },
      {
        Header: "code",
        accessor: "cust_code",
      },
      {
        Header: "Company Name",
        accessor: "cust_name",
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.cust_name}</div>
        ),
      },
      {
        Header: "Start date",
        accessor: "from_dt",
        Cell: ({ row }) =>
          new Date(row?.original?.from_dt).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "End date",
        accessor: "to_dt",
        Cell: ({ row }) =>
          new Date(row?.original?.to_dt).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Driver Name",
        accessor: "name",
        Cell: ({ row }) => (
          <div className="table-text">{row?.original?.name.trim()}</div>
        ),
        disableFilters: true,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },

      {
        Header: "Mobile",
        accessor: "mobile",
        disableFilters: true,
      },
      {
        Header: "Nationality",
        accessor: "nationality",
        disableFilters: true,
      },
      {
        Header: "Status ",
        accessor: "contract_status_desc",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "COST CENTRE (PO)",
        accessor: "cost_centre",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
      },
    ], // eslint-disable-next-line
    []
  ); // eslint-disable-next-line
  const data = React.useMemo(() => props?.tableData, []);
  return (
    <>
      <TableContainer
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (data?.column?.id === "plate_no") {
              openVehicleDetails(data?.row?.original);
            }
            if (data?.column?.id === "con_no") {
              openContractDetails(data?.row?.original);
            }
            if (data?.column?.id === "id_no") {
              openDriverDetails(data?.row?.original);
            }
          },
        })}
      />
      {vehicleDetailsModal && (
        <VehicleDetailsModal
          open={vehicleDetailsModal}
          onCloseModal={closeVehicleDetails}
          plateNo={formData?.plate_no}
        />
      )}
      {driverDetailsModal && (
        <DriverDetailsModal
          open={driverDetailsModal}
          onCloseModal={closeDriverDetails}
          formData={formData?.id_no}
          isMobile={true}
        />
      )}
      {contractDetailsModal && (
        <ContractDetailsModal
          open={contractDetailsModal}
          type="fleet"
          onCloseModal={closeContractDetails}
          formData={formData}
        />
      )}
    </>
  );
};

export default VehicleContractDriverTable;
