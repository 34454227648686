import React, { useMemo, useState } from "react";
import TableContainer from "./_table";
import StarRatings from "react-star-ratings";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { SelectColumnFilter } from "./_filters";
import { DateRangeColumnFilter } from "./_filters";
import Modal from "react-responsive-modal";
import { SelectFilter } from "./_filters";
import { tableDropdownData } from "_shared/commonFunctions";

const ChauffeurTable = (props) => {
  const [openDestinationModal, setOpenDestinationModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { searchId, searchFilter, statusList } = props;
  const handleDestination = (obj) => {
    setModalData(obj);
    setOpenDestinationModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "dummy",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div className="color-primary">Actions</div>,
        accessor: "action",
        Cell: (cell) =>
          props?.canEdit && (
            <div className="text-left">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v color-primary" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  <DropdownItem
                    onClick={() => props?.updateStatus(cell?.row?.original)}
                  >
                    Update Status
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "cha_status",
        Cell: ({ row }) =>
          tableDropdownData(row?.original?.cha_status, statusList),
        Filter: (props) => SelectFilter(props, statusList),
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Type",
        accessor: "cha_city_kingdon",
        Cell: ({ row }) =>
          row?.original?.cha_city_kingdon === "0" ? "City" : "Within Kingdom",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Company",
        accessor: "company_code",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.company_code === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.company_code !== "N/A" &&
              props?.customerModalOpen(row?.original, "company")
            }
          >
            {row?.original?.company_code}
          </div>
        ),
      },
      {
        Header: "User",
        accessor: "national_id",
        Cell: ({ row }) => (
          <div
            className={`color-primary ${
              row?.original?.national_id === "N/A" ? "" : "cursor-pointer"
            }`}
            onClick={() =>
              row?.original?.national_id !== "N/A" &&
              props?.customerModalOpen(row?.original, "customer")
            }
          >
            {row?.original?.national_id}
          </div>
        ),
      },
      {
        Header: "Start Date",
        accessor: "cha_startdate_time",
        Cell: ({ row }) =>
          new Date(row?.original?.cha_startdate_time).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "End Date",
        accessor: "cha_enddate_time",
        Cell: ({ row }) =>
          new Date(row?.original?.cha_enddate_time).toLocaleString("en-Us", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          }),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
        sortType: (rowA, rowB, id) => {
          if (new Date(rowA?.original[id]) > new Date(rowB?.original[id]))
            return 1;
          if (new Date(rowA?.original[id]) < new Date(rowB?.original[id]))
            return -1;
          return 0;
        },
      },
      {
        Header: "Service Type",
        accessor: "chr_rate_type_id",
        Cell: ({ row }) =>
          row?.original?.chr_rate_type_id === "1"
            ? "Hourly/Airport"
            : row?.original?.chr_rate_type_id === "2"
            ? "6 Hours"
            : row?.original?.chr_rate_type_id === "3"
            ? "12 Hours"
            : row?.original?.chr_rate_type_id === "4"
            ? "16 Hours"
            : row?.original?.chr_rate_type_id === "5"
            ? "Monthly"
            : "Annual",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: "Pickup",
        accessor: "cha_start_location",
        Cell: ({ row }) => (
          <span className="table-text">
            {row?.original?.cha_start_location}
          </span>
        ),
        disableFilters: true,
      },
      {
        Header: "Destination",
        accessor: "cha_destinaton",
        Cell: ({ row }) => {
          const options = row?.original?.cha_destination;
          return (
            <>
              <div className="table-text">
                {options && <div>{options[0]?.cha_end_location}</div>}
              </div>
              {options.length > 1 && (
                <span
                  className="font-12 color-primary cursor-pointer"
                  onClick={() => handleDestination(options)}
                >
                  View More
                </span>
              )}
            </>
          );
        },
        disableFilters: true,
        sortType: (rowA, rowB) => {
          let valA = rowA?.original?.cha_destination[0]?.cha_end_location
            .toLowerCase()
            .trim();
          let valB = rowB?.original?.cha_destination[0]?.cha_end_location
            .toLowerCase()
            .trim();
          if (valA > valB) return 1;
          if (valA < valB) return -1;

          return 0;
        },
      },
      {
        Header: "Car Type",
        accessor: "chr_bdy_desc",
      },
      {
        Header: "Chauffeur Rating",
        accessor: "cha_rating",
        Cell: ({ row }) =>
          row?.original?.cha_rating !== "" && (
            <StarRatings
              rating={parseInt(row?.original?.cha_rating)}
              starRatedColor="rgb(212,0,42)"
              starDimension="10px"
              starSpacing="1px"
            />
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "User Rating",
        accessor: "cha_user_rating",
        Cell: ({ row }) =>
          row?.original?.cha_user_rating !== "" && (
            <StarRatings
              rating={parseInt(row?.original?.cha_user_rating)}
              starRatedColor="rgb(212,0,42)"
              starDimension="10px"
              starSpacing="1px"
            />
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Rate",
        accessor: "chr_rate",
        rightAlign: true,
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.chr_rate}</span>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Unit",
        accessor: "cha_unit",
        rightAlign: true,
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.cha_unit}</span>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Total",
        accessor: "cha_selected_rate",
        rightAlign: true,
        Cell: ({ row }) => (
          <div className="text-right w-100">
            <span>{row?.original?.cha_selected_rate}</span>
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: "Approval",
        accessor: "cha_doc_img_url",
        Cell: ({ row }) =>
          row?.original?.cha_doc_img_url !== "" && (
            <div className="d-flex justify-content-center">
              <i className="fas fa-eye color-primary cursor-pointer"></i>
            </div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ID",
        accessor: "cha_id",
      },
    ],
    // eslint-disable-next-line
    [props?.canEdit, statusList]
  );
  // eslint-disable-next-line
  const data = useMemo(() => props?.tableData, []);

  return (
    <>
      <TableContainer
        searchFilter={searchFilter}
        searchId={searchId}
        columns={columns}
        data={data}
        getCellProps={(data) => ({
          onClick: () => {
            if (
              data?.column?.id === "cha_doc_img_url" &&
              data?.row?.original?.cha_doc_img_url !== ""
            ) {
              let obj = data?.row?.original?.cha_doc_img_url;
              props?.viewImage(obj, "Approval Letter");
            }
          },
        })}
      />
      {openDestinationModal && (
        <Modal
          open={openDestinationModal}
          onClose={() => {
            setOpenDestinationModal(false);
            setModalData(null);
          }}
          center
          showCloseIcon={true}
          closeOnEsc={true}
          closeOnOverlayClick={true}
          classNames={{ modal: "form-modal-own" }}
        >
          <div className="row p-5">
            <div className="col-12 text-center">
              <h3 className="color-primary ">Destination</h3>
            </div>
            <div className=" col-12 ">
              <ul className="list-unstyled text-center">
                {modalData &&
                  modalData.length > 0 &&
                  modalData.map((data, i) => (
                    <li key={i}>{data?.cha_end_location}</li>
                  ))}
              </ul>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ChauffeurTable;
